import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';

import { TopTriangleIcon } from 'src/assets/icons/arrows/index';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import SortingForPopup from './SortingForPopup';
import { IDashboardSort } from 'src/store/localStorage/forms';
import { useTranslation } from 'src/i18n/useTranslation';
export interface ISortItem {
  key: string,
  short: string,
  full: string,
  icon?: ReactNode,
  displayIcon?: ReactNode
}

const ArrowBottomIcon = styled(TopTriangleIcon)` transform: scaleY(-1); `

const orderItems: ISortItem[] = [
  //{key: 'none', short: 'Без сортировки', full: 'Без сортировки', icon: <FilterClearIcon/>, displayIcon: <ArrowBottomIcon/>},
  {key: 'desc', short: "chronos.mobile.components.sorting.descending.short", full: "chronos.app.dashboard.sortDown", icon: <ArrowBottomIcon/>, displayIcon: <ArrowBottomIcon/>},
  {key: 'asc', short: "chronos.mobile.components.sorting.ascending.short", full: "chronos.app.dashboard.sortUp", icon: <TopTriangleIcon/>, displayIcon: <TopTriangleIcon/>},
]

const fieldItems = [
  {key: 'name', short: "chronos.app.components.editForm.name", full: "chronos.mobile.components.sorting.fieldItems.full"},
  {key: 'place', short: "chronos.mobile.components.sorting.fieldItems.place.short", full: "chronos.mobile.components.sorting.fieldItems.place.full"},
  {key: 'birthDate', short: "chronos.app.components.zet.birthDate", full: "chronos.mobile.components.sorting.fieldItems.birthDate.full"},
  {key: 'modifiedDate', short: "chronos.app.store.dashboard.dateOfChanges", full: "chronos.mobile.components.sorting.fieldItems.modifiedDate.full"}
]

export default function Sorting(props: {
  sort: IDashboardSort,
  onSort(data: IDashboardSort):void
}){
  const [openPopup, setOpenPopup] = useState(false);
  const { t } = useTranslation();
  const setOrderAndClose = (item: ISortItem) => {
    setOpenPopup(false);
    props.onSort({'field': props.sort.field, 'order': item.key});
  }

  const setFieldAndClose = (item: ISortItem) => {
    setOpenPopup(false);
    props.onSort({'field': item.key, 'order': props.sort.order});
  }

  const order = orderItems.find(item => item.key === props.sort.order)!;
  const field = fieldItems.find(item => item.key === props.sort.field)!;

  return <>
    <Container onClick={() => setOpenPopup(true)}>
      {order.displayIcon}
      <Label>{t(field.short)}</Label>
    </Container>
    <PopupBottom open={openPopup} onClose={() => setOpenPopup(false)}>
      <SortingForPopup orderItems={orderItems} fieldItems={fieldItems} setOrder={setOrderAndClose} setField={setFieldAndClose} />
    </PopupBottom>
  </>
}


const Container = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  margin-left: 0.375rem;
  white-space: nowrap;
`

