import api from 'src/api';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import store from 'src/store';
import styled from 'styled-components';
import { ICourseInfo, ICoursePlan, Avatar, Button} from 'src/libs';
import Plan from './components/Plan';
import { updateCourse } from './utils';
import Header from './components/Header';
import { useSelector } from 'src/store/utils';
import { getProfile } from 'src/store/reducers/profile/selectors';
import { useTranslation } from 'src/i18n/useTranslation';

export default function CoursePlans(){
  const [course, setCourse] = useState<ICourseInfo | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<ICoursePlan | null>(null);
  const { t } = useTranslation();
  const { data: profile } = useSelector(getProfile);

  const navigate = useNavigate();

  const { id } = useParams<{
    id: string;
  }>();

  useEffect(() => {
    api.getCourse(Number(id)).then(data => {
      const course = updateCourse(data);
      setCourse(course)
    })    
  }, [])

  if(!course) return null;

  const onSuccess = (data: any) => {
    console.log('success', data);
    navigate(`/courses/${id}/lessons`);
  }

  const processPayment = () => {
    const res = api.payment({
      type: 'course',
      planId: selectedPlan?.id  
    }, 'charge', { onSuccess }).then(data => {
      console.log('payment', data);
    });
  }

  const headerButtonHandler = () => {
    navigate('/courses/')
  }
  
  return <Container>
    <Header buttonType="back" buttonHandler={headerButtonHandler} />
    <Content>
      <Avatar size={48} image={profile.avatarUrl!} />
      <Title>{t("chronos.app.payments.chooseTariff")}</Title>
      <SubTitle>{t("chronos.app.payments.verificationInfo")}</SubTitle>
      <Plans>
        {course.plans.map(plan => <Plan 
          plan={plan} 
          key={plan.id} 
          course={course} 
          setSelectedPlan={setSelectedPlan}
          active={selectedPlan?.id === plan.id}
        />)}
      </Plans>

      <PaymentBlock>
        <PaymentTitle>{t("chronos.app.payments.totalPayable")}</PaymentTitle>
        <PaymentPrice>{`${selectedPlan?.price || 0} ₽`}</PaymentPrice>
        <Button onClick={processPayment} color="var(--color-blue)" disabled={!selectedPlan}>{t("chronos.app.payments.goToPayment")}</Button>
        <Copyrights>
        {t("chronos.app.payments.paymentAgree.1")} <a href="https://auth.chronos.mg/#/confidentiality" target="_blank">{t("chronos.app.payments.paymentAgree.2")}</a> {t("base.union")} <a href="https://chronos.mg/courses.pdf" target="_blank">{t("chronos.app.payments.paymentAgree.3")}</a>
        </Copyrights>
      </PaymentBlock>
    </Content>
  </Container>
}

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
`

const Content = styled.div`
  text-align: center;
  padding-bottom: 2rem;
`

const Title = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--text-primary);
`

const SubTitle = styled.div`
  margin: 0 auto;
  max-width: 70%;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--text-secondary);
`

const Plans = styled.div`
  margin-bottom: 2rem;
`

const PaymentBlock = styled.div`
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
  text-align: left;
`

const PaymentTitle = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: 0.75rem;
  color: var(--text-secondary);
  width: 100%min-content;
`
const PaymentPrice = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  color: var(--text-primary);
`

const Copyrights = styled.div`
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 1.125rem;
  color: var(--text-third);
`