import React, { SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";


import { signOut } from 'src/store/reducers/profile/actions';
import { useSelector } from 'src/store/utils';
import { newNotifications } from 'src/store/reducers/notifications/selectors';

import HitIcon from 'src/ui/HitIcon';
import { Label, HeaderContainer } from './Header';
import { Avatar, AppSwitcher as AppsList } from 'src/libs';

import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';

import { SettingsIcon } from 'src/assets/icons/system/index';
import { SupportIcon } from 'src/assets/icons/system/index';
import { LogoutIcon } from 'src/assets/icons/system/index';

import { getProfile } from 'src/store/reducers/profile/selectors';
import { useTranslation } from 'src/i18n/useTranslation';
import { s3urls } from 'src/helpers/api';
import i18n from 'src/i18n/i18n';

export default function AppSwitcher({
  onClose
}: {
  onClose: () => void
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notifications = useSelector(newNotifications);
  const { data: profile } = useSelector(getProfile);

  const handleLogOut = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(signOut());
  }

  return <Overlay>
    <Container>
      <HeaderContainer>
        <HitIcon onClick={onClose}><LeftTriangleIcon /></HitIcon>
        <Label>{t("chronos.app.title")}</Label>
      </HeaderContainer>

      <Block>
        <header>{t("chronos.mobile.appSwitcher.accaunt")}</header>
        <ProfileBlock>
          <Avatar size={44} image={profile.avatarUrl!} bgColor="var(--bg-400)" />
          <ProfileName>
            {profile.firstName}
            <span>{profile.email}</span>
          </ProfileName>
          {notifications.length > 0 && <Notification>
            {notifications.length}
          </Notification>}
        </ProfileBlock>
        <SettingsButton href={process.env.REACT_APP_PROFILE_APP_URL} target="_blank">
          <span>{t("general.accountSettings")}</span>
        </SettingsButton>
      </Block>

      <Block>
        <header>{t("chronos.app.components.hotkeyslist.app")}</header>
        <StyledAppLinks 
          plain={true}
          current={''}
          t={t}
          lang={i18n.language}
        />
      </Block>

      <CrossLine />

      <Block>
        <header>{t("chronos.mobile.appSwitcher.more")}</header>
        <AppBlock onClick={() => navigate('/settings')}>
          <SettingsIcon />
          <AppName>
            {t("chronos.mobile.appSwitcher.appSettings")}
          </AppName>
        </AppBlock>
        <AppBlock href={s3urls.support + '/398e9d59c0c446648497a9c4f0168f82'} target="_blank">
          <SupportIcon />
          <AppName>
            {t("chronos.app.sidebar.support")}
          </AppName>
        </AppBlock>
        <AppBlock red={true} onClick={handleLogOut}>
          <LogoutIcon />
          <AppName>
            {t("chronos.app.components.appSwitcher.signOut")}
          </AppName>
        </AppBlock>
      </Block>
    </Container>
  </Overlay>
}

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: scroll;
`

const StyledAppLinks = styled(AppsList)`
  position: relative;
  top: 0;
  background: transparent;

  & .app-link {
    padding: 0;
  }

  & .app-link:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  & .link {
    min-height: auto;

    &:hover {
      border-color: transparent;
    }
  }

  & .icon {
    margin-left: 0;
    margin-right: 1rem;
  }

  & .title {
    font-weight: 400;
  }

  & .subtitle {
    font-weight: 400;
  }
`;

const Container = styled.div`
  width: 85vw;
  background: var(--bg-popup);
  backdrop-filter: blur(20px);
  padding: 1rem;
  padding-bottom: 1.5rem;
  min-height: 100vh;
`

const Block = styled.div`
  margin-bottom: 1rem;

  > header {
    font-size: 0.75rem;
    color: var(--text-secondary);
    margin-bottom: 0.675rem;
  }
`

const ProfileBlock = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  grid-column-gap: 1rem;
`

const ProfileName = styled.div`
font-size: 1.125rem;
  > span {
    display: block;
    font-size: 0.75rem;
    color: var(--text-secondary);
  }
`

const Notification = styled.div`
  width: 1.6875rem;
  background-color: var(--color-blue);
  border-radius: 6px;
  text-align: center;
  font-size: 0.875rem;
  padding-bottom: 1px;
`

const SettingsButton = styled.a`
  width: 100%;
  ///*padding-top: 0.75rem;
  //padding-bottom: 0.75rem;*/
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  text-align: center;
  border: 1px solid var(--border-lines);
  display: block;
  background: none;
  color: inherit;
  font-size: 0.75rem;
  margin-top: 0.96875rem;
  text-decoration: none;
  opacity: 0.6;
`

const AppBlock = styled.a<{ red?: boolean }>`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  grid-column-gap: 1rem;
  margin-bottom: 1.25rem;
  text-decoration: none;
  color: inherit;

  > svg {
    color: var(--text-secondary);
  }

  ${p => p.red && css`
    color: var(--color-red);
    > svg {
      color: currentColor;
      opacity: 0.6;
    }
  `}


`

const AppName = styled.div`
position: relative;
  > span {
    display: block;
    font-size: 0.75rem;
    color: var(--text-secondary);
  }
`

const Current = styled.div`
  background-color: var(--color-blue);
  position: absolute;
  top: 7px;
  right: 0px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`

const CrossLine = styled.div`
  height: 1px;
  background-color: var(--border-lines);
  margin-bottom: 1rem;
`