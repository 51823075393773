import React from 'react';
import { Routes, Route } from 'react-router-dom';

import CourseItem from 'src/app/pages/courses/CourseItem';
import CoursePlans from 'src/app/pages/courses/CoursePlans';
import CourseLesson from 'src/app/pages/courses/CourseLesson';
import CoursesList from 'src/app/pages/courses/CoursesList';
import Footer from 'src/components/Footer.tsx';

export default function Courses(){
  return <>
    <Routes>
      <Route path={`:id/plans`} element={<CoursePlans />} />
      <Route path={`:id/lessons`} element={<CourseItem />} />
      <Route path={`:courseId/lessons/:lessonId`} element={<CourseLesson />} />
      <Route path='/' element={<CoursesList/>} />
    </Routes>
    <Footer active="courses" />
  </>
};

