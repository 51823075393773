import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { CircleMode, IMap } from 'src/libs';
import { TimeIcon } from 'src/assets/icons/system/index';
import { WidgetsIcon } from 'src/assets/icons/system/index';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import ModeSelector, { modes } from './ModeSelector';
import { TMode, TSaveFormMode } from '../index';
import EditDateAndTime from './Timeline/EditDateAndTime';
import WidgetSelector from './WidgetSelector';
import { IWidget } from './Widgets/data';
import ConfirmPopupButton from 'src/ui/Popups/ConfirmPopupBottom';
import { IFormData } from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';
import { Button as UiButton } from 'src/libs';
import { clearURLSearchParams } from 'src/utils';
import api from "src/api";


const marginBottom = -56

export default React.memo(function Footer({
  mode,
  current,
  setMode,
  addMode,
  form,
  widgets,
  onOpenWidget,
  updateForm,
  calcMap,
  setOpenFormPopup,
  setSaveFormMode
}: {
  mode: TMode,
  current: CircleMode,
  setMode(mode: TMode):void,
  addMode(mode: TMode):void,
  form: IFormData,
  widgets: IWidget[],
  onOpenWidget(id: string): void;
  updateForm(v: IFormData): void;
  calcMap(v: IFormData): void;
  setOpenFormPopup(value: boolean): void;
  setSaveFormMode(value: TSaveFormMode): void;
}){
  const [openModePopup, setOpenModePopup] = useState(false);
  const [openTimePopup, setOpenTimePopup] = useState(false);
  const [openWidgetsPopup, setOpenWidgetsPopup] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = React.useState<Function | null>(null);
  const [openLockedPopup, setOpenLockedPopup] = useState(false);
  const { t } = useTranslation();
  const [currentButton, setCurrentBottom] = useState<'mode'|'time'|'widgets'|null>(null);

  const isLocked = !form.access.isPersonal;
  const isShare = new URL(window.location.href).searchParams.has('share');

  const handleModeClick = () => {
    if (isShare) {
      setOpenLockedPopup(true);
      return;
    }
    setOpenModePopup(true);
    setOpenTimePopup(false);
    setOpenWidgetsPopup(false);
    setCurrentBottom('mode');
  }

  const handleTimeClick = () => {
    if (isShare || isLocked) {
      setOpenLockedPopup(true);
      return;
    }
    setOpenModePopup(false);
    setOpenTimePopup(true);
    setOpenWidgetsPopup(false);
    setCurrentBottom('time');
  }

  const handleWidgetsClick = () => {
    setOpenModePopup(false);
    setOpenTimePopup(false);
    setOpenWidgetsPopup(true);
    setCurrentBottom('widgets');
  }

  const addModeWithClose = (mode: TMode) => {
    setOpenModePopup(false);
    addMode(mode);
  }

  const onOpenWidgetWithClose = (id: string) => {
    setOpenWidgetsPopup(false);
    onOpenWidget(id);
  }

  const setModeWithClose = (mode: TMode) => {
    setOpenModePopup(false);
    setMode(mode);
  }

  return <div>
    <Container id="footer__buttons">
      <Button onClick={() => handleModeClick()} active={currentButton === 'mode'}>{modes[mode].icon}</Button>
      <Button onClick={() => handleTimeClick()} active={currentButton === 'time'}><TimeIcon /></Button>
      <Button onClick={() => handleWidgetsClick()} active={currentButton === 'widgets'}><WidgetsIcon /></Button>
    </Container>

    <PopupBottom open={openModePopup} onClose={() => { setOpenModePopup(false); setCurrentBottom(null); }} title={t("chronos.mobile.pages.components.mode")} overlayColor="none" marginBottom={marginBottom}>
      <ModeSelector mode={mode} setMode={setModeWithClose} form={form} addMode={addModeWithClose} isLocked={isLocked} isLockedNotify={() => { setOpenLockedPopup(true) }} />
    </PopupBottom>

    <PopupBottom open={openTimePopup} modal={false} onClose={() => { setOpenTimePopup(false); setCurrentBottom(null); }} overlayColor="none" marginBottom={marginBottom} id="timelinePopup">
      <EditDateAndTime mode={mode === 'soul' ? 'natal' : mode} current={current} form={form} updateForm={updateForm} calcMap={calcMap} setOpenConfirmPopup={setOpenConfirmPopup} />
    </PopupBottom>

    <PopupBottom open={openWidgetsPopup} onClose={() => { setOpenWidgetsPopup(false); setCurrentBottom(null); }} title={t("chronos.mobile.pages.components.widgets")} overlayColor="none" marginBottom={marginBottom}>
      <WidgetSelector widgets={widgets} onOpenWidget={onOpenWidgetWithClose} />
    </PopupBottom>

    {/* сообщение о невозможности редактировать расшаренную карту */}
    <PopupBottom
      open={openLockedPopup}
      onClose={() => { setOpenLockedPopup(false); setCurrentBottom(null); }}
      title={""}
      overlayColor="none"
      marginBottom={marginBottom}
    >
      <div>{t("chronos.mobile.pages.cannotEditTheMap")}</div>
      <br />
      <div>{t("chronos.mobile.pages.signToChanges")}</div>
      <br />
      {isShare
        ? <UiButton
            onClick={() => {
              clearURLSearchParams('share');
              api.goToAuth();
            }}>
            {t("base.signIn")}
          </UiButton>
        : <UiButton
            onClick={() => {
              setOpenLockedPopup(false);
              setOpenFormPopup(true);
              setSaveFormMode('add');
            }}
          >
          {t('Сохранить себе')}
          </UiButton>
      }
    </PopupBottom>

    <ConfirmPopupButton
      open={!!openConfirmPopup} onClose={() => setOpenConfirmPopup(null)}
      title={t("base.resetFormations")}
      onConfirm={() => {
        openConfirmPopup && openConfirmPopup();
        setOpenConfirmPopup(null);
      }}
      confirmText={t("base.confirm")} strong={true}
    >
      {t("base.resetFormationsInfo")}
    </ConfirmPopupButton>
  </div>
});

const Container = styled.div`
  width: calc(100% + 2rem);
  display: flex;
  justify-content:space-around;
  margin-left: -1rem;
  margin-bottom: -1rem;
  background: var(--bg-400);
`;

const Button = styled.div<{active?: boolean}>`
  position: relative;
  color: var(--text-secondary);
  padding: 1rem 0rem;

  ${p => p.active && css`
    color: var(--text-primary);
    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      content: '';
      width: 100%;
      height: 0.1875rem;
      border-radius: 1.75rem;
      background: var(--color-blue);
    }
  `}
`;
