import React from 'react';
import styled, { css } from 'styled-components';

import { PlusIcon } from 'src/assets/icons/system/index';
import { useTranslation } from 'src/i18n/useTranslation';

export default function NewMap() {
  const { t } = useTranslation();
  return <Container>
    <span><PlusIcon /></span>
    <Title>{t("chronos.app.settings.createOwnMap")}</Title>
    <Text>{t("chronos.mobile.settings.createOwnMap.subTitle.1")}<br />{t("chronos.mobile.settings.createOwnMap.subTitle.2")}</Text>
    <Text>{t("base.functionalityInDev")} <br />{t("chronos.mobile.settings.newMap.functionalityInDev.2")}</Text>
  </Container>
}


const Container = styled.div`
  opacity: 0.5;
  margin-top: 1rem;
  background-color: var(--bg-dashboard-cell);
  border-radius: 5px;
  border: 1px solid var(--border-lines);
  height: 22.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  > span {
    display: inline-block;
    padding: 1.5rem;
    border-radius: 50%;
    background-color: var(--color-blue);
  }
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-top: 1.25rem;
`
const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
`