import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import CourseSidebar from './components/CourseSidebar';
import {ICourseInfo} from 'src/libs';
import {useNavigate, useParams} from 'react-router-dom';
import {getPlanId} from './utils';
import Header from './components/Header';
import {getCourse} from "../../../store/reducers/courses/actions";
import {useSelector} from "../../../store/utils";
import {getAllCourses} from "../../../store/reducers/courses/selectors";
import {removeLastLessonLC} from "../../../store/localStorage/courses";
import {useDispatch} from "react-redux";

export const COURSES_LAST_LESSON_ID = 'COURSES_LAST_LESSON';

export default function CourseItem() {
    const [course, setCourse] = useState<ICourseInfo | null>(null)
    const {courses: courses} = useSelector(getAllCourses)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {id} = useParams<{
        id: string;
    }>();

    useEffect(() => {
        const selectedCourse = courses.find(course => course.course.id === Number(id))
        if (selectedCourse) {
            if (getPlanId(selectedCourse.course)) {
                setCourse(selectedCourse)
            } else {
                navigate(`/courses/${id}/plans`)
            }
        } else {
            dispatch(getCourse(Number(id)))
        }
        removeLastLessonLC()
    }, [courses])

    const backToCourses = () => {
        navigate('/courses/')
    }

    if (!course) return null;
    if (!getPlanId(course.course)) return null;

    return <div>
        <Header buttonType="back" buttonHandler={backToCourses}/>
        <Container>
            <CourseSidebar course={course}/>
        </Container>
    </div>
        ;
}

const Container = styled.div`
  width: 100%;
`