import { fillNumber, parseISOString } from "src/libs";

export function toISOStringFromDateAndTime(date: string, time: string) {
  return `${date}T${time}.000Z`;
}

export function dateFromIsoString(value: string) {
  const parts = parseISOString(value);
  return `${fillNumber(parts[0])}-${fillNumber(parts[1])}-${fillNumber(parts[2])}`;
}

export function timeFromIsoString(value: string) {
  const parts = parseISOString(value);
  const result = `${fillNumber(parts[3])}:${fillNumber(parts[4])}:${fillNumber(parts[5])}`;
  return result;
}