import React, { useState } from 'react';
import styled from 'styled-components';
import { 
  getFormationDiscountPromo, 
  getFormationDiscountValue, 
  getFreeFormationPromo, 
  getFreeFormationsValue, 
  paymentsFullInfo,
  getPriceForAmount
} from 'src/store/reducers/payments/selectors';
  
import { useSelector } from 'src/store/utils';
import { PopupRight } from 'src/ui/Popups/PopupRight';
import BuyFormations from './BuyFormations';
import { useTranslation } from 'src/i18n/useTranslation';
import { useCurrency } from 'src/hooks';
import { Badge } from 'src/libs';


export default function FormationsView() {
  const { t } = useTranslation();
  const currency = useCurrency();
  
  const [openBuyPopup, setOpenBuyPopup] = useState(false);

  const info = useSelector(paymentsFullInfo).data!;
  const freeFormationPromo = useSelector(getFreeFormationPromo);
  const freeFormationsValue = useSelector(getFreeFormationsValue);
  const formationDiscountPromo = useSelector(getFormationDiscountPromo);
  const formationDiscountValue = useSelector(getFormationDiscountValue);
  
  let formationsCount = info.statistics.counter;
  
  if (freeFormationPromo) {
    formationsCount += freeFormationsValue;
  };

  const price = useSelector(getPriceForAmount(1, currency.currencyCode));

  const isFormationDiscount = Boolean(formationDiscountPromo);
  const isFreeFormations = Boolean(freeFormationPromo);
  const afterFreePrice = isFreeFormations ? t("chronos.mobile.settings.payments.then", { price: price }) : ``
  
  
  return <Container>
    <Title>{t("chronos.app.settings.buildings")}</Title>

    <ConstructingGrid>
      <BigGridTab>
        <div className='tab-header'>
          <div>{t("chronos.app.settings.yourBuilds")}</div>
          {
            isFormationDiscount && <div><Badge size='small' value={`-${formationDiscountValue}%`} state='success'/></div>
          }
        </div>
        <BigQuantity>{formationsCount}</BigQuantity>
        <ConstructPrice>
          { isFormationDiscount
            ? <><ins>{price}{currency.symbol}</ins>&nbsp;{price - (price * (formationDiscountValue / 100))}{currency.symbol}</>
            : isFreeFormations
              ? t("chronos.mobile.settings.payments.free", { freeFormationsValue: freeFormationsValue })
            : <>{t("chronos.mobile.settings.payments.price", { price: price })}{currency.symbol}</>
          } 
          <span>{afterFreePrice}{t("chronos.app.settings.forOne")}</span>
        </ConstructPrice>

        <AddMoreLink onClick={() => setOpenBuyPopup(true)}><a>{t("chronos.app.settings.addMore")}</a></AddMoreLink>
      </BigGridTab>
      <GridTab>
        {t("chronos.app.devPlan")}
        <Quantity>{info.statistics.ipr}</Quantity>
      </GridTab>
      <GridTab>
        {t("chronos.mobile.settings.payments.rectifications")}
        <Quantity>{info.statistics.rectifications}</Quantity>
      </GridTab>
      <GridTab>
        {t("chronos.app.widgets.title.autoPrediction")}
        <Quantity>{info.statistics.ap}</Quantity>
      </GridTab>
    </ConstructingGrid>

    <PopupRight open={openBuyPopup} onClose={() => {setOpenBuyPopup(false)}} verticalAlign='flex-end'>
        <BuyFormations onClose={() => {setOpenBuyPopup(false)}} />
    </PopupRight>
  </Container>
}

const Container = styled.div`
  margin-top: 2rem;
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
`

const ConstructingGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
  line-height: 0.875rem;
`

const GridTab = styled.div`
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--bg-dashboard-cell);
  border: 1px solid var(--bg-element-neutral);
  box-sizing: border-box;
`

const BigGridTab = styled.div`
  padding: 1.125rem;
  border-radius: 8px;
  background-color: var(--bg-dashboard-cell);
  border: 1px solid var(--bg-element-neutral);
  grid-area: 1/1/4/2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  & .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
`

const BigQuantity = styled.div`
  color: var(--text-primary);
  font-size: 3rem;
  line-height: 3rem;
  margin-top: 0.75rem;
`

const Quantity = styled.div`
  color: var(--text-primary);
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-top: 0.625rem;
`

const ConstructPrice = styled.div`
  margin-top: 0.75rem;
  color: var(--text-primary);
  font-size: 1.5rem;
  line-height: 1.75rem;
  > span {
    margin-top: 0.375rem;
    display: block;
    color: var(--text-secondary);
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  ins {
    opacity: 0.6;
    text-decoration: line-through;
  }
`

const AddMoreLink = styled.div`
  margin-top: auto;

  > a {
    display: inline-block;
    border: 1px solid var(--border-lines);
    padding: 0.625rem 0.625rem;
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 1rem;
  }
`