import React from 'react';
import styled from 'styled-components';
import { ICourse } from 'src/libs';
import HorizontalSliderDefault from './HorizontalSliderDefault';

export const COURSE_PREVIEW_WIDTH = '48.5rem'; // FIXME: 2 places

export default function HorizontalSliderItem({
  type = 'default',
  course
}: {
  type?: 'default',
  course?: ICourse
}) {

  let renderInner = null;
  
  if(type === 'default' && course) renderInner = <HorizontalSliderDefault course={course}  />;

  return <Container>
    {renderInner}
  </Container>
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: rgba(30, 30, 30, 1);
  border: 1px solid var(--input-border);
  box-shadow: var(--workspace-box-shadow);
  border-radius: 12px;
`