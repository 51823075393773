import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import NewMap from './NewMap';
import Map from './Map';
import { useSelector } from 'src/store/utils';
import { allSettings, getCustomizations, allSettingsAstro, getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
import { useDispatch } from 'react-redux';
import { getSettings, updateAstroSettings, updateSettings } from 'src/store/actions/settings';
import { getProfile } from 'src/store/localStorage/profile';
import { useTranslation } from 'src/i18n/useTranslation';
import { ICustomization } from 'src/libs';

export default function ColorTheme() {
  const dispatch = useDispatch();
  const {data: customizationsList} = useSelector(getCustomizations);
  const {data: settings} = useSelector(allSettings);
  // const {data: settingsAstro} = useSelector(allSettingsAstro);
  const activeAstroProfile = useSelector(getActiveAstroProfile);
  // const customizationsList: ICustomization[] = customizations;
  const { t } = useTranslation();

  const onChange = (id: number) => {
    dispatch( updateSettings({activeCustomizationId: id}) );

    // если текущий профиль - Мой профиль, то обновляем idCustomization в профиле
    if(![0, 1].includes(activeAstroProfile.id)) {
      dispatch( updateAstroSettings({...activeAstroProfile, idCustomization: id}) )
    }
  }

  return <Container>
    <Title>{t("chronos.app.settings.Map")}</Title>
    <Text>{t("chronos.app.settings.Map.subtitle")}</Text>

    {(customizationsList as ICustomization[]).map(item => {
      if (item.data.name)item.data.name = t(item.data.name)
      return <Map key={item.id} active={settings.activeCustomizationId === item.id} data={item} onClick={() => onChange(item.id)} />
    })}
    
    {!customizationsList.some((custom: ICustomization) => custom.user_id !== -1) && <NewMap />}
  </Container>
}


const Container = styled.div`
  margin-top: 2rem;
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
`
const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);
  margin-top: 0.375rem;
`
