import { PAYMENTS_CHANGE_AUTO_SUCCESS, PAYMENTS_FULL_INFO_GET_LOADING, PAYMENTS_FULL_INFO_GET_SUCCESS } from './actions';
import { IActionPaymentsFullInfo, IPaymentsFullInfoState } from './types';

const initialState: IPaymentsFullInfoState = {
  loading: false,
  loaded: false
};

export default function info(state = initialState, action: IActionPaymentsFullInfo){
  switch(action.type){
    case PAYMENTS_FULL_INFO_GET_LOADING:
      return {
        loading: true,
        loaded: false,
        data: state.data
      };
    case PAYMENTS_FULL_INFO_GET_SUCCESS:
      return {
        loading: false,
        loaded: true,
        data: action.payload
      };
    case PAYMENTS_CHANGE_AUTO_SUCCESS:
      return state.data ? {
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          autoPayment: action.payload
        }
      } : state
    default:
      return state;
  }
}

