import { Tooltip, astroObjects, ObjectType, nowISOString } from 'src/libs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { avgSpeed } from '../../Circle/Objects';
import { getAccidentalValue, getEssentialsValue } from './horar';

import { IWidgetData } from '../data';

import { objectsIcons } from 'src/helpers/icons';
import { useTranslation } from 'src/i18n/useTranslation';

const PercentMultiplier = 100;
const FractionDigits = 4;

export default function HorarSpeed(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
  openedWidget: boolean;
}): JSX.Element {

  const containerRef = React.useRef<HTMLInputElement>(null);
  const [tableRows, setTableRows] = useState<JSX.Element[] | null>(null);
  const { t } = useTranslation();
  const getSpeedText = (speed: number): string => {
    const avgPercent = 100;

    if (speed > avgPercent) return t("chronos.app.widgets.speed.fast");
    if (speed === avgPercent) return t("chronos.app.widgets.speed.medium");
    if (speed < avgPercent && speed !== 0) return t("chronos.app.widgets.speed.slow");
    if (speed === 0) return t("chronos.app.widgets.speed.motionless");
    return t("chronos.app.widgets.speed.motionless");
  };

  useEffect(() => {
    const init = async () => {
      setTableRows(await getTableRows());
    };

    const getTableRows = async () => {
      const rows = [];
      for (let p = ObjectType.Sun; p <= ObjectType.Saturn; p++) {
        const { speed } = props.data.maps[0].objects[p];

        const ObjectIcon = objectsIcons[p];

        const ObjectSpeed = parseInt((speed / avgSpeed[p] * PercentMultiplier).toFixed(0), 10);
        const speedDifference = (Math.abs(speed) - avgSpeed[p] > 0 ? '+' : '') + (Math.abs(speed) - avgSpeed[p]).toFixed(FractionDigits);

        const accidentalValue = await getAccidentalValue(p, props.data.maps[0].objects, props.data.maps[0].houses, props.data.form.horar?.dt || nowISOString());
        const essentialValue = getEssentialsValue(p, props.data.maps[0].objects, props.data.maps[0].houses);

        rows.push(
          <TableRow key={`row_${p}`}>
            <Cell>
              <ObjectIcon/>
              {t(astroObjects[p].ru)}
            </Cell>
            <Cell>
              <span>
                {speedDifference}
              </span>
              {` ${ObjectSpeed}%`}
              <Tooltip text={getSpeedText(Math.abs(ObjectSpeed))} vPosition="bottom" hPosition="left">
                <Circle speed={ObjectSpeed}/>
              </Tooltip>
            </Cell>
            <Cell className='cell' onTouchStart={onCellTouch}>
              <ToolTip className='tooltip' dangerouslySetInnerHTML={{ __html: accidentalValue.description + essentialValue.description }} />
              {accidentalValue.points + essentialValue.points}
            </Cell>
          </TableRow>
        );
      }
      return rows;
    };
    init();
  }, [props.data.form.horar?.dt, props.data.maps]);

  const onCellTouch = (evt: any) => {
    const { clientX, clientY } = evt.touches[0]

    containerRef.current?.querySelectorAll('.tooltip').forEach((elem: any) => {
      elem.style.display = 'none'
    })
    
    const tooltip = evt.target.querySelector('.tooltip');
    
    if (tooltip) {
      tooltip.style.display = 'block';
      tooltip.style.top = `${clientY}px`;
    }
    
  }


  return (
    <Container ref={containerRef} className="speed-container">
      <TableHeader>
        <Cell>
          {t("astro.planets")}
        </Cell>
        <Cell style={{ justifyContent: 'center' }}>
          {t("chronos.auth.index.speed")} 
        </Cell>
        <Cell>
          {t("chronos.app.widgets.power")}
        </Cell>
      </TableHeader>
      {tableRows}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
`;

const getCircleColor = (speed: number): string => {
  const avgPercent = 100;

  if (speed > avgPercent) return '--color-green';
  if (speed === avgPercent) return '--color-orange';
  if (speed < avgPercent && speed !== 0) return '--color-red';
  if (speed === 0) return '--text-third';
  return '--text-third';
};

const Circle = styled.span<{ speed: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  width: 1.5rem;
  height: 1.5rem;

  &::before {
    display: block;
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: var(${props => getCircleColor(Math.abs(props.speed))});
  }
`;

const ToolTip = styled.div`
  display: none;
  position: fixed;
  border-radius: 4px;
  background-color: var(--bg-100);
  color: var(--text-primary);
  padding: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.2rem;
  z-index: 100;
  right: 3rem;
  border: 1px solid var(--element-neutral);
  
  ul {
    position: relative;
    margin: 0;
    margin-bottom: 1rem;
    padding-left: 1rem;
    text-align: left;
    :nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`;

const TableHeader = styled.div`
  display: flex;
  color: var(--text-third);
  padding: 0.625rem 0;
  > :nth-child(1) {
    width: 50%;
    justify-content: flex-start;
  }
  > :nth-child(3) {
    width: 15%;
    margin-left: auto;
  }
`;

const TableRow = styled.div`
  border-bottom: 1px solid var(--element-neutral);
  display: flex;
  padding: 0.6rem 0;
  
  > :nth-child(1) {
    width: 50%;
    justify-content: flex-start;
  }
  > :nth-child(3) {
    width: 15%;
    margin-left: auto;
  }
  :nth-last-child(1) {
    border-bottom: none;
  }
  > :nth-last-child(1) {
    position: relative;    
  }
`;

const Cell = styled.span`
  position: relative;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:nth-last-child(1) {
    cursor: pointer;
  }

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
  span {
    &:nth-child(1) {
      margin-right: 1rem;
    }
  }
`;
