import React from 'react';
import styled from 'styled-components';

export default function LineHorizontal() {
  return <Line></Line>
}

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: var(--bg-element-neutral);
  margin: 0.4rem 0;
`