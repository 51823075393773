import React, { useEffect } from 'react';
import styled from 'styled-components';
import LineHorizontal from 'src/ui/LineHorizontal';

import { AttentionIcon } from 'src/assets/icons/system/index';

import PaymentHistoryItem from './PaymentHistoryItem';
import { Button } from 'src/libs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/store/utils';
import { getPayments as getPaymentsSelector } from 'src/store/reducers/payments/selectors';
import { getPayments } from 'src/store/reducers/payments/actions';
import { useTranslation } from 'src/i18n/useTranslation';
import { ITransaction } from 'src/api';

export default function PaymentsHistory({
  setOpenChangeTariffPopup
}: {
 setOpenChangeTariffPopup(value: boolean): void
}) {
  const dispatch = useDispatch();
  const payments = useSelector(getPaymentsSelector);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getPayments());
  }, [])
  
  if(!payments.loaded) {
    return <></>;
  }

  const paymentsList: ITransaction[] = Object.values(payments.data);

  return <Container>
    <Title>{t("chronos.app.settings.writeOffHistory")}</Title>
    <LineHorizontal />
    
    {paymentsList.length === 0 && <CentralBlock>
      <span><AttentionIcon /></span>
      <InnerTitle>{t("chronos.app.settings.transactionList.noTransactions.title")}</InnerTitle>
      <InnerText>{t("chronos.app.settings.transactionList.noTransactions.subTitle")}</InnerText>
      <SelectButton><Button color="var(--color-blue)" onClick={() => { setOpenChangeTariffPopup(true) }}>{t("chronos.app.chooseTariff")}</Button></SelectButton>

    </CentralBlock> }

    {paymentsList.length > 0 && paymentsList.sort((item1, item2) => {
            return item1.date === item2.date ? 0 : item1.date > item2.date ? -1 : 1;
          }).filter(item => ['subscribe', 'additional', 'formation'].includes(item.type)).map((item, index: number) => {
      return <div key={index}>
        <PaymentHistoryItem data={item} />
        {index !== paymentsList.length - 1 && <LineHorizontal />}
      </div>
    })}
  </Container>
}

const Container = styled.div`
  position: relative;
  margin-top: 2rem;
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-bottom: 1rem;
`

const CentralBlock = styled.div`
  position: relative;
  text-align: center;
  margin-top: 2.25rem;

  > span {
    color: var(--color-white);
    background-color: var(--color-orange);
    padding: 0.75rem;
    border-radius: 50%;
    display: inline-block;
  }
`
const InnerTitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-top: 1.5rem;
`
const InnerText = styled.div`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);
  margin-top: 0.375rem 0.5rem;
  white-space: break-spaces;
`

const SelectButton = styled.div`
  margin-top: 1.5rem;
`