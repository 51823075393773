import { IAstroSettings } from 'src/libs';
import { AnyAction } from 'redux';
import { SETTINGS_ASTRO_GET_LOADING, SETTINGS_ASTRO_GET_SUCCESS, SETTINGS_CHANGE_PROFILE_ORBISE } from '../../actions/settings';
import { ISettingsAstroState } from './types';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';

const initialState: ISettingsAstroState = {
  loading: false,
  loaded: false,
  data: []
};

export default function astro(state = initialState, action: AnyAction){
  switch(action.type){
    case SETTINGS_ASTRO_GET_LOADING:
      return {
        loading: true,
        loaded: false,
        data: state.data
      };
    case SETTINGS_ASTRO_GET_SUCCESS:
      const profiles = action.payload; 
      return {
        loading: false,
        loaded: true,
        data: addMyProfile(profiles)
      };
    case SETTINGS_CHANGE_PROFILE_ORBISE:
      const profiles_: IAstroSettings[] = [...state.data];
      const profile = profiles_.find(item => item.id === action.payload.profileId)!
      const mode: string = action.payload.mode;
      (profile.maps as any)[mode].orbiseCorrector = action.payload.value

      // console.log(`SETTINGS_CHANGE_PROFILE_ORBISE for mode: ${mode}`, (profile.maps as any)[mode].orbiseCorrector)
      
      return {
        loading: false,
        loaded: true,
        data: profiles_
      }
    default:
      return state;
  }
}

function addMyProfile(profiles: IAstroSettings[]) {
  return profiles.some(p => p.mutable) ?
  profiles :
  [
    {
      ...cloneDeep(profiles.find(p => p.id === 0)!),
      id: -1,
      title: t("chronos.app.astroStore.myProfile"),
      mutable: true
    },
    ...profiles
  ];
}

