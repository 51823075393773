import React from 'react';
import styled from 'styled-components';

export default function AmountIcon(props: {
  amount: number;
}) {
  return <Icon>{props.amount}</Icon>;
}

const Icon = styled.span`
    padding: 0.25rem 0.375rem 0.15rem 0.375rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px; 
    font-size: 0.75rem;
    line-height: 0.75rem;
`;
