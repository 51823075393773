import React from "react";
import styled, { css } from "styled-components";
import { debounce, isEqual } from "lodash";
import { useAsyncMemo } from 'use-async-memo';
import { Checkbox, CircleLoader, Input, Switcher, Tooltip, getSign, signs, degToString } from 'src/libs';
import astro from 'src/astro';
import { ChevronRightIcon, InfoIcon, SearchIcon } from 'src/assets/icons/system';
// import { InfoIcon1 } from 'src/assets/icons/notifications';
import { CloseSmallIcon } from "src/assets/icons/system";
import { formatMessage } from 'src/utils';
// import MapModeSelector from './MapModeSelector';
import i18n from 'src/i18n/i18n';
import { useTranslation } from 'src/i18n/useTranslation';
import MapModeSelector from "./MapModeSelector";
import { DividingLine } from "src/ui/DividingLine";


export const getStarName = (name: string) => {
  const textArr = name.split(',');
  return textArr.map((word, index) => {
    if (index === textArr.length - 1) {
      return word + '\n'
    } else {
      return `${word}, `
    }
  }).join('')
};

export default React.memo(function StarCatalog({
  list = [],
  selected = {},
  dateTime,
  withModeSelector,
  onSelect,
  outsideMode = 'natal'
}: {
  list: any[];
  selected: { [key: string]: any };
  dateTime: string;
  withModeSelector?: boolean;
  outsideMode: string;
  onSelect: (mode: string, list: string[], showWithObjects: boolean) => void
}) {
  const { t } = useTranslation();
  const [mode, setMode] = React.useState<string>(outsideMode ?? 'natal');

  const isRu = i18n.language === 'ru';
  const LS_KEY = '_s_c';
  const modesList = ['natal', 'synastry', 'horar'];

  const degreeMessage = withModeSelector // компонент открыт в общих настройках
    ? t("chronos.mobile.settings.astro.degreeMessage")
    : (mode === 'natal'
      ? t("chronos.mobile.settings.astro.degreeMessageNatal")
      : (mode === 'synastry'
        ? t("chronos.mobile.settings.astro.degreeMessageSynastry")
        : t("chronos.app.settings.astro.degreeMessageHorar")
      )
    )
  const debouncedOnSelect = React.useCallback(debounce(onSelect, 1000), []);

  const sortRows = (key: 'name' | 'constellation', dir: 'asc' | 'desc') => (star1: any, star2: any) => {
    const nameKey = key === 'name'
      ? (i18n.language === 'ru' ? 'nameRu' : 'nameEn')
      : (i18n.language === 'ru' ? 'constellationRu' : 'constellation');
    return star1[nameKey] === star2[nameKey]
      ? 0
      : ((star1[nameKey] > star2[nameKey])
        ? (dir === 'asc' ? 1 : -1)
        : (dir === 'asc' ? -1 : 1));
  };

  const scrollContainer = React.useRef<HTMLDivElement>(null);

  const [update, setUpdate] = React.useState<boolean>(false);
  const [localMode, setLocalMode] = React.useState<string>(mode);
  const [searchString, setSearchString] = React.useState<string>('');
  const [searchStringResult, setSearchStringResult] = React.useState<string>('');
  const [sortByStar, setSortByStar] = React.useState<boolean | undefined>();
  const [sortByConstellation, setSortByConstellation] = React.useState<boolean | undefined>();

  const [showWithObjects, setShowWithObjects] = React.useState<{ [key: string]: boolean }>(
    modesList.reduce((acc, mode, i) => {
      (acc as any)[mode] = selected[mode]?.showWithObjects ?? true
      return acc;
    }, {})
  );

  const [checkedAll, setCheckedAll] = React.useState<{ [key: string]: boolean | undefined }>({});

  const [checkedItems, setCheckedItems] = React.useState<{ [key: string]: number[] }>({});

  const [partialChecked, setPartialChecked] = React.useState<boolean>(false)




  const starList = useAsyncMemo(async (): Promise<{ horar: any[], other: any[] }> => {

    const lsResult = window.localStorage.getItem(LS_KEY);

    const { time, data } = JSON.parse(lsResult || '{}');

    if (false) { //time && data && (dateTime === time)

      return data;

    } else {

      let horarList = list
        .filter((star) => star.isHorar)
        .sort(sortRows('name', 'asc'));

      let otherList = list
        .sort(sortRows('name', 'asc'));
      const horarResult = [];
      for (const star of horarList) {
        let { lon } = await astro.star(dateTime, star.astroName);
        const sign = getSign(lon);
        // console.log(`*** calc horar star ${star.astroName} lon: ${lon} sign: ${t(signs[sign].en)}`)
        horarResult.push({
          ...star,
          lon: degToString(lon),
          degreeFromSign: degToString(lon % 30),
          signRu: t(signs[sign]?.ru),
          signEn: t(signs[sign]?.en)
        });
      }

      const otherResult = [];
      for (const star of otherList) {
        const { lon } = await astro.star(dateTime, star.astroName);
        const sign = getSign(lon);
        // console.log(`--- calc other star ${star.astroName} lon: ${lon} sign: ${sign}`)

        otherResult.push({
          ...star,
          lon: degToString(lon),
          degreeFromSign: degToString(lon % 30),
          signRu: t(signs[sign].ru),
          signEn: t(signs[sign].en)
        });
      }

      const result = {
        horar: horarResult,
        other: otherResult
      }

      // window.localStorage.setItem(LS_KEY, JSON.stringify({ time: dateTime, data: result }))

      return {
        horar: horarResult,
        other: otherResult
      };

    }
  }, []);

  const onCheckedAll = React.useCallback((val: boolean) => {
    setUpdate(true);
    setPartialChecked(false);
    setCheckedAll(state => ({
      ...state,
      [localMode]: val || undefined
    }));

    const list = localMode === 'horar' ? starList?.horar : starList?.other;
    setCheckedItems(state => ({
      ...state,
      [localMode]: val ? (list ? list.map((star: any) => star.id) : []) : []
    }))
  }, [localMode, starList])

  const onCheckItem = React.useCallback((id: number) => {
    const list = localMode === 'horar' ? starList?.horar : starList?.other;
    const activeItems = checkedItems[localMode].includes(id)
      ? checkedItems[localMode].filter(item => item !== id)
      : [...checkedItems[localMode], id]

    setUpdate(true);

    setCheckedItems(state => ({
      ...state,
      [localMode]: activeItems
    }));

    setCheckedAll((state) => ({
      ...state,
      [localMode]: (activeItems.length === list?.length) || undefined
    }));

    setPartialChecked((activeItems.length !== list?.length));

  }, [localMode, checkedItems]);

  const checkedNumber = React.useMemo(() => {
    return checkedItems[localMode]?.length || 0
  }, [localMode, checkedItems]);

  const onShowWithObjectsChange = React.useCallback((val: boolean) => {
    setUpdate(true);
    setShowWithObjects(state => ({ ...state, [localMode]: val }))
  }, [localMode])

  const resultList = React.useMemo(() => {
    if (!starList) return null
    const result = (localMode === 'horar' ? starList.horar! : starList?.other!)
      .map((star) => {

        star.active = checkedItems[localMode]?.includes(star.id);
        return star
      })
      .filter(({ nameEn, nameEnAlt, nameRu, nameRuAlt, constellation, constellationRu }) => {
        const searchString = searchStringResult?.trim().toLowerCase() || '';
        return searchString.length > 2
          ? (isRu ? `${nameRu}${nameRuAlt}` : `${nameEn}${nameEnAlt}`).toLowerCase().includes(searchString)
          || (isRu ? constellationRu : constellation).toLowerCase().includes(searchString)
          : true
      })
    if (sortByStar !== undefined) {
      result.sort(sortRows('name', !sortByStar ? 'asc' : 'desc'))
    } else
      if (sortByConstellation !== undefined) {
        result.sort(sortRows('constellation', !sortByConstellation ? 'asc' : 'desc'))
      }

    return result;
  }, [localMode, starList, searchStringResult, checkedAll, sortByStar, sortByConstellation, checkedItems]);

  const onSearch = (val: string) => {
    debounceSearch(val);
    setSearchString(val);
  }

  const debounceSearch = React.useCallback(debounce(setSearchStringResult, 350), []);

  React.useEffect(() => {
    const starList = localMode === 'horar' ? list.filter((star) => star.isHorar) : list;

    const checkedItems_: any = modesList.reduce((acc, mode) => {
      const selectedStars = selected[mode]?.list || [];
      const selectedIds: string[] = selectedStars.map((starName: string) => {
        const id = starList?.find(({ astroName }) => astroName === starName)?.id;
        return id
      });
      (acc as any)[mode] = selectedIds.filter((item: any) => item !== undefined);
      return acc;
    }, {})

    const checkedAll_: any = modesList.reduce((acc, mode, i) => {

      (acc as any)[mode] = (selected[mode]?.list.length === starList?.length);

      return acc;
    }, {})

    setUpdate(false);
    !isEqual(checkedItems_, checkedItems) && setCheckedItems(checkedItems_);
    !isEqual(checkedAll_, checkedAll) && setCheckedAll(checkedAll_);

    setPartialChecked(Boolean(checkedItems_[localMode].length && !checkedAll_[localMode]));

  }, [localMode, selected])

  React.useEffect(() => {
    if (!starList || !update) return;
    const selectedList = [...(localMode === 'horar' ? starList.horar : starList.other)].reduce((acc, curr) => {
      if (curr.active) {
        acc.push(curr.astroName.trim())
      }
      return acc
    }, []);

    debouncedOnSelect(localMode, [...selectedList], showWithObjects[localMode]);

  }, [checkedItems, showWithObjects]);

  const onClearSearch = () => {
    setSearchString('');
    debounceSearch('');
  }

	const onCheck = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const parent = (evt.target as HTMLElement).closest('.row')
		if (!parent) return;
		const id = Number((parent as HTMLElement).dataset.idx);
		onCheckItem(id);
	}

  return (
    <StarCatalogContainer>
      <header>
        {
          withModeSelector &&
          <div className="mode-selector">
            <MapModeSelector
              modeList={modesList}
              activeMode={localMode}
              onModeSelect={(mode) => {
                setLocalMode(mode);
                scrollContainer.current?.scrollTo(0, 0)
              }}
            />
          </div>
        }

        <StyledDividingLine />

        <div className="search-container">
          <Input
            size="small"
            value={searchString}
            onChange={onSearch}
            icon={<SearchIcon />}
            placeholder={t("chronos.app.ui.search")}
          />

          <button
            className={`clear-button${searchString?.length ? ` visible` : ``}`}
            onClick={onClearSearch}
          >
            <CloseSmallIcon />
          </button>
        </div>
      </header>


      <main>
        <Table>
          <div className="row-header subtitle">
            <div className="cell check-all">
              <Tooltip text={partialChecked ? t("chronos.app.ui.removeSelection") : t("chronos.app.ui.chooseAll")}>
                <Checkbox checked={checkedAll[localMode]} partialChecked={partialChecked} onChange={onCheckedAll} />
              </Tooltip>
            </div>

            <div className="cell">
              {/* <div className="cell title"> */}
              <span>{t("chronos.ui.star")}</span>
              <SortButton active={sortByStar} onClick={() => { setSortByStar(!sortByStar); setSortByConstellation(undefined) }}><StyledChevronRightIcon /></SortButton>
            </div>

            <div className="cell">
              <span>{t("Созвездие и градус")}</span>
              <SortButton active={sortByConstellation} onClick={() => { setSortByConstellation(!sortByConstellation); setSortByStar(undefined) }}><StyledChevronRightIcon /></SortButton>
            </div>
          </div>

          <div className="table-wrapper" ref={scrollContainer} onClick={(evt) => onCheck(evt)}>
            {
              resultList?.map((star: any, idx: number) => {
                return (
                  <div className="row" key={`star_row_${star.id}`} data-idx={star.id}>
                    <div className="cell check">
                      <Checkbox checked={star.active} />
                    </div>


                    <div className="cell star-name text-wrap">
                      <span>{isRu ? star.nameRu : star.nameEn}</span>
                      {
                        ((isRu && star.nameRuAlt) || star.nameEnAlt) &&
                        <div className="star-alt-name text-wrap"> {isRu ? `${star.nameRuAlt ? `${getStarName(star.nameRuAlt)}` : ``}` : `${star.nameRuAlt ? `${star.nameEnAlt}` : ''}`} </div>
                      }
                    </div>

                    <div className="cell star-name">
                      <span>{isRu ? star.constellationRu : star.constellation}</span>
                      <span className="subtitle">{`${star.degreeFromSign} ${isRu ? star.signRu : star.signEn}`}</span>
                    </div>
                  </div>
                )
              })
            }

            {
              !resultList?.length && searchString
                ? <span className="empty-message">Объектов не найдено</span>
                : !starList
                  ? <span className="loader"><CircleLoader /></span>
                  : null
            }
          </div>
        </Table>

        <div className="table-footer">
          <span>{t("chronos.app.ui.objectsSelected")} <strong>{checkedNumber} {isRu && formatMessage(checkedNumber, ['объекта', 'объектов', 'объектов'])}</strong></span>
          <InfoMessage>
            <div>{degreeMessage}</div>
            <InfoIcon />
          </InfoMessage>
        </div>
      </main>

      <footer>
        <div className="footer-title">{t("chronos.app.ui.displayStars")}</div>
        <div className="footer-controls">
          <span>{showWithObjects[localMode] ? t("chronos.app.ui.on") : t("chronos.app.ui.off")}</span>
          <Switcher checked={showWithObjects[localMode]} onChange={onShowWithObjectsChange} />
        </div>
      </footer>
    </StarCatalogContainer>
  );
});

const StarCatalogContainer = styled.section`
  height: 100%;
  bottom: 0;

	.subtitle {
		color: var(--text-secondary);
	}

  & header {
    display: flex;
    flex-direction: column;
		justify-content: flex-start;

		width: 100%;
    height: fit-content;
    position: relative;
    /* gap: 0.5rem; */

    & .mode-selector {
      flex: 1.5;
    }

    & .search-container {
      position: relative;
      flex: 1;

      .clear-button {
        position: absolute;
        appearance: none;
        border: none;
        width: 1.5rem;
        height: 100%;
        top: 0;
        right: 0.5rem;
        padding: 0;
        color: var(--text-secondary);

        background-color: transparent;

        opacity: 0;

        transition: all 0.2s ease;

        &:hover {
          color: var(--text-primary);
        }

        &.visible {
          opacity: 1;
        }
      }
    }
  }

  & main {
    margin-top: 1.25rem;
    background-color: var(--background);
    border-radius: 0.625rem;
    border: 1px solid var(--input-border);

		.table-footer {
      padding: 0.75rem;
      font-size: 0.75rem;
      font-weight: 500;
      color: var(--text-secondary);
    }
  }

  & footer {
    display: flex;
    width: 100% !important;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    gap: 1rem;
    height: 5.938rem;
    background-color: var(--bg-sidebar);
    border-radius: 0.625rem;

    .footer-content {
      display: flex;

      width: 100%;
    }

    .footer-title {
      white-space: wrap;
      font-size: 1rem;
      font-weight: 500;
    }

    .footer-subtitle {
      margin-top: 0.25rem;
      font-size: 0.813rem;
      color: var(--text-secondary);
    }

    .footer-controls {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
    }
  }
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  transform: rotate(90deg);
`

const SortButton = styled.div<{ active: boolean | undefined }>`
  /* position: absolute; */
  max-height: 1.5rem;
  opacity: 1;

  & svg {
    right: 1rem;
    transition: transform 0.2s ease;
    ${p => p.active === undefined && css`
      color: var(--text-third);
      opacity: 0.7;
    `}
    ${p => p.active && css`
      transform: rotate(270deg);
    `}
  }
`;

const InfoMessage = styled.div`
	opacity: 1;
	position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--bg-0);
  font-weight: 400;
  font-size: 12px;
  height: 4.5rem;
  border-radius: 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: start;
	padding: 0.5rem 0;

  & div {
    width: 80%;
    height: auto;
    text-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }


  & svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 2rem;
  }
`

const StyledDividingLine = styled(DividingLine)`
	margin-top: 1.75rem;
`

const Table = styled.div`
	width: 100%;

	svg {
    width: 1.5rem;
    height: 1.5rem;
  }

	.row,
  .row-header {
		display: grid;
		grid-template-columns: 3.2rem auto 50%;
	}

	.cell {
		display: flex;
    justify-content: space-between;
    align-items: center;

		padding: 0.75rem;
		font-size: 0.875rem;
    font-weight: 500;
		border-right: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
		width: 100%;
		word-wrap: break-word;
		overflow: hidden;
	}

  .check-all {
    justify-content: center;
  } 

	.check {
    justify-content: center;
		pointer-events: none;
	}

	.star-name {
    display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: flex-start;

    overflow: hidden;

    & .star-alt-name {
      color: var(--text-third);
    }
	}

	.empty-message,
  .loader {
    position: absolute;
    top: 50%;
    display: block;
    left: 50%;
    transform: translate(-50%,-50%);

    font-size: 0.875rem;
    font-weight: 600;
  }

  .table-wrapper {
    height: 16rem;
		overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
      display: none;
    }
  }

	.text-wrap {
		white-space: pre-line;
    width: 100%;
	}
`
