import { ICoursePlan, ICourseInfo } from 'src/libs';
import React from 'react';
import styled, { css } from 'styled-components';

import { LightningIcon } from 'src/assets/icons/system/index';
import { OkIcon } from 'src/assets/icons/system/index';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Plan({
  course,
  plan,
  active,
  setSelectedPlan
}: {
  course: ICourseInfo,
  plan: ICoursePlan,
  active?: boolean,
  setSelectedPlan: (selectedPlan: ICoursePlan) => void
}){

  const currentPlan = course.plans.find(plan => plan.id === course.course.planId);
  const isBuyed = course.course.planId === plan.id || currentPlan?.parents.includes(plan.id);
  const { t } = useTranslation();
  let buttonContent = null;
  if(isBuyed) {
    buttonContent = "chronos.app.courses.hasAlready";
  } else if(active) {
    buttonContent = <>
      <CircleOk color="black" inButton={true} okColor={plan.color}><OkIcon /></CircleOk>
      {t("base.selected")}
    </>
  } else {
    buttonContent = "base.choose";
  }

  const onClick = () => {
    setSelectedPlan(plan);
  }

  return <Container isBuyed={isBuyed} color={active ? plan.color: undefined}>
    <Circle color={plan.color}><LightningIcon /></Circle>
    <Title color={plan.color}>{plan.title}</Title>
    <Price>{plan.price} ₽</Price>
    <Attributes>
      {plan.parents.map(parentId => {
        const parentName = course.plans.find(item => item.id === parentId)?.title;
        return <Attribute  key={parentId}>
          <CircleOk color={plan.color}><OkIcon /></CircleOk>
          {parentName} {t("base.package")}
        </Attribute>
      })}
      {plan.attributes.map(item => {
        return <Attribute color={plan.color} key={item}>
          <CircleOk color={plan.color}><OkIcon /></CircleOk>
          {item}
        </Attribute>})}
    </Attributes>
    <Button color={plan.color} onClick={onClick}>{typeof buttonContent === 'string' ?  t(buttonContent) : buttonContent}</Button>
  </Container>;
}

const Container = styled.div<{isBuyed?: boolean, color?: string}>`
  width: 100%;
  max-width: 20rem;
  margin: 0 auto 1.25rem;
  background: var(--bg-500);
  border: 1px solid var(--input-border);
  border-radius: 8px;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${p => p.color && css`
    border: 2px solid ${p.color};
  `}

  ${p => p.isBuyed && css`
    opacity: 0.6;
    pointer-events: none;
  `}
`

const Circle = styled.div<{color: string}>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: ${p => p.color};
  margin-top: 0.5rem;
  margin-bottom: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: var(--bg-500);
    width: 1.125rem; 
    height: 1.125rem;
  }
`

const Title = styled.div<{color: string}>`
  font-size: 0.875rem;
  line-height: 1rem;
  padding-bottom: 0.5rem;
  color: ${p => p.color};
`

const Price = styled.div`
  font-size: 1.875rem;
  margin-bottom: 1.125rem;
  color: var(--text-secondary);
`

const Attributes = styled.div`
  /*min-height: 15rem; */
  margin-bottom: 1rem;
  width: 100%;
`

const Attribute = styled.div<{color?: string}>`
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${p => (p.color || 'var(--text-secondary)')};
  margin-bottom: 0.875rem;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
`

const CircleOk = styled.div<{color: string, okColor?: string, inButton?: boolean}>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: ${p => p.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;

  > svg {
    color: ${p => p.okColor || 'white'};
    width: 0.375rem; 
    height: 0.375rem;
  }

  ${p => p.inButton && css`
    width: 1.125rem;
    height: 1.125rem;
    background: black;
    margin-right: 0.375rem;
    > svg {
      width: 0.625rem; 
      height: 0.625rem;
    }
  `}
`

const Button = styled.div<{color: string}>`
  line-height: 1.125rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.875rem;
  padding: 0.625rem 0.75rem;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  background: ${p => p.color};
  display: flex;
  align-items: center;
  justify-content: center;
`