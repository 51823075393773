import { AspectType, ObjectType, SignType } from 'src/libs';

import  * as objects  from 'src/assets/icons/astro/objects'
import  * as signs  from 'src/assets/icons/astro/signs'
import  * as aspects  from 'src/assets/icons/astro/aspects'

export const objectsIcons: {
  [key: number]: any
} = {
  [ObjectType.Sun]: objects.SunIcon,
  [ObjectType.Moon]: objects.MoonIcon,
  [ObjectType.Mercury]: objects.MercuryIcon,
  [ObjectType.Venus]: objects.VenusIcon,
  [ObjectType.Mars]: objects.MarsIcon,
  [ObjectType.Jupiter]: objects.JupiterIcon,
  [ObjectType.Saturn]: objects.SaturnIcon,
  [ObjectType.Uranus]: objects.UranusIcon,
  [ObjectType.Neptune]: objects.NeptuneIcon,
  [ObjectType.Pluto]: objects.PlutoIcon,
  [ObjectType.NorthNode]: objects.NorthNodeIcon,
  [ObjectType.SouthNode]: objects.SouthNodeIcon,
  [ObjectType.Lilith]: objects.LilithIcon,
  [ObjectType.Chiron]: objects.ChironIcon,
  [ObjectType.Selena]: objects.SelenaIcon,
  [15]: objects.ErisIcon
};

export const signsIcons: {
  [key: number]: any;
} = {
  [SignType.Aries]: signs.AriesIcon,
  [SignType.Taurus]: signs.TaurusIcon,
  [SignType.Gemini]: signs.GeminiIcon,
  [SignType.Cancer]: signs.CancerIcon,
  [SignType.Leo]: signs.LeoIcon,
  [SignType.Virgo]: signs.VirgoIcon,
  [SignType.Libra]: signs.LibraIcon,
  [SignType.Scorpio]: signs.ScorpioIcon,
  [SignType.Sagittarius]: signs.SagittariusIcon,
  [SignType.Capricorn]: signs.CapricornIcon,
  [SignType.Aquarius]: signs.AquariusIcon,
  [SignType.Pisces]: signs.PiscesIcon
};

export const signsNames: {
  [key: number]: any;
} = {
  [SignType.Aries]: "chronos.auth.planets.mars.sign",
  [SignType.Taurus]: "chronos.app.instruments.widgets.prompts.signs.taurus.name",
  [SignType.Gemini]: "chronos.app.instruments.widgets.prompts.signs.gemini.name",
  [SignType.Cancer]: "chronos.auth.planets.moon.sign",
  [SignType.Leo]: "chronos.auth.planets.sun.sign", 
  [SignType.Virgo]: "chronos.app.instruments.widgets.prompts.signs.virgo.name",
  [SignType.Libra]: "chronos.app.instruments.widgets.prompts.signs.libra.name",
  [SignType.Scorpio]: "chronos.auth.planets.pluto.sign",
  [SignType.Sagittarius]: "chronos.auth.planets.jupiter.sign",
  [SignType.Capricorn]: "chronos.auth.planets.saturn.sign",  
  [SignType.Aquarius]: "chronos.auth.planets.uranus.sign",
  [SignType.Pisces]: "chronos.auth.planets.neptune.sign"
}

export const aspectsIcons: {
  [key: number]: any;
} = {
  [AspectType.Conjunction]: aspects.ConjunctionIcon,
  [AspectType.Semisextile]: aspects.SemisextileIcon,
  [AspectType.Semiquintile]: aspects.SemiquintileIcon,
  [AspectType.Novile]: aspects.NovileIcon,
  [AspectType.Semisquare]: aspects.SemisquareIcon,
  [AspectType.Sextile]: aspects.SextileIcon,
  [AspectType.Quintile]: aspects.QuintileIcon,
  [AspectType.Binovile]: aspects.BinovileIcon,
  [AspectType.Square]: aspects.SquareIcon,
  [AspectType.MinusOrPlus]: aspects.MinusOrPlusIcon,
  [AspectType.Tredecile]: aspects.TredecileIcon,
  [AspectType.Trine]: aspects.TrineIcon,
  [AspectType.Sesquiquadrate]: aspects.SesquiquadrateIcon,
  [AspectType.Biquintile]: aspects.BiquintileIcon,
  [AspectType.Quincunx]: aspects.QuincunxIcon,
  [AspectType.Opposition]: aspects.OppositionIcon
};
