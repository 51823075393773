import React from 'react';
import styled, { css } from 'styled-components';

import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';
import { Badge, Checkbox } from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';
import { ISubscription } from 'src/api';
import { useCurrency } from 'src/hooks';

interface ITariffProps {
  data: ISubscription,
  activePayType: number,
  active: boolean;
  free?: boolean;
  freeText?: string;
  arrow?: boolean;
	currency: string;
  onClick?(): void;
}

export default function Tariff({
  data,
  activePayType,
  active,
  free = false,
  freeText = '',
  arrow = true,
  onClick,
	currency

}: ITariffProps) {
  const best = data.id === 3;

  const currentSum = data.sum[activePayType];
  const { t } = useTranslation();

  return <Container color={data.color!} best={best} onClick={onClick}>
    <TariffContent>
      <Title>
        {active && <Checkbox checked={true} onChange={() => { }} color={data.color!} />}
        {t(data.name)}
        {currentSum.discount > 0 &&
          <BadgeContainer>
            <Badge state="success" value={`-${currentSum.discount}%`} />
          </BadgeContainer>
        }
      </Title>
      <Price>
        {!free
          ? <>
              {currentSum.discount > 0 ? <ins>{currentSum.original}</ins> : currentSum.final}{currentSum.discount ? ' ' + currentSum.final : ''}{currency}
              <span>{t("chronos.app.settings.inMonth")}</span>
            </>
          : <>
              {t("chronos.app.settings.isFree")}
              <span>{freeText}</span>
            </>
        }
      </Price>
    </TariffContent>
    {arrow && <TariffLink><LeftTriangleIcon /></TariffLink>}
    {best && <Sticker color={data.color!}>{t("chronos.mobile.settings.payments.bestPrice")}</Sticker>}
  </Container>
}

const Container = styled.div<{ color: string, best: boolean }>`
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: var(--bg-dashboard-cell);
  border: 1px solid var(--bg-element-neutral);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  position: relative;
  overflow: visible;
  color: ${p => p.color};
  ${p => p.best && css`
    border: 2px solid ${p.color};
  `}
`

const TariffContent = styled.div`
  text-align: left;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.3125rem;

  > div {
    margin-right: 0.5rem;
  }
`

const Price = styled.div`
  margin-top: 0.375rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--text-primary);

  > span {
    margin-left: 0.25rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: var(--text-secondary);
  }

  ins {
    opacity: 0.6;
    text-decoration: line-through;
  }
`

const TariffLink = styled.div`
  color: var(--text-secondary);
  background-color: var(--bg-100);
  border-radius: 50%;
  padding: 0.375rem;
  > svg {
    transform: scaleX(-1);
  }
`

const Sticker = styled.div<{ color: string }>`
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateY(50%);
  background-color: ${p => p.color};
  color: var(--text-primary);
  border-radius: 4px;
  padding: 0.3125rem 0.375rem 0.25rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
`

const BadgeContainer = styled.span`
  margin-left: 0.5rem;
`
