import React, { useMemo } from 'react';
import styled from 'styled-components';

import { IComputedRadius, ICustomizationData, radialPoint, CircleMode, IObject, IAspect, IPoint, isNotTransits, IFixedStar, IChain, isRelocation } from 'src/libs';

import { calcHorarSigns } from '../Widgets/Horar/horar';

import Objects from './Objects';
import Aspects, { MinorAspectsModes } from './Aspects';
import Zodiacs from './Zodiacs';
import Houses from './Houses';
import SoulObjects from './SoulObjects';

import { IStrongs } from '../Widgets/data';
import { IHighlight } from '../../index';

import defaultPersonalization, { defaultComputedRadius } from './settings/personalization';

import { getSettings, getSettingsAstro } from 'src/store/actions/settings';
import { getProfile } from 'src/store/localStorage/profile';
import { useSelector } from 'src/store/utils';
import { allSettings, allSettingsAstro, getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
import { useTranslation } from 'react-i18next';
import FixedStars from './FixedStars';

import { getWorkspaceIndicator } from 'src/store/reducers/mapIndicator/selectors';
import { ECompatibilityTransitMode } from 'src/store/reducers/mapIndicator/types';

export function getSectorLength(i: number, values: number[]): number {
  const lon = values[i];

  let nlon = values[(i + 1) % 12];

  if (nlon < lon) { nlon += 360 }

  return nlon - lon;
}
export interface IHighlightsData {
  items: IHighlight[];
  aspects?: IAspect[];  
}
export interface CirclePartProps {
  mode: CircleMode;
  hasExt: boolean;
  hasOuter: boolean;
  radius: number;
  x: number;
  y: number;
  rotation: number;
  highlights?: number[];
  personalization: ICustomizationData;
  computedRadius: IComputedRadius;
  isEditor?: boolean;
  doubleMapWithOutNatal?: boolean;
  point(r: number, a: number): IPoint;
  onHelp(x: string, i: number): void;
  onHover(x: string, i: number): void;
}

interface CircleProps {
  mode: CircleMode;
  x: number;
  y: number;
  r: number;
  houses: number[];
  objects: IObject[];
  housesExt: number[];
  objectsExt: IObject[];
  housesOuter: number[];
  objectsOuter: IObject[];
  aspects: IAspect[];
  transitNatalAspects?: IAspect[];
  transitPartnerAspects?: IAspect[];
  strongs: IStrongs;
  fixedStars: IFixedStar[];
  showFixedStars: boolean;
  soulStrongs: number[];
  soulObjects: number[];
  chains: IChain[];
  highlights?: IHighlightsData;
  personalization?: ICustomizationData;
  computedRadius?: IComputedRadius;
  isEditor?: boolean;
  minorAspectsMode?: MinorAspectsModes;
  onChanged(key: string, value: any): void;
  limitedAccess?: boolean;
  limitedAccessAction?: () => void;
  t?: any;
}

export const WITH_OUTER_OFFSET = 0.07;

export default function Circle(props: CircleProps) {
  const personalization = props.personalization || defaultPersonalization;
  const computedRadius = props.computedRadius || defaultComputedRadius;

  const { prognosticsNatal, compatibilityTransits } = useSelector(getWorkspaceIndicator);
  const compatibilityTransitMode2 = compatibilityTransits?.[props.mode]?.transitMode === ECompatibilityTransitMode.MODE_2;

  const hasExt = Boolean(props.housesExt?.length) && !isRelocation(props.mode);
  const hasOuter = Boolean(props.housesOuter?.length) && !isRelocation(props.mode);

  const objects = props.objects;
  const houses = props.houses;
  
  const { data: profiles } = useSelector(allSettingsAstro);
  const { data: settings } = useSelector(allSettings);
  const { t } = useTranslation();

  const lsUser = getProfile();
  if (lsUser.id) {
    getSettings(lsUser.id, false)
  } else {
    console.log('')
    throw new Error('No user');
  }
  
  const activeAstroProfile = useSelector(getActiveAstroProfile);
  const isNotCustomProfile = [0, 1].includes(activeAstroProfile.id);
  
  const startPoint = isNotCustomProfile ? 'asc' : activeAstroProfile.startingPoint;
  const rotationDir = isNotCustomProfile ? 'ccw' : activeAstroProfile.rotationDir;
  const showHigherPlanets = activeAstroProfile?.showHigherPlanets;
  const showMinorPlanets = activeAstroProfile?.showMinorPlanets;
  
  let rotation: number;
  
  if (props.mode === 'horar') {
    rotation = 180 - (houses[0] || 0);
  } else if (props.mode === 'soul') {
    rotation = 180;
  } else {
    if (startPoint === 'aries') {

      if ( rotationDir === 'cw' ) {
        rotation = 0;
      } else {
        rotation = 180;
      }
    
    } else {
      if (rotationDir === 'cw') {
        rotation = 180 - (houses[0] - 180 || 0)
      } else {
        if (isRelocation(props.mode)) {
          rotation = props.mode === 'relocation_natal' ? 180 - ( houses[0] || 0) : 180 - props.housesExt[0];
        } else {
          rotation = 180 - ( houses[0] || 0)
        }
      }
    }
  }


  const partProps = {
    mode: props.mode,
    x: props.x,
    y: props.y,
    hasExt,
    hasOuter,
    radius: props.r,
    point: (r: number, a: number) => {
      if (props.mode === 'horar') {
        return radialPoint(props.x, props.y, r, a + (props.mode === 'horar' ? 0 : rotation))
      } else if (props.mode === 'soul') {
        return radialPoint(props.x, props.y, r, a + (rotation))
      } else {
        if (rotationDir === 'ccw')  {
          return radialPoint(props.x, props.y, r, a + (rotation))
        } else {
          return radialPoint(props.x, props.y, r, -(a + (rotation)))
        }
      }
    
    },
    onHelp: (category: string, id: number) => {
      if (props.limitedAccess && props.limitedAccessAction) {
        props.limitedAccessAction?.()
      } else {
        onHelpNew(category, id);
      }
    },
    onHover: (what: string, id: number) => {
      if (props.limitedAccess) return false;
      /*if (id === -1) {
        props.onChanged('highlights', []);
      } else if (what === 'aspect') {
        props.onChanged('highlights', highlightAspect(props.mode, props.aspects[id], hasExt));
      }*/
    },
    rotation,
    isEditor: props.isEditor,
    personalization,
    computedRadius,
    doubleMapWithOutNatal: !(prognosticsNatal as any)[props.mode],
    t: props.t
  };

  const onHelpNew = (category: string, id: number) => {
    if ( props.mode !== 'natal' || category === 'objects' && id >= 10) { return }
    props.onChanged('prompt', { category, id });
  };

  const { radius } = personalization.circle;

  const radiusModified = {
    internalSoul: props.r * (radius.internalSoul || 0.1),
    internalHorar: props.r * radius.internalHorar,
    internal: props.r * ((hasExt && !hasOuter) ? computedRadius.internalExt : (hasOuter && hasExt ?  (computedRadius.internalExt - WITH_OUTER_OFFSET) : computedRadius.internal)),
    zodiacsInternal: props.r * (hasExt ? computedRadius.zodiacsInternalExt : computedRadius.zodiacsInternal),
    zodiacsExternal: props.r * (hasExt ? computedRadius.zodiacsExternalExt : computedRadius.zodiacsExternal),
    external: props.r * radius.external,
    outer: props.r * (computedRadius.zodiacsExternalExt),
    middle: props.r * (computedRadius.internalExt + (WITH_OUTER_OFFSET))
  };

  const getInternalRadius = (mode: CircleMode) => {
    switch (mode) {
      case 'horar': return radiusModified.internalHorar;
      case 'soul': return radiusModified.internalSoul || 0.1;
      default: return radiusModified.internal;
    }
  };

  const getDelimeterRadius = (index: number) => {
    const objectsRadius = radiusModified.zodiacsInternal - (radiusModified.internalSoul || 0.1);
    if (index === 0) {
      return objectsRadius / 8 * index + (radiusModified.internalSoul || 0.1);
    }

    return objectsRadius / 8 * (index + 1) + (radiusModified.internalSoul || 0.1);
  };

  const highlights = (props.highlights?.items.length || 0) === 0 ? null : props.highlights!.items;

  const hlAspects = highlights?.filter(h => h.type === 'aspect').map(h => h.id) || (compatibilityTransitMode2 ? [-1] : undefined);

  const hlObjects = highlights?.filter(h => h.type === 'object').map(h => h.id) || (compatibilityTransitMode2 ? [-1] : undefined);
  const hlObjectsExt = highlights?.filter(h => h.type === 'object_ext').map(h => h.id) || (compatibilityTransitMode2 ? [-1] : undefined);
  const hlObjectsOuter = highlights?.filter(h => h.type === 'object_outer').map(h => h.id) || (compatibilityTransitMode2 ? [-1] : undefined);

  const hlHouses = highlights?.filter(h => h.type === 'house').map(h => h.id) || (compatibilityTransitMode2 ? [-1] : undefined);
  const hlHousesExt = highlights?.filter(h => h.type === 'house_ext').map(h => h.id) || (compatibilityTransitMode2 ? [-1] : undefined);
  // const hlHousesOuter = highlights?.filter(h => h.type === 'house_outer').map(h => h.id) || (compatibilityTransitMode2 ? [-1] : undefined);

  const hlSigns = highlights?.filter(h => h.type === 'sign').map(h => h.id) || (compatibilityTransitMode2 ? [-1] : undefined);

  const horarSigns = props.mode === 'horar' ? calcHorarSigns(props.houses) : [];

  const soulCircles = () => Array.from({ length: 7 }, (v, k) => k).map(i =>
    <circle
      key={`soul-circle-${i}`}
      cx={props.x}
      cy={props.y}
      r={getDelimeterRadius(i)}
      stroke="var(--circle-border)"
      fill="transparent"
    />
  );

  const staticCircles = useMemo(() => <>
    {/* External objects background */}
    {hasExt &&
        <circle
          cx={props.x}
          cy={props.y}
          r={radiusModified.external}
          fill="var(--workspace-background)"
          stroke="var(--circle-border)"
        />
    }

    {/* External circle */}
    <circle
      cx={props.x}
      cy={props.y}
      r={radiusModified.zodiacsExternal}
      stroke={personalization.circle.zodiacs.style === 0 ? 'var(--circle-zodiacs-border)' : 'none'}
      fill={personalization.circle.zodiacs.style === 0 ? 'var(--circle-zodiacs-background)' : 'none'}
    />

    {/* Objects background */}
    <circle
      cx={props.x}
      cy={props.y}
      r={radiusModified.zodiacsInternal}
      fill="var(--workspace-background)"
    />

    {props.mode === 'soul' && soulCircles()}

    {/* Middle circle */}
    {
      hasOuter && 
      <circle
        cx={props.x} 
        cy={props.y}
        r={radiusModified.middle}
        stroke="var(--circle-border)"
        fill="var(--workspace-background)"    
      />
    }

    {/* Internal circle */}
    <circle
      cx={props.x}
      cy={props.y}
      r={getInternalRadius(props.mode)}
      stroke="var(--circle-border)"
      fill="var(--circle-internal-background)"
    />
  </>, [props.x, props.y, props.mode, hasExt, radiusModified, personalization]);

  return (
    <Container id={props.mode}>
      {staticCircles}

      { isRelocation(props.mode) && props.mode !== 'relocation_natal' 
        ? <Houses
            {...partProps}
            houses={props.housesExt}
            highlights={hlHouses}
            t={t}
          /> 
        : <Houses
            {...partProps}
            houses={houses}
            highlights={hlHouses}
            t={t}
          />

      }

      {(props.mode.includes('compatibility') || isNotTransits(props.mode) && props.mode !== 'prog_prog') &&
        <Houses
          {...partProps}
          isExt={true}
          houses={props.housesExt}
          highlights={hlHousesExt}
          t={t}
        />
      }

      <Zodiacs
        {...partProps}
        highlights={hlSigns}
        horarSigns={horarSigns}
        direction={rotationDir}
        t={t}
      />

      {(props.mode !== 'horar' || settings.ui.instruments.cardSettingsForms.horar.showAspects) && 
        <Aspects
          {...partProps}
          aspects={
            props.highlights?.aspects?.length 
              ? props.highlights?.aspects 
              : (props.transitNatalAspects?.length || props.transitPartnerAspects?.length)
                ? [...props.transitNatalAspects!, ...props.transitPartnerAspects!]
                : props.aspects
          }
          radius={props.mode === 'horar' ? getInternalRadius(props.mode) : partProps.radius}
          strongs={props.strongs}
          highlights={hlAspects}
          objects={(props.mode === 'horar' ? props.objects : props.objectsExt) ?? []}
          settings={settings}
          houses={houses}
          horarSigns={horarSigns}
          minorAspectsMode={props.minorAspectsMode}
          synastryTransits={hasOuter}
    
        />
      }

      {props.mode === 'soul' && 
        <SoulObjects
          {...partProps}
          soulObjects={props.soulObjects}
        />
      }

      {
       hasOuter &&
        <Objects
          {...partProps}
          isOuter={true}
          objects={props.objectsOuter}
          houses={[]}
          strongs={props.strongs}
          highlights={hlObjectsOuter}
          horarSigns={horarSigns}
          mode={props.mode}
          showMinorPlanets={props.mode !== 'soul' && showMinorPlanets}
          t={t}
        />
      }

      {hasExt &&
        <Objects
          {...partProps}
          isExt={true}
          isOuter={false}
          objects={props.objectsExt}
          houses={[]}
          strongs={props.strongs}
          highlights={hlObjectsExt}
          horarSigns={horarSigns}
          showMinorPlanets={props.mode !== 'soul' && showMinorPlanets}
          t={t}
        />
      }

      <Objects
        {...partProps}
        isExt={false}
        isOuter={false}
        objects={objects}
        houses={houses}
        strongs={props.strongs}
        highlights={hlObjects}
        horarSigns={horarSigns}
        soulStrongs={props.soulStrongs}
        chains={props.chains}
        showHigherPlanets={((props.mode === 'horar') && showHigherPlanets)}
        showMinorPlanets={props.mode !== 'soul' && showMinorPlanets}
        t={t}
      />

      <FixedStars
        {...partProps}
        fixedStars={props.showFixedStars ? props.fixedStars : []}
        objects={objects}
        houses={houses}
        highlights={hlSigns}
        horarSigns={horarSigns}
        soulStrongs={props.soulStrongs}
        t={t}
      />

    </Container>
  );
};

export const Container = styled.g`
  /* & * {
    transition: all 0.25s ease-in-out;
  } */
`;

export const Group = styled.g`
  cursor: pointer;
`;


