import React from 'react';
import styled from "styled-components";
import { NoChartIcon } from 'src/assets/illustrations/index';
import api from "src/api";
import { Button } from 'src/libs';
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'src/i18n/useTranslation';
import { clearURLSearchParams } from 'src/utils';

interface IInaccessibleMap {
	accessError: string
}

export default function InaccessibleMap(props: IInaccessibleMap) {
	const navigate = useNavigate()
	const { t } = useTranslation();
	return (
		<Container>
			<NoChartIcon />

			<div className='title'>
				{props.accessError === 'NOT_FOUND' ? t("chronos.app.mapNotFound") : t("chronos.app.accessDenied")}
			</div>

			<div className='description'>
				{
					props.accessError === 'NOT_FOUND' ?
						t("chronos.app.accessDenied.hint") :
						props.accessError === 'PRIVATE' ?
							t("chronos.app.accessDenied.userDeniedAccess") :
							t("chronos.app.accessDenied.onlyAuthUsers")
				}
			</div>

			<Button
				onClick={() => {
					if (props.accessError === 'PROTECTED') {
						clearURLSearchParams('share');
						api.goToAuth();
					} else {
						navigate('/dashboard');
					}
				}}
				color="var(--color-blue)"
			>{props.accessError === 'PROTECTED' ? t("base.signIn") : t("chronos.app.goToMapList")}</Button>
		</Container>
	);
}

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	color: var(--text-primary);

	& button {
		margin-top: 20px;
	}

	& > .title {
		font-size: 1.25em;
		margin-top: 2.5em;
		margin-bottom: 1em;
	}

	& > .description {
		color: var(--text-third);
		margin-bottom: 1em;
		text-align: center;
	}
`