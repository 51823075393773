import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Radio } from 'src/libs';

import DarkThemeImage from 'src/assets/images/settings/dark-theme.png';
import LightThemeImage from 'src/assets/images/settings/light-theme.png';
import theme, { Themes } from 'src/theme';
import { useTranslation } from 'src/i18n/useTranslation';

const themeBlocks = [
  {value: 'dark', label: "chronos.app.settings.personal.colorTheme.dark", img: DarkThemeImage},
  {value: 'light', label: "chronos.app.settings.personal.colorTheme.light", img: LightThemeImage}
]

export default function ColorTheme() {
  const [value, setValue] = useState(theme.current || 'dark');
  const { t } = useTranslation();
  const setTheme = (t: any) => {
    setValue(t);
    theme.current = t;
  }

  return <Container>
    {themeBlocks.map(item => {
      return <Theme
        key={item.value}
        active={value === item.value} 
        onClick={() => setTheme(item.value)}>
          <Image><img src={item.img} /></Image>
          <Name>
            {t(item.label)}
            <Radio
              value={item.value}
              label=""
              checked={value === item.value}
              onChange={()=>{}}
            />
          </Name>
      </Theme>
    })}
    
  </Container>
}


const Container = styled.div`
  white-space: nowrap;
  overflow-x: scroll;
  width: 100%;
  margin-top: 1rem;
  > &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`

const Theme = styled.div < { active?: boolean }> `
  border-radius: 5px;
  display: inline-block;
  margin-right: 0.75rem;
  border: 1px solid var(--border-lines);
  ${p => p.active && css`
    border: 2px solid var(--color-blue);
  `}
`

const Image = styled.div`
  width: 15rem;
  height: 8.56rem;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Name = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.8125rem 0.75rem;
  background-color: var(--bg-dashboard-cell);
`

