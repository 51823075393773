import React from 'react';
import styled from 'styled-components';
import { Button, ICourse } from 'src/libs';
import dayjs from 'dayjs';
import { months2 } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { COURSES_LAST_LESSON_ID } from '../CourseItem';
import { useTranslation } from 'src/i18n/useTranslation';

export default function HorizontalSliderDefault({
  course
}: {
  course: ICourse
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClick = () => {
    const lastLessonId = Number(localStorage.getItem(COURSES_LAST_LESSON_ID) || '0');
    if(lastLessonId > 0) {
      navigate(`/courses/${course.id}/lessons/${lastLessonId}`);
    } else {
      navigate(`/courses/${course.id}/lessons`);
    }
  }

  const buttonText = course.planId ? t("base.open") : t("chronos.app.courses.enroll");

  return <Container url={course.banner}>
    <Tags>
      <Tag color="white">{course.lecturer}</Tag>
      <Tag color="var(--accent-pink)">{dayjs(course.start).date()} {t(months2[dayjs(course.start).month()])}</Tag>
    </Tags>
    <Title>
      {course.title}
    </Title>
    <SubTitle>
      {course.description}
    </SubTitle>
    <Buttons>
      <Button color="var(--accent-pink)" onClick={onClick}>{t(buttonText)}</Button>
      <a href={course.aboutUrl} target="_blank"><Button color="transparent" onClick={() => {}}>{t("base.learnMore")}</Button></a>
    </Buttons>
  </Container>
}

const Container = styled.div<{url: string}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2a2a2a;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-image: url(${p => p.url});
  box-sizing: border-box;
  padding: 2rem 1rem 1rem;
`

const Tags = styled.div`
  margin-bottom: 1rem;
  display: flex;
`

const Tag = styled.div<{color: string}>`
  color: ${p => p.color};
  border: 1px solid ${p => p.color};
  border-radius: 2.4rem;
  margin: 0 0.25rem;
  font-size: 0.875rem;
  padding: 0.325rem 0.75rem;
`

const Title = styled.div`
  width: 95%;
  font-size: 2.25rem;
  line-height: 2.3rem;
  margin-bottom: 1.125rem;
  color: white;
  font-weight: bold;
`

const SubTitle = styled.div`
  width: 95%;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: rgba(255, 255, 255, 0.6);
`

const Buttons = styled.div`
  width: 100%;
  button {
    font-size: 0.875rem;
    color: white;
  }
  button:first-child{
    margin-bottom: 0.75rem;
  }
`