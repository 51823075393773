import React from 'react';
import styled from 'styled-components';
import LessonPreview from './LessonPreview';
import { Link } from 'react-router-dom';
import { Avatar, ICourseInfo } from 'src/libs';

export default function CourseSidebar({
  course
}: {
  course: ICourseInfo
}) {

  return <Container>
    <Header>
      <AvatarContainer>
        <Avatar size={16} image={course.lecturer.avatarUrl} />
        {course.lecturer.firstName} {course.lecturer.lastName}
      </AvatarContainer>
      <Title>{course.course.title}</Title>
    </Header>

    <Lessons>
      {course.lessons.map((lesson, index) => <Link to={`/courses/${course.course.id}/lessons/${lesson.id}`} key={index}>
        <LessonPreview
            lesson={lesson}
            course={course}
            index={index + 1}
            key={index}
        />
      </Link>)}
    </Lessons>
  </Container>;
}

const Container = styled.div`
  font-size: 0.8125rem;
  color: var(--text-secondary);
  padding-bottom: 2rem;
`

const Header = styled.div`
  padding-bottom: 1.5rem;
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.875rem;

  > span:first-child {
    margin-right: 0.5rem;
  }
`

const Title = styled.div`
  font-size: 1.875rem;
  color: var(--text-primary);
  line-height: 1;
`

const Lessons = styled.div`
`