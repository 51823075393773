import React, { useEffect } from 'react';
import { useTranslation } from 'src/i18n/useTranslation';
import styled from 'styled-components';

import { Button } from 'src/libs';

import TariffCompare from './TariffCompare';
import { useDispatch, useSelector } from 'react-redux';
import { getTariffs } from 'src/store/reducers/payments/actions';
import { getTariffs as getTariffsSelector } from 'src/store/reducers/payments/selectors';

export default function TariffsCompare({
  onClose
}: {
  onClose(): void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tariffDetails = useSelector(getTariffsSelector);

  useEffect(() => {
    dispatch(getTariffs());
  }, [])
  return <Container>
    { tariffDetails?.length
        ? tariffDetails.map(({ title, note, rows }: any, idx: number) => (
            <TariffCompare title={title} rows={rows} note={note} key={`compare_${idx}`}/>
          ))
        : null
    }

    <SelectButton><Button color="var(--color-blue)" onClick={onClose}>{t("chronos.mobile.settings.payments.tariffs.toTheChoiceOfTariffs")}</Button></SelectButton>
  </Container>
}

const Container = styled.div`
  overflow-y: scroll;
`
const SelectButton = styled.div`
  margin-top: 1.5rem;
`