import { IUser } from "src/libs";
import { t } from "i18next";
import { PROFILE_GET_LOADING, PROFILE_GET_SUCCESS } from "./actions";

export interface IProfileState {
  loading: boolean;
  loaded: boolean;
  data: IUser;
}

export const levelNames:{[key: string]: string} = {
  '0': t("chronos.mobile.store.reducersprofile.levelNames.begining"),
  '1': t("chronos.app.settings.amateur"), 
  '2': t("chronos.app.settings.professional")
};

export interface IActionProfileGetLoading {
  type: typeof PROFILE_GET_LOADING;
}

export interface IActionProfileGetSuccess {
  type: typeof PROFILE_GET_SUCCESS,
  payload: IUser;
}

export type IActionProfile = 
  IActionProfileGetLoading | 
  IActionProfileGetSuccess;
