import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';
import styled from 'styled-components';
import { Container, Mode } from './ModeSelector';

import { IWidget } from './Widgets/data';

export default function WidgetSelector({
  widgets,
  onOpenWidget
}: {
  widgets: IWidget[],
  onOpenWidget(id: string): void;
}){
  const { t } = useTranslation();
  
  return <Container>
    {widgets.map((item: IWidget) => {
      return <WidgetMode key={item.id} onClick={() => onOpenWidget(item.id)}>
        {item.icon} {t(item.short)}
      </WidgetMode>
    })}
  </Container>
}

const WidgetMode = styled(Mode)`
  width: 7rem;
`