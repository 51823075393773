import React from 'react';
import styled from 'styled-components';
import { ICourseLesson, ICourseInfo } from 'src/libs';

import { LightningIcon } from 'src/assets/icons/system/index';
import dayjs from 'dayjs';
import { getPlanId } from '../utils';
import { useTranslation } from 'src/i18n/useTranslation';

export default function LessonPreview({
  lesson,
  course,
  index
}: {
  lesson: ICourseLesson,
  course: ICourseInfo,
  index: number
}) {
  const isFuture = dayjs().add(10, 'minutes') < dayjs(lesson.start);

  const currentPlan = course.plans.find(plan => plan.id === getPlanId(course.course));
  const neededPlan = course.plans.find(plan => plan.id === lesson.planId)!;
  const isByued = lesson.planId === getPlanId(course.course) || currentPlan?.parents.includes(lesson.planId); 
  const { t } = useTranslation();
  let renderContent = null;

  if(!isByued) {
    renderContent = <>
      <Preview>
        <Circle color={neededPlan.color}><LightningIcon /></Circle>
        <BlockedTitle>{t("chronos.app.courses.lesson.preview.onlyOnThePackage")} {neededPlan.title}</BlockedTitle>
      </Preview>
    </>
  } else if(isFuture) {
    renderContent = <>
      <Preview>
        <StartDateBadge>{t("chronos.app.courses.lesson.preview.willBeAvailable")} {dayjs(lesson.start).format('DD.MM.YYYY')}</StartDateBadge>
      </Preview>
    </>
  } else {
    renderContent = <>
      <Preview url={lesson.banner}></Preview>
    </>
  }

  return <Container>
    <Title>{index}.&nbsp;&nbsp;{lesson.title}</Title>
    {renderContent}
  </Container>
}

const Container = styled.div`
  margin-bottom: 1.25rem;
  cursor: pointer;
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.1;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
`

const Preview = styled.div<{url?: string}>`
  width: 100%;
  height: 11.5rem;
  border-radius: 4px;
  background: var(--bg-100);
  background-size: cover;
  background-position: center center;
  background-image: url(${p => p.url});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Circle = styled.div<{color: string}>`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: ${p => p.color};
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: var(--bg-200);
    width: 1rem; 
    height: 1rem;
  }
`

const BlockedTitle = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--text-primary);
  max-width: 60%;
  text-align: center;
`

const StartDateBadge = styled.div`
  padding: 0.25rem 0.5rem 0.175rem;
  background: var(--bg-400);
  color: var(--text-secondary);
  border-radius: 4px;
`