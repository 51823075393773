import styled from "styled-components";
import { s3urls } from '../../helpers/api';
import { useTranslation } from 'src/i18n/useTranslation';
import { IPaymentCardMethod } from "./types";

interface PolicyProps {
  className?: string
  paymentMethod: IPaymentCardMethod
}

export const Policy = (props: PolicyProps) => {
  const {
    className = '',
    paymentMethod,
  } = props

  const { t } = useTranslation()

  const getPrivacyLink = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
          <LinkDoc target="_blank" href={s3urls.privacyPolicyKZ}>
          {t("chronos.app.payments.paymentAgree.2")}
          </LinkDoc>
				)
			default:
				return (
					<LinkDoc target="_blank" href={s3urls.privacyPolicyRU}>
          {t("chronos.app.payments.paymentAgree.2")}
          </LinkDoc>
				)
		}
	}

  const getTermOfUseLink = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
          <LinkDoc target="_blank" href={s3urls.termsOfUseKZ}>
          {t("chronos.app.payments.termOfUse.2")}
          </LinkDoc>
				)
			default:
				return (
					<LinkDoc target="_blank" href={s3urls.termsOfUseRU}>
          {t("chronos.app.payments.termOfUse.2")}
          </LinkDoc>
				)
		}
	}

  return (
    <Container className={className}>
      <Content>{t("chronos.app.payments.paymentAgree.1")} {getPrivacyLink()} {t("base.union")} {getTermOfUseLink()}</Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 2rem 0;
`

const Content = styled.div`
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--text-third);
`

const LinkDoc = styled.a`
  color: var(--colors-blue);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`
