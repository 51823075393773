import React, { ReactNode } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import Notification from 'src/ui/Notification/';

export const NotificationContext = React.createContext<INotificationContext | null>(null);

export type NotificationType = 'success' | 'warning' | 'error';

interface INotification {
  id: number;
  type: NotificationType;
  text: string;
}

interface INotificationContext {
  notifications: INotification[];
  add: any
}

export function NotificationsProvider({
  children
}: {
  children: ReactNode
}) {
  const [notifications, setNotifications] = React.useState<INotification[]>([]);

  const add = (text: string, type: NotificationType = 'success', delay: number = 2500) => {
      const id = +new Date();
      setNotifications((notifications) => [...notifications, { text, type, id }]);

      setTimeout(() => {
          setNotifications((notifications) => [...notifications].filter(item => item.id !== id))
      }, delay);
  }

  const notificationContextValue: INotificationContext = {
      notifications, add
  }

  return <NotificationContext.Provider value={notificationContextValue}>

    {children}

    <NotificationsList>
        <TransitionGroup>
            {notifications.map((item) =>
                <CSSTransition
                    key={item.id}
                    timeout={500}
                    classNames="notification"
                >
                    <Notification type={item.type}>{item.text}</Notification>
                </CSSTransition>
            )}
        </TransitionGroup>
    </NotificationsList>
  </NotificationContext.Provider>
}

const NotificationsList = styled.div`
    position: fixed;
    bottom: 2rem;
    left: 0rem;
    z-index: 50000;

    width: 100%;
    display: flex;
    justify-content: center;

    & > div > div {
        margin-top: 0.75rem;
    }
`