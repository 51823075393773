import { CircleMode, IAuth, IChain } from 'src/libs';
import dayjs from 'dayjs';

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const months = [
  "base.months.january.nom",
  "base.months.february.nom",
  "base.months.march.nom",
  "base.months.april.nom",
  "base.months.may.nom",
  "base.months.june.nom",
  "base.months.july.nom",
  "base.months.august.nom",
  "base.months.september.nom",
  "base.months.october.nom",
  "base.months.november.nom",
  "base.months.december.nom"
];

export const months2 = [
  "base.months.january.gen",
  "base.months.february.gen",
  "base.months.march.gen",
  "base.months.april.gen",
  "base.months.may.gen",
  "base.months.june.gen",
  "base.months.july.gen",
  "base.months.august.gen",
  "base.months.september.gen",
  "base.months.october.gen",
  "base.months.november.gen",
  "base.months.december.gen"
];

export function loadImage(url: string): Promise<unknown> {
  const image = new Image();
  const promise = new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = () => reject(Error(`Loading error - ${image.src}`));
  });
  image.src = url;
  return promise;
}

export function getURL() {
  return new URL(document.location.href)
}

export function latLonToZet(value: number, isLatitude: boolean) {
  // const deg = Math.floor(value);
  // const symbols = isLatitude ? ["N", "S"] : ["E", "W"];
  // return `${deg}${value > 0 ? symbols[0] : symbols[1]}${Math.floor((value - deg) * 60)}`;
  const deg = Math.floor(value);
  const symbols = isLatitude ? ['N', 'S'] : ['E', 'W'];
  return `${deg}°${Math.floor((value - deg) * 60)}'${value > 0 ? symbols[0] : symbols[1]}`;
}

export function getObjectHouses(lon: number, houses: number[]) {
  const result: number[] = [];

  for (let h = 0; h < houses.length; h++) {
    const nextH = (h + 1) % 12;

    const lonH = houses[h];
    let lonNH = houses[nextH];
    let tlon = lon;

    if (lonNH < lonH) {
      if (tlon <= lonNH) {
        tlon += 360;
      }
      lonNH += 360;
    }

    if (tlon >= lonH && tlon < lonNH) {
      result.push(h);
      break;
    }
  }

  return result.sort((a, b) => b - a);
}

// описание объекта options https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat

export function dateFormat(date: any, options?: {[key:string]: string}) {
  return new Intl.DateTimeFormat(localStorage.getItem('i18nextLng') ?? 'ru-RU' , options).format(new Date(date));
}

export function nowLocalISOString() {
  const nowDate = new Date();
  const currentTimeZoneOffset = nowDate.getTimezoneOffset()
  nowDate.setMinutes(nowDate.getMinutes() - currentTimeZoneOffset)
  return nowDate.toISOString()
}

export function formatMessage(n: number, text_forms: string[]) {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2] }
  if (n1 > 1 && n1 < 5) { return text_forms[1] }
  if (n1 === 1) { return text_forms[0] }
  return text_forms[2];
}

export function roundTo(num: number, precision: number) {
  const div = 10 ** precision;
  return Math.round((num + Number.EPSILON) * div) / div;
}

export function getOrbit(chains: IChain[], planet: number): number {
  return chains.find(chain => !!chain.objects[planet])?.objects[planet].orbit || 0;
}

export const initialAuth: IAuth = {
  id: -1,
  name: '',
  firstName: '',
  gender: null,
  lastName: '',
  subscriptions: [],
  expiryDate: dayjs().add(1, 'hours').toISOString(),
  autoPayment: false,
  token: {
    refresh: '',
    access: ''
  },
  theme: 'dark',
  permissions: {
    isPromo: false,
    isTrial: false,
    isUnlimited: false,
    canUsePro: false,
    canUseOnline: true,
    canUseOffline: false
  },
  levelOfAstrology: 0,
  avatarUrl: '',
  showWelcome: false,
  showDawnOnboarding: false,
  showWelcomeNewInterface: false,
  showWelcomeInstruments: false,
  confirmations: {
    privacyAndTerms: false,
  },
  notifications: [],
  isUnderConstruction: false,
  sessionId: -1,
  language: '',
  nps2Visited: [],
  region: undefined,
} as any; // FIXME: any

export const getDeviceInfo = () => {

  const userAgent = (typeof window?.navigator) === 'undefined' ? '' : navigator.userAgent;
  const isMobile = Boolean(
    /android|webos|iphone|ipod|blackberry|bb|playbook|iemobile|windows phone|kindle|silk|opera mini|wpdesktop/i.exec(userAgent)
  );
  const isTablet = Boolean(
    /Tablet|iPad|Nexus 7|Nexus 10|SM-T\d+|KFAPWI/i.exec(userAgent)
  )

  return { isMobile, isTablet };
}

export const getCurrencySymbol = (code?: string) => {
  switch (code?.toLowerCase()) {
    case 'rub':
      return '₽';
    case 'usd':
      return '$';
    case 'kzt':
      return '₸';
    default:
      return '';
  }
}


export const smoothHorizontalScrolling = (e: any, time: number, start: number, distance: number) => {
  let currentFraction = 0
  let currentTime = 0

  while (currentTime <= time) {
    const currentDistance = distance / 1000 * currentFraction

    window.setTimeout(() => {
      e.current.scrollTo({'left': start + currentDistance})
    }, currentTime);

    currentTime += time / 1000
    currentFraction++
  }
}

export const isCompatibility = (mode: CircleMode) => /^compatibility\d*$/.test(mode);

export const isPartner = (mode: CircleMode) => /^partner\d+$/.test(mode);

export function clearURLSearchParams(keys: string | string[], method?: 'push' | 'replace'): void {
  if (!keys || !window.location.search) return;

  const methodName = method || 'push';
  let { pathname, search } = window.location
  let href: string;

  keys = Array.isArray(keys) ? keys : [keys];
  const sp = new URLSearchParams(search)
  keys.map(key => sp.delete(key))
  const searchStr = sp.toString()
  href = `${pathname}${searchStr.length ? `?${searchStr}` : ``}`
  history[methodName](href)
}