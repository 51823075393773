export const subscriptions = [
  "chronos.app.ArchivedTariff",
  "chronos.app.AmateurTariff",
  "chronos.app.ProfessionalTariff",
  "chronos.app.UnlimitedTariff"
];

export const ObjectType = {
  Sun: 0,
  Moon: 1,
  Mercury: 2,
  Venus: 3,
  Mars: 4,
  Jupiter: 5,
  Saturn: 6,
  Uranus: 7,
  Neptune: 8,
  Pluto: 9,
  NorthNode: 10,
  SouthNode: 11,
  Lilith: 12,
  Chiron: 13,
  Selena: 14,
  Eris: 15
};

export enum StatusType {
  ruler,
  exaltation,
  triplicity,
  bounds,
  face,
  fall,
  detriment
}

export const SignType = {
  Aries: 0,
  Taurus: 1,
  Gemini: 2,
  Cancer: 3,
  Leo: 4,
  Virgo: 5,
  Libra: 6,
  Scorpio: 7,
  Sagittarius: 8,
  Capricorn: 9,
  Aquarius: 10,
  Pisces: 11
};

export interface IDispositor {
  [key: number]: {
    [key: number]: (number | null)[];
  };
}

export const dispositors: IDispositor = {
  [SignType.Aries]: {
    [StatusType.ruler]: [ObjectType.Mars, ObjectType.Pluto],
    [StatusType.exaltation]: [ObjectType.Sun],
    [StatusType.triplicity]: [ObjectType.Jupiter, ObjectType.Neptune],
    [StatusType.bounds]: [ObjectType.Moon],
    [StatusType.face]: [ObjectType.Mercury, ObjectType.Uranus],
    [StatusType.fall]: [ObjectType.Saturn],
    [StatusType.detriment]: [ObjectType.Venus]
  },
  [SignType.Taurus]: {
    [StatusType.ruler]: [ObjectType.Venus],
    [StatusType.exaltation]: [ObjectType.Moon],
    [StatusType.triplicity]: [ObjectType.Saturn, ObjectType.Mercury],
    [StatusType.bounds]: [ObjectType.Sun],
    [StatusType.face]: [ObjectType.Jupiter, ObjectType.Neptune],
    [StatusType.fall]: [ObjectType.Uranus],
    [StatusType.detriment]: [ObjectType.Mars, ObjectType.Pluto]
  },
  [SignType.Gemini]: {
    [StatusType.ruler]: [ObjectType.Mercury],
    [StatusType.exaltation]: [ObjectType.Mercury],
    [StatusType.triplicity]: [ObjectType.Venus, ObjectType.Saturn, ObjectType.Uranus],
    [StatusType.bounds]: [ObjectType.Moon],
    [StatusType.face]: [ObjectType.Sun, ObjectType.Mars, ObjectType.Pluto],
    [StatusType.fall]: [null],
    [StatusType.detriment]: [ObjectType.Jupiter, ObjectType.Neptune]
  },
  [SignType.Cancer]: {
    [StatusType.ruler]: [ObjectType.Moon],
    [StatusType.exaltation]: [ObjectType.Jupiter],
    [StatusType.triplicity]: [ObjectType.Pluto, ObjectType.Neptune],
    [StatusType.bounds]: [ObjectType.Sun],
    [StatusType.face]: [ObjectType.Mercury, ObjectType.Venus],
    [StatusType.fall]: [ObjectType.Mars],
    [StatusType.detriment]: [ObjectType.Saturn, ObjectType.Uranus]
  },
  [SignType.Leo]: {
    [StatusType.ruler]: [ObjectType.Sun],
    [StatusType.exaltation]: [ObjectType.Pluto],
    [StatusType.triplicity]: [ObjectType.Jupiter, ObjectType.Mars],
    [StatusType.bounds]: [ObjectType.Moon],
    [StatusType.face]: [ObjectType.Mercury, ObjectType.Venus],
    [StatusType.fall]: [ObjectType.Neptune],
    [StatusType.detriment]: [ObjectType.Saturn, ObjectType.Uranus]
  },
  [SignType.Virgo]: {
    [StatusType.ruler]: [ObjectType.Mercury],
    [StatusType.exaltation]: [ObjectType.Mercury],
    [StatusType.triplicity]: [ObjectType.Saturn, ObjectType.Uranus],
    [StatusType.bounds]: [ObjectType.Sun],
    [StatusType.face]: [ObjectType.Moon, ObjectType.Mars, ObjectType.Pluto],
    [StatusType.fall]: [ObjectType.Venus],
    [StatusType.detriment]: [ObjectType.Jupiter, ObjectType.Neptune]
  },
  [SignType.Libra]: {
    [StatusType.ruler]: [ObjectType.Venus],
    [StatusType.exaltation]: [ObjectType.Saturn],
    [StatusType.triplicity]: [ObjectType.Mercury, ObjectType.Uranus],
    [StatusType.bounds]: [ObjectType.Moon],
    [StatusType.face]: [ObjectType.Jupiter, ObjectType.Neptune],
    [StatusType.fall]: [ObjectType.Sun],
    [StatusType.detriment]: [ObjectType.Mars, ObjectType.Pluto]
  },
  [SignType.Scorpio]: {
    [StatusType.ruler]: [ObjectType.Pluto, ObjectType.Mars],
    [StatusType.exaltation]: [ObjectType.Uranus],
    [StatusType.triplicity]: [ObjectType.Jupiter, ObjectType.Neptune],
    [StatusType.bounds]: [ObjectType.Sun],
    [StatusType.face]: [ObjectType.Saturn, ObjectType.Mercury],
    [StatusType.fall]: [ObjectType.Moon],
    [StatusType.detriment]: [ObjectType.Venus]
  },
  [SignType.Sagittarius]: {
    [StatusType.ruler]: [ObjectType.Jupiter, ObjectType.Neptune],
    [StatusType.exaltation]: [null],
    [StatusType.triplicity]: [ObjectType.Sun, ObjectType.Mars, ObjectType.Pluto],
    [StatusType.bounds]: [ObjectType.Moon],
    [StatusType.face]: [ObjectType.Venus, ObjectType.Saturn, ObjectType.Uranus],
    [StatusType.fall]: [ObjectType.Mercury],
    [StatusType.detriment]: [ObjectType.Mercury]
  },
  [SignType.Capricorn]: {
    [StatusType.ruler]: [ObjectType.Saturn, ObjectType.Uranus],
    [StatusType.exaltation]: [ObjectType.Mars],
    [StatusType.triplicity]: [ObjectType.Mercury, ObjectType.Venus],
    [StatusType.bounds]: [ObjectType.Sun],
    [StatusType.face]: [ObjectType.Pluto, ObjectType.Neptune],
    [StatusType.fall]: [ObjectType.Jupiter],
    [StatusType.detriment]: [ObjectType.Moon]
  },
  [SignType.Aquarius]: {
    [StatusType.ruler]: [ObjectType.Uranus, ObjectType.Saturn],
    [StatusType.exaltation]: [ObjectType.Neptune],
    [StatusType.triplicity]: [ObjectType.Mercury, ObjectType.Venus],
    [StatusType.bounds]: [ObjectType.Moon],
    [StatusType.face]: [ObjectType.Jupiter, ObjectType.Mars],
    [StatusType.fall]: [ObjectType.Pluto],
    [StatusType.detriment]: [ObjectType.Sun]
  },
  [SignType.Pisces]: {
    [StatusType.ruler]: [ObjectType.Neptune, ObjectType.Jupiter],
    [StatusType.exaltation]: [ObjectType.Venus],
    [StatusType.triplicity]: [ObjectType.Moon, ObjectType.Pluto, ObjectType.Mars],
    [StatusType.bounds]: [ObjectType.Sun],
    [StatusType.face]: [ObjectType.Saturn, ObjectType.Uranus],
    [StatusType.fall]: [ObjectType.Mercury],
    [StatusType.detriment]: [ObjectType.Mercury]
  }
};
