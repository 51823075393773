import React, { useState } from 'react';
import styled from 'styled-components';

import DashboardInput from 'src/ui/DashboardInput';
import Header from './components/Header';
import DashboardRows, { Folder } from './DashboardRows';
import DashboardGrid from './DashboardGrid';

import { SearchIcon } from 'src/assets/icons/system/index';
import { RowsIcon } from 'src/assets/icons/system/index';
import { GridIcon } from 'src/assets/icons/system/index';

import { IDashboardSort } from 'src/store/localStorage/forms';
import { useSelector } from 'src/store/utils';
import { allForms } from 'src/store/reducers/forms/selectors';
import { getDashboardViewType, setDashboardViewType, getDashboardSort, setDashboardSort } from 'src/store/localStorage/forms';
import { IFolder, IShortFormData } from 'src/libs';
import { getAllFolders } from 'src/store/reducers/folders/selectors';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import EditGroupForPopup from './components/EditGroupForPopup';
import EditLabelForPopup from './components/EditLabelForPopup';
import Footer from 'src/components/Footer.tsx';
import { IFolderWithCount } from 'src/store/reducers/folders/types';
import { useTranslation } from 'src/i18n/useTranslation';

function sortForms(items: IShortFormData[], field: string, order: string){
  if(order === 'none') return [...items];
  if(field === 'name') {
    return items.sort((a: IShortFormData, b: IShortFormData) => {
      return sortValues(order, a.name, b.name);
    });
  }

  if(field === 'place') {
    return items.sort((a: IShortFormData, b: IShortFormData) => {
      return sortValues(order, a.place, b.place);
    });
  }

  if(field === 'modifiedDate') {
    return items.sort((a: IShortFormData, b: IShortFormData) => {
      return sortValues(order, new Date(a.modified), new Date(b.modified));
    });
  }

  if(field === 'birthDate') {
    return items.sort((a: IShortFormData, b: IShortFormData) => {
      const aParts = a.dateTime.split('.');
      const aDate = new Date(+aParts[2], +aParts[1]-1, +aParts[0]);
      const bParts = b.dateTime.split('.');
      const bDate = new Date(+bParts[2], +bParts[1]-1, +bParts[0]);
      return sortValues(order, aDate, bDate);
    });
  }
}

function sortFolders(items: IFolder[], _: string, order: string){
  if(order === 'none') return [...items];
  return items.sort((a: IFolder, b: IFolder) => {
    return sortValues(order, a.title, b.title);
  });
}

function sortFormsByPin(items: IShortFormData[]){
  const pinned = items.filter(item => item.isPinned) || [];
  const unpinned = items.filter(item => !item.isPinned) || [];
  return pinned.concat(unpinned);
}

function sortFoldersByPin(items: IFolder[]){
  const pinned = items.filter(item => item.is_pinned) || [];
  const unpinned = items.filter(item => !item.is_pinned) || [];
  return pinned.concat(unpinned);
}

function sortValues(order: string, a: any, b: any) {
  if(order === 'asc') return a > b ? 1 : -1;
  else return a < b ? 1 : -1;
}

export default function DashboardPage() {
  const forms = useSelector(allForms);
  const folders = useSelector(getAllFolders)
  
  const [viewType, setViewType] = useState(getDashboardViewType() || 'ROWS');
  const [sort, setSort] = useState<IDashboardSort>(getDashboardSort() || {order: 'desc', field: 'modifiedDate'});
  const [filter, setFilter] = useState('');
  const [folder, setFolder] = useState<IFolder|null>(null);
  const [openAddGroupPopup, setOpenAddGroupPopup] = useState(false);
  const [openAddLabelPopup, setOpenAddLabelPopup] = useState(false);
  const { t } = useTranslation();


  let sortedForms = sortForms([...forms.data], sort.field, sort.order) || [];
  sortedForms = sortedForms.filter((form: IShortFormData) => !form.isTemporary);
  const allItems = sortedForms.filter((item: IShortFormData) => item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  sortedForms = sortFormsByPin(sortedForms);
  sortedForms = sortedForms.filter((item: any) => item.folderId === (folder?.id || null));
  const allItemsWithoutFolders = sortedForms.filter((item: IShortFormData) => item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);

  let sortedFolders = sortFolders([...folders.data], sort.field, sort.order) || [];
  sortedFolders = sortFoldersByPin(sortedFolders);
  sortedFolders.forEach((item: IFolderWithCount) => item.count = forms.data.filter((form: any) => form.folderId === item.id).length)
  if(folder) sortedFolders = [];

  const changeViewType = () => {
    const value = viewType === 'ROWS' ? 'GRID' : 'ROWS';
    setDashboardViewType(value);
    setViewType(value);
  }

  const onSort = (data: IDashboardSort) => {
    setDashboardSort(data);
    setSort(data);
  }

  const onChangeFolder = (id: number) => {
    setFolder(folders.data.find((item: IFolder) => item.id === id) || null);
  }

  const viewTypeIcon = <div onClick={changeViewType}>
    {viewType === 'ROWS' ? <GridIcon/> : <RowsIcon/>}
  </div>;

  const props = {
    folders: sortedFolders.filter((item: IFolder) => item.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1),
    items: filter ? allItems : allItemsWithoutFolders,
    sort,
    onSort,
    onChangeFolder,
    onOpenAddGroupPopup: setOpenAddGroupPopup,
    onOpenAddLabelPopup: setOpenAddLabelPopup
  }
  const dashboardItems = <>
    {viewType === 'ROWS' && <DashboardRows {...props} />}
    {viewType === 'GRID' && <DashboardGrid {...props} />}
  </>;

  
  

  return <div>
    <Header></Header>
    <SearchContainer>
      <DashboardInput 
        placeholder={t("chronos.app.mapSearch")} 
        iconLeft={<SearchIcon />} 
        iconRight={viewTypeIcon}
        value={filter}
        onChange={setFilter}
      />
    </SearchContainer>

    
    {folder && <Navigation onClick={() => setFolder(null)}>
      <span>{t("chronos.app.dashboard.maps")} </span>
      <span>/</span> 
      <Folder color={folder.color}></Folder>
      <span>{folder.title}</span>
    </Navigation>}

    {dashboardItems}

    <PopupBottom open={openAddGroupPopup} onClose={() => setOpenAddGroupPopup(false)} title={t("chronos.mobile.dashboard.addGroup")} back={true}>
      <EditGroupForPopup open={openAddGroupPopup} onClose={() => setOpenAddGroupPopup(false)}  />
    </PopupBottom>

    <PopupBottom open={openAddLabelPopup} onClose={() => setOpenAddLabelPopup(false)} title={t("chronos.mobile.dashboard.addLabel")} back={true}>
      <EditLabelForPopup open={openAddLabelPopup} onClose={() => setOpenAddLabelPopup(false)}  />
    </PopupBottom>

    <Footer active="dashboard" setOpenAddGroupPopup={setOpenAddGroupPopup} />
    
  </div>
}

const SearchContainer = styled.div`
  margin-bottom: 1rem;
`

const Navigation = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 0.875rem;

  svg {
    display: inline-block;
  }

  * {
    margin-right: 0.375rem;
  }
`
