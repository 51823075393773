import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import redirect from 'src/store/middlewares/redirect';
import { rootReducer } from './reducers';
import { ReactNode } from 'react';

const middlewares = [thunk, redirect];

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export type IRootState = ReturnType<typeof rootReducer>;

export default function StoreProvider({
  children
}:{
  children: ReactNode
}) {
  return <Provider store={store}>
    {children}
  </Provider>
}