import api, { IFormLabel } from "src/api";
import { Dispatch } from "redux";
import { signOut } from "../profile/actions";
import { IActionLabelsGetLoading, IActionLabelsGetSuccess } from "./types";

export const LABELS_GET_SUCCESS = 'LABELS_GET_SUCCESS';
export const LABELS_GET_LOADING = 'LABELS_GET_LOADING';

export const getLabels = (loading: boolean = true) => {
  return (dispatch: Dispatch) => {
      loading && dispatch(getLoading());
      api.labels()
          .then((data: IFormLabel[]) => { dispatch(getSuccess(data)) })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const addLabel = (title: string, callback: () => void) => {
  return (dispatch: Dispatch) => {
      api.addLabel({ title, color: '#EF5350' } as IFormLabel)
          .then(() => { callback && callback(); })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const deleteLabel = (id: number, callback: () => void) => {
  return (dispatch: Dispatch) => {
      api.deleteLabel(id)
          .then(() => { callback && callback(); })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const updateLabel = (item: IFormLabel, callback: () => void) => {
  return (dispatch: Dispatch) => {
      const { id, color, title } = item;
      api.updateLabel(id, { title, color } as IFormLabel)
          .then(() => { callback && callback(); })
          .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

const getLoading = (): IActionLabelsGetLoading => {
  return {
    type: LABELS_GET_LOADING
  }
}

const getSuccess = (data: IFormLabel[]): IActionLabelsGetSuccess => {
  return {
    type: LABELS_GET_SUCCESS,
    payload: data
  }
}