import React from 'react';
import styled, { css } from 'styled-components';

import { toDateTime } from 'src/libs';

import ResultEvent from './ResultEvent';
import AmountIcon from './AmountIcon';
import { IInterval } from '.';

import { IEvent } from './types';

import { PlusIcon } from 'src/assets/icons/system/index';
import { ArrowRightIcon } from 'src/assets/icons/arrows/index';
import { ArrowLeftIcon } from 'src/assets/icons/arrows/index';
import { TopTriangleIcon } from 'src/assets/icons/arrows/index';
import { useTranslation } from 'src/i18n/useTranslation';
import { t } from 'i18next';
import { useDeviceDetect } from 'src/hooks';

export default function ResultItem(props: {
  index: number;
  interval: IInterval;
  openMore(): void;
  selectRect(i: number, restore: boolean): void;
  isCurrent: boolean;
  allEvents: IEvent[];
}) {
  const { isMobile } = useDeviceDetect();
  const { begin, end, timestamp } = props.interval;

  const maxScore = begin.events.reduce((p, e) => Math.max(p, e.score), 0);

  const returnTime = isMobile ? <ArrowLeftIcon /> : t("chronos.app.instruments.resultitem.returnTime");

  const beginTime = toDateTime(begin.key).time;
  const endTime = end ? toDateTime(end.key).time : '';

  const intervalName = (index: number) => {
    return index == 0
      ? isMobile
          ? <span>{t("chronos.app.instruments.resultitem.recommendedTimeShort")}</span>
          : <span>{t("chronos.app.instruments.resultitem.recommendedTimeFull")}</span>
      : <span>{t("chronos.app.instruments.resultitem.interval")} {index + 1}</span>;
  }

  return <ResultItemC recommend={props.index == 0}>
    <header>
      <span><a onClick={props.openMore}>{intervalName(props.index)} <TopTriangleIcon /></a></span>
      <button
        onClick={() => props.selectRect(props.index, props.isCurrent)}
        style={{
          background: props.isCurrent ? 'var(--color-blue)' : 'inherit'
        }}
      >
        {props.isCurrent ? returnTime : <><PlusIcon /> {t("chronos.app.instruments.resultitem.inCard")}</>}
      </button>
    </header>
    <Interval>
      <span>{beginTime}</span>
      {end &&
        <>
          <span><ArrowRightIcon /></span>
          <span>{endTime}</span>
          <span>{!isMobile && t("chronos.app.instruments.widgets.rectification.result")}{toDateTime(new Date(timestamp!).toISOString()).time}</span>
        </>
      }
    </Interval>
    <Events>
      <header>{t("chronos.app.widgets.title.horarEvents")} <AmountIcon amount={begin.score} /></header>
      <EventsList>
        {begin
          .events
          .map((ev, i) => <ResultEvent
            key={`result_${i}`}
            amount={ev.score}
            name={t(props.allEvents.find(e => e.id == ev.id)!.name)}
            width={ev.percentAmountEvents || 0}
          />)
        }
      </EventsList>
    </Events>
  </ResultItemC>;
}

const ResultItemC = styled.section<{ recommend?: boolean }>`
  padding: 0.5rem;
  background: var(--input-background);
  border: 1px solid var(--input-border);
  border-radius: 6px;
  margin-bottom: 0.85rem;

  ${props => props.recommend && css`
    border: 2px solid var(--color-blue);
  `}

  & > header {
    display: flex;
    margin-bottom: 0.7rem;
    align-items: center;
    color: var(--text-primary);

    & [data-mobile] {
      display: none !important;
    }
    
    & > span {
      flex: 1;
      font-size: 1rem;

      & a {
        color: var(--color-blue);
        cursor: pointer;

        & svg {
          display: inline-block;
          margin-left: 0.2rem;
          width: 1rem;
          transform: rotate(90deg);
          vertical-align: middle;
        }

        :hover {
          text-decoration: underline;
        }
      }
    }

    & button {
      display: flex;
      align-items: center;
      margin-left: auto;
      padding: 0.5rem 0.4rem;
      outline: none;
      background: var(--input-background);
      border: 1px solid var(--input-border);
      border-radius: 4px;
      background: none;
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: var(--text-secondary);

      cursor: pointer;

      & svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.4rem;
        fill-opacity: 0.5;
      }

      &:hover svg {
        fill-opacity: 1;
      }
    }
  }
`;

const Interval = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  align-items: center;
  color: var(--text-secondary);

  & span {
    margin-right: 0.7rem;
  }
  & span:last-child {
    color: var(--text-primary);
  }

  & svg {
    width: 0.875rem;
  }
`;

const Events = styled.div`
  & > header {
    margin-bottom: 0.65rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: var(--text-primary);
  }
`;

const EventsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
  grid-gap: 0.375rem;
`;
