import React, { useState } from 'react';
import styled from 'styled-components';

import { PersonalisationIcon } from 'src/assets/icons/system/index';
import { TabWithIcons } from 'src/ui/SwipeTabs';

import ColorTheme from './components/personalisation/ColorTheme';
import Maps from './components/personalisation/Maps';
import { useTranslation } from 'src/i18n/useTranslation';
import LanguageSwitcher from './components/LanguageSwitcher';



export default function Personalisation() {
  return <Container>
    <ColorTheme />
    <LangWrapper><LanguageSwitcher /></LangWrapper>
    <Maps />
  </Container>
}

export function Tab ({text}: {text: string}) { 
  const { t } = useTranslation();
  
  return <TabWithIcons>
    <PersonalisationIcon />
      {t(text)}
</TabWithIcons>}

const Container = styled.div`
  width: calc(100% - 1px);
  overflow: hidden;
`
const LangWrapper = styled.div`
  width: 15rem;
`
const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
`