import React from 'react';
import styled from 'styled-components';

export default function NavigationPoints({
  count,
  active,
  onChange
}:{
  count: number;
  active: number;
  onChange(index: number): void;
}){
  return <Container>
    {Array(count).fill(null).map((_, index) => <NavigationPoint key={`point_n_${index}`} active={index === active}></NavigationPoint>)}
  </Container>
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const NavigationPoint = styled.div<{active?: boolean}>`
  width: ${p => p.active ? '0.875rem' : '0.25rem'};
  height: 0.25rem;
  border-radius: 1.75rem;
  background: ${p => p.active ? 'var(--color-blue)' : 'var(--navigation-points-bg)'};
  margin-right: 0.5rem;
  transition: all 0.2s;

  &:last-child {
    margin-left: 0;
  }
` 