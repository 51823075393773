import React from 'react';
import styled, { css } from 'styled-components';

import { WarningIcon } from 'src/assets/icons/system';

export type MessageType = 'info' | 'warning' | 'error' | 'success';

const IconByType: { [key: string]: any } = {
  'warning': WarningIcon
};

export default function Alert({
  type,
  children
}: {
  type: MessageType;
  children: any;
}) {
  const Icon = IconByType[type];
  return (
    <Container type={type}>
      <IconBar><Icon /></IconBar>
      <Content>{children}</Content>
    </Container>
  );
}

export const Container = styled.figure<{ type: MessageType }>`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  padding: 0.8rem 1.2rem;

  box-shadow: 0px 3.2px 7.2px rgba(90, 91, 106, 0.24), 0px 0.6px 1.8px rgba(58, 58, 68, 0.24);

  ${props => props.type === 'error' && css`
    background-color: var(--colors-red);
  `}

  ${props => props.type === 'warning' && css`
    background-color: var(--colors-orange);
  `}

  ${props => props.type === 'info' && css`
    background-color: var(--colors-blue);
  `}

  ${props => props.type === 'success' && css`
    background-color: var(--colors-green);
  `}
  
`;

const IconBar = styled.div`
  display: flex;
  /* position: relative; */
  /* top: 0.25rem; */
  min-width: 2.625rem;
  color: #fff;

  & svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const Content = styled.div`
  display: flex;
  white-space: nowrap;
  /* position: relative; */
  /* flex-direction: column; */

  font-weight: 600;
  font-size: 0.875rem;
  /* line-height: 150%; */

  color: var(--text-alert);

  & > * {
    margin: 0;
  }
`;
