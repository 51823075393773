import { IDropdownOption } from "src/libs";

export type IRule = {
  id: number;
  name: string;
  checked?: boolean;
  asp?: string;
  dir?: string;
  nat?: string;
};

export type IEvent = {
  id: number;
  enabled: boolean;
  name: string;
  rules: IRule[];
  date?: string;
  weight: number;
  school?: number;
  isDeath?: boolean;
};

export const intervals: IDropdownOption[] = [
  { label: "chronos.app.instruments.widgets.rectification.types.10min", value: 600 },
  { label: "chronos.app.instruments.widgets.rectification.types.30min", value: 1800 }
];


export const steps: IDropdownOption[] = [
  { label: "chronos.app.instruments.widgets.rectification.types.1min", value: 60 },
  { label: "chronos.app.instruments.widgets.rectification.types.10sec", value: 10 },
  { label: "chronos.app.instruments.widgets.rectification.types.1sec", value: 1 }
];

