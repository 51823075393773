import React, { useEffect } from 'react';
import styled from 'styled-components';

import Header from './components/Header';
import Footer from './components/Footer';
import SwipeTabs, { ISwipeTab } from 'src/ui/SwipeTabs';

import Payments, { Tab as PaymentsTab } from './Payments';
import Personalisation, { Tab as PersonalisationTab } from './Personalisation';
import MapSettings, { Tab as MapSettingsTab } from './MapSettings';
//import ChangeLogs, { Tab as ChangeLogsTab } from './ChangeLogs';
import Support, { Tab as SupportTab } from './Support';

import { routes } from 'src/app/routing';
import { useTranslation } from 'src/i18n/useTranslation';


export default function SettingsPage() {
  const items: ISwipeTab[] = [
    { key: 'payments', title: <PaymentsTab text="settings.payments" />, content: <Payments /> },
    { key: 'personal', title: <PersonalisationTab text="settings.personal"/>, content: <Personalisation /> },
    { key: 'maps', title: <MapSettingsTab text="settings.maps" />, content: <MapSettings /> },
    //{ title: ChangeLogsTab, content: <ChangeLogs /> },
    { key: 'support', title: <SupportTab text="settings.support" />, content: <Support /> },
  ]

  const { t } = useTranslation();

  useEffect(() => {

    document.body!.style.backgroundColor = 'var(--bg-400)';
    return () => {
      document.body!.style.backgroundColor = 'var(--bg-200)';
    }
  }, [])

  return <Container>
    <Header />

    <SwipeTabs open={true} items={items} onChangeTab={(key: any) => {
      document.title = `${t(routes.settings.title.prefix)} - ${t(routes.settings.title[key])}`
    }} />

    <Footer />
  </Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // background-color: var(--bg-400);
`
