import React from 'react';
import styled from 'styled-components';
import { Button } from 'src/libs';

import { PopupBottom, IPopupBottomProps } from '../PopupBottom';
import { useTranslation } from 'src/i18n/useTranslation';

interface IConfirmPopupBottomProps extends IPopupBottomProps {
  onConfirm: () => void;
  confirmText: string;
}

export default function ConfirmPopupButton(props: IConfirmPopupBottomProps){
  const { t } = useTranslation();
  return <PopupBottom {...props}>
    <Text>{props.children}</Text>
    <Buttons>
      <Button color="var(--color-red)" onClick={props.onConfirm}>{props.confirmText}</Button>
      <Button onClick={props.onClose}>{t("base.cancel")}</Button>
    </Buttons>
  </PopupBottom>
}

const Text = styled.div`
  font-size: 1rem;
  color: var(--text-secondary);
  line-height: 1.5rem;
  margin-top: 0.5rem;
  text-align: center;
`

const Buttons = styled.nav`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  
  > button {
    margin-top: 1rem;
  }

  & > button + button {
    margin-left: 0.5rem;
  }
`