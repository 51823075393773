import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import _, { cloneDeep } from 'lodash';

import { astroObjects, aspects, IStrongAspect, ObjectType, signs, ElementType, getSign, elements, SignType, AspectType, checkAspect, planetsPower, housesPower, IAstroSettings, getObjectName, houseNames, getPlanetsInfo, ICalculation, localTime } from 'src/libs';

import astro from 'src/astro';
import { aspectsIcons, objectsIcons } from 'src/helpers/icons';

import { IWidgetData } from '../data';
import { t } from 'i18next';
import { useSelector } from 'src/store/utils';
import { allSettingsAstro, getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
import { useTranslation } from 'react-i18next';
import { acccesOnlyForRu } from 'src/helpers/permissions';
import i18n from 'src/i18n/i18n';
import { default as TooltipWrapper } from 'src/ui/Wrappers/TooltipWrapper';
import { calcNatalAspectsWithFloatingOrbiseCorrector } from '../../../utils';


const powerHouses = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII'
];

const strongHousesLimit = 4;

function elementBySign(sign: SignType) {
  for (let e = 0; e < 4; e++) {
    if (elements[e].includes(sign)) return e;
  }

  return ElementType.Air;
}

function strongElements(data: IWidgetData) {
  const objects: {
    [key: number]: number
  } = {
    [ObjectType.Sun]: ElementType.Fire,
    [ObjectType.Mars]: ElementType.Fire,
    [ObjectType.Jupiter]: ElementType.Fire,

    [ObjectType.Venus]: ElementType.Land,
    [ObjectType.Saturn]: ElementType.Land,

    [ObjectType.Moon]: ElementType.Water,
    [ObjectType.Neptune]: ElementType.Water,
    [ObjectType.Pluto]: ElementType.Water,

    [ObjectType.Mercury]: ElementType.Air,
    [ObjectType.Uranus]: ElementType.Air,
  };

  const els = {
    [ElementType.Land]: 0,
    [ElementType.Air]: 0,
    [ElementType.Water]: 0,
    [ElementType.Fire]: 0,
  };

  // const elNames = {
  //   0: "Земля",
  //   1: "Воздух",
  //   2: "Вода",
  //   3: "Огонь"
  // };

  // const printEls = (lbl: string) => {
  //   console.log(lbl);
  //   Object.keys(els)
  //     .forEach(key => console.log(elNames[+key], els[+key]));
  // };

  const natal = data.natalMap
  //@ts-ignore
  const addObj = (obj: ObjectType) => els[objects[obj]]++;
  //@ts-ignore
  const addObjBySign = (obj: ObjectType) => els[elementBySign(getSign(natal.objects[obj].lon))]++;
  //@ts-ignore
  els[elementBySign(getSign(natal.houses[0]))]++;
  // printEls("1 asc");
  addObjBySign(ObjectType.Sun);
  // printEls("2 sun");
  addObjBySign(ObjectType.Moon);
  // printEls("3 moon");

  const opts = astro.checkAspectOpts('natal');

  for (let obj = ObjectType.Sun; obj <= ObjectType.Pluto; obj++) {
    if (
      obj != ObjectType.Sun && checkAspect(
        astro.getPairOrbise("natal", ObjectType.Sun, obj, AspectType.Conjunction),
        natal.objects[ObjectType.Sun].lon,
        natal.objects[obj].lon,
        AspectType.Conjunction,
        opts
      ).exists ||
      obj != ObjectType.Moon && checkAspect(
        astro.getPairOrbise("natal", ObjectType.Moon, obj, AspectType.Conjunction),
        natal.objects[ObjectType.Moon].lon,
        natal.objects[obj].lon,
        AspectType.Conjunction,
        opts
      ).exists ||
      checkAspect(
        4,
        natal.houses[0],
        natal.objects[obj].lon,
        AspectType.Conjunction,
        opts
      ).exists
    ) {
      addObj(obj);
    }
  }

  // printEls("4");

  const spl = [...data.strongs.planets];

  while (spl.length) {
    const stable = els[ElementType.Fire] + els[ElementType.Land];
    const unstable = els[ElementType.Water] + els[ElementType.Air];

    const sels = Object.keys(els)
      //@ts-ignore
      .map(k => ({ el: +k, v: els[+k] }))
      .sort((a, b) => b.v - a.v);

    // console.log("6.1", "стаб", stable, "нестаб", unstable);
    // console.log("6.2", "стихии", sels);

    if (stable != unstable && sels[1].v != sels[2].v) {
      const names = [
        t("chronos.app.dashboard.strongObjects.earth"),
        t("chronos.app.dashboard.strongObjects.air"),
        t("chronos.app.dashboard.strongObjects.wather"),
        t("chronos.app.dashboard.strongObjects.fire"),
      ];

      return t("chronos.mobile.instruments.widgets.strongObjects.stableUnstable", { stabUnstab: stable > unstable ? t("chronos.app.dashboard.strongObjects.sustainable") : t("chronos.app.dashboard.strongObjects.unstable"), el1: names[sels[0].el], el2: names[sels[1].el] });
    }

    const { p } = spl.shift()!;
    // console.log("6.2.x", "сильная планета", astroObjects[p].ru);
    addObj(p);
  }

  return null;
}

export default function StrongObjects(props: {
  data: IWidgetData;
  openedWidget: boolean;
}) {
  const [natal, setNatal] = useState<ICalculation | null>(null);
  const { data: profiles } = useSelector(allSettingsAstro);
  const activeAstroProfile = useSelector(getActiveAstroProfile);
  const _map = cloneDeep(props.data.maps[0])

  const oldSettings = cloneDeep(astro.settings);

  const profilePA = profiles.find((p: IAstroSettings) => p.id === 0)!;
  const orbise = astro.settings.maps.natal.orbiseCorrector;

  const floatingOrbise = (activeAstroProfile && activeAstroProfile.closureConfig) ?? 0;

  astro.settings = cloneDeep(profilePA);
  astro.settings.maps.natal.orbiseCorrector = orbise;

  astro.settings.closureConfig = floatingOrbise;

const getNatal = useCallback(async () => {
  try {
    const ErisObjectNatal = await astro.object(localTime(props.data.form.natal), 136199 + 10000);
    const res = await astro.natal(
      localTime(props.data.form.natal),
      props.data.form.natal.place.lat,
      props.data.form.natal.place.lon,
      props.data.form.cosmogram,
    );
    res.objects = [...res.objects, ErisObjectNatal]
    setNatal(res);
  } catch (error) {
    console.error(error);
  } 
}, [props.data]);

useEffect(() => {
  getNatal();
}, [getNatal]);



// FIXME: Check astro.natalAspects. It can give a wrong result
// @ts-ignore
// if (_map) _map.aspects = astro.natalAspects(_map.objects);
// if (_map) _map.aspects = props.data.natalMap.aspects;


if (_map && natal && natal.objects && natal.houses ) {
  _map.aspects = astro.natalAspects(natal.objects, undefined, natal.houses, undefined, { floatingOrbise: 0 })
  _map.aspects = calcNatalAspectsWithFloatingOrbiseCorrector(_map.aspects, natal, floatingOrbise);
  _map.houses = cloneDeep(natal.houses)
  _map.objects = cloneDeep(natal.objects)
  _map.mode = 'natal'
  }



  const strongs = {
    planets: planetsPower(_map),
    houses: housesPower(_map),
    aspects: astro.strongAspects(_map)
  }
  const isSameAspects = (aspect1: { obj1: number, obj2: number }, aspect2: { obj1: number, obj2: number }) => (aspect1.obj1 === aspect2.obj1 && aspect1.obj2 === aspect2.obj2) || (aspect1.obj1 === aspect2.obj2 && aspect1.obj2 === aspect2.obj1);
  // todo Переехать в либу?
  strongs.aspects = strongs.aspects.filter(SAsp => _map.aspects.find(a => isSameAspects(SAsp, a)));
  const planetsInfo = getPlanetsInfo(_map);
  const includes = (arr: Array<number>, values: Array<number>) => values.some(v => arr.includes(v));

  // Оставляем среди контролирующих планет только те, которые входят в состав контролирующих домов, учитывая лимит
  strongs.houses.houses = strongs.houses.houses.filter((h, i) => i < strongHousesLimit);
  strongs.houses.planets = strongs.houses.planets.filter(p => includes(strongs.houses.houses, planetsInfo[p].controls));


  const fullPoints = _.intersection(
    strongs.houses.planets,
    strongs.planets.map(i => i.p)
  );

  const aspects = props.data.maps[0].aspects;

  const _aspects = strongs.aspects
    .filter(a => a.weight > 0)
    .sort((a, b) => b.weight - a.weight);

  const sels = strongElements(props.data);

  astro.settings = cloneDeep(oldSettings);

  return <Container>
    {strongs.planets?.length > 0 && <Group>
      <header>{t("chronos.app.instruments.widgets.StrongObjects.strongPlanets")}</header>
      <SingleItemsList>
        {strongs.planets.map((p: any, index: number) => {
          const ObjIcon = objectsIcons[p.p];
          return (
            <TW text={t(astroObjects[p.p].ru)} key={`planet_${p.p}`}>
              <SingleIcon key={index} data-object-name={astroObjects[p.p].en}>
                <ObjIcon />
              </SingleIcon>
            </TW>
          );
        })}
      </SingleItemsList>
    </Group>}

    {strongs.houses?.houses?.length > 0 && <Group>
      <header>{t("chronos.app.instruments.widgets.StrongObjects.strongHouses")}</header>
      <SingleItemsList>
        {strongs.houses.houses.map((h: any, i: number) =>
          i < strongHousesLimit && <TW text={i18n.language === 'ru' ? `${h + 1} ${t("astro.house")}` : `${t("astro.house")} ${h + 1}`} key={`house_${h}`}>
            <SingleIcon data-object-name={powerHouses[h]}>{powerHouses[h]}</SingleIcon>
          </TW>
        )}
      </SingleItemsList>
    </Group>}

    {fullPoints.length > 0 && <Group>
      <header>{t("chronos.app.instruments.widgets.StrongObjects.fullResonancePoints")}</header>
      <SingleItemsList>
        {fullPoints.map((p: any, i: number) => {
          const ObjIcon = objectsIcons[p];
          return (
            <TW text={t(astroObjects[p].ru)} key={`point_${p}`}>
              <SingleIcon key={i} data-object-name={astroObjects[p].en}>
                <ObjIcon />
              </SingleIcon>
            </TW>
          );
        })}
      </SingleItemsList>
    </Group>}

    {_aspects.length > 0 && <Group>
      <header>{t("chronos.app.instruments.widgets.StrongObjects.strongsAspects")}</header>
      <TwinItemsList>
        {_aspects.map((item: any, i: number) =>
          <TwinIcon aspect={item} key={`aspect_${i}`}>
          </TwinIcon>
        )}
      </TwinItemsList>
    </Group>}

    <Group>
      <header>{t("chronos.app.instruments.widgets.StrongObjects.strongElements")}</header>
      <StrongElements>{sels ? <span>{sels}</span> : <>{t("chronos.mobile.instruments.widgets.strongObjects.complexCore")}</>}</StrongElements>
    </Group>

  </Container>;
}



function SingleIcon(props: {
  className?: string;
  children: React.ReactNode | string;
}) {
  return <Icon {...props} className={props.className}>{props.children}</Icon>;
}

function TwinIcon(props: {
  children?: React.ReactNode[] | string;
  aspect: IStrongAspect;
}) {
  const { aspect } = props;
  const link = aspects[props.aspect.type];
  const AspIcon = aspectsIcons[props.aspect.type];

  const Obj1Icon = objectsIcons[aspect.obj1];
  const Obj2Icon = objectsIcons[aspect.obj2];
  const aspectWithCuspTitle = (aspect: IStrongAspect, objIdx: number) => houseNames[(aspect as any)[`obj${objIdx}`] - ObjectType.House1];


  return <TwinIconC color={`var(--circle-aspects-${aspect.type})`}>
    <TW text={t(getObjectName(aspect.obj1))}>
      <Icon data-object-name={astroObjects[aspect.obj1]?.en || aspectWithCuspTitle(aspect, 1)}>
        {aspect.obj1 < ObjectType.House1 ?
          <Obj1Icon /> :
          <span>{aspectWithCuspTitle(aspect, 1)}</span>
        }
      </Icon>
    </TW>
    <Circle />
    <Line />
    <TW text={acccesOnlyForRu() ? `<span style="color: var(--circle-aspects-${aspect.type})">${t(link.ru)}</span>` : ''}><AspIcon width="1em" /></TW>
    <Line />
    <Circle />
    <TW text={t(getObjectName(aspect.obj2))}>
      <Icon data-object-name={astroObjects[aspect.obj2]?.en || aspectWithCuspTitle(aspect, 2)}>
        {aspect.obj2 < ObjectType.House1 ?
          <Obj2Icon /> :
          <span>{aspectWithCuspTitle(aspect, 2)}</span>
        }
      </Icon>
    </TW>

  </TwinIconC>;
}
//<AspIcon width="1em" />

const Container = styled.div`
  width: 100%;
  white-space: initial;
`

const Hr = styled.div`
  height: 0px;
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--element-neutral);
`;

const Group = styled.div`
  margin-bottom: 1.125rem;
  font-size: 1rem;
  color: var(--text-primary);

  & > header {
    margin-bottom: 0.875rem;
  }
`;

const Icon = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: var(--input-background);

  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-size: 0.875rem;

  & > svg {
    width: 1.125rem;
    color: var(--text-primary);
    fill: var(--text-primary);
  }
  & > span {
    color: var(--text-primary);
  }
`;

const SingleItemsList = styled.div`
  display: grid;
  grid-template-columns: repeat(15, min-content);
  grid-gap: 0.75rem;
`;

const TwinItemsList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-gap: 0.75rem;
`;

const TwinIconC = styled('div') <{ color: string }>`
  display: flex;
  align-items: center;
  color: ${props => props.color};

  & svg {
    display: block;
    width: 1rem;
  }
`;

const Circle = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: currentColor;

  &:first-of-type {
    margin-left: -3px;
  }
  &:last-of-type {
    margin-right: -3px;
  }
`;

const Line = styled.div`
  width: 9px;
  height: 1px;
  background: currentColor;

  &:first-of-type {

  }
  &:last-of-type {

  }
`;

const StrongElements = styled.div`
  font-size: 0.8em;
  color: var(--text-secondary);
`;


const Tooltip = styled.div<{ color: string }>`
  display: none;
  position: fixed;
  border-radius: 4px;
  background-color: var(--bg-100);
  color: var(--text-primary);
  padding: 0.20rem 0.4rem 0.15rem 0.4rem;
  font-size: 0.75rem;
  text-align:center;
  line-height: 1.2rem;
  z-index: 100;
  border: 1px solid var(--element-neutral);

  ${props => props.color === 'blue' && css`
    background: var(--accent-blue);
    color: var(--text-primary);
  `}
`;

const TW = styled(TooltipWrapper)`
`;