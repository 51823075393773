import React from 'react';
import styled from 'styled-components';
import { ICourseLesson, ICoursePlan } from 'src/libs';
import Markdown from 'react-markdown';

import { LinkIcon } from 'src/assets/icons/system/index';
import { useTranslation } from 'src/i18n/useTranslation';

export default function LessonContent({
  lesson,
  isByued,
  isFuture,
  neededPlan,
}: {
  lesson: ICourseLesson,
  isByued?: boolean,
  isFuture: boolean,
  neededPlan: ICoursePlan,
}) {
  const { t } = useTranslation();
  let renderContent = null;

  if((!isByued || isFuture) || (!lesson.content)) {
    renderContent = <>
      <Content>{t("chronos.app.courses.lesson.content.title")}</Content>
    </>
  } else {
    renderContent = <>
      <Content>
        <Markdown>{lesson.content}</Markdown>
      </Content>
    </>
  }

  return <Container>
    <ZoomLinkBlock>
      <BlockedChain>
        <LinkIcon />
      </BlockedChain>
      {/*lesson.webinar?.link && <ZoomLink href={lesson.webinar?.link} target="_blank">Открыть вебинар через сайт ZOOM</ZoomLink>*/}
    </ZoomLinkBlock>
    <Title>{t("chronos.app.courses.lesson.lessonMaterials")}</Title>
    {renderContent}
  </Container>
}

const Container = styled.div`
  //margin-bottom: 1.365rem;
`

const BlockedChain = styled.div`
  width: 2.625rem;
  height: 2.625rem;
  background: var(--bg-400);
  border: 1px solid var(--input-border);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 165%;
  margin-bottom: 0.25rem;
  color: var(--text-primary);
`

const Content = styled.div`
  max-width: 90%;
  line-height: 1.5;
  & a {
    color: var(--accent-blue) !important;
  }
`

const WebinarLink = styled.a`
  display: flex;
  align-items: center;
  line-height: 1.5rem;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
`

const ZoomLinkBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`

const ZoomLink = styled.a`
  margin-left: 0.75rem;
  color: var(--accent-blue);
  font-size: 0.875rem;
`