import React, { ReactNode } from 'react';
import { NotificationType } from 'src/store/context/notifications';
import styled, { css } from 'styled-components';

export default function Notification({
  children,
  type
}: {
  children: ReactNode,
  type: NotificationType
}) {
    return <Container type={type}>
        {children}
    </Container>
}

const Container = styled.div<{type: NotificationType}>`
    display: flex;
    align-items: center;
    width: min-content; 
    box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
    border-radius: 0.375rem;
    padding: 0.875rem 1rem;
    font-size: 1.125rem;
    white-space: nowrap;
    max-width: 95vw;

    color: var(--text-primary);
    ${p => p.type === 'warning' && css`
        color: var(--color-orange);
    `}
    ${p => p.type === 'error' && css`
        color: var(--color-red);
    `}

    background: var(--bg-100);

    & svg {
        width: 1.5rem;
        margin-right: 0.5rem;
    }

    &.notification-enter {
        opacity: 0.01;
    }

    &.notification-enter.notification-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    &.notification-exit {
        opacity: 1;
    }

    &.notification-exit.notification-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }

    
`