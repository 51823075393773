import dayjs from 'dayjs';
import api from 'src/api';
import { getProfile, setProfile } from 'src/store/localStorage/profile';

import './common.css';
import './dark.css';
import './light.css';

export type Themes = 'dark' | 'light' | 'auto';

class Theme {
  public actual: Themes = 'dark';
  private _timer = 0;
  private _cachedCurrent: Themes = 'auto';
  private _current: Themes = 'auto';

  init(theme: Themes) {
    this._current = theme;
    this._cachedCurrent = theme;
    this.actual = theme;

    // api.theme(theme);

    if (theme === 'auto') {
      const hour = (new Date).getHours();
      let diff = 0;

      if (hour >= 8 && hour < 20) {
        this.actual = 'light';
        document.documentElement.setAttribute('data-theme', 'light');
        diff = dayjs().hour(20).minute(0).second(0).millisecond(0).valueOf() - dayjs().valueOf();
      } else {
        this.actual = 'dark';
        document.documentElement.setAttribute('data-theme', 'dark');
        diff = dayjs().hour(8).minute(0).second(0).millisecond(0).valueOf() - dayjs().valueOf();
        if (diff < 0) {
          diff += 3600 * 24 * 1000;
        }
      }

      this._timer = +setTimeout(() => {
        this.current = 'auto';
      }, diff + 1);
    } else {
      this._timer && clearTimeout(this._timer);
      document.documentElement.setAttribute('data-theme', theme || 'dark');
    }
  }

  setPreviewTheme(theme: Themes) {
    this._current = theme;
    document.documentElement.setAttribute('data-theme', this._current);
  }

  resetPreviewTheme() {
    this.current = this._cachedCurrent;
    document.documentElement.setAttribute('data-theme', this.actual);
  }

  get current() {
    return this._current;
  }

  set current(theme: Themes) {
    this.init(theme);
    api.updateTheme(theme);
    setProfile({
      ...getProfile(),
      theme
    });
  }
}

export default new Theme();
