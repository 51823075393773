import api from 'src/api';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ICourse } from 'src/libs';
import HorizontalSliderItem from './HorizontalSliderItem';

export default function HorizontalSlider() {
  const [items, setItems] = useState<ICourse[]>([])

  useEffect(() => {
    api.getCourses().then((data) => {
      setItems(data)
    });
  }, [])

  if(items.length === 0) return null;

  return <Container>
    <Items>
      {items.map(item => <HorizontalSliderItem course={item} key={item.id} />)}
    </Items>
  </Container>
}

const Container = styled.div`
  width: 100%;
`

const Items = styled.div`
  > div {
    margin-bottom: 1.25rem;
  }
`