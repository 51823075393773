import React from 'react';
import styled from 'styled-components';

import { Checkbox, Loader, PrintedHeader, PrintedTitle, PrintedFormData, PrintNatalProlog, IGenInfo } from 'src/libs';

import { IWidgetData } from '../data';
import GenDialog from '../GenDialog';
import { autoprediction } from 'src/helpers/api';
import { toDateTimeString } from 'src/api';
import { TrendTypes } from '.';

import LocalTrends, { ILocalTrends } from './LocalTrends';
import GlobalTrends, { IGlobalTrends } from './GlobalTrends';
import { useTranslation } from 'src/i18n/useTranslation';
import dayjs from 'dayjs';
import {InfoEmptyIcon} from 'src/assets/icons/system';


export function printPage(divIds: string[]) {
  let printContents = '';
  divIds.forEach((divId) => {
    const element = document.getElementById(divId);
    if (element) {
      printContents += element.innerHTML;
    } 
  });

  if (printContents) {
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    setTimeout(() => {
        document.body.innerHTML = originalContents;
        window.location.reload()
      }, 1000);
  }
}

export default function AutoPredictionScreen(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
  trendType: TrendTypes,
  openedWidget: boolean;
}) {
  const trendType = props.trendType;

  const [info, setInfo] = React.useState<IGenInfo | null>(null);
  const [data, setData] = React.useState<{
    globalTrends: IGlobalTrends;
    localTrends: ILocalTrends;
  } | null>(null);
  const [serviceLife, setServiceLife] = React.useState<string | null>(null)
  const [showReasons, setShowReasons] = React.useState(false);
  const [showAffects, setShowAffects] = React.useState(false);
  const { t } = useTranslation();

  const onBuild = async (confirmed = true) => {
    const resp: any = await autoprediction(confirmed, props.data.form);

    props.onChanged('autoprediction', resp.done);

    if (resp.done) {
      setInfo(null);
      setData(resp.data);
      setServiceLife(dayjs(resp.created).add(1, 'year').format('DD.MM.YYYY'))
    } else {
      // Force update
      setInfo({
        ...resp,
        timestamp: Date.now()
      });
      setData(null);
    }
  };

  React.useEffect(() => {
    if (!props.openedWidget) return;

    onBuild(false);
  }, [props.openedWidget]);

  if (info) {
    return (
      <GenDialog
        title={t("chronos.app.widgets.title.autoPrediction")}
        info={info}
        onBuild={onBuild}
      />
    );
  }

  if (!data) { return <LoaderContainer><Loader /></LoaderContainer> }


  return (
    <Container>
      {serviceLife && <Validity>
          <IconContainer>
              <InfoEmptyIcon/>
          </IconContainer>
          <div className={'text'}>
              <div className={'title'}>{t("chronos.app.widgets.autoprediction.valid")}<span>{serviceLife}</span></div>
              <div>По истечении срока Автопрогноз будет недоступен. Для получения Автопрогноза на следующий год потребуется оплата.</div>
          </div>
      </Validity>}

      <div className='section-controls'>
        <StyledToPDFButton className='to-print' onClick={() => printPage(['ap-print-header', 'print-map', 'ap-print-content'])}><span>{t("chronos.app.downloadInPdf")}</span></StyledToPDFButton>
      </div>

      
        {trendType === 'localTrends' &&
          <Header>
            <CheckBoxes>
              <Checkbox
                label={t("chronos.app.instruments.widgets.autoPrediction.causes")}
                labelPosition='left'
                checked={showReasons}
                onChange={setShowReasons}
              />
              <Checkbox
                label={t("chronos.app.instruments.widgets.autoPrediction.consequences")}
                labelPosition='left'
                checked={showAffects}
                onChange={setShowAffects}
              />
            </CheckBoxes>
          </Header>
        }

      <div id="ap-print-header">
        <PrintedHeader label={t("chronos.app.widgets.title.autoPrediction")} />
        <PrintedTitle>
          {`${trendType === 'localTrends' ? t("chronos.app.widgets.autopredictionScreen.locals") : t("chronos.app.widgets.autopredictionScreen.globals")} ${t("chronos.app.widgets.autopredictionScreen.trends")}`}
        </PrintedTitle>
        <PrintedFormData>
          <span>{props.data.form.name}</span>,&nbsp;
          <span>{toDateTimeString(props.data.form.natal.dt)}</span>,&nbsp;
          <span>{props.data.form.natal.place.name}</span>
        </PrintedFormData>
      </div>

      <div id="ap-print-content">
        <PrintNatalProlog text={t("chronos.app.downloadInPdf.printInfo")} />
        <Content
          showReasons={showReasons}
          showAffects={showAffects}
        >
          { trendType === 'localTrends'
              ? <LocalTrends {...data.localTrends} />
              : <GlobalTrends {...data.globalTrends} />
          }
        </Content>
      </div>
    </Container>
  );
};

const Container = styled.div`
  white-space: normal;
`

const Content = styled.div<{ showAffects: boolean; showReasons: boolean }>`  
  padding-left: 0.5em;

  @media print {
    padding-left: 0;
  }
  
  .ap-affects {
    display: ${props => props.showAffects ? 'block' : 'none'};
  }

  .ap-reasons {
    display: ${props => props.showReasons ? 'block' : 'none'};
  }

  .section-controls {
    display: flex;
    position: relative;
    margin: 1rem 0;

    &::empty {
      margin: 0;
    }
  }

  .trends {
    position: relative;
    /* font-family: Inter; */
    margin-bottom: 2.25rem;
    --red-color: #EF5350;
    --green-color: #73B230;
  }
  .trends h1 {
    margin: 1rem 0rem;
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 2.723rem;
    color: var(--text-primary);
  }
  .trends h2 {
    margin: 0.5rem 0rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.475rem;
    color: var(--text-primary);
  }

  .trends p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: var(--text-secondary);
  }

  .comment {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 1em;
    font-weight: 400;
    color: var(--text-secondary);
  }

  .trends ins {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-third);
    text-decoration: none;
  }

  .trends span {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-third);
    text-decoration: none;
  }

  .trends ul {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .local-trend {
    margin-bottom: 2rem;
  }

  .local-trend ul {
    list-style: disc !important;
    padding-left: 0.5rem;
    margin-top: 1.75rem;
  }

  .local-trend ul .list-item {
    margin-bottom: 1rem;
    border-left: none;
  }

  .list-item {
    position: relative;
    margin-bottom: 1.313rem;
    padding-left: 0.875rem;
    border-left: 2px solid;
  }

  .list-item-data {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    color: var(--text-secondary);
    margin-bottom: 1.313rem;
  }

  .ul-with-items {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    color: var(--text-secondary) !important;
    margin-bottom: 1.313rem;  
  }

  .list-item-data:last-child {
    margin-bottom: 0;
  }

  .list-item-data .block {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.313rem;
  }

  .list-item-data .marker {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.75rem;
    height: 1.5rem;
    border-radius: 0.313rem;
    color: #ffffff;

    font-size: 0.75rem;
    font-weight: 600;
  }

  .list-item-data .text {
    margin-left: 0.875rem;
    color: var(--text-secondary);
  }

  .list-item-data.compensation .marker {
    background-color: var(--green-color) !important;
  }

  .list-item-data.compensation .intro {
    color: var(--green-color);
    font-weight: 600;
  }

  .list-item.red {
    border-color: var(--red-color);
  }

  .list-item.red .marker {
    background-color: var(--red-color);
  }

  .list-item.gray {
    border-color: var(--text-third);
  }

  .list-item.gray .marker {
    background-color: var(--text-third);
  }
`;

const Header = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;

  //position: sticky;
  //top: 5.7rem;
  //background-color: var(--workspace-widget-background);
  z-index: 2;

  & > * {
    margin: .5em 0
  }

  &::empty {
    margin: 0;
  }
`;

const CheckBoxes = styled.div`
  display: flex;
  gap: 1em;
`;

export const LoaderContainer = styled.div`
  height: calc(100vh - 10rem);
  width: 100%;
`;

const StyledToPDFButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  color: var(--accent-blue);
  background: transparent;
`;

const Validity = styled.div`
  display: flex;
  justify-content: space-between;

  background: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;

  span {
    margin-left: 0.4rem;
  }

  .text {
    color: var(--text-secondary);
    padding: 0 1rem;
  }

  .title {
    color: #FFFFFF;
    margin-bottom: 0.5rem;
  }
`
const IconContainer = styled.div`
  width: 3.5rem;
  color: #FFFFFF;
`
