import React from 'react';
import styled, { css } from 'styled-components';

import { Input, IDropdownOption, astroObjects, getHouseFormulas, HouseFormulaPlanet } from 'src/libs';
import { SearchIcon } from 'src/assets/icons/system/index';


import { IWidgetData } from '../data';
import astro from 'src/astro';
import formulasDesc from 'src/guide/formulas';
import { aspectsIcons, objectsIcons } from 'src/helpers/icons';
import { useTranslation } from 'src/i18n/useTranslation';
import {acccesOnlyForRu} from "../../../../../../helpers/permissions";
import { useSelector } from 'src/store/utils';
import { getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
// import { t } from 'i18next';

const sortOptions: IDropdownOption[] = [
  { value: 'houseDesc', label: "chronos.app.instruments.widgets.houseFormulas.houseDesc" },
  { value: 'houseAsc', label: "chronos.app.instruments.widgets.houseFormulas.houseAsc" },
  { value: 'countDesc', label: "chronos.app.instruments.widgets.houseFormulas.quantity" }
];

const join = (items: number[]) => items.map(i => i + 1).join(', ');

function getSuffix(planet: HouseFormulaPlanet, house: number) {
  return (
    planet.significators.includes(house) ?
      'S' :
      planet.controls.includes(house) ?
        'A' :
        ''
  ) + (house + 1);
}

export default function HouseFormulas(props: {
  data: IWidgetData;
  openedWidget: boolean;
}) {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState('');
  const [sort, setSort] = React.useState<any>(sortOptions[0]);

  const [formulas, setFormulas] = React.useState<any>([]);
  const [planets, setPlanets] = React.useState<any>([]);
  const [aspectFormulas, setAspectFormulas] = React.useState<any>([]);
  const [houseFormulas, setHouseFormulas] = React.useState<any>([]);
  const [specialFormulas, setSpecialFormulas] = React.useState<any>([]);

  const [popupData, setPopupData] = React.useState<any>(null);
  const activeAstroProfile = useSelector(getActiveAstroProfile);

  const openPopup = (formula: string) => {
    setPopupData({
      formula,
      planets,
      aspectFormulas: aspectFormulas[formula],
      houseFormulas: houseFormulas[formula],
      specialFormulas: specialFormulas[formula]
    });
  };

  React.useEffect(() => {
    const closePopupEscape = (e: any) => {
      if (e.key === 'Escape') { setPopupData(null) }
    };
    const closePopupMouse = (e: any) => {
      if (!e.target.closest('.housesFormulaItem')) { setPopupData(null) }
    };
    document.addEventListener('click', closePopupMouse);
    document.addEventListener('keyup', closePopupEscape);
    return () => {
      document.removeEventListener('click', closePopupMouse);
      document.removeEventListener('keyup', closePopupEscape);
    };
  }, []);

  React.useEffect(() => {
    const natal = props.data.maps.find(m => m.mode == 'natal');
    if (!natal) { return }
    const houseFormulas = getHouseFormulas(natal, activeAstroProfile);
    setFormulas(houseFormulas.formulas);
    setPlanets(houseFormulas.planets);
    setAspectFormulas(houseFormulas.aspectFormulas);
    setHouseFormulas(houseFormulas.houseFormulas);
    setSpecialFormulas(houseFormulas.specialFormulas);
  }, [props.data.maps, activeAstroProfile]);

  const filteredFormulas =
    Object.keys(formulas)
      .filter((item: string) => (filter === '') || (item.split('#').includes(filter) && item))
      .sort((a, b) => {
        if (sort.value === 'countDesc') {
          return formulas[b] - formulas[a];
        }
        const aParts = a.split('#');
        const bParts = b.split('#');
        if (sort.value === 'houseDesc') {
          return +bParts[0] - +aParts[0] || +bParts[1] - +aParts[1];
        }
        return +aParts[0] - +bParts[0] || +aParts[1] - +bParts[1];

      });

  return <Container>

    <Filters>
      <div><Input size='big' placeholder={t("chronos.app.instruments.widgets.houseFormulas.filter")} value={filter} onChange={setFilter} icon={<SearchIcon />} /></div>
      {/* <label>Сортировать по</label>
      <div><Select options={sortOptions} value={sort} onChange={setSort} /></div> */}
    </Filters>

    <Formules>
      {filteredFormulas.map((item, i) =>
        <span key={i} onClick={() => { openPopup(item) }} className="housesFormulaItem">
          {item}x{formulas[item]}
        </span>
      )}

      {acccesOnlyForRu() && popupData && <Popup
        aspectFormulas={popupData.aspectFormulas}
        houseFormulas={popupData.houseFormulas}
        specialFormulas={popupData.specialFormulas}
        planets={popupData.planets}
        formula={popupData.formula}
      />}

    </Formules>
    <Hr />

    <Properties>
      <header>{t("chronos.app.instruments.widgets.houseFormulas.planetaryProperties")}</header>
      <div>
        <Table>
          <Tr header={true}>
            <Td>{t("chronos.app.instruments.widgets.horar.planet")}</Td>
            <Td>{t("chronos.app.instruments.widgets.houseFormulas.control")}</Td>
            <Td>{t("chronos.app.instruments.widgets.coordinates.house")}</Td>
            <Td>{t("chronos.app.instruments.widgets.houseFormulas.signification")}</Td>
          </Tr>
          {Object.keys(planets).map(i => +i).map((item, i) => {
            const ObjIcon = objectsIcons[item];
            return (
              <Tr key={i}>
                <Td><ObjIcon/> {t(astroObjects[item].ru)}</Td>
                <Td>{join(planets[item].controls)}</Td>
                <Td>{join(planets[item].houses)}</Td>
                <Td>{join(planets[item].significators)}</Td>
              </Tr>
            );
          })}
        </Table>
      </div>
    </Properties>

  </Container>;
}

const Container = styled.div`
  color: var(--text-secondary);
  font-size: 0.875rem;
  max-width: 30rem;
  width: 100%;
`;

const Hr = styled.div`
  height: 0px;
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--element-neutral);
`;

const Filters = styled.nav`
  display: grid;
  // grid-template-columns: 1fr min-content 0.6fr;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;

  & > div:first-of-type {
      // padding-right: 0.75rem;
      width: 100%;
  }

  & > label {
      white-space: nowrap;
  }
`;

const Formules = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  & > span {
      margin-right: 0.875rem;
    margin-bottom: 0.875rem;

    &:hover {
      color: var(--text-primary);
      cursor: pointer;
    }
  }
`;

const Properties = styled.div`
  overflow-x: hidden;
  width: 100%;

  & > div {
    overflow-x: scroll;
    width: 100%;
  }

  & > div::webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  & > header {
      margin-bottom: 1.25rem;
    color: var(--text-primary);
    font-size: 1rem;
  }
`;

const Table = styled.div`
  width: auto;
  min-width: 26rem;
`;

const Tr = styled('div') <{ header?: boolean }>`
    display: grid;
    grid-template-columns: 7rem 1fr 1fr 1.6fr;
    grid-column-gap: 1rem;
    color: var(--text-secondary);
    padding: 0.675rem 0;
    border-bottom: 1px solid var(--element-neutral);
    align-items: center;

  ${props => props.header && css`
    color: var(--text-third);
    padding: 0;
    margin-bottom: 0.375rem;
    border-bottom: none;
  `}

  &:last-of-type {
        border-bottom: none;
  }
`;

const Td = styled.div`
  text-align: right;

  &:first-of-type {
    text-align: left;
    display: flex;
    align-items: center;

    & > svg {
      width: 1em;
      color: var(--icon-primary);
      margin-right: 0.5rem;
    }
  }
`;

function Popup({
  aspectFormulas = [],
  houseFormulas = [],
  specialFormulas = [],
  planets = [],
  formula = ''
}: {
  aspectFormulas: any;
  houseFormulas: any;
  specialFormulas: any;
  planets: any;
  formula: string;
}) {
  const [p1, p2] = formula.split('#').map(p => parseInt(p, 10) - 1);
  const { t } = useTranslation();
  return <PopupC>
    <PopupIcons>
      {aspectFormulas.map((asp: any) => {
        const AspIcon = aspectsIcons[asp.type];
        const Obj1Icon = objectsIcons[asp.obj1];
        const Obj2Icon = objectsIcons[asp.obj2];

        return (
          <span key={`${asp.obj1}_${asp.obj2}`}>
            <Obj1Icon />
            ({getSuffix(planets[asp.obj1], p1)})
            <i><AspIcon /></i>
            <Obj2Icon />
            ({getSuffix(planets[asp.obj2], p2)})
          </span>
        );
      })}

      {specialFormulas.map((f: any) => {
        const AspIcon = aspectsIcons[f.aspType];
        const ObjIcon = objectsIcons[f.planet];

        return (
          <span key={`special_${formula}_${f.planet}_${f.suffix}`}>
            <ObjIcon />
            ({f.suffix}{f.house + 1})
            <i><AspIcon /></i>
            K{f.aspHouse + 1}
          </span>
        );
      })}

      {houseFormulas.map((i: {
        p: number;
        suffix: string;
      }) =>
        planets[i.p].houses.filter((h: number) =>
          formula.includes(`${h + 1}`) &&
          (
            i.suffix === 'A' ?
              planets[i.p].controls.includes(h === p1 ? p2 : p1) :
              planets[i.p].significators.includes(h === p1 ? p2 : p1)
          )
        ).map((house: number) => {
          const ObjIcon = objectsIcons[i.p];
          return (
            <span key={`planet_${i}_${house}`}>
              <ObjIcon />
              ({i.suffix}{(house === p1 ? p2 : p1) + 1}) {t("base.prepositions.in")}{house === 1 ? t("chronos.app.instruments.widgets.houseFormulas.about") : ''} {house + 1}
            </span>
          );
        })
      )}
    </PopupIcons>

    {formulasDesc[formula] && <ul>
      {t(formulasDesc[formula]).split(';').map(v =>
        <li key={v}>{v}</li>
      )}
    </ul>}
  </PopupC>;
}


const PopupC = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  box-sizing: border-box;
  width: 23rem;
  padding: 1rem 2rem;
  background: var(--bg-100);
  border-radius: 6px;
  white-space: normal;


  & > ul {
        margin: 0;
    padding-left: 0;
    list-style: none;

    & > li {
        position: relative;
      margin-bottom: 0.675rem;
      padding-left: 1.8rem;
      font-size: 0.875rem;
      line-height: 1.25rem;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: var(--element-neutral);
        left: 0.4rem;
        top: 0.4rem;
      }
    }
  }
`;

const PopupIcons = styled.div`
  margin-bottom: 0.75rem;

  & > span {
    display: inline-flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.5em;

    & > svg {
      width: 1.5em;
      color: var(--icon-primary);
      fill: var(--icon-primary);
    }

    & > i {
      display: flex;
      align-items: center;
      & > svg {
        width: 1.25em;
        fill: var(--icon-primary);
        margin: 0 0.175rem 0 0.375rem;
      }
    }
  }
`;
