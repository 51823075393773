import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';
import styled from 'styled-components';

import { ISortItem } from './Sorting';

interface ISortingForPopupProps {
  orderItems: ISortItem[],
  fieldItems: ISortItem[],
  setOrder: (item: ISortItem) => void,
  setField: (item: ISortItem) => void,
}

export default function SortingForPopup(props: ISortingForPopupProps){
  const { t } = useTranslation();
  return <div>
    {props.orderItems.map((item, i: number) => 
      <Item key={i} onClick={() => props.setOrder(item)}>{item.icon} {t(item.full)}</Item>
    
    )}
    <Item><LineHorizontal /></Item>

    {props.fieldItems.map((item, i: number) => 
      <Item key={i} onClick={() => props.setField(item)}>{t(item.full)}</Item>
    )}
  </div>
}

const Item = styled.div`
  height: 2.5rem;
  display: flex;
  align-items: center;

  font-size: 0.875rem;

  > svg {
    margin-right: 1rem;
    color: var(--text-secondary);
  }
`

const LineHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background: var(--bg-element-neutral);
`