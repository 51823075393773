import api, { IAstroSettings, IUISettings } from 'src/api';
import { Dispatch } from 'redux';
import { ISettingsState } from '../reducers/settings/types';
import { signOut } from '../reducers/profile/actions';
import { merge } from 'lodash';

export const SETTINGS_ASTRO_GET_SUCCESS = 'SETTINGS_ASTRO_GET_SUCCESS';
export const SETTINGS_ASTRO_GET_LOADING = 'SETTINGS_ASTRO_GET_LOADING';
export const SETTINGS_GET_SUCCESS = 'SETTINGS_GET_SUCCESS';
export const SETTINGS_GET_LOADING = 'SETTINGS_GET_LOADING';
export const CUSTOMIZATIONS_GET_SUCCESS = 'CUSTOMIZATIONS_GET_SUCCESS';
export const CUSTOMIZATIONS_GET_LOADING = 'CUSTOMIZATIONS_GET_LOADING';
export const SETTINGS_CHANGE_PROFILE_ORBISE = 'SETTINGS_CHANGE_PROFILE_ORBISE';



export interface IProfileSettingsData {
  showHint: boolean;
  syncMaps: boolean;
  newInterface: boolean;
  activeProfileId: number;
  activeCustomizationId: number;
}

const dfltUI: IUISettings = {
  sidebar: true,
  instruments: {
    cardSettingsForms: {
      common: {
        basic: true,
        common: true,
        northern: true
      },
      natal: {
        basic: true
      },
      synastry: {
        basic: true
      },
      prognostics: {
        basic: true
      },
      horar: {
        basic: true,
        additional: true,
        showAspects: false
      },
      // FIXME:
      // @ts-ignore
      soul: {
        basic: true,
        additional: true
      }
    },
  }
};

export const getSettings = (id: number, loading: boolean = true) => {
  return (dispatch: Dispatch) => {
    loading && dispatch(getSettingsLoading());
    api.profileSettings(id)
      .then((result: any) => {
        result.ui = merge(dfltUI, result.ui);

        result.syncMaps = result.syncMaps ?? true;
        // считаем отсутствие activeCustomizationId признаком первого входа после регистрации 
        // и назначаем кастомизацию в соответсвие со школами
        result.activeCustomizationId = result.activeCustomizationId ?? (result.activeProfileId == 0 ? 2 : 7);
        dispatch(getSettingsSuccess(result))
      })
      .catch(_ => { signOut(_)(dispatch); });
  };
}

export const getSettingsAstro = (id: number, loading: boolean = true) => {
  return (dispatch: Dispatch) => {
    loading && dispatch(getAstroLoading());
    api.astroSettings(id)
      .then((data: any) => { dispatch(getAstroSuccess(data)); }) // 
      .catch(_ => { signOut(_)(dispatch); });
  };
}

export const getCustomizations = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getCustomizationsLoading());
    api.getPersonalizationList(id)
      .then((data: any) => { dispatch(getCustomizationsSuccess(data)) })
      .catch(_ => { signOut(_)(dispatch); });
  };
}

export const updateSettings = (data: Partial<IProfileSettingsData>, callback?: (updatedData: IProfileSettingsData) => void) => {
  return (dispatch: Dispatch, getState: any) => {

    const { settings: { settings: { data: currentSettings } } } = getState();
    const updatedSettings = {...currentSettings, ...data};
    
    dispatch(getSettingsSuccess(updatedSettings))

    api.updateProfileSettings(data)
      .then((result: any) => callback?.(updatedSettings))
      .catch(_ => { 
        // signOut(_)(dispatch);
        console.log('updateSettings error -', _)
        throw Error(_)
      });
  };
}

export const updateAstroSettings = (profile: IAstroSettings, callback?: (data: any) => void) => {
  return (dispatch: Dispatch, getState: any) => {
    
    let { settings: { astro: { data: astroProfiles } } } = getState();
    const isFirstMayProfileCreate = profile?.id === -1;

    if (isFirstMayProfileCreate) {
      
      api.updateAstroSettings(profile)
      .then((result: any) => {
        astroProfiles = astroProfiles.map((p: IAstroSettings) => p.id == -1 ? result : p);
        dispatch(getAstroSuccess(astroProfiles));
        callback?.(result)
      })
      .catch((_: any) => { 
        // signOut(_)(dispatch); 
        console.log('updateAstroSettings error -', _)
        throw Error(_)
      });
    
    } else {
      
      // сразу обновляем локальный state
      astroProfiles = astroProfiles.map((p: IAstroSettings) => p.id == profile.id ? profile : p)
      dispatch(getAstroSuccess(astroProfiles))
      
      profile.mutable && api.updateAstroSettings(profile)
      .then((result: any) => callback?.(result))
      .catch((_: any) => { 
        // signOut(_)(dispatch); 
        console.log('updateAstroSettings error -', _)
        throw Error(_)
      });
    }

  }
}

export const changeOrbisesCorrectorInProfile = (profileId: number, mode: string, value: number) => {
  return {
    type: SETTINGS_CHANGE_PROFILE_ORBISE,
    payload: { profileId, mode, value }
  }
}

const getSettingsLoading = () => {
  return {
    type: SETTINGS_GET_LOADING
  }
}

export const getSettingsSuccess = (data: any) => {
  return {
    type: SETTINGS_GET_SUCCESS,
    payload: data
  }
}

const getCustomizationsLoading = () => {
  return {
    type: CUSTOMIZATIONS_GET_LOADING
  }
}

const getCustomizationsSuccess = (data: any) => {
  return {
    type: CUSTOMIZATIONS_GET_SUCCESS,
    payload: data
  }
}

const getAstroLoading = () => {
  return {
    type: SETTINGS_ASTRO_GET_LOADING
  }
}

const getAstroSuccess = (data: any) => {
  return {
    type: SETTINGS_ASTRO_GET_SUCCESS,
    payload: data
  }
}

