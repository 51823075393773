import { MouseEvent, useState } from "react";
import { BinIcon, ShevronIcon } from "src/assets/icons/system";
import styled, { css } from "styled-components";

interface SectionProps {
	title: string;
  isOpened: boolean;
  switch(): void;
  children: any;
  icon?: any;
  leftChevron?: boolean;
  removable?: boolean;
  onDelete?(): void;
  isLast?: boolean;
  className?: string;
}

export function ExpandedSection(props: SectionProps) {
	const onDelete = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
		if (props.onDelete && props.removable) {
			e.stopPropagation()
			props.onDelete()
		}
	}

  return <Group
    className={props.className}
    isOpened={props.isOpened}
    leftChevron={props.leftChevron}
    isLast={props.isLast}
		removable={props.removable}
  >
    {
      props.leftChevron
        ? <header onClick={props.switch}>
          <ShevronIcon className='chevron' />
          <span>{props.title}</span>
          {props.removable && <BinIcon className='bin' onClick={(e) => onDelete(e)} />}
        </header>
        : <header onClick={props.switch}>
          <span>{props.title}</span>
          {props.icon || <ShevronIcon className='chevron' />}
        </header>
    }

    {(props.isOpened) && props.children}
  </Group>;
}

export const Group = styled.div<{
  isOpened: boolean;
  leftChevron?: boolean;
  isLast?: boolean;
  removable?: boolean;
}>`
  margin-bottom: 1.5rem;
  padding-bottom: 0.125rem;

  ${props => props.isOpened && css`
    padding-bottom: 1.25rem;
  `}

  ${props => props.isLast ? '' : 'border-bottom: 1px solid var(--element-neutral)'};

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin-bottom: 1.125rem;

    cursor: pointer;
    
    & > span {
      font-size: 1rem;
      color: var(--text-primary);

      ${props => props.leftChevron && css`
        width: 100%;
        margin-right: auto;
				margin-left: 0.5rem;
      `}
    }

    & > svg {
      width: 1.5rem;
      height: 1.5rem;
			fill: var(--icon-third);

			&.bin {
				${props => props.removable && css`
          fill: var(--text-secondary);
        `}
			}

      &.chevron {
        ${props => props.isOpened && css`
          transform: rotate(180deg);
        `}
      }
    }
  }
`;
