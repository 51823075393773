import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Objects from './Objects';
import { useTranslation } from 'src/i18n/useTranslation';
import { IWidgetData } from '../data';
import { isCompatibility, isPartner } from 'src/utils';
import { CircleMode } from 'src/libs';
import { TWidgetCircleMode } from '../index';
import { extractMode } from '../../../utils';

import { useSelector } from 'src/store/utils';
import { getWorkspaceIndicator } from 'src/store/reducers/mapIndicator/selectors';
import { IMapIndicatorState, ECompatibilityTransitMode } from 'src/store/reducers/mapIndicator/types';

export default function Coordinates(props: { subMode: TWidgetCircleMode, data: IWidgetData }) {
  const { t } = useTranslation();
  

  const isHasSynastryPrognostics = Boolean(props.data?.form?.syn_prognostics);
  const { compatibilityTransits, compatibilityReversed } = useSelector(getWorkspaceIndicator);

  const prognosticsMode = [
    'transits',
    'solars',
    'directions',
    'prog_prog',
    'prog_natal',
  ];

  // вычисляем реальный mode, так как в props.subMode могут быть субрежимы типа 'compatibility1-transit-partner'
  const _mode = React.useMemo(() => {
    return extractMode(props.subMode, '-') as CircleMode;
  }, [props.subMode]);

  const isCompatibilityTransit = React.useMemo(() => {
    return props.subMode?.includes('-transit')
  }, [props.subMode]);


  // if (isCompatibility(_mode) && props.subMode.includes(`${_mode}-transit`)  ) return  <CoordinatesWidgetContainer></CoordinatesWidgetContainer>

  
  if (_mode === 'natal' && _mode === props.data.widgetMode) {
    return (
      <CoordinatesWidgetContainer>
        {
          props.data.maps?.[0]?.objects! && props.data.maps?.[0]?.houses
          ? <Objects
              planets={props.data.maps?.[0].objects}
              houses={props.data.maps?.[0].houses}
            />
          : null
        }
        
      </CoordinatesWidgetContainer>
    );
  }


  if (_mode === 'syn_natal' && _mode === props.data.widgetMode) {
    const currentMap = props.data?.maps?.find(map => _mode === map.mode);
    return (
      <CoordinatesWidgetContainer>
        {
          currentMap?.objects! && currentMap?.houses
          ? <Objects
              planets={currentMap.objects}
              houses={currentMap.houses}
            />
          : null
        }
        
      </CoordinatesWidgetContainer>
    );
  }
  

  // просто совместимость
  if (isCompatibility(_mode as CircleMode) && _mode === props.subMode) { 
    const currentMap = props.data?.maps?.find(map => _mode === map.mode);   
    
    return (
      <CoordinatesWidgetContainer>
        {currentMap?.objectsExt! && currentMap?.housesExt! ?
          <Objects
            mode={_mode as CircleMode}
            planets={!compatibilityReversed ? currentMap?.objectsExt! : currentMap?.objects!}
            houses={!compatibilityReversed ? currentMap?.housesExt! : currentMap?.houses!}
          /> 
          : null
        }
      </CoordinatesWidgetContainer>
    );
  }

  if (_mode === 'syn_natal' && isCompatibility(props.data.widgetMode)) {
    const currentMap = props.data?.maps?.find(map => props.data.widgetMode === map.mode);
    return (
      <CoordinatesWidgetContainer>
        {
          currentMap?.objects! && currentMap?.houses
          ? <Objects
              planets={!compatibilityReversed ? currentMap.objects : currentMap.objectsExt!}
              houses={!compatibilityReversed ? currentMap.houses : currentMap.housesExt!}
            />
          : null
        }
        
      </CoordinatesWidgetContainer>
    );
  }
  
  // транзиты в совместимости
  if (isCompatibilityTransit && isHasSynastryPrognostics) {
    
    const currentMap = props.data.maps.find(map => _mode === map.mode);

    return (
      <CoordinatesWidgetContainer>
        { currentMap?.objectsOuter && currentMap?.housesOuter
          ? <Objects
              mode={_mode as CircleMode}
              planets={currentMap.objectsOuter! || []}
              houses={currentMap.housesOuter! || []}
          />
          : null
        }
      </CoordinatesWidgetContainer>
    );
  }

  if (isPartner(_mode)) {
    const currentMap = props.data.maps.find(map => _mode === map.mode);
    return (
      <CoordinatesWidgetContainer>
        {currentMap?.objects! && currentMap?.houses! ?
        <Objects
          mode={_mode as CircleMode}
          planets={currentMap?.objects!}
          houses={currentMap?.houses!}
        />
        : null
      }
      </CoordinatesWidgetContainer>
    );
  }

  if (prognosticsMode.includes(_mode)) {
    const currentMap = props.data.maps.find(map => _mode === map.mode);
    return (
      <CoordinatesWidgetContainer>
        {currentMap?.objectsExt! && currentMap?.housesExt! ?
          <Objects
            mode={_mode as CircleMode}
            planets={currentMap?.objectsExt!}
            houses={currentMap?.housesExt!}
          /> 
          : null
        }
      </CoordinatesWidgetContainer>
    );
  }

  return null;
}

const CoordinatesWidgetContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`;


