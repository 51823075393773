import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';
import styled from 'styled-components';

import Themes from './Themes';

export default function ColorTheme() {
  const { t } = useTranslation();
  return <Container>
    <Title>{t("chronos.app.settings.colorTheme")}</Title>
    <Text>{t("chronos.app.settings.personal.colorTheme.subtitle")}</Text>
    <Themes />
  </Container>
}


const Container = styled.div`
  margin-top: 1rem;
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
`
const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);
  margin-top: 0.375rem;
`
