import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import { ArrowLeftIcon } from 'src/assets/icons/arrows/index';
import HitIcon from 'src/ui/HitIcon';
import { swipeAndCall } from 'src/ui/SwipeTabs';

interface IPopupRightProps {
  children: ReactNode,
  open?: boolean,
  onClose: () => void,
  verticalAlign?: string
}

export function PopupRight(props: IPopupRightProps){
  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => swipeAndCall(e, () => props.onClose()),
    onSwipedRight: (e) => swipeAndCall(e, () => props.onClose())
  });
  
  return <Container {...swipeHandlers} open={props.open}>
    <Header><HitIcon onClick={props.onClose}><ArrowLeftIcon /></HitIcon></Header>
    <Content verticalAlign={props.verticalAlign}>{props.children}</Content>
  </Container>
}

export const Container = styled.div<{open?:boolean}>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10001;
  top: 0;
  transform: translateX(${p => p.open ?  '0' : '-100%'});
  right: 0;
  display: grid;
  grid-template-rows: min-content 1fr;
  
  background: var(--bg-200);
  transition: transform 0.2s;
`

const Header = styled.div`
  display:flex;
  padding: 1rem;
`

const Content = styled.div<{verticalAlign?: string}>`
  overflow: auto;
  ${p => p.verticalAlign && css`
    display: flex;
    align-items: ${_ => p.verticalAlign};
  `}
`