import React, { ReactNode } from 'react';

import { AspectsIcon, CoordinatesIcon } from 'src/assets/icons/widgets/index';
import { OrbiseIcon } from 'src/assets/icons/widgets/index';
import { StrongIcon } from 'src/assets/icons/widgets/index';
import { FormulasIcon } from 'src/assets/icons/widgets/index';
import { AnalyzeIcon } from 'src/assets/icons/widgets/index';
import { DevplanIcon } from 'src/assets/icons/widgets/index';
import { AutopredictionIcon } from 'src/assets/icons/widgets/index';
import { InterpretationIcon } from 'src/assets/icons/widgets/index';
import { RectificationIcon } from 'src/assets/icons/widgets/index';
import { DispositorsIcon } from 'src/assets/icons/widgets/index';

import { FormulaStrengthIcon } from 'src/assets/icons/widgets/index';
import { EphemerisFormulasIcon } from 'src/assets/icons/widgets/index';
import { FormulaYearsIcon } from 'src/assets/icons/widgets/index';
import { SoulFormulaIcon } from 'src/assets/icons/widgets/index';
import { InterpretationSoulIcon } from 'src/assets/icons/widgets/index';

import { HorarEventsIcon } from 'src/assets/icons/widgets/index';
import { HorarEssentialsIcon } from 'src/assets/icons/widgets/index';
import { HorarAnalysisIcon } from 'src/assets/icons/widgets/index';
import { HorarLightIcon } from 'src/assets/icons/widgets/index';
import { HorarSpeedIcon } from 'src/assets/icons/widgets/index';


import OrbiseCorrector from './OrbiseCorrector';
import StrongObjects from './StrongObjects';
import HouseFormulas from './HouseFormulas';
import Analyze from './Analyze';
import AspectsTable from './AspectsTable';
import Interpretation from './Interpretation';
import Dispositors from './Dispositors';
import AutoPrediction from './AutoPrediction';
import Devplan from './Devplan';
import Rectification from './Rectification';

import FormulaStrength from './Soul/FormulaStrength';
import EphemerisFormulas from './Soul/EphemerisFormulas';
import FormulaYears from './Soul/FormulaYears';
import SoulFormula from './Soul/SoulFormula';
import SoulInterpretation from './Soul/Interpretation';

import { IFormData, IMap, IStrongAspect, IPower, CircleMode, IAstroSettings } from 'src/libs';

import HorarLight from './Horar/Light';
import HorarAnalysis from './Horar/Analysis';
import HorarEssentials from './Horar/Essentials';
import HorarSpeed from './Horar/Speed';
import HorarEvents from './Horar/Events';
import Coordinates from './Coordinates';


export interface IStrongs {
  planets: IPower[];
  houses: {
    houses: number[];
    planets: number[];
    power: number[];
  };
  aspects: IStrongAspect[];
}

export interface IWidgetData {
  form: IFormData;
  maps: IMap[];
  modes: CircleMode[];
  activeWidget: string | undefined;
  widgetMode: CircleMode;
  aspectsTableMode: CircleMode;
  strongs: IStrongs;
  pinnedAspects: number[];
  activeAstroProfile: IAstroSettings;
	natalMap: IMap;
  //description: IDescription | null;
  //prompt: IPrompt | null;
  //highlights: IHighlight[];
}
export interface IWidget {
  order: number;
  id: string;
  short: string;
  full: string;
  icon: ReactNode;
  active: boolean;
  component?: any;
  fullscreen?: boolean;
}

export const widgets: IWidget[] = [
  {
    order: 0,
    id: 'aspects-table',
    short: "chronos.app.settings.astro.aspects",
    full: "chronos.app.widgets.title.aspectsTable",
    icon: <AspectsIcon />,
    active: true,
    component: AspectsTable,
    fullscreen: true
  },
  {
    order: 1,
    id: 'coordinates-of-planets-and-houses',
    short: "chronos.app.widgets.title.coordinates.short",
    full: "chronos.app.widgets.title.coordinates",
    icon: <CoordinatesIcon />,
    active: true,
    component: Coordinates,
  },
  {
    order: 2,
    id: 'strong-objects',
    short: "chronos.mobile.widgets.short.strongObjects",
    full: "chronos.app.widgets.title.strongObjects",
    icon: <StrongIcon />,
    active: true,
    component: StrongObjects,
    fullscreen: true
  },
  {
    order: 3,
    id: 'house-formulas',
    short: "chronos.mobile.widgets.short.houseFormulas",
    full: "chronos.app.widgets.title.houseFormulas",
    icon: <FormulasIcon />,
    active: true,
    component: HouseFormulas,
    fullscreen: true
  },
  {
    order: 4,
    id: 'analyze',
    short: "chronos.mobile.widgets.short.analize",
    full: "chronos.app.widgets.title.analyze",
    icon: <AnalyzeIcon />,
    active: true,
    component: Analyze ,
    fullscreen: true
  },
  {
    order: 5,
    id: 'dispositors',
    short: "chronos.mobile.widgets.short.dispositors",
    full: "chronos.app.widgets.title.dispositors",
    icon: <DispositorsIcon />,
    active: true,
    component: Dispositors,
    fullscreen: true
  },
  {
    order: 6,
    id: 'orbise-corrector',
    short: "chronos.mobile.widgets.short.orbiseCorrector",
    full: "chronos.app.widgets.title.orbiseCorrector",
    icon: <OrbiseIcon />,
    active: true,
    component: OrbiseCorrector
  },
  {
    order: 7,
    id: 'rectification',
    short: "chronos.app.widgets.title.rectification",
    full: "chronos.app.widgets.title.rectification",
    icon: <RectificationIcon />,
    active: true,
    component: Rectification,
    fullscreen: true
  },
  {
    order: 8,
    id: 'devplan',
    short: "chronos.app.devPlan",
    full: "chronos.app.devPlan",
    icon: <DevplanIcon />,
    active: true,
    component: Devplan,
    fullscreen: true
  },
  {
    order: 9,
    id: 'autoprediction',
    short: "chronos.app.widgets.title.autoPrediction",
    full: "chronos.app.widgets.title.autoPrediction",
    icon: <AutopredictionIcon />,
    active: true,
    component: AutoPrediction,
    fullscreen: true
  },
  {
    order: 10,
    id: 'interpretation',
    short: "chronos.app.prompts.interpretation",
    full: "chronos.app.prompts.interpretation",
    icon: <InterpretationIcon />,
    active: true,
    component: Interpretation,
    fullscreen: true
  },
  {
    order: 11,
    id: 'horar-events',
    short: "chronos.app.widgets.title.horarEvents",
    full: "chronos.app.widgets.title.horarEvents",
    icon: <HorarEventsIcon />,
    active: true,
    component: HorarEvents,
    fullscreen: true
  },
  {
    order: 12,
    id: 'formula-strength',
    short: "chronos.mobile.widgets.short.formulaStrength",
    full: "chronos.app.widgets.title.formulaStrength",
    icon: <FormulaStrengthIcon />,
    active: true,
    component: FormulaStrength,
    fullscreen: true
  },
  {
    order: 13,
    id: 'ephemeris-formulas',
    short: "chronos.app.widgets.title.ephemerisFormulas",
    full: "chronos.app.widgets.title.ephemerisFormulas",
    icon: <EphemerisFormulasIcon />,
    active: true,
    component: EphemerisFormulas,
    fullscreen: true
  },
  {
    order: 14,
    id: 'formula-years',
    short: "chronos.app.widgets.title.formulaYears",
    full: "chronos.app.widgets.title.formulaYears",
    icon: <FormulaYearsIcon />,
    active: true,
    component: FormulaYears,
    fullscreen: true
  },
  {
    order: 15,
    id: 'horar-speed',
    short: "chronos.auth.index.speed",
    full: "chronos.app.widgets.title.horarSpeed",
    icon: <HorarSpeedIcon />,
    active: true,
    component: HorarSpeed,
    fullscreen: true
  },
  {
    order: 16,
    id: 'horar-essentials',
    short: "chronos.mobile.widgets.short.horarEssentials",
    full: "chronos.app.widgets.title.horarEssentials",
    icon: <HorarEssentialsIcon />,
    active: true,
    component: HorarEssentials,
    fullscreen: true
  },
  {
    order: 17,
    id: 'formula-soul',
    short: "astro.formulaSoul",
    full: "astro.formulaSoul",
    icon: <SoulFormulaIcon />,
    active: true,
    component: SoulFormula,
    fullscreen: true
  },
  {
    order: 19,
    id: 'horar-analysis',
    short: "chronos.app.widgets.title.horarAnalysis",
    full: "chronos.app.widgets.title.horarAnalysis",
    icon: <HorarAnalysisIcon />,
    active: true,
    component: HorarAnalysis,
    fullscreen: true
  },
  {
    order: 20,
    id: 'horar-light',
    short: "chronos.app.widgets.title.horarLights",
    full: "chronos.app.widgets.title.horarLights",
    icon: <HorarLightIcon />,
    active: true,
    component: HorarLight,
    fullscreen: true
  },
  {
    order: 21,
    id: 'interpretation-soul',
    short: "chronos.app.prompts.interpretation",
    full: "chronos.app.prompts.interpretation",
    icon: <InterpretationSoulIcon />,
    active: true,
    component: SoulInterpretation,
    fullscreen: true
  },
]
