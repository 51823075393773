import React from "react";
import styled, { css } from "styled-components";

import { useTranslation } from 'src/i18n/useTranslation';


export default React.memo(function MapModeSelector({
  modeList,
  activeMode = 'natal',
  onModeSelect,
  className = ''
}: {
  modeList: string[];
  activeMode: string;
  onModeSelect: (mode: string) => void;
  className?: string;
}) {
  const { t } = useTranslation();
  
  const [localMode, setLocalMode] = React.useState<string>(activeMode);
  
  React.useEffect(() => {
    setLocalMode(modeList.includes(activeMode) ? activeMode : modeList[0])
  }, [activeMode]);
  
  return (
    <TabsPane className={className}>
      {
        modeList?.map((mode: string) => {
          return <Tab 
            key={`tab_${mode}`}
            active={localMode == mode} 
            onClick={() => onModeSelect(mode)}>
              {t(`astro.${mode}`)}
            </Tab>
        })
      }
    </TabsPane>
  );
});


const TabsPane = styled.div`
  display: flex;
  position: relative;
`;

const Tab = styled.button<{ active?: boolean }>`
  position: relative;
  appearance: none;
  border: none;
  padding: 0.5rem 0.75rem;
  background-color: transparent;
  border-radius: 6px;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--text-secondary);
  cursor: pointer;

  & + & {
    margin-left: 1.312rem;
  } 

  &:focus {
    outline: none;
  }

  ${props => props.active && css`
    background-color: var(--element-neutral);
    color: var(--text-primary);
    transition: background-color 0.2s ease;
  `}
`;