import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';
import { useTranslation } from 'src/i18n/useTranslation';

export interface ILabelsWithIcon{
  icon: ReactNode,
  short: string,
  full?: string,
  arrow?: boolean,
  color?: string,
  action?: () => void,
  disabled?: boolean
}

export default function LabelsWithIcon(props: ILabelsWithIcon){
  const { t } = useTranslation();
  return <Container onClick={props.action} withFull={!!props.full} color={props.color} disabled={props.disabled}>
    <Icon>{props.icon}</Icon>
    <div>
      <Short>{t(props.short)}</Short>
      {props.full && <Full>{t(props.full)}</Full>}
    </div>
    {props.arrow && <ArrowIcon/>}
  </Container>;
}

const Container = styled.div<{withFull: boolean, disabled?: boolean}>`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 1rem;
  align-items: center;
  min-height: 2.5rem;

  ${p => p.withFull && css`
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  `}

  ${p => p.color && css`
    color: ${p.color};
  `}

  ${p => p.disabled && css`
    opacity: 0.4;
    pointer-events: none;
  `}

  
`

const Icon = styled.div`
  opacity: 0.6;
`

const Short = styled.div`
  font-size: 0.875rem;
`

const Full = styled.div`
  color: var(--text-secondary);
  font-size: 0.75rem;
`

const ArrowIcon = styled(LeftTriangleIcon)`
  transform: scaleX(-1);
`