import React from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import { Checkbox }  from 'src/libs' ;
import { useTranslation } from "src/i18n/useTranslation";


export interface IDispositorsSettings {
  planets: {
    id: string;
    label: string;
    isActive: boolean;
  }[];
}

export default function DispositorsSettings(props: {
  onChange(data: IDispositorsSettings): void;
  settings: IDispositorsSettings;
}) {
  
  
  const settings = props.settings;
  
  const { t } = useTranslation();

  const onChange = (index: number) => {
    const data = cloneDeep(settings!);
    data.planets[index].isActive = !data.planets[index].isActive;
    props.onChange?.(data);
  };



  return (
    <Container>
      <div className='title'>{t("astro.objects")}</div>
      {settings?.planets.map((item: any, index: number) => (
        <StyledCheckcox
          key={index}
          label={t(item.label)}
          checked={item.isActive}
          onChange={() => onChange(index)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid var(--button-border);
  margin-top: 0.5rem;
  padding-top: 1.2rem;

  label {
    margin-bottom: 0.1rem;
    margin-left: 1.2rem;
  }

  .title {
    margin-bottom: 1rem;
    font-size: 16px;
    
  }
`

const StyledCheckcox = styled(Checkbox)`
  margin-bottom: 1.25rem;

  .box {
    span {
      max-height: 100%;
    }
  }

`;