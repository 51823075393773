import React from 'react';
import styled, { css } from 'styled-components';

import { CircleMode, IFormData, IMap } from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';
import { getModeMetaData } from '../utils';
import { TMode } from '../index';
import { SynastryIcon, PrognosticsIcon } from 'src/assets/icons/maps';
import { PencilIcon } from 'src/assets/icons/system';

import { useDispatch } from 'react-redux';
import { useSelector } from 'src/store/utils';
import { getWorkspaceIndicator } from 'src/store/reducers/mapIndicator/selectors';
import { EInitSynastryTransitsCommand } from 'src/store/reducers/mapIndicator/types';

export default function MapSelector({
  current,
  onChangeMode,
  maps,
  mode,
  setOpenFormPopup,
  isLocked = false,
  form
}: {
  current: string,
  onChangeMode(mode: CircleMode): void,
  maps: IMap[],
  mode: TMode,
  setOpenFormPopup?(value: boolean, forMode?: TMode): void;
  isLocked?: boolean; 
  form: IFormData
}) {
  const { t } = useTranslation();
  const mapIndicator = useSelector(getWorkspaceIndicator);
  // console.log('MApSelector mapIndicator', mapIndicator.initSynastryTransits)

  return <Container>
    {(mode === 'synastry' && !isLocked) &&
      <Item onClick={() => setOpenFormPopup?.(true)} active={false}>
        <SynastryIcon /> <PencilIcon />
      </Item>
    }
    {
      mode === 'synastry' 
      && !isLocked 
      && (mapIndicator.initSynastryTransits === EInitSynastryTransitsCommand.ADD || form.syn_prognostics) &&
      <Item onClick={() => setOpenFormPopup?.(true, 'syn_prognostics')} active={false}>
        <PrognosticsIcon /> <PencilIcon />
      </Item>
    }
    {maps.map(map => {
      const {icon: Icon, title} = getModeMetaData(map.mode);
      return (
        <Item onClick={() => onChangeMode(map.mode)} key={map.mode} active={map.mode === current}>
          <Icon />{t(title)}
        </Item>
      );
    })}
    <div>&nbsp;</div>
  </Container>
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 1rem;
`
const Item = styled.div<{active: boolean}>`
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 0.45rem 0.75rem 0.30rem 0.55rem;
  font-size: 0.75rem;
  border-radius: 6px;
  background: var(--bg-element-neutral);
  border: 1px solid var(--input-border);
  margin-right: 0.75rem;
  margin-bottom: 1rem;

  ${p => p.active && css`
    border-width: 2px;
    border-color: var(--accent-blue);
  `}

  &:last-child {
    margin-right: 1rem;
  }

  & > svg {
    width: 0.9375rem;
    height: 0.9375rem;
    margin-right: 0.35rem;
    color: var(--text-secondary);
    margin-top: -3px;
  }

  & > svg:not(:first-child):last-child {
    margin-right: 0;
  }
`