import React from 'react';
import { useTranslation } from 'src/i18n/useTranslation';
import SwipeTabs, { ISwipeTab } from 'src/ui/SwipeTabs';
import styled from 'styled-components';
import { IWidgetData } from '../data';
import DevplanScreen from './DevplanScreen';

const options=[
  { value: '0', label: "chronos.app.devPlan.birth" },
  { value: '1', label: "chronos.app.devPlan.energy" },
  { value: '2', label: "chronos.app.devPlan.careerGuidance" },
  { value: '3', label: "chronos.app.devPlan.health" },
  { value: '4', label: "chronos.app.devPlan.finance" }
];

export default function Devplan(props: {
  data: IWidgetData;
  openedWidget: boolean;
  onChanged(key: string, value: any): void;
}) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(options[0]);
  const items: ISwipeTab[] = options.map(item => {
    return {
      key: item.value,
      title: <div onClick={() => setPage(item)}>{t(item.label)}</div>,
      content: ''};
  })

  return <Container>
    <SwipeTabs items={items} open={props.openedWidget} />
    <MarginTop>
      <DevplanScreen page={page} data={props.data} onChanged={props.onChanged} opened={props.openedWidget} />
    </MarginTop>
  </Container>
}

const Container = styled.div`
  white-space: normal;
  overflow: scroll;
`
const MarginTop = styled.div`
  margin-top: 1rem;
`
