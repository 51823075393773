import React from 'react';
import styled from 'styled-components';

import { degToString } from 'src/api';
import { IFixedStar, IObject, getSign, ObjectType, signs, CircleMode, isNotTransits } from 'src/libs';
// import { arabicSigns } from './Horar/Analysis';
// import { calcHorarSigns } from 'src/pages/Instruments/Widgets/Horar/horar';
// import { getArrangeObjects, fortuneLon, IArrangedObject } from 'src/components/Circle/Objects';
import { objectsIcons, signsIcons } from 'src/icons';

import { default as TooltipWrapper } from 'src/ui/Wrappers/TooltipWrapper';
import { useTranslation } from 'src/i18n/useTranslation';
import i18n from 'src/i18n/i18n';

import { acccesOnlyForRu } from "src/helpers/permissions";
import { calcHorarSigns } from './Horar/horar';
import { IArrangedObject } from '../Circle/FixedStars';
import { arrangeObjects, fortuneLon } from '../Circle/Objects';
import { ConjunctionIcon } from 'src/assets/icons/astro/aspects';
import { allSettings, allSettingsAstro, getActiveAstroProfile, getCurrentCustomization } from 'src/store/reducers/settings/selectors';
import { useSelector } from 'react-redux';

export interface IFixedStarsProps {
  houses: number[];
  objects: IObject[];
  fixedStars: IFixedStar[];
  show: boolean;
  mode: CircleMode;
  soulStrongs: number[];
  title?: string;
  className?: string;
}

interface IStarRow { 
  star: IFixedStar; 
  sign: number; 
  planets: number[]; 
  houses: number[]; 
  el: JSX.Element;
}

export const arabicSigns = ['Asc', 'II', 'III', 'IC', 'V', 'VI', 'Dsc', 'VIII', 'IX', 'MC', 'XI', 'XII'];

export default React.memo(function FixedStarsView(props: IFixedStarsProps) {
  const { t } = useTranslation();
  const activeAstroProfile = useSelector(getActiveAstroProfile);
  const personalization = useSelector(getCurrentCustomization);

  const isRu = i18n.language === 'ru';
  const orbis = 1;

  const horarSigns = props.mode === 'horar' ? calcHorarSigns(props.houses) : [];
  const orderSize = props.mode === 'horar' ?
    3
    : props.mode === 'soul'
      ? 1
      : 1

  const rows = React.useMemo(() => {
    const _rows: IStarRow[] = [];

    let arrangedObjects: IArrangedObject[] = arrangeObjects(
      props.objects
      .filter((obj, id) => (id !== ObjectType.Chiron || !isNotTransits(props.mode)) && (props.mode === 'soul' || id !== ObjectType.Selena))
      .map((obj, id) => {
        if (props.mode === 'soul' && id === ObjectType.SouthNode) {
          return {
            ...obj,
            lon: fortuneLon(props.objects, props.houses)
          };
        }
        return obj;
      })
      ,
      props.houses,
      +(16 / 4).toFixed(2),
      horarSigns,
      orderSize,
      props.soulStrongs || [],
      activeAstroProfile?.showHigherPlanets
    );

    for (const star of props.fixedStars) {
      let accuracyAspectHouses: number[] = [];
      
      props.houses.map((house: any, id: number, currArr: number[]) => {
             const cuspLon = ['horar', 'soul'].includes(props.mode) ? ((props.mode === 'soul' ? 0 : 180) + id * 30) : currArr[id];
    
        Math.abs(star.lon - currArr[id]) <= orbis && accuracyAspectHouses.push(id);
      });

      let accuracyAspectObjects: number[] = [];

      for (let object of arrangedObjects) {
        if ([ObjectType.Chiron, ObjectType.Lilith, ObjectType.SouthNode, ObjectType.NorthNode].includes(object.id) || (props.mode === 'horar' && object.id === ObjectType.Selena)) continue;
        
        const isFortune = (props.mode === 'soul' && object.id === ObjectType.SouthNode);
        const realLon = isFortune ? fortuneLon(props.objects, props.houses.length ? props.houses : [0]) : props.objects[object.id].lon;
        Math.abs(star.lon - realLon) <= orbis && accuracyAspectObjects.push(object.id);
      }

      accuracyAspectHouses = accuracyAspectHouses.sort();
      accuracyAspectObjects = accuracyAspectObjects.sort();

      const sign = getSign(star.lon);

      const SignIcon = signsIcons[sign] as React.ElementType;

      const starName = t(isRu ? star.ru : star.en);
      const bracketIndex = starName.indexOf('(');
      const [starName1, starName2] = bracketIndex === -1
        ? [starName]
        : [starName.slice(0, bracketIndex - 1), starName.slice(bracketIndex)?.replace(/[\(\)]/g, '')];

      if (accuracyAspectHouses.length || accuracyAspectObjects.length) {
        _rows.push({
          star,
          sign,
          planets: accuracyAspectObjects,
          houses: accuracyAspectHouses,
          el: <>
            <TooltipWrapper text={acccesOnlyForRu() ? (t(star.description) === star.description ? '' : t(star.description)) : ''}>
              <StarName>
                <div className='star-name'>
                  <span>{starName1}</span>
                  {starName2 && <span className="name2">{starName2}</span>}
                </div>
                <ConjunctionIcon />
              </StarName>
            </TooltipWrapper>
            {accuracyAspectHouses.length !== 0 &&
              <House>
                {accuracyAspectHouses.sort().map(house => arabicSigns[house])}
              </House>
            }
            {accuracyAspectObjects.sort().map((object, i) => {
              const Icon = objectsIcons[object] as React.ElementType;
              return <Icon key={`object_icon_${i}`} style={{ fill: 'var(--text-secondary)', flexShrink: '0' }} />;
            })}
            <Sign>
              <span>{degToString(star.lon % 30, {isInternational: false})}</span>
              <SignIcon style={{ fill: `rgb(var(--circle-zodiacs-${signs[sign].element}-rgb-${personalization.data.circle.colorThemes.zodiacs.active}))` }} />
            </Sign>
          </>,
        });
      }
    }




    const withPlanetRows = _rows.filter((row) => row.planets.length)
      .sort((row1:any, row2: any) => {
        return row1.planets[0] === row2.planets[0]
          ? (t(row1.star.ru) > t(row2.star.ru) ? 1 : -1)
          : (row1.planets[0] > row2.planets[0]) ? 1 : -1
      });
    const withCoupsideRows = _rows.filter((row) => {
        const allReadyHas = withPlanetRows.some(({ planets, houses }) => {
          return planets.toString() === row.planets.toString() && houses.toString() === row.houses.toString()
        })
        return row.houses.length && !allReadyHas
      })
      .sort((row1:any, row2: any) => {
        return row1.houses[0] === row2.houses[0]
          ? (t(row1.star.ru) > t(row2.star.ru) ? 1 : -1)
          : (row1.houses[0] > row2.houses[0]) ? 1 : -1
      });

    return [
      ...withPlanetRows,
      ...withCoupsideRows
    ]
      .map((row: IStarRow, index) => {

        return (
          <Cell key={`fixed_stars_${row.star.en}_${index}`} >
            {row.el}
          </Cell>
        );
      });
  }, [props.fixedStars, props.houses, props.objects]);
  
  return (
    <FixedStarsContainer className={props.className}>
      
      { Boolean(props.title) && 
        <SectionTitle className='section-title'>{props.title}</SectionTitle> 
      }

      { Boolean(props.fixedStars.length && !rows.length) &&
        <SectionTitle className='section-title empty'><span>{'Нет соединения на карте выбранных звезд с планетами и куспидами'}</span></SectionTitle>
      }

      { (!Boolean(props.fixedStars.length) || !Boolean(props.show)) &&
        <SectionTitle className='section-title empty'>{'Выберите или включите звезды в настройках'}</SectionTitle>
      }
      
      { Boolean(props.fixedStars.length && rows.length && props.show) &&
        <SectionBody className='section-body'>
          {rows}
        </SectionBody>
      }
    
    </FixedStarsContainer>
  );
});

const FixedStarsContainer = styled.div`
  position: relative;
  border-bottom: 1px solid var(--element-neutral);
  
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

const SectionTitle = styled.div`
  position: relative;
  white-space: pre-wrap;

  &.empty {
    margin-top: 0.25rem;
    color: var(--text-secondary);
  }
`;


const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  box-sizing: border-box;
  padding: 1rem 0;
  
  
  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StarName = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  & .star-name {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .name2 {
    margin-top: 0.2rem;
    font-size: 0.8rem;
    color: var(--text-third);
  }

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
`;

const House = styled.span`
  display: inline-block;
  font-size: 1rem;
  color: var(--text-third);
  flex-shrink: 0;
`;

const Sign = styled.div`
  display: flex;
  color: var(--text-secondary);
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  span {
    font-size: 0.875rem;
    line-height: 1rem;
    white-space: nowrap;
  }
  
  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;