import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from './components/Header';

import { StarIcon } from 'src/assets/icons/system/index';
import { ArrowLeftIcon } from 'src/assets/icons/arrows/index';

import { PersonIcon } from 'src/assets/icons/system/index';
import { PrivateIcon } from 'src/assets/icons/system/index';
import { ForumIcon } from 'src/assets/icons/system/index';

import HorizontalSlider from './components/HorizontalSlider';
import { useTranslation } from 'src/i18n/useTranslation';

export default function CoursesList() {
  const { t } = useTranslation();
  return <div>
    <Header />

    <Content>
      <StarIconStyled />
      <Title>
        {t("chronos.app.sidebar.events")}
      </Title>
      {/* <Text>
        {t("chronos.app.courses.sectionDescription")}
      </Text>
      <SubTitle>{t("chronos.app.courses.firstCours")} <ArrowLeftIcon /></SubTitle> */}
      <HorizontalSlider />
      {/* <Advantages>
        <Advantage>
          <PersonIcon />
          {t("chronos.mobile.courses.professionals")} 
        </Advantage>
        <Advantage>
          <PrivateIcon />
          {t("chronos.mobile.courses.accessToCourses")}
        </Advantage>
        <Advantage>
          <ForumIcon />
          {t("chronos.app.courses.privateChat")}
        </Advantage>
      </Advantages> */}
    </Content>
  </div>
}



const Content = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
`

const StarIconStyled = styled(StarIcon)`
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.875rem;
  color: var(--accent-yellow);
  filter: drop-shadow(0 0 24px var(--accent-yellow));
`

const Title = styled.div`
  max-width: 16.25rem;
  width: 90%;
  font-size: 2.25rem;
  line-height: 2.25rem;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, var(--text-primary), var(--text-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
`

const Text = styled.div`
  max-width: 18.5rem;
  width: 90%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--text-secondary);
  margin: 0 auto;
  margin-bottom: 1rem;
`

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  color: var(--accent-pink);
  line-height: 1.5rem;

  & > svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    transform: rotate(-90deg);
  }
`

const Advantages = styled.div`
  margin: 2.5rem auto 0;
  width: 100%;
`

const Advantage = styled.div`
  width: 80%;
  max-width: 16.5rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--text-primary);
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 1.625rem;

  > svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 1.25rem;
    color: var(--accent-yellow);
  }
`