import { LABELS_GET_LOADING, LABELS_GET_SUCCESS } from './actions';
import { IActionLabels, ILabelsState } from './types';

const initialState: ILabelsState = {
  loading: false,
  loaded: false,
  data: []
};


export default function labels(state = initialState, action: IActionLabels){
  switch(action.type){
    case LABELS_GET_LOADING:
      return {
        loading: true,
        loaded: false,
        data: state.data
      };
    case LABELS_GET_SUCCESS:
      return {
        loading: false,
        loaded: true,
        data: action.payload
      };
    default:
      return state;
  }
}
