import React from 'react';
import styled from 'styled-components';

import { Input, InputContainer } from 'src/libs';
import { SettingsIcon } from 'src/assets/icons/system/index';

import { IEvent, intervals, steps } from './types';

//import onboarding from './onboarding.cfg';

//import { showOnboarding, nextOnboarding } from '~lib/onboarding';
import SchoolSelector, { schools } from './SchoolSelector';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Settings(props: {
  interval: number;
  setInterval: Function;
  step: number;
  setStep: Function;
  builded: boolean;
  unsetBuilded: Function;
  events: IEvent[];
  school: number;
  setSchool(id: number): void;
}) {
  let events, interval, step, school, settings;
  const ref = React.useRef(null);
  const { t } = useTranslation();
  intervals.map((item) => {
    item.label = t(item.label!)  
  })
  steps.map((item) => {
    item.label = t(item.label!)  
  })
  if (props.builded) {
    events = <span>{t("chronos.app.instruments.widgets.rectifications.settings.events")} <i>{props.events.length}</i></span>;
    interval = <span>{t("chronos.app.instruments.resultitem.interval")} <i>{intervals?.find(({ value }) => props.interval === value)?.label}</i></span>;
    step = <span>{t("chronos.app.instruments.widgets.rectifications.settings.step")} <i>{steps?.find(({ value }) => props.step === value)?.label}</i></span>;
    school = <span>{t("chronos.app.instruments.widgets.rectifications.settings.school")} <i>
      {props.events.some(e => e.school != (props.events[0]?.school ?? -1)) ?
        t("chronos.app.instruments.widgets.rectifications.settings.mix") :
        schools[props.events.every(e => e.school == 0) ? 0 : 1].title
      }
    </i></span>;
    settings = <div ref={ref}><SettingsIcon onClick={() => {
      props.unsetBuilded();
      //onboarding.show && nextOnboarding('end');
    }} /></div>;
  } else {
    interval = <>
      <InputContainer label={t("chronos.app.instruments.resultitem.interval")}>
        <Input
          size='big'
          options={intervals}
          value={props.interval}
          onChange={e => props.setInterval(e)}
        />
      </InputContainer>
    </>;
    step = <>
      <InputContainer label={t("chronos.app.instruments.widgets.rectifications.settings.step")}>
        <Input
          size='big'
          options={steps}
          value={props.step}
          onChange={e => props.setStep(e)}
        />
      </InputContainer>
    </>;
    school = <>
      <span>{t("chronos.app.instruments.widgets.rectifications.settings.school")}</span>
      <SchoolSelector
        school={props.school}
        onSelect={props.setSchool}
      />
    </>;
  }

  /*return <SettingsC>
    {events}
    {interval}
    {step}
    {school}
    {settings}
  </SettingsC>;*/
  return <SettingsC>
    {events}
    {interval}
    {step}
    {settings}
  </SettingsC>
}

const SettingsC = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 0.875rem;

  /* & > :nth-child(2n) {
    grid-row: 2;
  }

  & > span {
    font-size: 0.75rem;
  }

  & > * {
    width: 7.5rem;
  }

  & > * {
    margin: 0 0.5rem 0 0;
    font-size: 0.875rem;
  }

  & > div {
    min-width: initial;
  }

  & i {
    font-style: normal;
    color: var(--text-primary);
  } */

`;
