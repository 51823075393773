import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { getAutoPayment, getExpiryDate, getSubscriptions, getUserTariffData, getPeriod, getPayment } from 'src/store/reducers/payments/selectors';
import { useSelector } from 'src/store/utils';
import { getCurrencySymbol } from 'src/utils';
import { useTranslation } from 'src/i18n/useTranslation';
import { MastercardIcon } from 'src/assets/icons/cards/index';
import { useCurrency } from 'src/hooks';

interface StateItem {
  title: string;
  titleColor?: string;
  subtitle: string;
  sum?: string;
  showCardInfo?: boolean;
}

export interface IPaymentInfo {
  amount: string;
  currency: string;
  cardLastFour: string;
  next?: {
    amount: string;
  };
}

const states: {[key: string]: StateItem} = {
  'activateSubscribe': {
    title: 'chronos.app.payments.connectSubscription',
    subtitle: 'chronos.app.payments.connectSubscriptionSub',
  }, 
  'activeSubscribe': {
    title: 'chronos.app.payments.activeSubscription',
    titleColor: 'var(--color-green)',
    subtitle: 'chronos.app.payments.activeSubscriptionSub',
    sum: '$final$currency',
    showCardInfo: true,
  },
  'inactiveSubscribe': {
    title: 'chronos.app.payments.inactiveSubscription',
    subtitle: 'chronos.app.payments.inactiveSubscriptionSub',
    titleColor: 'var(--colors-yellow)',
  },
  'autoRenewalSuspended': {
    title: 'chronos.app.payments.autoRenewalSuspendedSubscription',
    titleColor: 'var(--colors-yellow)',
    subtitle: 'chronos.app.payments.autoRenewalSuspendedSubscriptionSub',
  },
  'suspendedSubscribe': {
    title: 'chronos.app.payments.suspendedSubscription',
    subtitle: 'chronos.app.payments.suspendedSubscriptionSub',
    titleColor: 'var(--colors-red)',
  },
};

export function TariffInfo() {
  const { t } = useTranslation();

  let state = states['activateSubscribe'];

  const subscriptions = useSelector(getSubscriptions);
  const expiryDate = useSelector(getExpiryDate);
  const autoPayment = useSelector(getAutoPayment);
  const curTariff = useSelector(getUserTariffData);
  const period = useSelector(getPeriod);
  const payment = useSelector(getPayment);

  const isExpired = new Date(expiryDate) < new Date();
  const expiryDateFormatted = dayjs(expiryDate).format('DD.MM.YYYY');
  const { amount: final, next: nextAmount} = payment as IPaymentInfo;
  const currency = getCurrencySymbol(useCurrency().currencyCode);

  if (subscriptions.length === 0) {
    state = states['activateSubscribe'];
  } else if (subscriptions.length > 0 && !isExpired && autoPayment) {
    state = states['activeSubscribe'];
  } else if (subscriptions.length > 0 && !isExpired && !autoPayment) {
    state = states['autoRenewalSuspended'];
  } else if (subscriptions.length > 0 && isExpired && autoPayment) {
    state = states['inactiveSubscribe'];
  } else if (!autoPayment && isExpired) {
    state = states['activateSubscribe'];
  }

  const title = t(state.title);
  const subtitle = t(state.subtitle).replaceAll('$expiryDate', expiryDateFormatted);

  return <Container>
    {title && <Title titleColor={state.titleColor}>{title}</Title>}
    {subtitle &&
      <Subtitle>
        {state.sum && currency && (nextAmount || final) &&
          <Sum>{state.sum.replace('$final', (+(nextAmount || final)).toString()).replace('$currency', currency)}</Sum>
        }
        {subtitle}
      </Subtitle>
    }
    {state.showCardInfo && payment.cardLastFour &&
      <CardInfo>
        <MastercardIcon />
        Mastercard * {payment.cardLastFour}
      </CardInfo>
    }
  </Container>;
}

const Container = styled.div`
  background: var(--bg-dashboard-cell);
  border: 1px solid var(--switcher-button-background);
  border-radius: 0.5rem;
  padding: 1.125rem 1.25rem 1.125rem 1.125rem;
  display: flex;
  margin-top: 1rem;
  max-width: 30rem;
  flex-direction: column;
`

const Title = styled.div<{titleColor?: string}>`
  font-size: 1.125rem;
  line-height: 1.1666;
  margin-bottom: 0.625rem;
  color: ${p => p.titleColor ? p.titleColor : 'var(--text-primary)'};
  font-weight: 600;
`

const Subtitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--text-secondary);
  white-space: initial;
  font-weight: 400;
  display: inline-flex;
  gap: 0.25rem;
  align-items: flex-end;
`;

const Sum = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.1666;
  color: var(--text-primary);
`;

const CardInfo = styled.div`
  padding: 0.625rem 0.75rem;
  border: 1px solid var(--border-lines);
  border-radius: 5px;
  display: inline-grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.4375rem;
  align-items: center;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--text-secondary);
  background-color: var(--switcher-button-background);
`;