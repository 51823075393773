const DASHBOARD_VIEW_TYPE = 'mobile_dashboard_view_type_v3';
const DASHBOARD_SORT = 'mobile_dashboard_sort_v3';

export interface IDashboardSort{
  field: string;
  order: string
}

export const getDashboardViewType = () => {
  const item = localStorage.getItem(DASHBOARD_VIEW_TYPE);
  return item;
}

export const setDashboardViewType = (value: string) => {
  localStorage.setItem(DASHBOARD_VIEW_TYPE, value);
}

export const getDashboardSort = () => {
  const item = localStorage.getItem(DASHBOARD_SORT);
  return item && JSON.parse(item);
}

export const setDashboardSort = (value: IDashboardSort) => {
  localStorage.setItem(DASHBOARD_SORT, JSON.stringify(value));
}