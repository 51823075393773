import { astroObjects, getSign, ObjectType, signs } from 'src/libs';
import React from 'react';
import styled from 'styled-components';
import { objectsIcons, signsIcons } from 'src/helpers/icons';
import { IWidgetData } from '../../data';
import { getOrbit } from 'src/utils';
import { fortuneLon } from '../../../Circle/Objects';
import { FortuneIcon } from 'src/assets/icons/astro/objects/index';
import { useTranslation } from 'src/i18n/useTranslation';

export default function FormulaStrength(props: {
  data: IWidgetData;
  openedWidget: boolean;
}) {
  const { t } = useTranslation();
  const getTableRows = () => {
    const rows = [];
    for (let p = ObjectType.Sun; p <= ObjectType.Selena; p++) {
      const isFortune = p === ObjectType.SouthNode;
      const lon = isFortune ? fortuneLon(props.data.maps[0].objects, props.data.maps[0].houses.length ? props.data.maps[0].houses : [0]) : props.data.maps[0].objects[p].lon;

      const ObjectIcon = isFortune ? FortuneIcon : objectsIcons[p];

      const sign = getSign(lon);
      const SignIcon = signsIcons[sign];

      const angle = Math.round(lon % 30);

      const strength = props.data.maps[0].strongs?.[p] || 0;

      // FIXME:
      // @ts-ignore
      const orbit = getOrbit(props.data.maps[0].chains, p);
      rows.push(
        <TableRow key={`row_${p}`}>
          <Cell>
            <ObjectIcon/>
            {isFortune ? t("chronos.app.instruments.widgets.soul.formulaStrength.wheelOfFortune") : t(astroObjects[p].ru)}
          </Cell>
          <Cell>
            <SignIcon/>
            {t(signs[sign].ru)}
          </Cell>
          <Cell>
            {angle}°
          </Cell>
          <Cell>
            {strength}{props.data.maps[0].objects[p].speed < 0 ? 'r' : ''}
          </Cell>
          <Cell>
            {orbit}
          </Cell>
        </TableRow>
      );
    }
    return rows;
  };

  return (
    <Container>
      <TableHeader>
        <Cell>
          {t("chronos.app.instruments.widgets.horar.planet")}
        </Cell>
        <Cell>
          {t("chronos.app.instruments.widgets.coordinates.sign")}
        </Cell>
        <Cell>
          {t("chronos.app.instruments.widgets.soul.formulaStrength.injection")}
        </Cell>
        <Cell>
          {t("chronos.app.widgets.power")}
        </Cell>
        <Cell>
          {t("chronos.app.instruments.widgets.soul.formulaStrength.orbit")}
        </Cell>
      </TableHeader>

      {getTableRows()}
    </Container>
  );
}

const Container = styled.div`
  font-size: 0.675em;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  color: var(--text-third);
  padding: 0.625rem 0;
  > :nth-child(n + 3) {
    width: 13%;
    justify-content: flex-end;
  }
`;

const TableRow = styled.div`
  border-bottom: 1px solid var(--element-neutral);
  display: flex;
  padding: 0.625rem 0;
  
  > :nth-child(n + 3) {
    width: 13%;
    justify-content: flex-end;
  }
  > :nth-child(2) {
    width: 25%;
  }
  :nth-last-child(1) {
    border-bottom: none;
  }
`;

const Cell = styled.span`
  width: 35%;
  display: flex;
  align-items: center;
  > svg {
    min-width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.375rem;
  }
  overflow: hidden;
`;


