
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

const redirect: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {

  if (action.type === 'REDIRECT') {
    window.location.href = action.payload.url;
  }

  return next(action)
}

export default redirect;