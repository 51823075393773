import React, { useState } from 'react';
import styled from 'styled-components';

import { DashboardIcon } from 'src/assets/icons/footer/index';
import { CoursesIcon } from 'src/assets/icons/footer/index';
import { PlusIcon, TmpMapIcon } from 'src/assets/icons/system/index';

import { Link, useNavigate } from 'react-router-dom';
import { ILabelsWithIcon } from 'src/ui/LabelsWithIcon';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import MenuForPopup from 'src/ui/Popups/PopupBottom/MenuForPopup';
import EditForm from 'src/app/pages/dashboard/components/EditForm';
import { useTranslation } from 'src/i18n/useTranslation';
import { TMode } from 'src/app/pages/workspace';
import { IBaseData } from 'src/libs';

export const FOOTER_HEIGHT = '4rem';

export default React.memo(function Footer({
  active,
  setOpenAddGroupPopup,
  onUpdateFormNode,
}: {
  active?: 'dashboard' | 'courses';
  setOpenAddGroupPopup?: Function;
  onUpdateFormNode?: (node: TMode, nodeData?: IBaseData) => void;
}) {
  const navigate = useNavigate();

  const [openFormPopup, setOpenFormPopup] = useState(false);
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);

  const openTempForm = () => {
    navigate('/workspace/-1');
  }

  const buttons: ILabelsWithIcon[] = []

  buttons.push({icon: <PlusIcon/>, short: t("chronos.app.components.hotkeyslist.buildMap"), full: t("chronos.mobile.dashboard.addButton.addInList.full"), action: () => { setOpenPopup(false); setOpenFormPopup(true)}  });
  buttons.push({icon: <TmpMapIcon/>, short: t("chronos.app.components.hotkeyslist.temporaryMap"), full: t("chronos.mobile.dashboard.addButton.buildAMap.full"), action: () => { setOpenPopup(false);  openTempForm() } });

  return <>
    <FooterSpace className="hideOnWebinar" />
    <Container className="hideOnWebinar" >
      {/*<Gradient />*/}
      <ButtonLink to="/dashboard" active={active === 'dashboard' ? 1 : 0}><DashboardIcon />{t("chronos.app.dashboard.maps")}</ButtonLink>
      <ButtonLink to="/courses" active={active === 'courses' ? 1 : 0}><CoursesIcon />{t("chronos.app.sidebar.events")}</ButtonLink>
      <Button onClick={() => setOpenPopup(true)}><Circle><PlusIcon /></Circle>{t("add")}</Button>
    </Container>


    <PopupBottom open={openPopup} onClose={() => setOpenPopup(false)}>
      <MenuForPopup buttons={buttons}  />
    </PopupBottom>

    <PopupBottom open={openFormPopup} onClose={() => setOpenFormPopup(false)} back={true}>
      <EditForm 
        onClose={() => setOpenFormPopup(false)} 
        formId={-1} 
        mode='natal' 
        open={openFormPopup} 
        saveFormMode={'add'}
        onAddNode={(nodeData, node) => onUpdateFormNode?.(node, nodeData)}
        onRemoveNode={(node) => onUpdateFormNode?.(node)}
      />
    </PopupBottom>
  </>
});

const FooterSpace = styled.div`
  height: ${FOOTER_HEIGHT};
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${FOOTER_HEIGHT};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--bg-200);
`

const ButtonLink = styled(Link)<{active: number}>`
  height: 100%;
  width: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${p => p.active ? 'var(--text-primary)' : 'var(--text-secondary)'};
  font-size: 0.75rem;
  line-height: 0.875rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.375rem;
  }
`

const Button = styled.div`
  height: 100%;
  width: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  font-size: 0.75rem;
  line-height: 0.875rem;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.375rem;
  }
`

const Circle = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--accent-blue);
  color: var(--bg-200);

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`
const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 2.5rem;
  top: -2.5rem;
  background: linear-gradient(0deg, var(--bg-200), transparent);
  pointer-events: none;
`
