import { AnyAction } from 'redux';
import { 
  MAPINDICATOR_SET_COMPATIBILITY_REVERSED,
  MAPINDICATOR_SET_PARTNER_NATAL,
  MAPINDICATOR_SET_PARTNER_EXT,
  MAPINDICATOR_SET_PROGNOSTICS_NATAL,
  MAPINDICATOR_SET_PROGNOSTICS_EXT,
  MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_MODE,
  MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_SHOW,
  MAPINDICATOR_SET_SYNASTRY_TRANSITS_INIT,
  MAPINDICATOR_SET_SYNASTRY_TRANSITS_RESET
} from './actions';

import { IMapIndicatorState, ECompatibilityTransitMode, prognosticsMapIndicatorsDefault, synastryMapIndicatorsDefault, EInitSynastryTransitsCommand } from './types'

const initialState: IMapIndicatorState = {
  compatibilityReversed: false,
    
  compatibilityTransits: {
    'compatibility1': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    'compatibility2': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    'compatibility3': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    'compatibility4': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    'compatibility5': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    'compatibility6': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    'compatibility7': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    'compatibility8': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
    'compatibility9': { showTransit: true, transitMode: ECompatibilityTransitMode.MODE_1 },
  },

  initSynastryTransits: EInitSynastryTransitsCommand.NONE,

  prognosticsExt: prognosticsMapIndicatorsDefault(), // for multipla maps
  prognosticsNatal: prognosticsMapIndicatorsDefault(),

  partnerExt: synastryMapIndicatorsDefault(),
  partnerNatal: synastryMapIndicatorsDefault()
};

export default function workspace(state = initialState, action: AnyAction) {
  switch(action.type) {
    
    case MAPINDICATOR_SET_PROGNOSTICS_NATAL: {
      state.prognosticsNatal[action.payload.mapMode] = action.payload.value;
      state = { ...state };
      return state;
    }

    case MAPINDICATOR_SET_PARTNER_NATAL: {
      state.partnerNatal[action.payload.mapMode] = action.payload.value;
      state = { ...state };
      return state;
    }

    case MAPINDICATOR_SET_PARTNER_EXT: {
      state.partnerExt[action.payload.mapMode] = action.payload.value;
      state = { ...state };
      return state;
    }

    case MAPINDICATOR_SET_COMPATIBILITY_REVERSED: {
      state.compatibilityReversed = action.payload;
      state = { ...state };
      return state;
    }
    case MAPINDICATOR_SET_PROGNOSTICS_EXT: {
      state.prognosticsExt[action.payload.mapMode] = action.payload.value;
      state = { ...state };
      return state;
    }

    case MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_MODE: {
      state.compatibilityTransits[action.payload.mapMode].transitMode = action.payload.value;
      state = { ...state };
      return state;
    }

    case MAPINDICATOR_SET_COMPATIBILITY_TRANSIT_SHOW: {
      state.compatibilityTransits[action.payload.mapMode].showTransit = action.payload.value;
      state = { ...state };
      return state;
    }

    case MAPINDICATOR_SET_SYNASTRY_TRANSITS_INIT: {
      state.initSynastryTransits = action.payload.value;
      state = { ...state };
      return state;
    }

    case MAPINDICATOR_SET_SYNASTRY_TRANSITS_RESET: {
      for(let key in state.compatibilityTransits) {
        state.compatibilityTransits[key].showTransit = true;
        state.compatibilityTransits[key].transitMode = ECompatibilityTransitMode.MODE_1;
      }
      state = { ...state };
      return state;
    }
    
    
    default: return state;
  }
}