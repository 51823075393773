import React, { useState } from 'react';
import styled from 'styled-components';
import { TabWithIcons } from 'src/ui/SwipeTabs';

import { PaymentIcon } from 'src/assets/icons/system/index';

import Card from './components/payments/Card';
import { TariffInfo } from './components/payments/TariffInfo';
import Promocode from './components/payments/Promocode';
import Tariffs from './components/payments/Tariffs';
import FormationsView from './components/payments/FormationsView';
import PaymentsHistory from './components/payments/PaymentsHistory';
import { PopupRight } from 'src/ui/Popups/PopupRight';
import TariffChange from './components/payments/TariffChange';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Payments() {

  const [openChangeTariffPopup, setOpenChangeTariffPopup] = useState(false);
  
  return <Container>
    <TariffInfo />
    <Promocode />
    <Tariffs setOpenChangeTariffPopup={setOpenChangeTariffPopup} />
    <FormationsView />
    <PaymentsHistory setOpenChangeTariffPopup={setOpenChangeTariffPopup} />

    <PopupRight open={openChangeTariffPopup} onClose={() => {setOpenChangeTariffPopup(false)}}>
      <TariffChange />
    </PopupRight>

  </Container>

}

export function Tab ({text}: {text: string}) { 
  const { t } = useTranslation();
  return <TabWithIcons>
    <PaymentIcon />
      {t(text)}
</TabWithIcons>}

const Container = styled.div`
  width: calc(100% - 1px);
  vertical-align: top;
`