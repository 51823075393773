import { useTranslation } from 'src/i18n/useTranslation';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { GlobalStyleHorizontalMode } from './LessonWebinar';

export default function VideoPlayer({
  url,
  banner
}: {
  url: string,
  banner?: string
}){
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [ played, setPlayed ] = React.useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    containerRef.current && (containerRef.current.oncontextmenu = function(e){ e.preventDefault(); return false; })
  }, [containerRef])

  return <Container ref={containerRef}>
      <video 
        width="100%" 
        height="100%" 
        controls 
        poster={banner} 
        controlsList="nodownload" 
        playsInline
        onContextMenu={() => {return false;}} 
        onPlay={() => {setPlayed(true)}}
      >
        <source src={url} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
        {t("chronos.mobile.courses.notSupportedByTheBrowser")} 
    </video>

    {played && <GlobalStyleHorizontalMode />}

  </Container>
}

const Container = styled.div`
  position: relative;
  margin-bottom: 1.365rem;
  height: 13rem;
`
