import { useTranslation } from 'src/i18n/useTranslation';
import React, { useState } from 'react';
import styled from 'styled-components';
import SwipeTabs, { ISwipeTab } from 'src/ui/SwipeTabs';
import { IWidgetData } from '../data';
import AutoPredictionScreen from './AutoPredictionScreen';

export type TrendTypes = 'localTrends' | 'globalTrends';

const options = [
  { value: 'localTrends', label: "chronos.ap.local.heading" },
  { value: 'globalTrends', label: "chronos.app.instruments.globalTrends" }
];

export default function AutoPrediction(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
  openedWidget: boolean;
}) {
  const [trendType, setTrendType] = React.useState<TrendTypes>('localTrends');
  const { t } = useTranslation();
  const items: ISwipeTab[] = options.map(item => {
    return { 
      key: item.value, 
      title: <div onClick={() => setTrendType(item.value as TrendTypes)}>{t(item.label)}</div>, 
      content: ''
    };
  })

  return <Container>
    <SwipeTabs items={items} open={props.openedWidget} />
    <MarginTop>
      <AutoPredictionScreen trendType={trendType} data={props.data} onChanged={props.onChanged} openedWidget={props.openedWidget} />
    </MarginTop>
  </Container>
}

const Container = styled.div`
  white-space: normal;
  overflow: scroll;
`
const MarginTop = styled.div`
  margin-top: 1rem;
`

     