import { t } from "i18next";
import { IPaymentFullInfo, ISubscription, ITransaction } from "src/api";
import { 
  PAYMENTS_CHANGE_AUTO_SUCCESS,
  PAYMENTS_FULL_INFO_GET_LOADING, 
  PAYMENTS_FULL_INFO_GET_SUCCESS, 
  PAYMENTS_GET_LOADING,
  PAYMENTS_GET_SUCCESS,
  TARIFFS_GET_LOADING,
  TARIFFS_GET_SUCCESS
} from "./actions";

export interface IPaymentsState {
  loading: boolean;
  loaded: boolean;
  data: ITransaction[];
}

export interface ITariffsState {
  loading: boolean;
  loaded: boolean;
  data: ISubscription[];
}

export interface IPaymentsFullInfoState {
  loading: boolean;
  loaded: boolean;
  data?: IPaymentFullInfo;
}

export const colors: {[key: string]: string} = {
  '1': 'var(--color-orange)',
  '2': 'var(--color-blue)',
  '3': 'var(--color-red)',
  '4': 'var(--color-magenta)'
};

export const descs: { [key: string]: string } = {
  '1': t("chronos.app.settings.payment.descs.1"),
  '2': t("chronos.app.settings.payment.descs.2"),
  '3': t("chronos.app.settings.payment.descs.3"),
  '4': t("chronos.app.settings.payment.descs.4")
};

export interface IActionPaymentsFullInfoGetLoading {
  type: typeof PAYMENTS_FULL_INFO_GET_LOADING
}

export interface IActionPaymentsFullInfoGetSuccess {
  type: typeof PAYMENTS_FULL_INFO_GET_SUCCESS;
  payload: IPaymentFullInfo
}
export interface IActionPaymentsChangeAutoSuccess {
  type: typeof PAYMENTS_CHANGE_AUTO_SUCCESS,
  payload: boolean
}

export interface IActionPaymentsGetLoading {
  type: typeof PAYMENTS_GET_LOADING
}

export interface IActionPaymentsGetSuccess {
  type: typeof PAYMENTS_GET_SUCCESS,
  payload: ITransaction[]
}


export interface IActionTariffsGetLoading {
  type: typeof TARIFFS_GET_LOADING
}

export interface IActionTariffsGetSuccess {
  type: typeof TARIFFS_GET_SUCCESS,
  payload: ISubscription[]
}

export type IActionPaymentsFullInfo = 
  IActionPaymentsFullInfoGetLoading |
  IActionPaymentsFullInfoGetSuccess |
  IActionPaymentsChangeAutoSuccess;

export type IActionPayments = 
  IActionPaymentsGetLoading |
  IActionPaymentsGetSuccess;

export type IActionTariffs = 
  IActionTariffsGetLoading |
  IActionTariffsGetSuccess;