import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import { Avatar, IShortFormData } from 'src/libs';
import { useDispatch } from 'react-redux';
import api from 'src/api';
import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';
import { CloseIcon, SearchIcon } from 'src/assets/icons/system/index';
import { useTranslation } from 'src/i18n/useTranslation';
import { NotificationContext } from 'src/store/context/notifications';
import { updateForm } from 'src/store/reducers/forms/actions';
import { allForms } from 'src/store/reducers/forms/selectors';
import { useSelector } from 'src/store/utils';
import ConfirmPopupButton from 'src/ui/Popups/ConfirmPopupBottom';

export default function AddPartner({
  form,
  onClose,
	popupRef
}: {
  form: IShortFormData
  onClose(): void
	popupRef?: React.RefObject<HTMLDivElement>
}) {
  const [searchMode, setSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openConfirmPopup, setOpenConfirmPopup] = useState<Function | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const {data: forms} = useSelector(allForms);
  const dispatch = useDispatch();
  const context = useContext(NotificationContext);
	const marginBottom = useRef(0)

  const onOpenSearch = () => {
    setSearchMode(true);
    setTimeout(() => inputRef.current!.focus(), 300)
  }
  const onCloseSearch = () => {
    setSearchMode(false);
    setSearchValue('');
  }

  const onSelectForm = (partner: IShortFormData) => {
    const update = () => {
      api.form(form.id)
        .then(form => {
          api.form(partner.id).then(partner => {
            if (form && partner) {
              const partners = form?.partners ?? []
              partners.push({ ...partner.natal, name: partner.name })
              const newForm = {
                ...form,
                partners
              }

              dispatch(updateForm(newForm, () => {
                context?.add(t("chronos.mobile.dashboard.components.addPartner.partnerChanged"));
              }));
            }

            onClose();
          });
        })
    }

    if (form.synastry) {
      getMarginBottom()
      setOpenConfirmPopup(() => update);
    } else {
      update();
    }
  }

	const onCloseHandler = () => {
		setOpenConfirmPopup(null)
		popupRef && popupRef.current?.classList.remove('block_scroll')
	}

	const getMarginBottom = () => {
		if (popupRef) {
			const scrollTop = popupRef.current?.scrollTop
			marginBottom.current = scrollTop ?? 0

			popupRef.current?.classList.add('block_scroll')
		}
	}

  return <>
    <Header>
      <BackIconContainer onClick={onClose}><LeftTriangleIcon /></BackIconContainer>

      {!searchMode && <HeaderTitle>{t("chronos.app.addPartner")} </HeaderTitle>}
      {searchMode && <HeaderSearch>
        <input ref={inputRef} placeholder={t("chronos.app.dashboard.findInList")} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
      </HeaderSearch>}

      {!searchMode && <SearchIconContainer onClick={onOpenSearch}><SearchIcon /></SearchIconContainer> }
      {searchMode && <SearchIconContainer onClick={onCloseSearch}><CloseIcon /></SearchIconContainer> }
    </Header>
    <Forms>
      {forms
      .filter((item: IShortFormData) => form.id !== item.id)
      .filter((item: IShortFormData) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
      .map((item: IShortFormData) => {
          return <Form onClick={() => onSelectForm(item)} key={item.id}>
            <div><Avatar size={24} color={item.color} /></div>
            <FormName>{item.name}</FormName>
          </Form>
      })}
    </Forms>

    <ConfirmPopupButton
      open={!!openConfirmPopup}
			onClose={onCloseHandler}
      title={t("chronos.app.dashboard.changePartnerInfo")}
      onConfirm={() => {
        openConfirmPopup && openConfirmPopup();
        setOpenConfirmPopup(null);
      }}
      confirmText={t("base.confirm")} strong={true}
			marginBottom={marginBottom.current}
    >
      {t("chronos.app.dashboard.attentionData")}
    </ConfirmPopupButton>

  </>;
}

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.875rem;

`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  > svg {
    margin-right: 0.625rem;
  }
`

const HeaderSearch = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 0.875rem;

  > input {
    width: 100%;
    color: var(--text-secondary);
  }
`

const BackIconContainer = styled.div`
  color: var(--text-secondary) !important;
`

const SearchIconContainer = styled.div`
  color: var(--text-third) !important;
`

const Forms = styled.div`
  margin-top: 1.5rem;
  font-size: 0.875rem;
  padding-bottom: 1rem;
`

const Form = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr;
  grid-gap: 1rem;
  height: 2.5rem;
`

const FormName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
