import React, { useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IParts, TTimeType } from './EditDateAndTime';
import { fillNumber } from 'src/libs';

export const daysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
}

export default function Timeline({
  type,
  parts,
  onChange,
  onFixed
}: {
  type: TTimeType;
  parts: IParts;
  onChange(type: string, value: number): void;
  onFixed(type: string, value: number): void;
}){
  const ref = useRef<HTMLDivElement>(null);
  const [current, setCurrent] = useState<number | null>(null);
  const [smoothScroll, setSmoothScroll] = useState(false);

  let timer: any;

  const value = parts[type];

  useLayoutEffect(() => {
    
    const moveLineToItem = (item: HTMLDivElement) => {
      const left = item.offsetLeft;
      ref.current!.scrollLeft = left - window.innerWidth / 2 + item.offsetWidth / 2;
      setSmoothScroll(false);
    }

    Array.from(ref.current!.children).forEach(item_ => {
      const item = item_ as HTMLDivElement;
      if(+item.textContent!.trim() === +value) {
        moveLineToItem(item);
      }
    })

    const onScroll = () => { 
      const block = ref.current!;
      const center = block.offsetWidth / 2;
  
      let min = Infinity;
      let nearestItem: HTMLDivElement | undefined = undefined;
      Array.from(block.children).forEach((item_, i) => {
        const item = item_ as HTMLDivElement;
        const delta = Math.abs((block.scrollLeft + center) - item.offsetLeft )
        if(delta < min) {
          min = delta;
          nearestItem = item;
        }
      })

      if(nearestItem !== undefined){
        const value = +(nearestItem as HTMLDivElement).textContent!.trim();
        onChange(type, value);

        clearTimeout(timer);
        timer = setTimeout(() => {
          nearestItem?.classList.add('selected');
          onFixed(type, value);
        }, 150);
      }
        
    }

    ref.current!.addEventListener('scroll', onScroll);
    return () => {
      if(ref.current) ref.current!.removeEventListener('scroll', onScroll);
    }
  }, [type, current]);


  let min = 0;
  let max = 59;
  let items: number[] = [];
  let width = '2.5rem';

  switch(type){
    case 'day':
      min = 1;
      max = daysInMonth(parts.year, parts.month);
      break;
    case 'month':
      min = 1;
      max = 12;
      break;
    case 'year':
      min = 1200;
      max = 2100;
      width = '3.3rem';
      break;
    case 'hour':
      min = 0;
      max = 23;
      break;
  }

  for(let i = min; i <= max; i++) {
    items.push(i);
  }
  
  const onChangeByClick = (type: string, item: number) => { 
    setSmoothScroll(true);
    setCurrent(item);
    onChange(type, item);
  };

  return <Container ref={ref} smoothScroll={smoothScroll}>
    {items.map(item => <Item key={item} active={+item === +value} width={width}>
      <span onClick={() => {onChangeByClick(type, item)}}>{fillNumber(item)}</span>
    </Item>)}
  </Container>
}

const Container = styled.div<{smoothScroll: boolean}>`
  width: 100%;
  overflow-x: auto;
  font-size: 0.875rem;
  white-space: nowrap;
  color: var(--text-secondary);
  padding: 0 calc(50vw - 1rem);
  scroll-behavior: ${p => p.smoothScroll ? 'smooth' : 'auto'};
`
const Item = styled.div<{active?: boolean, width: string}>`
  /*padding: 1rem 0.75rem;*/
  display: inline-block;
  padding: 1rem 0;
  width: ${p => p.width};
  text-align: center;
  transition: transform 0.3s;

  span {
    display: block;
    transition: transform 0.1s;

    ${p => p.active && css`
      transform: scale(1.5);
      color: var(--text-primary);
    `}
  }

  &.selected{
    transform: translateY(-0.4rem);
    background-color: var(--bg-100);
    border-radius: 1.5rem;
    padding: 0.4rem;
  }
  
`