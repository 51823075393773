import { combineReducers } from 'redux';

import info from './info';
import payments from './payments';
import tariffs from './tariffs';

const paymentsReducer = combineReducers({
  info,
  payments,
  tariffs,
});

export default paymentsReducer;
