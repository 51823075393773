import {
  AspectType,
  checkAspect,
  circularDistance,
  getSign,
  IFixedStar,
  IObject,
  majorAspects,
  ObjectType,
  SignType
} from 'src/libs';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import fixedStars from 'src/guide/fixedStars';
import astro from 'src/astro';
import { avgSpeed } from '../../Circle/Objects';
import { getObjectHouses } from 'src/utils';
import { useTranslation } from 'src/i18n/useTranslation';
import { t } from 'i18next';

dayjs.extend(isBetween);

const steps: {
  [key: number]: {
    step: number;
    mul: number;
  };
} = {
  [ObjectType.Sun]: { step: 100, mul: 0.25 },
  [ObjectType.Moon]: { step: 12, mul: 0.15 },
  [ObjectType.Mercury]: { step: 100, mul: 0.25 },
  [ObjectType.Venus]: { step: 100, mul: 0.15 },
  [ObjectType.Mars]: { step: 100, mul: 0.15 },
  [ObjectType.Jupiter]: { step: 150, mul: 0.35 },
  [ObjectType.Saturn]: { step: 250, mul: 0.35 }
};

const horarObjects: ObjectType[] = [
  ObjectType.Sun,
  ObjectType.Moon,
  ObjectType.Mercury,
  ObjectType.Venus,
  ObjectType.Mars,
  ObjectType.Jupiter,
  ObjectType.Saturn
];

export enum EssentialsType {
  House,
  Exaltation,
  Exile,
  Fall,
  Triplicity,
  Terms,
  Face
}

export type IEssential = {
  [key in EssentialsType]: ObjectType | null | AdditionEssentials | TriplicityType;
};

export type TriplicityType = Array<ObjectType>;

export type AdditionEssentials = {
  [key: number]: ObjectType;
};

export const essentials: {
  [key in SignType]: IEssential;
} = {
  [SignType.Aries]: {
    [EssentialsType.House]: ObjectType.Mars,
    [EssentialsType.Exaltation]: ObjectType.Sun,
    [EssentialsType.Exile]: ObjectType.Venus,
    [EssentialsType.Fall]: ObjectType.Saturn,
    [EssentialsType.Triplicity]: [ObjectType.Sun, ObjectType.Jupiter],
    [EssentialsType.Terms]: {
      6: ObjectType.Jupiter,
      14: ObjectType.Venus,
      21: ObjectType.Mercury,
      26: ObjectType.Mars,
      30: ObjectType.Saturn
    },
    [EssentialsType.Face]: {
      10: ObjectType.Mars,
      20: ObjectType.Sun,
      30: ObjectType.Venus
    }
  },
  [SignType.Taurus]: {
    [EssentialsType.House]: ObjectType.Venus,
    [EssentialsType.Exaltation]: ObjectType.Moon,
    [EssentialsType.Exile]: ObjectType.Mars,
    [EssentialsType.Fall]: null,
    [EssentialsType.Triplicity]: [ObjectType.Venus, ObjectType.Moon],
    [EssentialsType.Terms]: {
      8: ObjectType.Venus,
      15: ObjectType.Mercury,
      22: ObjectType.Jupiter,
      26: ObjectType.Saturn,
      30: ObjectType.Mars
    },
    [EssentialsType.Face]: {
      10: ObjectType.Mercury,
      20: ObjectType.Moon,
      30: ObjectType.Saturn
    }
  },
  [SignType.Gemini]: {
    [EssentialsType.House]: ObjectType.Mercury,
    [EssentialsType.Exaltation]: null,
    [EssentialsType.Exile]: ObjectType.Jupiter,
    [EssentialsType.Fall]: null,
    [EssentialsType.Triplicity]: [ObjectType.Saturn, ObjectType.Mercury],
    [EssentialsType.Terms]: {
      7: ObjectType.Mercury,
      14: ObjectType.Jupiter,
      21: ObjectType.Venus,
      25: ObjectType.Saturn,
      30: ObjectType.Mars
    },
    [EssentialsType.Face]: {
      10: ObjectType.Jupiter,
      20: ObjectType.Mars,
      30: ObjectType.Sun
    }
  },
  [SignType.Cancer]: {
    [EssentialsType.House]: ObjectType.Moon,
    [EssentialsType.Exaltation]: ObjectType.Jupiter,
    [EssentialsType.Exile]: ObjectType.Saturn,
    [EssentialsType.Fall]: ObjectType.Mars,
    [EssentialsType.Triplicity]: [ObjectType.Mars, ObjectType.Mars],
    [EssentialsType.Terms]: {
      6: ObjectType.Mars,
      13: ObjectType.Jupiter,
      20: ObjectType.Mercury,
      27: ObjectType.Venus,
      30: ObjectType.Saturn
    },
    [EssentialsType.Face]: {
      10: ObjectType.Venus,
      20: ObjectType.Mercury,
      30: ObjectType.Moon
    }
  },
  [SignType.Leo]: {
    [EssentialsType.House]: ObjectType.Sun,
    [EssentialsType.Exaltation]: null,
    [EssentialsType.Exile]: ObjectType.Saturn,
    [EssentialsType.Fall]: null,
    [EssentialsType.Triplicity]: [ObjectType.Sun, ObjectType.Jupiter],
    [EssentialsType.Terms]: {
      6: ObjectType.Saturn,
      13: ObjectType.Mercury,
      19: ObjectType.Venus,
      25: ObjectType.Jupiter,
      30: ObjectType.Mars
    },
    [EssentialsType.Face]: {
      10: ObjectType.Saturn,
      20: ObjectType.Jupiter,
      30: ObjectType.Mars
    }
  },
  [SignType.Virgo]: {
    [EssentialsType.House]: ObjectType.Mercury,
    [EssentialsType.Exaltation]: ObjectType.Mercury,
    [EssentialsType.Exile]: ObjectType.Jupiter,
    [EssentialsType.Fall]: ObjectType.Venus,
    [EssentialsType.Triplicity]: [ObjectType.Venus, ObjectType.Moon],
    [EssentialsType.Terms]: {
      7: ObjectType.Mercury,
      13: ObjectType.Venus,
      18: ObjectType.Jupiter,
      24: ObjectType.Saturn,
      30: ObjectType.Mars
    },
    [EssentialsType.Face]: {
      10: ObjectType.Sun,
      20: ObjectType.Venus,
      30: ObjectType.Mercury
    }
  },
  [SignType.Libra]: {
    [EssentialsType.House]: ObjectType.Venus,
    [EssentialsType.Exaltation]: ObjectType.Saturn,
    [EssentialsType.Exile]: ObjectType.Mars,
    [EssentialsType.Fall]: ObjectType.Sun,
    [EssentialsType.Triplicity]: [ObjectType.Saturn, ObjectType.Mercury],
    [EssentialsType.Terms]: {
      6: ObjectType.Saturn,
      11: ObjectType.Venus,
      19: ObjectType.Jupiter,
      24: ObjectType.Mercury,
      30: ObjectType.Mars
    },
    [EssentialsType.Face]: {
      10: ObjectType.Moon,
      20: ObjectType.Saturn,
      30: ObjectType.Jupiter
    }
  },
  [SignType.Scorpio]: {
    [EssentialsType.House]: ObjectType.Mars,
    [EssentialsType.Exaltation]: null,
    [EssentialsType.Exile]: ObjectType.Venus,
    [EssentialsType.Fall]: ObjectType.Moon,
    [EssentialsType.Triplicity]: [ObjectType.Mars, ObjectType.Mars],
    [EssentialsType.Terms]: {
      6: ObjectType.Mars,
      14: ObjectType.Jupiter,
      21: ObjectType.Venus,
      27: ObjectType.Mercury,
      30: ObjectType.Saturn
    },
    [EssentialsType.Face]: {
      10: ObjectType.Mars,
      20: ObjectType.Sun,
      30: ObjectType.Venus
    }
  },
  [SignType.Sagittarius]: {
    [EssentialsType.House]: ObjectType.Jupiter,
    [EssentialsType.Exaltation]: null,
    [EssentialsType.Exile]: ObjectType.Mercury,
    [EssentialsType.Fall]: null,
    [EssentialsType.Triplicity]: [ObjectType.Sun, ObjectType.Jupiter],
    [EssentialsType.Terms]: {
      8: ObjectType.Jupiter,
      14: ObjectType.Venus,
      19: ObjectType.Mercury,
      25: ObjectType.Saturn,
      30: ObjectType.Mars
    },
    [EssentialsType.Face]: {
      10: ObjectType.Mercury,
      20: ObjectType.Moon,
      30: ObjectType.Saturn
    }
  },
  [SignType.Capricorn]: {
    [EssentialsType.House]: ObjectType.Saturn,
    [EssentialsType.Exaltation]: ObjectType.Mars,
    [EssentialsType.Exile]: ObjectType.Moon,
    [EssentialsType.Fall]: ObjectType.Jupiter,
    [EssentialsType.Triplicity]: [ObjectType.Venus, ObjectType.Moon],
    [EssentialsType.Terms]: {
      6: ObjectType.Venus,
      12: ObjectType.Mercury,
      19: ObjectType.Jupiter,
      25: ObjectType.Mars,
      30: ObjectType.Saturn
    },
    [EssentialsType.Face]: {
      10: ObjectType.Jupiter,
      20: ObjectType.Mars,
      30: ObjectType.Sun
    }
  },
  [SignType.Aquarius]: {
    [EssentialsType.House]: ObjectType.Saturn,
    [EssentialsType.Exaltation]: null,
    [EssentialsType.Exile]: ObjectType.Sun,
    [EssentialsType.Fall]: null,
    [EssentialsType.Triplicity]: [ObjectType.Saturn, ObjectType.Mercury],
    [EssentialsType.Terms]: {
      6: ObjectType.Saturn,
      12: ObjectType.Mercury,
      20: ObjectType.Venus,
      25: ObjectType.Jupiter,
      30: ObjectType.Mars
    },
    [EssentialsType.Face]: {
      10: ObjectType.Venus,
      20: ObjectType.Mercury,
      30: ObjectType.Moon
    }
  },
  [SignType.Pisces]: {
    [EssentialsType.House]: ObjectType.Jupiter,
    [EssentialsType.Exaltation]: ObjectType.Venus,
    [EssentialsType.Exile]: ObjectType.Mercury,
    [EssentialsType.Fall]: ObjectType.Mercury,
    [EssentialsType.Triplicity]: [ObjectType.Mars, ObjectType.Mars],
    [EssentialsType.Terms]: {
      8: ObjectType.Venus,
      14: ObjectType.Jupiter,
      20: ObjectType.Mercury,
      26: ObjectType.Mars,
      30: ObjectType.Saturn
    },
    [EssentialsType.Face]: {
      10: ObjectType.Saturn,
      20: ObjectType.Jupiter,
      30: ObjectType.Mars
    }
  }
};

export const getEssentialObject = (planets: AdditionEssentials, lon: number): ObjectType => {
  for (const key of Object.keys(planets)) {
    const keyNumber = parseInt(key, 10);
    if (Math.max(lon, keyNumber) === keyNumber) {
      return planets[keyNumber];
    }
  }
  return 0;
};

const isTriplicity = (currentPlanetId: number, objects: IObject[], houses: number[]): boolean => {
  const { lon } = objects[currentPlanetId];

  const sign: SignType = getSign(lon);

  const triplicity = essentials[sign][EssentialsType.Triplicity] as TriplicityType;

  return triplicity[isEastern(objects[ObjectType.Sun].lon, houses[6]) ? 0 : 1] === currentPlanetId;
};

const isReciprocity = (essentialType: EssentialsType, currentPlanetId: number, objects: IObject[]): boolean => {
  const { lon } = objects[currentPlanetId];

  const sign: SignType = getSign(lon);

  const associate = essentials[sign][essentialType] as ObjectType;
  if (associate === null) {
    return false;
  }

  const associateSign: SignType = getSign(objects[associate].lon);

  return associate !== currentPlanetId
    && currentPlanetId === essentials[associateSign][essentialType];
};

export const getEssentialsValue = (currentPlanetId: number, objects: IObject[], houses: number[]): { points: number; description: string } => {
  let points = 0;
  let description = t("chronos.app.instruments.widgets.horar.event.values");

  const { lon } = objects[currentPlanetId];

  const sign: SignType = getSign(lon);

  // Планета в своем знаке
  if (essentials[sign][EssentialsType.House] === currentPlanetId) {
    points += 5;
    description += t("chronos.app.instruments.widgets.horar.events.planetInSign");
  }
  // Планета во взаимной рецепции по владению
  if (isReciprocity(EssentialsType.House, currentPlanetId, objects)) {
    points += 5;
    description += t("chronos.app.instruments.widgets.horar.planetInReceptions");
  }
  // Планета в экзальтации
  if (essentials[sign][EssentialsType.Exaltation] === currentPlanetId) {
    points += 4;
    description += t("chronos.app.instruments.widgets.horar.planetInExalted");
  }
  // Планета во взаимной рецепции по экзальтации
  if (isReciprocity(EssentialsType.Exaltation, currentPlanetId, objects)) {
    points += 4;
    description += t("chronos.app.instruments.widgets.horar.planetInReceptionExalted");
  }
  // Планета в триплицитете
  if (isTriplicity(currentPlanetId, objects, houses)) {
    points += 3;
    description += t("chronos.app.instruments.widgets.horar.planetInTriplicity");
  }
  // Планета в терме
  if (getEssentialObject(essentials[sign][EssentialsType.Terms] as AdditionEssentials, lon % 30) === currentPlanetId) {
    points += 2;
    description += t("chronos.app.instruments.widgets.horar.planetInTerm");
  }
  // Планета в фасе
  if (getEssentialObject(essentials[sign][EssentialsType.Face] as AdditionEssentials, lon % 30) === currentPlanetId) {
    points += 1;
    description += t("chronos.app.instruments.widgets.horar.planetInFace");
  }
  // Планета в изгнании
  if (essentials[sign][EssentialsType.Exile] === currentPlanetId) {
    points += -5;
    description += t("chronos.app.instruments.widgets.horar.planetInExile");
  }
  // Планета в падении
  if (essentials[sign][EssentialsType.Fall] === currentPlanetId) {
    points += -4;
    description += t("chronos.app.instruments.widgets.horar.planetInFall");
  }
  // Планета перегрин
  if (points === 0) {
    points += -5;
    description += t("chronos.app.instruments.widgets.horar.planetPeregrine");
  }
  description += '</ul>';

  return { points, description };
};

export const isEastern = (current: number, target: number): boolean => {
  if (Math.abs(current - target) > 180) {
    if (target < current) {
      target += 360;
    } else {
      current += 360;
    }
  }
  return current - target < 180 && current - target > 0;
};

const isBesieged = (
  target: number,
  objects: IObject[],
  besieger1: number = ObjectType.Mars,
  besieger2: number = ObjectType.Saturn
): boolean => {
  if (target === besieger1 || target === besieger2) {
    return false;
  }

  const indexedObjects = [];
  for (let p = ObjectType.Sun; p <= ObjectType.Saturn; p++) {
    indexedObjects.push({
      id: p,
      lon: objects[p].lon,
      speed: objects[p].speed
    });
  }

  indexedObjects.sort((a, b) => a.lon - b.lon);

  const targetIndex = indexedObjects.findIndex(obj => obj.id === target);
  const leftIndex = targetIndex === 0 ? indexedObjects.length - 1 : targetIndex - 1;
  const rightIndex = targetIndex === indexedObjects.length - 1 ? 0 : targetIndex + 1;

  return [besieger1, besieger2].includes(indexedObjects[leftIndex].id)
    && [besieger1, besieger2].includes(indexedObjects[rightIndex].id);
};

export const getAccidentalValue = async (currentPlanetId: number, objects: IObject[], houses: number[], dt: string): Promise<{ points: number; description: string }> => {
  let points = 0;
  let description = t("chronos.app.instruments.widgets.horar.accidentalMeanings");

  const { lon, speed } = objects[currentPlanetId];

  const hasAspect = (obj: ObjectType, type: AspectType) => checkAspect(1, lon, objects[obj].lon, type).exists;

  const [ objectHouses ] = getObjectHouses(lon, houses);

  // Точное соединение с Регулом +6
  if (circularDistance(lon, (await astro.star(dt, 'Regulus')).lon, 360) < 1) {
    points += 6;
    description += t("chronos.app.instruments.widgets.horar.connectionWithRegulus");
  }
  // Точное соединение со Спикой +5
  if (circularDistance(lon, (await astro.star(dt, 'Spica')).lon, 360) < 1) {
    points += 5;
    description += t("chronos.app.instruments.widgets.horar.connectionWithSpica");
  }
  // В 1 или 10 доме
  if (objectHouses === 0 || objectHouses === 9) {
    points += 5;
    description += t("chronos.app.instruments.widgets.horar.in1or10houses");
  }
  // Не сожжена под лучами Солнца
  if (
    currentPlanetId !== ObjectType.Sun
    && circularDistance(lon, objects[ObjectType.Sun].lon, 360) < 0.28
    || circularDistance(lon, objects[ObjectType.Sun].lon, 360) > 17
  ) {
    points += 5;
    description += t("chronos.app.instruments.widgets.horar.notBurnedBySun");
  }
  // Точное соединение с Юпитером или Венерой
  if (
    currentPlanetId === ObjectType.Jupiter
    && circularDistance(lon, objects[ObjectType.Venus].lon, 360) < 1
    || currentPlanetId === ObjectType.Venus
    && circularDistance(lon, objects[ObjectType.Jupiter].lon, 360) < 1
    || (currentPlanetId !== ObjectType.Jupiter && circularDistance(lon, objects[ObjectType.Jupiter].lon, 360) < 1
      || currentPlanetId !== ObjectType.Venus && circularDistance(lon, objects[ObjectType.Venus].lon, 360) < 1)
  ) {
    points += 5;
    description += t("chronos.app.instruments.widgets.horar.conjunctionJupiterOrVenus");
  }
  // Казими
  if (
    currentPlanetId !== ObjectType.Sun
    && circularDistance(lon, objects[ObjectType.Sun].lon, 360) < 0.28
  ) {
    points += 5;
    description += t("chronos.app.instruments.widgets.horar.kazimi");
  }
  // В 4, 7, 11 домах
  if (
    objectHouses === 3
    || objectHouses === 6
    || objectHouses === 10
  ) {
    points += 4;
    description += t("chronos.app.instruments.widgets.horar.in4or7or11houses");
  }
  // Директная
  if (speed > 0) {
    points += 4;
    description += t("chronos.app.instruments.widgets.horar.directions");
  }
  // Точный трин с Юпитером или Венерой
  if (
    currentPlanetId === ObjectType.Jupiter
    && hasAspect(ObjectType.Venus, AspectType.Trine)
    || currentPlanetId === ObjectType.Venus
    && hasAspect(ObjectType.Jupiter, AspectType.Trine)
    || (currentPlanetId !== ObjectType.Jupiter && hasAspect(ObjectType.Jupiter, AspectType.Trine)
    || currentPlanetId !== ObjectType.Venus && hasAspect(ObjectType.Venus, AspectType.Trine))
  ) {
    points += 4;
    description += t("chronos.app.instruments.widgets.horar.exactTrineJupiterOrVenus");
  }
  // Точное соединение с восходящим лунным узлом
  if (hasAspect(ObjectType.NorthNode, AspectType.Conjunction)) {
    points += 4;
    description += t("chronos.app.instruments.widgets.horar.exactConjunctionWithNode");
  }
  // Во 2 или 5 доме
  if (
    objectHouses === 1
    || objectHouses === 4
  ) {
    points += 3;
    description += t("chronos.app.instruments.widgets.horar.in2or5houses");
  }
  // Точный секстиль с Юпитером или Венерой
  if (
    currentPlanetId === ObjectType.Jupiter
    && hasAspect(ObjectType.Venus, AspectType.Sextile)
    || currentPlanetId === ObjectType.Venus
    && hasAspect(ObjectType.Jupiter, AspectType.Sextile)
    || (currentPlanetId !== ObjectType.Jupiter && hasAspect(ObjectType.Jupiter, AspectType.Sextile)
    || currentPlanetId !== ObjectType.Venus && hasAspect(ObjectType.Venus, AspectType.Sextile))
  ) {
    points += 3;
    description += t("chronos.app.instruments.widgets.horar.sextileWhithJupiterOrVenus");
  }
  // В 9 доме
  if (
    objectHouses === 8
  ) {
    points += 2;
    description += t("chronos.app.instruments.widgets.horar.in9house");
  }
  // Быстрая
  if (Math.abs(speed) / avgSpeed[currentPlanetId] > 1) {
    points += 2;
    description += t("chronos.app.instruments.widgets.horar.quick");
  }
  // Сатурн, Юпитер, Марс Восточные
  if (
    ObjectType.Saturn === currentPlanetId && isEastern(objects[ObjectType.Saturn].lon, objects[ObjectType.Sun].lon)
    || ObjectType.Jupiter === currentPlanetId && isEastern(objects[ObjectType.Jupiter].lon, objects[ObjectType.Sun].lon)
    || ObjectType.Mars === currentPlanetId && isEastern(objects[ObjectType.Mars].lon, objects[ObjectType.Sun].lon)
  ) {
    points += 2;
    description += t("chronos.app.instruments.widgets.horar.suturnJupMarsEastern");
  }
  // Меркурий, Венера западные
  if (
    ObjectType.Mercury === currentPlanetId && !isEastern(objects[ObjectType.Mercury].lon, objects[ObjectType.Sun].lon)
    || ObjectType.Venus === currentPlanetId && !isEastern(objects[ObjectType.Venus].lon, objects[ObjectType.Sun].lon)
  ) {
    points += 2;
    description += t("chronos.app.instruments.widgets.horar.merkuryVenusWestern");
  }
  // Луна растущая или западная
  if (ObjectType.Moon === currentPlanetId && !isEastern(objects[ObjectType.Moon].lon, objects[ObjectType.Sun].lon)) {
    points += 2;
    description += t("chronos.app.instruments.widgets.horar.moonWaxingOrWestern");
  }
  // В 3 доме
  if (
    objectHouses === 2
  ) {
    points += 1;
    description += t("chronos.app.instruments.widgets.horar.in3house");
  }
  // В 12 доме
  if (
    objectHouses === 11
  ) {
    points += -5;
    description += t("chronos.app.instruments.widgets.horar.in12house");
  }
  // Ретроградная
  if (
    speed < 0
  ) {
    points += -5;
    description += t("chronos.app.instruments.widgets.horar.retro");
  }
  // Сожжена
  if (
    currentPlanetId !== ObjectType.Sun
    && circularDistance(lon, objects[ObjectType.Sun].lon, 360) > 0.28
    && circularDistance(lon, objects[ObjectType.Sun].lon, 360) < 8.5
    && getSign(lon) === getSign(objects[ObjectType.Sun].lon)
  ) {
    points += -5;
    description += t("chronos.app.instruments.widgets.horar.burned");
  }
  // Точное соединение с Сатурном или Марсом
  if (
    currentPlanetId === ObjectType.Saturn
    && circularDistance(lon, objects[ObjectType.Mars].lon, 360) < 1
    || currentPlanetId === ObjectType.Mars
    && circularDistance(lon, objects[ObjectType.Saturn].lon, 360) < 1
    || (currentPlanetId !== ObjectType.Saturn && circularDistance(lon, objects[ObjectType.Saturn].lon, 360) < 1
    || currentPlanetId !== ObjectType.Mars && circularDistance(lon, objects[ObjectType.Mars].lon, 360) < 1)
  ) {
    points += -5;
    description += t("chronos.app.instruments.widgets.horar.conjunctionSaturnOrMars");
  }
  // Осаждена Сатурном и Марсом
  if (isBesieged(currentPlanetId, objects)) {
    points += -5;
    description += t("chronos.app.instruments.widgets.horar.besiegedBySaturnAndMars");
  }
  // Точное соединение с Алголем -5
  if (circularDistance(lon, (await astro.star(dt, 'Algol')).lon, 360) < 1) {
    points += -5;
    description += t("chronos.app.instruments.widgets.horar.connectionWithAlgol");
  }
  // Под лучами солнца
  if (
    currentPlanetId !== ObjectType.Sun
    && circularDistance(lon, objects[ObjectType.Sun].lon, 360) > 8.5
    && circularDistance(lon, objects[ObjectType.Sun].lon, 360) < 17
  ) {
    points += -4;
    description += t("chronos.app.instruments.widgets.horar.underSun");
  }
  // Точный аспект с нисходящим лунным узлом
  if (hasAspect(ObjectType.SouthNode, AspectType.Conjunction)) {
    points += -4;
    description += t("chronos.app.instruments.widgets.horar.conjuncionWithNode");
  }
  // Точная оппозиция с Сатурном или Марсом
  if (hasAspect(ObjectType.Mars, AspectType.Opposition) || hasAspect(ObjectType.Saturn, AspectType.Opposition)) {
    points += -4;
    description += t("chronos.app.instruments.widgets.horar.oppositionWithSuturnOrMars");
  }
  // Точная квадратура с Сатурном или Марсом
  if (hasAspect(ObjectType.Mars, AspectType.Square) || hasAspect(ObjectType.Saturn, AspectType.Square)) {
    points += -3;
    description += t("chronos.app.instruments.widgets.horar.squareWithSaturnOrMars");
  }
  // В 6 или 8 доме
  if (
    objectHouses === 7
    || objectHouses === 5
  ) {
    points += -2;
    description += t("chronos.app.instruments.widgets.horar.in6or8houses");
  }
  // Медленная
  if (Math.abs(speed) / avgSpeed[currentPlanetId] < 1) {
    points += -2;
    description += t("chronos.app.instruments.widgets.horar.slow");
  }
  // Юпитер, Сатурн, Марс, западные
  if (
    ObjectType.Jupiter === currentPlanetId && !isEastern(objects[ObjectType.Jupiter].lon, objects[ObjectType.Sun].lon)
    || ObjectType.Saturn === currentPlanetId && !isEastern(objects[ObjectType.Saturn].lon, objects[ObjectType.Sun].lon)
    || ObjectType.Mars === currentPlanetId && !isEastern(objects[ObjectType.Mars].lon, objects[ObjectType.Sun].lon)
  ) {
    points += -2;
    description += t("chronos.app.instruments.widgets.horar.jupiterSaturnMarsWestern");
  }
  // Меркурий, Венера восточные
  if (
    ObjectType.Mercury === currentPlanetId && isEastern(objects[ObjectType.Mercury].lon, objects[ObjectType.Sun].lon)
    || ObjectType.Venus === currentPlanetId && isEastern(objects[ObjectType.Venus].lon, objects[ObjectType.Sun].lon)
  ) {
    points += -2;
    description += t("chronos.app.instruments.widgets.horar.mercuryVenusEastern");
  }
  // Луна убывающая
  if (ObjectType.Moon === currentPlanetId && isEastern(objects[ObjectType.Moon].lon, objects[ObjectType.Sun].lon)) {
    points += -2;
    description += t("chronos.app.instruments.widgets.horar.moonWanning");
  }
  description += '</ul>';

  return { points, description };
};

async function calcSignBorders(obj: ObjectType, natSign: number, m: Dayjs, dir: number) {
  // const m = dayjs(natM);

  let step = steps[obj].step * 3600;
  let last = { lon: 0, speed: 0 };

  let counter = 0;

  while (step >= 0.5) {
    m = m.add(step * dir, 'seconds');

    const { lon } = last = await astro.object(m.toISOString(), obj);

    if (Math.floor(lon / 30) !== natSign) {
      m = m.add(-step * dir, 'seconds');

      step *= steps[obj].mul;
    }

    counter++;
  }

  // console.log("counter:", counter);

  return {
    m,
    ...last,
    counter
  };
}

interface IDichotomy {
  m: Dayjs;
  lon: number;
  speed: number;
  dir?: number;
}

async function dichotomy(obj: ObjectType, a: IDichotomy, b: IDichotomy) {
  while (true) {
    const diff = b.m.diff(a.m, 'seconds');

    if (diff <= 1) { return a }

    const m = dayjs(a.m).add(diff / 2, 'seconds');

    const x = {
      m,
      ...await astro.object(m.toISOString(), obj)
    };

    if (Math.sign(a.speed) === Math.sign(x.speed)) {
      a = x;
    } else {
      b = x;
    }
  }
}

async function calcRetro(obj: ObjectType, enter: IDichotomy, leave: IDichotomy) {
  const results: IDichotomy[] = [];

  const diff = leave.m.diff(enter.m, 'seconds');

  const step = diff / 50;

  let prev: IDichotomy = enter;

  let m = dayjs(enter.m).add(step, 'seconds');

  while (m.isBefore(leave.m)) {
    const cur = {
      m: dayjs(m),
      ...await astro.object(m.toISOString(), obj)
    };

    const dir = Math.sign(cur.speed);

    if (dir !== Math.sign(prev.speed)) {
      const d = await dichotomy(obj, prev, cur);
      results.push({
        dir,
        ...d
      });
    }

    prev = cur;

    m = m.add(step, 'seconds');
  }

  return results;
}
interface IAspectsObjects {
  [key: number]: {
    sign: number;
    enter: IDichotomy;
    leave: IDichotomy;
    lon: number;
    speed: number;
  };
}

function timeRange(a0: Dayjs, b0: Dayjs, a1: Dayjs, b1: Dayjs) {
  return {
    enter: a0.isBetween(a1, b1) ? a0 : a1.isBetween(a0, b0) ? a1 : null,
    leave: b0.isBetween(a1, b1) ? b0 : b1.isBetween(a0, b0) ? b1 : null
  };
}

const toSec = 1 / (24 * 3600);

async function calc(obj1: ObjectType, obj2: ObjectType, asp: AspectType, dt: string) {
  const { lon: lon1, speed: speed1 } = await astro.object(dt, obj1);
  const { lon: lon2, speed: speed2 } = await astro.object(dt, obj2);

  const orb = Math.abs(circularDistance(lon1, lon2, 360) - asp);

  return {
    orb,
    speed: Math.abs(circularDistance(lon1 + speed1 * toSec, lon2 + speed2 * toSec, 360) - asp) - orb
  };
}

async function findOptimum(asp: AspectType, obj1: ObjectType, obj2: ObjectType, orb1: number, speed1: number, m1: Dayjs, m2: Dayjs) {
  let counter = 0;

  let m = dayjs(m1);

  // console.log(m1.format(), m2.format());

  do {
    m = m.add(-orb1 / speed1, 'seconds');

    if (m.isBefore(m1) || m.isAfter(m2)) {
      // console.log("BAD INTERVAL")
      return;
    }

    const { speed, orb } = await calc(obj1, obj2, asp, m.toISOString());

    // console.log(m.format(), orb);

    if (orb < 1e-6) {
      // console.log(counter, m.format());
      break;
    }

    speed1 = speed;
    orb1 = orb;
  } while (counter++ < 20);

  // console.log("COUNTER")
  return m;
}

async function calcAspects(obj: ObjectType, objects: IAspectsObjects) {
  const results: any[] = [];

  const { enter: objEnter, leave: objLeave, sign: objSign } = objects[obj];

  for (let opp = obj + 1; opp <= ObjectType.Saturn; opp++) {
    const { enter: oppEnter, leave: oppLeave, sign: oppSign } = objects[opp];

    const { enter, leave } = timeRange(objEnter.m, objLeave.m, oppEnter.m, oppLeave.m);

    if (!enter || !leave) { continue }

    // console.log(getObjectName(obj), getObjectName(opp), momFmt(enter), momFmt(leave));

    const diff = leave.diff(enter, 'seconds');
    const step = diff / 100;

    for (const asp of majorAspects) {
      if (circularDistance(objSign, oppSign, 12) != asp / 30) { continue }

      let prevSpeed = null;
      let prevX = null;
      let prevOrb = null;

      let x = dayjs(enter);

      while (x.isBefore(leave)) {
        const { orb, speed } = await calc(obj, opp, asp, x.toISOString());

        if (
          prevSpeed !== null &&
          Math.sign(prevSpeed) == -1 &&
          Math.sign(prevSpeed) != Math.sign(speed)
        ) {
          const m = await findOptimum(asp, obj, opp, prevOrb!, prevSpeed, prevX!, x);

          if (m) {
            results.push({
              m,
              obj,
              asp,
              opp,
              obj1: {
                ...await astro.object(m.toISOString(), obj),
                id: obj
              },
              obj2: {
                ...await astro.object(m.toISOString(), opp),
                id: opp
              }
            });
          }
        }

        prevX = dayjs(x);
        prevSpeed = speed;
        prevOrb = orb;

        x = x.add(step, 'seconds');
      }
    }
  }

  return results;
}

export const calcHorarSigns = (houses: number[]) => {
  const signs: number[] = [];

  let sign0 = 0;

  for (let h = 0; h < 12; h++) {
    const lon = houses[h];

    let nlon = houses[(h + 1) % 12];
    if (nlon < lon) { nlon += 360 }

    const dist = nlon - lon;

    for (let s = Math.ceil(lon / 30) * 30; s >= lon && s < nlon; s += 30) {
      if (s % 360 === 0) {
        sign0 = signs.length;
      }
      signs.push(30 * (h + (s - lon) / dist));
    }
  }

  return [
    ...signs.slice(sign0),
    ...signs.slice(0, sign0)
  ];
};

export const getPlanetaryDayObject = (objects: IObject[], houses: number[], dt: Date): ObjectType => {
  const day: Weekday = dayjs(dt).day();
  let astroDay: Weekday;
  if (isEastern(objects[ObjectType.Sun].lon, houses[9]) && isEastern(objects[ObjectType.Sun].lon, houses[0])) {
    astroDay = day === Weekday.Sunday ? Weekday.Saturday : day - 1;
  } else {
    astroDay = day;
  }
  // const astroDay: Weekday = isEastern(objects[ObjectType.Sun].lon, houses[6]) && isEastern(objects[ObjectType.Sun].lon, houses[3]) ? day : day === Weekday.Sunday ? Weekday.Saturday : day + 1;

  return PlanetaryDays[astroDay];
};

export enum Weekday {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export const PlanetaryDays: {
  [key in Weekday]: ObjectType
} = {
  [Weekday.Sunday]: ObjectType.Sun,
  [Weekday.Monday]: ObjectType.Moon,
  [Weekday.Tuesday]: ObjectType.Mars,
  [Weekday.Wednesday]: ObjectType.Mercury,
  [Weekday.Thursday]: ObjectType.Jupiter,
  [Weekday.Friday]: ObjectType.Venus,
  [Weekday.Saturday]: ObjectType.Saturn
};

export enum HorarEventType {
  Border,
  Direction,
  Aspect
}

export interface IHorarObject {
  id: ObjectType;
  lon: number;
  speed: number;
}

export interface IHorarEvent {
  m: Dayjs;
  id?: number;
  type: HorarEventType;
  obj: ObjectType;
  dir?: number;
  opp?: ObjectType;
  dist?: number;
  asp?: AspectType;
  lon?: number;
  speed?: number;
  sign: number;
  obj1?: IHorarObject;
  obj2?: IHorarObject;
}

export interface IArabicCalc {
  name: string;
  formula: {
    [key: string]: [string, number];
  };
  night: boolean;
}

export const arabicCalc: IArabicCalc[] = [
  {
    name: t("chronos.app.instruments.widgets.horar.fortune"),
    formula: {
      a: ['house', 0],
      b: ['object', ObjectType.Moon],
      c: ['object', ObjectType.Sun]
    },
    night: true
  },
  {
    name: t("chronos.app.instruments.widgets.horar.marriage"),
    formula: {
      a: ['house', 0],
      b: ['house', 6],
      c: ['object', ObjectType.Venus]
    },
    night: false
  },
  {
    name: t("chronos.app.instruments.widgets.horar.divorce"),
    formula: {
      a: ['house', 0],
      b: ['house', 6],
      c: ['object', ObjectType.Mars]
    },
    night: false
  },
  {
    name: t("chronos.app.instruments.widgets.horar.layoffs"),
    formula: {
      a: ['object', ObjectType.Saturn],
      b: ['object', ObjectType.Jupiter],
      c: ['object', ObjectType.Sun]
    },
    night: false
  },
  {
    name: t("chronos.app.instruments.widgets.horar.operations"),
    formula: {
      a: ['house', 0],
      b: ['object', ObjectType.Saturn],
      c: ['object', ObjectType.Mars]
    },
    night: true
  },
  // {
  //   name: 'Призвания',
  //   formula: {
  //     a: ['house', 9],
  //     b: ['object', ObjectType.Moon],
  //     c: ['object', ObjectType.Sun]
  //   },
  //   night: false
  // },
  {
    name: t("chronos.app.instruments.widgets.horar.fame"),
    formula: {
      a: ['house', 0],
      b: ['object', ObjectType.Jupiter],
      c: ['object', ObjectType.Sun]
    },
    night: true
  }
];

export const calcFixedStars = async (dt: string) : Promise<IFixedStar[]> => {
  const stars = [];

  for (const star of fixedStars) {
    const starLon = (await astro.star(dt, star.name)).lon;
    stars.push({
      ...star,
      lon: starLon,
      // FIXME: remove
      ru: star.title,
      en: star.name,
    });
  }
  return stars;
};

export default async (m: Dayjs) => {
  const events: IHorarEvent[] = [];

  const objects: IAspectsObjects = {};

  for (const obj of horarObjects) {
    const { lon, speed } = await astro.object(m.toISOString(), obj);

    const sign = getSign(lon);

    const enter = await calcSignBorders(obj, sign, m, -1);
    const leave = await calcSignBorders(obj, sign, m, 1);

    const addBorderEvent = (b: IDichotomy, dir: number) => {
      const dist = circularDistance(b.lon, lon, 360);

      events.push({
        type: HorarEventType.Border,
        dir,
        sign,
        m: b.m,
        obj,
        lon: b.lon,
        dist,
        speed: b.speed
      });
    };

    addBorderEvent(enter, -1);
    addBorderEvent(leave, 1);

    objects[obj] = {
      lon,
      speed,
      sign,
      enter,
      leave
    };
  }

  for (const obj of horarObjects) {
    const { sign, enter, leave } = objects[obj];

    const retros = await calcRetro(obj, enter, leave);

    retros.forEach(r => {
      events.push({
        type: HorarEventType.Direction,
        sign,
        obj,
        dist: r.lon % 30,
        ...r
      });
    });
  }

  for (const obj of horarObjects) {
    const aspects = await calcAspects(obj, objects);

    aspects.forEach(a => {
      let fast = a.obj1;
      let slow = a.obj2;

      if (Math.abs(objects[slow.id].speed) > Math.abs(objects[fast.id].speed)) {
        const t = fast;
        fast = slow;
        slow = t;
      }

      const isPrev = a.m.isBefore(m);

      events.push({
        type: HorarEventType.Aspect,
        sign: objects[obj].sign,
        obj,
        dist: Math.abs(fast.lon - objects[fast.id].lon),
        ...a,
        obj1: isPrev ? slow : fast,
        obj2: isPrev ? fast : slow
      });
    });
  }

  return events.sort((a, b) => a.m.isBefore(b.m) ? -1 : 1);
};
