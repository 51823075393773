import React from 'react';
import styled from 'styled-components';

export default function Overlay(props: {
  children: any;
  onClick?(): void;
}): any {
  return (
    <OverlayWrapper >
      <OverlaySibling onClick={props.onClick} />
      {props.children}
    </OverlayWrapper>
  );
}

const OverlayWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  backdrop-filter: brightness(0.9) blur(3px);

  z-index: 20;
`;

const OverlaySibling = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--background);
  z-index: -1;
  left: 0;
  top: 0;
  filter: blur(10px);
  opacity: 0.8;
`;
