import React, { ReactNode } from 'react';
import styled from 'styled-components';

export default function HitIcon({
  children,
  onClick = () => {}
}: {
  children: ReactNode,
  onClick?: () => void
}) {
  return <Container onClick={onClick}>{children}</Container>
}

const Container = styled.div`
  display: inline-block;
  padding: 0.375rem;
`