import { PROFILE_GET_LOADING, PROFILE_GET_SUCCESS } from './actions';
import { IProfileState, IActionProfile } from './types';

const initialState: IProfileState = {
  loading: false,
  loaded: false,
  data: {
    partner: '',
    id: -1,
    sessionId: -1,
    permissions: null,
    showWelcomeInstruments: false,
    firstName: '',
    lastName: '',
    avatarUrl: null,
    gender: null,
    levelOfAstrology: null,
    birth: {},
    email: null,
    phoneNumber: null,
  } as any, // FIXME: any
};

export default function profile(state = initialState, action: IActionProfile){
  switch(action.type){
    case PROFILE_GET_LOADING:
      return {
        loading: true,
        loaded: false,
        data: state.data
      };
    case PROFILE_GET_SUCCESS:
      return {
        loading: false,
        loaded: true,
        data: action.payload
      };
    default:
      return state;
  }
}