import { useTranslation } from 'src/i18n/useTranslation';
import React, { useState } from 'react';
import styled from 'styled-components';
import SwipeTabs, { ISwipeTab } from 'src/ui/SwipeTabs';
import { IWidgetData } from '../data';
import DispositorsScreen from './DispositorsScreen';
import { StatusType } from 'src/helpers/utils';

export default function Dispositors(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
  openedWidget: boolean;
}) {
  const { t } = useTranslation();
  const [activeSign, setActiveSign] = useState(0);
  const items: ISwipeTab[] = [
    {key: 0, title: <div onClick={() => setActiveSign(StatusType.ruler)}>{t("astro.byOwnership")}</div>, content: ''},
    {key: 1, title: <div onClick={() => setActiveSign(StatusType.exaltation)}>{t("astro.byExaltation")}</div>, content: ''},
    {key: 2, title: <div onClick={() => setActiveSign(StatusType.fall)}>{t("astro.byFall")}</div>, content: ''},
    {key: 3, title: <div onClick={() => setActiveSign(StatusType.detriment)}>{t("astro.byIssuance")}</div>, content: ''},
    
  ]
  return <Container>
    <SwipeTabs items={items} open={props.openedWidget} />
    <MarginTop>
      <DispositorsScreen activeSign={activeSign} data={props.data} />
    </MarginTop>
  </Container>
}

const Container = styled.div`
  white-space: normal;
  width: 100%;
`
const MarginTop = styled.div`
  margin-top: 1rem;
`
