import { allAspects, AspectType, IOrbises, ObjectType, Dropdown, Input } from 'src/libs';
import _ from 'lodash';
import React, { useRef } from 'react'
import { aspectsIcons, objectsIcons } from 'src/helpers/icons';
import { useTranslation } from 'src/i18n/useTranslation';
import styled, { css } from 'styled-components';
import { emptyMenu, IMenu } from './AspectsTableSwitcher';

type Actions = 'major' | 'disable' | 'edit' | 'value';

interface ICellProps {
  aspType: AspectType
  objType: ObjectType
  orbises: IOrbises
  onChange(data: IOrbises): void
  menu?: IMenu
  setMenu?(menu: IMenu): void
}

export default React.memo(function Cell(props: ICellProps) {
  const [edit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState('');
  const { t } = useTranslation();

  const showEditMode = props.menu && +props.objType === +props.menu.objType && +props.aspType === +props.menu.aspType
  const showContent = !(props.menu && edit && +props.objType === props.menu.objType && +props.aspType === props.menu.aspType)

  const screenWidth = window.screen.width
  const screenHeight = window.screen.height
  const cellRef = useRef<any>(null);
  const cellRect = cellRef.current && cellRef.current.getBoundingClientRect();

  const onAction = (action: Actions) => {
    const orbises = _.cloneDeep(props.orbises);

    if (action === 'edit') {
      setValue(orbises[props.aspType < 0 ? 0 : props.aspType][props.objType < 0 ? 0 : props.objType].value.toString());
      setEdit(true);
      return;
    }

    const changeOrb = (orb: any, major: boolean, disabled: boolean) => {
      if (action === 'major') {
        orb.major = major;
      } else if (action === 'disable') {
        orb.disabled = disabled;
      } else if (action === 'value') {
        orb.value = +value;
      }
    };

    if (props.aspType < 0) {
      const major = !allAspects.every(aspType => orbises[aspType][props.objType].major);
      const disabled = !allAspects.every(aspType => orbises[aspType][props.objType].disabled);
      allAspects.forEach(aspType => changeOrb(orbises[aspType][props.objType], major, disabled));
    } else if (props.objType < 0) {
      const major = !orbises[props.aspType].every((orb: any) => orb.major);
      const disabled = !orbises[props.aspType].every((orb: any) => orb.disabled);
      orbises[props.aspType].forEach((orb: any) => changeOrb(orb, major, disabled));
    } else {
      const orb = orbises[props.aspType][props.objType];
      changeOrb(orb, !orb.major, !orb.disabled);
    }

    props.onChange(orbises);

    props.setMenu && props.setMenu(emptyMenu);
  };

  const onClick = (ev: any, action: Actions) => {
    // ev && ev.stopPropagation();
    onAction(action);
  };

  let className = 'cell';
  let content = null;

  if (props.aspType >= 0 && props.objType >= 0) {
    const orb = props.orbises[props.aspType][props.objType];
    content = orb.value;
    className += `${orb.major ? ' major' : ''}${orb.disabled ? ' disabled' : ''}`;
  } else {
    if (props.aspType < 0) {
      if (+props.objType === 13) {
        content = 'K';
      } else {
        const Icon = objectsIcons[props.objType <= ObjectType.NorthNode ? props.objType : props.objType + 1];
        content = <Icon />;
      }
    } else {
      const Icon = aspectsIcons[props.aspType];
      content = <Icon />;
    }

    className += ' icon';
  }

  const handleChange = (e: any): void => {
    const reg = /^(\d+|\d+\.\d{0,9})$/;
    if (e === '' || reg.test(e)) {
      setValue(e);
    }
  };

  const renderSwitch = () => {
    let switcher = '';
    if (props.objType >= 0 && props.aspType >= 0) {
      switcher = props.orbises[props.aspType][props.objType].disabled ? t("base.turnOn") : t("base.disable");
    } else if (props.objType < 0) {
      switcher = props.orbises[props.aspType].every(({ disabled }: any) => disabled) ? t("base.turnOn") : t("base.disable");
    } else {
      switcher = allAspects.every(aspType => props.orbises[aspType][props.objType].disabled) ? t("base.turnOn") : t("base.disable");
    }
    return switcher;
  };

  const onBlurHandler = () => {
    onAction('value')
    props.setMenu && props.setMenu(emptyMenu)
    setEdit(false)
  }

  const onActionHandler = () => {
    onAction('value')
    props.setMenu && props.setMenu(emptyMenu)
    setEdit(false)
  }

  const onClickCellHandler = () => {
    !edit && props.setMenu && props.setMenu({ aspType: props.aspType, objType: props.objType })
  }

  return (
    <Container
      key={`${props.aspType} + ${props.objType}`}
      className={className}
      onClick={onClickCellHandler}
      ref={cellRef}
    >
      {showEditMode && (
        edit ?
          <form onSubmit={onActionHandler}>
            <Input
              value={value}
              onChange={handleChange}
              onBlur={onBlurHandler}
              autoFocus={true}
              selectOnFocus={true}
            />
          </form> :
              <CustomDropdown
                options={[
                  { value: 1, label: t("base.edit"), action: (ev) => { onClick(ev, 'edit') }, },
                  { value: 2, label: renderSwitch(), action: (ev) => { onClick(ev, 'disable') } }
                ]}
                _right={screenWidth - cellRect.right < 155}
                _bottom={screenHeight - cellRect.bottom < 155 || props.aspType > 140}
              />
      )}

      {showContent && content}
    </Container>
  );
})

const Container = styled.div`
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  width: 3.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  font-size: 1rem;
  line-height: 1rem;
  border-right: 1px solid var(--astro-table-border-light);
  border-bottom: 1px solid var(--astro-table-border-light);
  cursor: pointer;

  &.major {
    color: var(--astro-table-object-major);
    border-bottom: 3px solid var(--astro-table-object-major);
  }

  &.disabled {
    color: var(--text-third);
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const CustomDropdown = styled(Dropdown)<{_right?: boolean, _bottom?: boolean}>`
  position: absolute;
  left: 0  !important;
  top: 0  !important;
  transform: translate(0, 0) !important;
  width: auto !important;
  height: auto !important;

  ${p => p._right && css`
    left: 100% !important;
    transform: translateX(-100%) !important;
  `}

  ${p => p._bottom && css`
    top: 100% !important;
    transform: translateY(-100%) !important;
  `}

  ${p => p._bottom && p._right && css`
    transform: translate(-100%, -100%) !important;
  `}
`;
