import { TARIFFS_GET_LOADING, TARIFFS_GET_SUCCESS } from './actions';
import { IActionTariffs, ITariffsState } from './types';

const initialState: ITariffsState = {
  loading: false,
  loaded: false,
  data: []
};

export default function tariffs(state = initialState, action: IActionTariffs){
  switch(action.type){
    case TARIFFS_GET_LOADING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case TARIFFS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    default:
      return state;
  }
}

