import React from 'react';
import styled from 'styled-components';

import { InfoIcon } from 'src/assets/icons/system/index';
import { TabWithIcons } from 'src/ui/SwipeTabs';
import { Button } from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';
import { s3urls } from 'src/helpers/api';

export default function Support() {
  const { t } = useTranslation();
  return <Container>
    <Title>{t("settings.support")}</Title>
    <Text>{t("astro.chronos.app.prompts.emailUs")}<br />
      <a href="mailto:support@chronos.mg">support@chronos.mg</a></Text>

    <Title>{t("chronos.mobile.mapSettings.support.faq")}</Title>
    <Text>{t("chronos.mobile.mapSettings.support.faq.description")}</Text>
    <ButtonLink href={s3urls.support + '/398e9d59c0c446648497a9c4f0168f82'} target="_blank">
      <Button size='big' onClick={() => { }} color="var(--color-blue)">{t("chronos.mobile.goTo")}</Button>
    </ButtonLink>

  </Container>
}

export function Tab ({text}: {text: string}) { 
  const { t } = useTranslation();
  return <TabWithIcons>
    <InfoIcon />
      {t(text)}
</TabWithIcons>}

const Container = styled.div`
  width: calc(100% - 1px);
  white-space: initial;
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-top: 1rem;
`

const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);
  margin-top: 0.375rem;
  > a {
    color: var(--color-blue);
  }
`

const ButtonLink = styled.a`
  display: block;
  margin-top: 1rem;
`