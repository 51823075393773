import React, { useState } from 'react';
import styled from 'styled-components';

import HitIcon from 'src/ui/HitIcon';

import { SwitcherIcon } from 'src/assets/icons/system/index';
import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';
import AppSwitcher from 'src/app/pages/dashboard/components/AppSwitcher';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Header({
  buttonType = 'switcher',
  buttonHandler,
  title = "chronos.app.sidebar.events"
}: {
  buttonType?: 'switcher' | 'back',
  buttonHandler?: Function,
  title?: string;
}){
  const [ appSwitcherOpen, setAppSwitcherOpen ] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openAppSwitcher = () => setAppSwitcherOpen(true);
  const closeAppSwitcher = () => setAppSwitcherOpen(false);

  const color = title === '' ? 'white' : 'var(--text-secondary)';

  return <>
    <HeaderContainer color={color} className="hideOnWebinar">
      {buttonType === 'switcher' && <HitIcon onClick={openAppSwitcher}><SwitcherIcon/></HitIcon>}
      {buttonType === 'back' && <HitIcon onClick={() => buttonHandler?.()}><LeftTriangleIcon/></HitIcon>}

      <Center>
        <Label>{t(title)}</Label>
      </Center>
    </HeaderContainer>

    <AppSwitcherContainer open={appSwitcherOpen}><AppSwitcher onClose={closeAppSwitcher} /></AppSwitcherContainer>
  </>
}

export const HeaderContainer = styled.header<{color: string}>`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  margin-bottom: 0.75rem;
  position: relative;
  z-index: 1000;

  svg {
    color: ${p => p.color};
    height: 1.5rem;
    width: 1.5rem;
  }
`

const Center = styled.div`
  padding-left: 0.675rem;
`

export const Label = styled.span`
  font-size: 1.125rem;
`

const AppSwitcherContainer = styled.div<{ open?:boolean}>`
  position: fixed;
  top: 0;
  left: ${p => p.open ? '0' : '-100vw'};
  transition: left 0.3s;
  z-index: 1000;

`