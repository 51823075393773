import { CurrencyCode, Region } from "src/libs";

export interface IPaymentCardMethod {
  country: Region;
  title: string;
	code: CurrencyCode;
}

export const paymentMethods: IPaymentCardMethod[] = [
	{
	  country: Region.ru,
	  title: "chronos.app.payments.paymentMethod.ru.title",
		code: CurrencyCode.RUB,
	},
	{
		country: Region.kz,
	  title: "chronos.app.payments.paymentMethod.kz.title",
		code: CurrencyCode.KZT,
	},
	{
	  country: Region.us,
	  title: "chronos.app.payments.paymentMethod.foreign.title",
		code: CurrencyCode.USD,
	},
]

export const initPaymentMethod = (region?: Region) => {
	if (region) {
		return paymentMethods.find(paymentMethod => paymentMethod.country === region) || paymentMethods[2]
	} else {
		return paymentMethods[0]
	}
}
