import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';

export default React.memo(function DoubleTap({
  children,
  action
}: {
  children: ReactNode,
  action(e: Event):void;
}) {
  const [myLatestTap, setMyLatestTap] = React.useState(0);
  //let myLatestTap: number;

  const onClick = (e: SyntheticEvent) => {
    var now = new Date().getTime();
    var timeSince = now - myLatestTap;
    if((timeSince < 300) && (timeSince > 0)){
      action(e.nativeEvent); 
    }
    setMyLatestTap(new Date().getTime());
  }
  return <Container onClick={onClick}>{children}</Container>
})


const Container = styled.div`
  width: 100%;
  height: 100%;
`