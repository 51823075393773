import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { ICourseLesson, ICoursePlan, ICourseInfo, Button } from 'src/libs';

import { LightningIcon } from 'src/assets/icons/system/index';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import { useSelector } from 'src/store/utils';
import { getProfile } from 'src/store/reducers/profile/selectors';
import VideoPlayer from './VideoPlayer';
import { useTranslation } from 'react-i18next';

const client = ZoomMtgEmbedded.createClient();

export default function LessonWebinar({
  course,
  lesson,
  isByued,
  isFuture,
  neededPlan,
}: {
  course: ICourseInfo,
  lesson: ICourseLesson,
  isByued?: boolean,
  isFuture: boolean,
  neededPlan: ICoursePlan,
}) {
  const [started, setStarted] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: profile } = useSelector(getProfile);

  useEffect(() => {
    const checkClosebuttonClick = (e: any) => {
      if(e.target.closest('.jss138')) {
        window.location.reload();
      }
    }
    document.addEventListener('click', checkClosebuttonClick)
    return () => {
      document.removeEventListener('click', checkClosebuttonClick)
    }
  }, [lesson])

  const onStart = () => {
    const meetingNumber = lesson.webinar.meetingId;
    const apiKey = lesson.webinar.apiKey;
    const signature = lesson.webinar.signature;
    const userName = profile.firstName + profile.lastName;
    const email = profile.email;
  
    let meetingSDKElement = document.getElementById('meetingSDKElement');
    meetingSDKElement && (meetingSDKElement!.classList.add('opened'));

    meetingSDKElement  && client.init({
      debug: false,
      zoomAppRoot: meetingSDKElement,
      language: 'ru-RU',
      customize: {
        meetingInfo: [],
        toolbar: {
        }
      }
    })
    .then(result => {console.log('init sucess: ', result);})
    .catch(result => console.log('init error: ', result));

    client.join({
      sdkKey: apiKey,
      signature: signature,
      meetingNumber: String(meetingNumber),
      userName: userName,
      userEmail: email || undefined,
    })
    .then(result => {console.log('join sucess: ', result); setStarted(true)})
    .catch(result => console.log('join error: ', result))  

  } 

  
  let renderContent = null;

  if(!isByued) {
    renderContent = <>
      <Preview>
        <Circle color={neededPlan.color}><LightningIcon /></Circle>
        <BlockedTitle>{t("chronos.app.courses.lesson.preview.onlyOnThePackage")} {neededPlan.title}</BlockedTitle>
        <Button color={neededPlan.color} onClick={() => navigate(`/courses/${course.course.id}/plans`)}>{t("chronos.app.courses.lesson.preview.openAcceess")}</Button>
      </Preview>
    </>
  } else if(isFuture) {
    renderContent = <>
      <Preview>
        <StartDateBadge>{t("chronos.app.courses.lesson.preview.willBeAvailable")} {dayjs(lesson.start).format('DD.MM.YYYY')}</StartDateBadge>
      </Preview>
    </>
  } else {
    renderContent = <>
      {(!lesson.webinar?.recordings || lesson.webinar.recordings.length === 0) && <Preview url={lesson.banner} />}

      {!lesson.webinar?.link && lesson.webinar?.recordings && lesson.webinar.recordings.map((item, index) => {
          return <VideoPlayer url={item.video} banner={lesson.banner} key={index} />
      })}
    
      {lesson.webinar?.link && <>
        {!started && <ButtonUIContainer>
          <Button onClick={onStart}  color="var(--color-blue)">{t("chronos.app.courses.lesson.joinToLesson")}</Button>
        </ButtonUIContainer> }

        <MeetingBlock id="meetingSDKElement">
        </MeetingBlock>
        {started && <GlobalStyleHorizontalMode />}
      </>}

    </>
  }

  return <Container>
    {renderContent}
  </Container>
}

const Container = styled.div`
`

const Preview = styled.div<{url?: string}>`
  width: 100%;
  height: 15rem;
  border-radius: 4px;
  background: var(--bg-100);
  background-size: cover;
  background-position: center center;
  background-image: url(${p => p.url});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Circle = styled.div<{color: string}>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: ${p => p.color};
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: var(--bg-100);
    width: 1rem; 
    height: 1rem;
  }
`

const BlockedTitle = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--text-primary);
  max-width: 70%;
  text-align: center;
  margin-bottom: 1rem;
`

const StartDateBadge = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.4325rem 0.75rem 0.25rem;
  background: var(--bg-500);
  color: var(--text-secondary);
  border-radius: 4px;
`

// const Button = styled.div<{color: string}>`
//   font-size: 0.75rem;
//   padding: 0.3125rem 0.375rem;
//   color: black;
//   border-radius: 5px;
//   cursor: pointer;
//   background: ${p => p.color};
// `

const MeetingBlock = styled.div`
  position: fixed;
  left: 100vw;
  opacity: 0;
  top: 0 !important;
  right: initial !important;
  //width: 100vw;
  //height: 100vh;
  display: block !important ;
  z-index: 999;

  &.opened{
    left: 0;
    opacity: 1;
  }

  .full-view {
    height: calc(100vh - 80px) !important;
  }

  .jss10 {
    width: 100vw !important;
  }

  .jss29{
    display: none !important;
  }
`

const ButtonUIContainer = styled.div`
  margin-top: 1rem;
  text-align: center;

  button {
    width: initial;
  }
`

export const GlobalStyleHorizontalMode = createGlobalStyle`
  #transition-popper{
    z-index:1000;
  }

  div[role=dialog] {
    display: none;
  }

  @media screen and (min-width: 480px) and (orientation: landscape) {
    #root {
      display: initial !important;
    }

    body::before {
      display: none !important;
    }

    .hideOnWebinar{
      display: none;
    }
  }
`