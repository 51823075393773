import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import { PlusIcon } from 'src/assets/icons/system/index';
import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';
import { OkIcon } from 'src/assets/icons/system/index';
import { MenuIcon } from 'src/assets/icons/system/index';
import { ThemeIcon } from 'src/assets/icons/system/index';
import { DeleteIcon } from 'src/assets/icons/system/index';
import { LetterIcon } from 'src/assets/icons/system/index';

import { IShortFormData, IFormLabel } from 'src/libs';
import { useSelector } from 'src/store/utils';
import { getAllLabels } from 'src/store/reducers/labels/selectors';
import MenuForPopup from 'src/ui/Popups/PopupBottom/MenuForPopup';
import { useDispatch } from 'react-redux';
import { deleteLabel, getLabels, updateLabel } from 'src/store/reducers/labels/actions';
import { getForms } from 'src/store/reducers/forms/actions';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import EditLabelForPopup from './EditLabelForPopup';
import ColorSelector from 'src/ui/ColorSelector';
import { NotificationContext } from 'src/store/context/notifications';
import { useTranslation } from 'src/i18n/useTranslation';

export default function LabelSelector({
  form,
  onChange,
  onOpenAddGroupPopup,
  onClose
}:{
  form: IShortFormData;
  onChange(isCurrentLabel: boolean, formId: number, folderId: number): void;
  onOpenAddGroupPopup(value: boolean): void;
  onClose(): void;
}) {
  const { t } = useTranslation();
  const {data: labels} = useSelector(getAllLabels);
  
  return <>
    <Header>
      <div onClick={onClose}><LeftTriangleIcon /></div>
      <HeaderTitle>
        {t("chronos.app.dashboard.labels")}
      </HeaderTitle>
      <div onClick={() => onOpenAddGroupPopup(true)}><PlusIcon /></div>
    </Header>
    
    <Items>
      {labels.map((item: IFormLabel) => {
        const formLabels: IFormLabel[] = (form as any).labels;
        const isCurrentLabel: boolean = Boolean(formLabels?.find(label => item.id === label.id));
        return <Item key={item.id}>
          <div>{isCurrentLabel && <OkIcon />}</div>
          <FolderName color={item.color} onClick={() => onChange(isCurrentLabel, form.id, item.id)}>{item.title}</FolderName>
          <LabelMenu item={item} formId={form.id} />
        </Item>
      })}
    </Items>
  </>
}

function LabelMenu({
  formId,
  item
}: {
  formId: number,
  item: IFormLabel
}) {
  const dispatch = useDispatch();
  const context = useContext(NotificationContext);
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openEditNamePopup, setOpenEditNamePopup] = useState(false);
  const [openEditColor, setOpenEditColor] = useState(false);

  const onDelete = () => {
    dispatch(deleteLabel(item.id, () => {
      dispatch(getLabels(false));
      dispatch(getForms(false))
    }))
  }

  const onEdit = () => {
    setOpenEditNamePopup(true);
  }

  const onColor = () => {
    setOpenEditColor(true);
    setOpenMenu(false)
  }

  const onColorChange = (color: string) => {
    const newItem = {...item, color: color.trim()};
    dispatch(updateLabel(newItem, () => {
      dispatch(getLabels(false));
      dispatch(getForms(false));
      setOpenEditColor(false);
      context?.add(t("chronos.mobile.dashboard.colorChanged"));
    }));
  }

  const buttons = [
    {icon: <LetterIcon/>, short: t("chronos.app.dashboard.removeGroup.changeName"), action: onEdit},
    {icon: <ThemeIcon/>, short: t("chronos.mobile.dashboard.components.labelSelector"), action: onColor},
    {icon: <DeleteIcon/>, short: t("chronos.app.dashboard.removeLabel"), color: 'var(--color-red)', action: onDelete}
  ]

  let style = {};
  if(openMenu) style = {color: 'var(--text-primary)'}
  
  return <div>
    <MenuIcon onClick={() => setOpenMenu(open => !open)} style={style} />
    {openEditColor && <ColorContainer>
          <DropDownStatic>
            <ColorSelector current={item.color} onChange={onColorChange} />
          </DropDownStatic>
    </ColorContainer> }

    {openMenu && <>
      <DropDownContainer>
        <DropDownStatic>
          <MenuForPopup buttons={buttons}></MenuForPopup>
        </DropDownStatic>
      </DropDownContainer>

      <PopupBottom open={openEditNamePopup} onClose={() => setOpenEditNamePopup(false)} title={t("chronos.mobile.dashboard.deleteGroup.changeName")} strong={true} back={true}>
          <EditLabelForPopup open={openEditNamePopup} onClose={() => setOpenEditNamePopup(false)} label={item} />
      </PopupBottom>
    </>}
  </div>
}

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.875rem;

  svg {
    color: var(--text-secondary);
  }
`


const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  > svg {
    margin-right: 0.625rem;
  }
`

const Items = styled.div``

const Item = styled.div`
  display: grid;
  grid-template-columns: 1.5rem 1fr min-content;
  grid-gap: 1rem;
  align-items: center;

  svg {
    color: var(--text-secondary);
  }
`

const FolderName = styled.div<{color: string}>`
  padding: 0.675rem 0;
  color: ${p => p.color};
`

const DropDownContainer = styled.div`
  position: fixed;
  bottom: 2.8rem;
  right: 3rem;
  width: 12.6rem;
`

const DropDownStatic = styled.div<{ autoWidth?: boolean }>`
  width: 100%;
  padding: 0.375rem;
  background: var(--bg-100);
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
  border-radius: 0.5rem;
`

const ColorContainer = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 2.5rem;
  left: 1rem;
  right: 1rem;

  & > div {
    padding: 0.75rem 0;
  }
`