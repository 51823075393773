import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import DashboardPage from './pages/dashboard';
import SettingsPage from './pages/settings';
import WorkspacePage from './pages/workspace';
import CoursesPage from './pages/courses';
import ScrollToTop from 'src/components/ScrollToTop';
import { useTranslation } from 'src/i18n/useTranslation';

export const routes: {
  [key: string]: { path: string; title: { [key: string]: string } };
} = {
  dashboard: {
    path: 'dashboard',
    title: {
      index: 'chronos.mobile.dashboard.pageTitle'
    }
  },
  settings: {
    path: 'settings',
    title: {
      prefix: 'chronos.mobile.settings.pageTitle',
      payments: 'settings.payments',
      chooseTariff: 'chronos.app.chooseTariff',
      addBuilds: 'chronos.app.settings.addBuilds',
      personal: 'settings.personal',
      consultations: 'settings.consultations',
      maps: 'chronos.app.dashboard.maps',
      changes: 'settings.changes',
      support: 'settings.support'
    }
  },
  workspace: {
    path: 'workspace/:id',
    title: {
      index: 'Рабочее пространство'
    }
  },
  courses: {
    path: 'courses/*',
    title: {
      index: 'chronos.app.sidebar.events'
    }
  },
};

export const history = createBrowserHistory();


const PageTitleProvider = () => {
  const location = useLocation();
  const { t } = useTranslation();
  
  useEffect(() => {
    const [ , path = ''] = location.pathname.split('/');
    const page = routes[location.pathname === '/' ? 'dashboard' : path];
    let title = t(page.title.index);

    if (page.path === 'settings') {
      title = `${t(page.title.prefix)} - ${t(page.title.payments)}`;
    }

    setTimeout(() => {
      document.title = title;
    }, 300);
  }, [location]);
  
  return (null);
}

export default function MainRouter() {

  return <BrowserRouter>
    <PageTitleProvider />
    <ScrollToTop />
    <Routes>
      <Route path={routes.dashboard.path} element={<DashboardPage />} />
      <Route path={routes.settings.path} element={<SettingsPage />} />
      <Route path={routes.workspace.path} element={<WorkspacePage />} />
      <Route path={routes.courses.path} element={<CoursesPage />} />
      <Route path='*' element={<DashboardPage />} />
    </Routes>
  </BrowserRouter>
}