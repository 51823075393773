import { CircleMode, profileMode, RangeInput } from 'src/libs';
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { IWidgetData } from '../data';
import { debounce } from 'lodash'
import { useTranslation } from 'src/i18n/useTranslation';
import { TWidgetCircleMode } from '..';
import { isCompatibility, isPartner } from 'src/utils';

export default React.memo(function OrbiseCorrector({
  data,
  onChanged,
  openedWidget,
  subMode
}: {
  data: IWidgetData,
  subMode: TWidgetCircleMode,
  onChanged(key: string, value: any): void,
  openedWidget: boolean,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const activeAstroProfile: any = data.activeAstroProfile;

  const aspectsTableMode = data.aspectsTableMode;
  const normalizeMode = profileMode(aspectsTableMode);

  useEffect(() => {
    // В таблице аспектов активный таб Транзит-Натал или Транзит-Партнер
    if (subMode.includes('transit')) {
      const newValue = activeAstroProfile.maps['transits'].orbiseCorrector || 0;
      setValue(newValue);
    }
     else if (aspectsTableMode === "natal") {
      const newValue = activeAstroProfile.maps[normalizeMode].orbiseCorrector || 0;
      setValue(newValue);
    } else if (isCompatibility(subMode as CircleMode) || isPartner(subMode as CircleMode)) {
      const newValue = activeAstroProfile.maps['synastry'].orbiseCorrector || 0;
      setValue(newValue);
    }
    else {
      data.maps.map((map, idx) => {
        if (map.mode === aspectsTableMode) {
          const newValue = activeAstroProfile?.maps[normalizeMode]?.orbiseCorrector || 0;
          return setValue(newValue);
        }
      })
    }
  }, [data.aspectsTableMode, subMode])



  const onChangeDebounced = useCallback(debounce((value) => {
    // В таблице аспектов активный таб Транзит-Натал или Транзит-Партнер
    if (subMode.includes('transit')) {
      onChanged('orbise-corrector', {
        activeAstroProfileId: activeAstroProfile.id,
        mode: 'transits',
        value
      })
    }
     else if (aspectsTableMode === "natal") {
      onChanged('orbise-corrector', {
        activeAstroProfileId: activeAstroProfile.id,
        normalizeMode,
        value
      })
    } else {
      data.maps.map((map, idx) => {
        if (map.mode === aspectsTableMode) {
          onChanged('orbise-corrector', {
            activeAstroProfileId: activeAstroProfile.id,
            aspectsTableMode,
            value
          })
        }
      })
    }
  }, 256), [data.aspectsTableMode, subMode]);

  const onChangeNew = (value: number) => {
    setValue(value);
    setTimeout(() => {
      onChangeDebounced(value);
    })
  }



  return <StyledRangeInput
    label={t("chronos.mobile.mapSettings.orbises")}
    value={value}
    onChange={onChangeNew}
    min={-100}
    max={100}
    step={1}
    height={'3rem'}
  />
});

const StyledRangeInput = styled(RangeInput)`

  & input[type="range"] {
    display: block;
    position: relative;
    width: calc(100% - 4px);
    margin: 0 auto;
    background-image: none;
    border-radius: 0.5rem;
    background-color: var(--bg-element-neutral);
    border: 1px solid var(--border-lines);
  }

  &::-webkit-slider-thumb {
    box-sizing: border-box;
    width: 2rem;
    border-radius: 0.5rem;
    background: none;
    background-color: var(--bg-element-neutral);
    /* border: 1px solid var(--border-lines); */
  }
  &::-moz-range-thumb {
    box-sizing: border-box;
    width: 2rem;
    border-radius: 0.5rem;
    background: none;
    background-color: var(--bg-element-neutral);
    /* border: 1px solid var(--border-lines); */
  }
`;
