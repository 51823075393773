import { AnyAction } from 'redux';
import { CUSTOMIZATIONS_GET_LOADING, CUSTOMIZATIONS_GET_SUCCESS } from '../../actions/settings';
import { IPersonalizationState } from './types';

const initialState: IPersonalizationState = {
  loading: false,
  loaded: false,
  data: []
};

export default function customizations(state = initialState, action: AnyAction){
  switch(action.type){
    case CUSTOMIZATIONS_GET_LOADING:
      return {
        loading: true,
        loaded: false,
        data: state.data
      };
    case CUSTOMIZATIONS_GET_SUCCESS:
      return {
        loading: false,
        loaded: true,
        data: action.payload
      };
    default:
      return state;
  }
}

