import React, { useRef, useState } from 'react';
import { UserRegion, Button, IDropdownOption, Input } from 'src/libs';
import styled from 'styled-components';
import { countries } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { getName } from 'country-list';

import api from 'src/api';
//import store from 'src/store';
import { changeLanguage, useTranslation } from 'src/i18n/useTranslation';
import Modal from './Modal';
import Alert from './Alert';

import { WorldIcon } from 'src/assets/icons/system/32';
import { OkIcon } from 'src/assets/icons/system';
import { getProfile, setProfile } from 'src/store/localStorage/profile';

interface CountryOption {
  label: string;
  value: string;
  icon: JSX.Element | null | undefined;
}

const countriesList: IDropdownOption[] = countries
  .filter(item => item && getName(item))
  .map(item => ({
    label: getName(item),
    value: item.toLocaleLowerCase(),
    icon: <>{getUnicodeFlagIcon(item)}</>
  } as CountryOption))
  .sort((item1, item2) => {
    if(item1.label === item2.label) {
      return 0;
    }
    return item1.label > item2.label ? 1 : -1;
  });

const languagesList: IDropdownOption[] = [{
  label: 'chronos.app.settings.sideBar.russian',
  value: 'ru'
},{
  label: 'chronos.mobile.english',
  value: 'en'
},{
  label: 'chronos.mobile.portuguese',
  value: 'pt'
}, {
  label: 'chronos.mobile.spanish',
  value: 'es'
}];

export default function RegionSelectModal({
  onClose
}: {
  onClose(): void;
}) {
  const lsUser = getProfile();
  const defaultDetectedCountry = lsUser?.region?.region || 'ru';
  const defaultDetectedLanguage = lsUser?.region?.language || 'ru';

  const [country, setCountry] = useState(defaultDetectedCountry);
  const [language, setLanguage] = useState(defaultDetectedLanguage);
  const [step, setStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const timeoutId: { current: any} = useRef();

  const { t } = useTranslation();

  const confirmFirstStep = async () => {
    try {
      const responseData: any = await api.setUserRegion({
        language: language as UserRegion.Language,
        region: country as UserRegion.Region,
      });
      if(responseData?.id) {
        setProfile({
          ...lsUser,
          region: responseData,
        });
        responseData.language && changeLanguage(responseData.language)
      }
      setStep(1);
    } catch {
      setShowError(true);
      if(timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  return <>
    <Modal closable={false}>
      <Container>
        <Inner>
          {step === 0 && <>
            <div />
            <InnerAlign>
              <IconWrapper><WorldIcon /></IconWrapper>
              <Title>{t('chronos.app.selectRegionTitle')}</Title>
              <SubTitle>{t('chronos.app.selectRegionDescription')}</SubTitle>
              <Cells>

                <CountryField>
                  <Label>{t('chronos.app.region')}</Label>
                  <Input
                    value={country}
                    options={countriesList}
                    onChange={setCountry}
                  />
                </CountryField>

                <LanguageField>
                  <Label>{t('chronos.mobile.language')}</Label>
                  <Input
                    value={language}
                    options={languagesList.map(item => ({
                      ...item,
                      label: item.label ? t(item.label) : '',
                    }))}
                    onChange={setLanguage}
                  />
                </LanguageField>

              </Cells>
            </InnerAlign>
            <Button style={{width: '100%'}} onClick={confirmFirstStep}>{t('base.confirm')}</Button>
          </>}
          {step === 1 && <>
            <div />
            <InnerAlign>
              <IconWrapper color={'green'}><OkIcon /></IconWrapper>
              <Title>{t('chronos.app.regionSelected')} {getName(country)}</Title>
              <SubTitle>{t('chronos.app.regionSupport')}</SubTitle>
            </InnerAlign>
            <Button style={{width: '100%'}} onClick={onClose}>{t('chronos.app.continueApp')}</Button>
          </>}
        </Inner>
      </Container>
    </Modal>
    {showError &&
      <StyledAlert className="fixed-alert">
        <Alert type="warning">
          <p>{t('chronos.app.somethingWentWrong')}</p>
        </Alert>
      </StyledAlert>
    }
  </>
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
`

const Inner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`

const IconWrapper = styled.div<{color?: string}>`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background: ${p => p.color ? `var(--colors-${p.color})` : `var(--bg-300)`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--text-primary);
  margin-bottom: 0.75rem;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 2rem;
  text-align: center;
`

const Cells = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  & > div {
    margin-bottom: 1rem;
  }
`

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`

const StyledAlert = styled.div`
  position: fixed;
  bottom: 0.5rem;
  max-width: 48rem;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
`;

const InnerAlign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CountryField = styled.div`
`
const LanguageField = styled.div`
  .mobile {
    height: auto;
  }
`
