import { ChronosApi, IFormData, IShortFormData, defaultHousesSystem } from 'src/libs';
import { setProfile } from 'src/store/localStorage/profile';

export * from 'src/libs';

class Api extends ChronosApi {
  constructor() {
    super(process.env.REACT_APP_BACKEND as string, process.env.REACT_APP_AUTH_URL as string);
  }

  showError(text: string) {
    alert(`api error: ${text}`) // FIXME: show notification
    console.log(`api error: ${text}`)
  }

  // @ts-ignore
  async form(id: number): Promise<IFormData | undefined> {
    return await super.form(id, defaultHousesSystem); //  // FIXME: load from store, checkToken, store.settings.user.id !== -1);
  }

  onUpdateShortForms(forms: IShortFormData[]) {
    // FIXME:
    // @ts-ignore
    //return store.dashboard.forms = forms;
    return forms;
  }

  onUpdateAuth(auth: any) {
    setProfile(auth);
  }
}

export default new Api();
