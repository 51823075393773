import React from 'react';
import styled from 'styled-components';

import { VkontakteIcon } from 'src/assets/icons/social/index';
import { TelegramIcon } from 'src/assets/icons/social/index';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Footer(){
  const { t } = useTranslation();
  return <Container>
    <SocialIcons>
      <a href="https://vk.com/chronos_mg" target="_blank"><VkontakteIcon /></a>
      <a href="https://t.me/astroprocessor" target="_blank"><TelegramIcon /></a>
    </SocialIcons>
    <Content>
      Chronos Mobile<br/>
      {(new Date().getFullYear())}
    </Content>
  </Container>
}

const Container = styled.div`
  text-align:center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: auto;
`

const SocialIcons = styled.div`
  color: var(--text-secondary);
  > a {
    display: inline-block;
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
`

const Content = styled.div`
  color: var(--text-third);
  margin-top: 0.75rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
`