import React from 'react';
import styled, { css } from 'styled-components';

type ColorType = 'dark' | 'blue';

export default function TooltipWrapper({
  children = null,
  text = '',
  color = 'dark'
}: { 
  children: any;
  text: string;
  color?: ColorType;
}) {
  const tooltipEl = React.useRef<HTMLDivElement>(null);


  const onMouseMove = (e: any) => {
    if (!tooltipEl.current) return;
    const maxWidth = `${window.innerWidth - 32}px`;
    tooltipEl.current.style.maxWidth =  maxWidth;
  };

  const onMouseOver = (_: any) => {
    if (!tooltipEl.current) return;
    tooltipEl.current.style.display = 'block';
  };

  const onMouseLeave = (_: any) => {
    if (!tooltipEl.current) return;
    tooltipEl.current.style.display = 'none';
  };

  let _text = text;
  _text = _text.replace(/<purple>/, '<span style=\'color: var(--color-purple)\'>');
  _text = _text.replace(/<\/purple>/, '</span>');
  _text = _text.replace(/<orange>/, '<span style = \'color: var(--color-orange)\'> ');
  _text = _text.replace(/<\/orange>/, '</span> ');

  return (
    <span onMouseMove={onMouseMove} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      {children}
      {text && <Tooltip color={color} ref={tooltipEl} dangerouslySetInnerHTML={{ __html: _text }} />}
    </span>
  );
};

const Tooltip = styled.div<{ color: ColorType }>`
  display: none;
  position: fixed;
  border-radius: 4px;
  background-color: var(--bg-100);
  color: var(--text-primary);
  padding: 0.20rem 0.4rem 0.15rem 0.4rem;
  font-size: 0.75rem;
  text-align:center;
  line-height: 1.2rem;
  z-index: 100;
  border: 1px solid var(--element-neutral);
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;

  ${props => props.color === 'blue' && css`
      background: var(--color-blue);
      color: var(--text-primary);
  `}
`;
