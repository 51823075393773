import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation, changeLanguage } from 'src/i18n/useTranslation';
import i18n from 'src/i18n/i18n';
import { IDropdownOption, Input } from 'src/libs';
import api from 'src/api';

export default function LanguageSwitcher() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') ?? 'ru');
  const langItems: IDropdownOption[] = [
    { value: 'ru', label: t("chronos.app.settings.sideBar.russian") },
    { value: 'en', label: t("chronos.mobile.english") },
    { value: 'pt', label: t("chronos.mobile.portuguese") },
    { value: 'es', label: t("chronos.mobile.spanish") }
  ];

  useEffect(() => {
    document.body.dataset.turnDeviceMsg = t("chronos.mobile.css.theme");
  })

  const _changeLanguage = (v: string) => {
    setLanguage(v);
    changeLanguage(v);
    api.changeLanguage(v);
  }

  return (
    <Container>
      <Title>{t("chronos.mobile.language")}</Title>
      <Input 
        // @ts-ignore
        value={language} 
        onChange={(value: string) => _changeLanguage(value)}
        options={langItems}
      />        
    </Container>
  )
} 

const Container = styled.div`
  margin-top: 2rem;
  font-size: 1.125rem;
  & > div{
    margin-bottom: 0.5rem;
  }
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
`