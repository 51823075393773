 
export default [
  {
    name: 'Diphda',
    title: "chronos.guide.fixedStars.0.name",
    degree: "chronos.guide.fixedStars.0.degree",
    description: "chronos.guide.fixedStars.0.description"
  },
  {
    name: 'Sirrah',
    title: "chronos.guide.fixedStars.1.name",
    degree: "chronos.guide.fixedStars.1.degree",
    description: "chronos.guide.fixedStars.1.description"
  },
  {
    name: 'Baten Kaitos',
    title: "chronos.guide.fixedStars.2.name",
    degree: "chronos.guide.fixedStars.2.degree",
    description: "chronos.guide.fixedStars.2.description"
  },
  {
    name: 'Sheratan',
    title: "chronos.guide.fixedStars.3.name",
    degree: "chronos.guide.fixedStars.3.degree",
    description: "chronos.guide.fixedStars.3.description"
  },
  {
    name: 'Hamal',
    title: "chronos.guide.fixedStars.4.name",
    degree: "chronos.guide.fixedStars.4.degree",
    description: "chronos.guide.fixedStars.4.description"
  },
  {
    name: 'Schedar',
    title: "chronos.guide.fixedStars.5.name",
    degree: "chronos.guide.fixedStars.5.degree",
    description: "chronos.guide.fixedStars.5.description"
  },
  {
    name: 'Almaak',
    title: "chronos.guide.fixedStars.6.name",
    degree: "chronos.guide.fixedStars.6.degree",
    description: "chronos.guide.fixedStars.6.description"
  },
  {
    name: 'Algol',
    title: "chronos.guide.fixedStars.7.name",
    degree: "chronos.guide.fixedStars.7.degree",
    description: "chronos.guide.fixedStars.7.description"
  },
  {
    name: 'Alcyone',
    title: "chronos.guide.fixedStars.8.name",
    degree: "chronos.guide.fixedStars.8.degree",
    description: "chronos.guide.fixedStars.8.description"
  },
  {
    name: 'Prima Hyadum',
    title: "chronos.guide.fixedStars.9.name",
    degree: "chronos.guide.fixedStars.9.degree",
    description: "chronos.guide.fixedStars.9.description"
  },
  {
    name: 'Ain',
    title: "chronos.guide.fixedStars.10.name",
    degree: "chronos.guide.fixedStars.10.degree",
    description: "chronos.guide.fixedStars.10.description"
  },
  {
    name: 'Aldebaran',
    title: "chronos.guide.fixedStars.11.name",
    degree: "chronos.guide.fixedStars.11.degree",
    description: "chronos.guide.fixedStars.11.description"
  },
  {
    name: 'Rigel',
    title: "chronos.guide.fixedStars.12.name",
    degree: "chronos.guide.fixedStars.12.degree",
    description: "chronos.guide.fixedStars.12.description"
  },
  {
    name: 'Bellatrix',
    title: "chronos.guide.fixedStars.13.name",
    degree: "chronos.guide.fixedStars.13.degree",
    description: "chronos.guide.fixedStars.13.description"
  },
  {
    name: 'Capella',
    title: "chronos.guide.fixedStars.14.name",
    degree: "chronos.guide.fixedStars.14.degree",
    description: "chronos.guide.fixedStars.14.description"
  },
  {
    name: 'Phact',
    title: "chronos.guide.fixedStars.15.name",
    degree: "chronos.guide.fixedStars.15.degree",
    description: "chronos.guide.fixedStars.15.description"
  },
  {
    name: 'El Nath',
    title: "chronos.guide.fixedStars.16.name",
    degree: "chronos.guide.fixedStars.16.degree",
    description: "chronos.guide.fixedStars.16.description"
  },
  {
    name: 'Alnilam',
    title: "chronos.guide.fixedStars.17.name",
    degree: "chronos.guide.fixedStars.17.degree",
    description: "chronos.guide.fixedStars.17.description"
  },
  {
    name: 'Al Hecka',
    title: "chronos.guide.fixedStars.18.name",
    degree: "chronos.guide.fixedStars.18.degree",
    description: "chronos.guide.fixedStars.18.description"
  },
  {
    name: 'Betelgeuse',
    title: "chronos.guide.fixedStars.19.name",

    degree: "chronos.guide.fixedStars.19.degree",
    description: "chronos.guide.fixedStars.19.description"
  },
  {
    name: 'Sirius',
    title: "chronos.guide.fixedStars.20.name",
    degree: "chronos.guide.fixedStars.20.degree",
    description: "chronos.guide.fixedStars.20.description"
  },
  {
    name: 'Canopus',
    title: "chronos.guide.fixedStars.21.name",
    degree: "chronos.guide.fixedStars.21.degree",
    description: "chronos.guide.fixedStars.21.description"
  },
  {
    name: 'Castor',
    title: "chronos.guide.fixedStars.22.name",

    degree: "chronos.guide.fixedStars.22.degree",
    description: "chronos.guide.fixedStars.22.description"
  },
  {
    name: 'Pollux',
    title: "chronos.guide.fixedStars.23.name",
    degree: "chronos.guide.fixedStars.23.degree",
    description: "chronos.guide.fixedStars.23.description"
  },
  {
    name: 'Procyon',
    title: "chronos.guide.fixedStars.24.name",
    degree: "chronos.guide.fixedStars.24.degree",
    description: "chronos.guide.fixedStars.24.description"
  },
  {
    name: 'Praesepe',
    title: "chronos.guide.fixedStars.25.name",
    degree: "chronos.guide.fixedStars.25.degree",
    description: "chronos.guide.fixedStars.25.description"
  },
  {
    name: 'Asellus Borealis',
    title: "chronos.guide.fixedStars.26.name",
    degree: "chronos.guide.fixedStars.26.degree",
    description: "chronos.guide.fixedStars.26.description"
  },
  {
    name: 'Asellus Australis',
    title: "chronos.guide.fixedStars.27.name",
    degree: "chronos.guide.fixedStars.27.degree",
    description: "chronos.guide.fixedStars.26.description"
  },
  {
    name: 'Acubens',
    title: "chronos.guide.fixedStars.28.name",
    degree: "chronos.guide.fixedStars.28.degree",
    description: "chronos.guide.fixedStars.28.description"
  },
  {
    name: 'Dubhe',
    title: "chronos.guide.fixedStars.29.name",
    degree: "chronos.guide.fixedStars.29.degree",
    description: "chronos.guide.fixedStars.29.description"
  },
  {
    name: 'Alphard',
    title: "chronos.guide.fixedStars.30.name",
    degree: "chronos.guide.fixedStars.30.degree",
    description: "chronos.guide.fixedStars.30.description"
  },
  {
    name: 'Al Jabhah',
    title: "chronos.guide.fixedStars.31.name",
    degree: "chronos.guide.fixedStars.31.degree",
    description: "chronos.guide.fixedStars.31.description"
  },
  {
    name: 'Regulus',
    title: "chronos.guide.fixedStars.32.name",
    degree: "chronos.guide.fixedStars.32.degree",
    description: "chronos.guide.fixedStars.32.description"
  },
  {
    name: 'Zosma',
    title: "chronos.guide.fixedStars.33.name",
    degree: "chronos.guide.fixedStars.33.degree",
    description: "chronos.guide.fixedStars.33.description"
  },
  {
    name: 'Denebola',
    title: "chronos.guide.fixedStars.34.name",
    degree: "chronos.guide.fixedStars.34.degree",
    description: "chronos.guide.fixedStars.34.description"
  },
  {
    name: 'Diadem',
    title: "chronos.guide.fixedStars.35.name",
    degree: "chronos.guide.fixedStars.35.degree",
    description: "chronos.guide.fixedStars.35.description"
  },
  {
    name: 'Labrum',
    title: "chronos.guide.fixedStars.36.name",
    degree: "chronos.guide.fixedStars.36.degree",
    description: "chronos.guide.fixedStars.36.description"
  },
  {
    name: 'Vindemiatrix',
    title: "chronos.guide.fixedStars.37.name",
    degree: "chronos.guide.fixedStars.37.degree",
    description: "chronos.guide.fixedStars.37.description"
  },
  {
    name: 'Algorab',
    title: "chronos.guide.fixedStars.38.name",
    degree: "chronos.guide.fixedStars.38.degree",
    description: "chronos.guide.fixedStars.38.description"
  },
  {
    name: 'Seginus',
    title: "chronos.guide.fixedStars.39.name",
    degree: "chronos.guide.fixedStars.39.degree",
    description: "chronos.guide.fixedStars.39.description"
  },
  {
    name: 'Foramen',
    title: "chronos.guide.fixedStars.40.name",
    degree: "chronos.guide.fixedStars.40.degree",
    description: "chronos.guide.fixedStars.40.description"
  },
  {
    name: 'Spica',
    title: "chronos.guide.fixedStars.41.name",
    degree: "chronos.guide.fixedStars.41.degree",
    description: "chronos.guide.fixedStars.41.description"
  },
  {
    name: 'Arcturus',
    title: "chronos.guide.fixedStars.42.name",
    degree: "chronos.guide.fixedStars.42.degree",
    description: "chronos.guide.fixedStars.42.description"
  },
  {
    name: 'Princeps',
    title: "chronos.guide.fixedStars.43.name",
    degree: "chronos.guide.fixedStars.43.degree",
    description: "chronos.guide.fixedStars.43.description"
  },
  {
    name: 'Alphecca',
    title: "chronos.guide.fixedStars.44.name",
    degree: "chronos.guide.fixedStars.44.degree",
    description: "chronos.guide.fixedStars.44.description"
  },
  {
    name: 'Zuben Elgenubi',
    title: "chronos.guide.fixedStars.45.name",
    degree: "chronos.guide.fixedStars.45.degree",
    description: "chronos.guide.fixedStars.45.description"
  },
  {
    name: 'Zuben Eschamali',
    title: "chronos.guide.fixedStars.46.name",
    degree: "chronos.guide.fixedStars.46.degree",
    description: "chronos.guide.fixedStars.46.description"
  },
  {
    name: 'Unukalhai',
    title: "chronos.guide.fixedStars.47.name",
    degree: "chronos.guide.fixedStars.47.degree",
    description: "chronos.guide.fixedStars.47.description"
  },
  {
    name: 'Agena',
    title: "chronos.guide.fixedStars.48.name",
    degree: "chronos.guide.fixedStars.48.degree",
    description: "chronos.guide.fixedStars.48.description"
  },
  {
    name: 'Toliman',
    title: "chronos.guide.fixedStars.49.name",
    degree: "chronos.guide.fixedStars.49.degree",
    description: "chronos.guide.fixedStars.49.description"
  },
  {
    name: 'Yed Prior',
    title: "chronos.guide.fixedStars.50.name",
    degree: "chronos.guide.fixedStars.50.degree",
    description: "chronos.guide.fixedStars.50.description"
  },
  {
    name: 'Antares',
    title: "chronos.guide.fixedStars.51.name",
    degree: "chronos.guide.fixedStars.51.degree",
    description: "chronos.guide.fixedStars.51.description"
  },
  {
    name: 'Rastaban',
    title: "chronos.guide.fixedStars.52.name",
    degree: "chronos.guide.fixedStars.52.degree",
    description: "chronos.guide.fixedStars.52.description"
  },
  {
    name: 'Rasalgethi',
    title: "chronos.guide.fixedStars.53.name",
    degree: "chronos.guide.fixedStars.53.degree",
    description: "chronos.guide.fixedStars.53.description"
  },
  {
    name: 'Aculeus',
    title: "chronos.guide.fixedStars.54.name",
    degree: "chronos.guide.fixedStars.54.degree",
    description: "chronos.guide.fixedStars.54.description"
  },
  {
    name: 'Acumen',
    title: "chronos.guide.fixedStars.55.name",
    degree: "chronos.guide.fixedStars.55.degree",
    description: "chronos.guide.fixedStars.55.description"
  },
  {
    name: 'Sinistra',
    title: "chronos.guide.fixedStars.56.name",
    degree: "chronos.guide.fixedStars.56.degree",
    description: "chronos.guide.fixedStars.56.description"
  },
  {
    name: 'Facies',
    title: "chronos.guide.fixedStars.57.name",
    degree: "chronos.guide.fixedStars.57.degree",
    description: "chronos.guide.fixedStars.57.description"
  },
  {
    name: 'Ascella',
    title: "chronos.guide.fixedStars.58.name",
    degree: "chronos.guide.fixedStars.58.degree",
    description: "chronos.guide.fixedStars.58.description"
  },
  {
    name: 'Manubrium',
    title: "chronos.guide.fixedStars.59.name",
    degree: "chronos.guide.fixedStars.59.degree",
    description: "chronos.guide.fixedStars.59.description"
  },
  {
    name: 'Vega',
    title: "chronos.guide.fixedStars.60.name",
    degree: "chronos.guide.fixedStars.60.degree",
    description: "chronos.guide.fixedStars.60.description"
  },
  {
    name: 'Dheneb',
    title: "chronos.guide.fixedStars.61.name",
    degree: "chronos.guide.fixedStars.61.degree",
    description: "chronos.guide.fixedStars.61.description"
  },
  {
    name: 'Terebellium',
    title: "chronos.guide.fixedStars.62.name",
    degree: "chronos.guide.fixedStars.62.degree",
    description: "chronos.guide.fixedStars.62.description"
  },
  {
    name: 'Altair',
    title: "chronos.guide.fixedStars.63.name",
    degree: "chronos.guide.fixedStars.63.degree",
    description: "chronos.guide.fixedStars.63.description"
  },
  {
    name: 'Oculus',
    title: "chronos.guide.fixedStars.64.name",
    degree: "chronos.guide.fixedStars.64.degree",
    description: "chronos.guide.fixedStars.64.description"
  },
  {
    name: 'Bos',
    title: "chronos.guide.fixedStars.65.name",
    degree: "chronos.guide.fixedStars.65.degree",
    description: "chronos.guide.fixedStars.65.description"
  },
  {
    name: 'Nashira',
    title: "chronos.guide.fixedStars.66.name",
    degree: "chronos.guide.fixedStars.66.degree",
    description: "chronos.guide.fixedStars.66.description"
  },
  {
    name: 'Deneb Algedi',
    title: "chronos.guide.fixedStars.67.name",
    degree: "chronos.guide.fixedStars.67.degree",
    description: "chronos.guide.fixedStars.67.description"
  },
  {
    name: 'Sadalsuud',
    title: "chronos.guide.fixedStars.68.name",
    degree: "chronos.guide.fixedStars.68.degree",
    description: "chronos.guide.fixedStars.68.description"
  },
  {
    name: 'Fomalhaut',
    title: "chronos.guide.fixedStars.69.name",
    degree: "chronos.guide.fixedStars.69.degree",
    description: "chronos.guide.fixedStars.69.description"
  },
  {
    name: 'Deneb Adige',
    title: "chronos.guide.fixedStars.70.name",
    degree: "chronos.guide.fixedStars.70.degree",
    description: "chronos.guide.fixedStars.70.description"
  },
  {
    name: 'Skat',
    title: "chronos.guide.fixedStars.71.name",
    degree: "chronos.guide.fixedStars.71.degree",
    description: "chronos.guide.fixedStars.71.description"
  },
  {
    name: 'Achernar',
    title: "chronos.guide.fixedStars.72.name",
    degree: "chronos.guide.fixedStars.72.degree",
    description: "chronos.guide.fixedStars.72.description"
  },
  {
    name: 'Markab',
    title: "chronos.guide.fixedStars.73.name",
    degree: "chronos.guide.fixedStars.73.degree",
    description: "chronos.guide.fixedStars.73.description"
  }
] as {
  name: string;
  title: string;
  degree: string;
  description: string;
}[];