import { t } from "i18next";

export class SynthesisSigns {
  objects: any;
  signs: any;

  constructor(objects: any, houses: any) {
    // приводим в соответсвие с 1 домом,
    // избавляемся от отрицательных углов
    // убираем большие числа
    // сортируем
    this.objects = objects
      .map((item: any, i: number) => ({ lon: item.lon + (180 - (houses[0] || 0)), id: i }))
      .map((item: any) => ({ lon: item.lon >= 0 ? item.lon : item.lon + 360, id: item.id }))
      .map((item: any) => ({ lon: item.lon % 360, id: item.id }))
      .sort((a: any, b: any) => a.lon >= b.lon ? 1 : -1);

    this.signs = {
      0: { element: 'fire', cross: 'cardinal' },
      1: { element: 'earth', cross: 'fix' },
      2: { element: 'air', cross: 'mutable' },
      3: { element: 'water', cross: 'cardinal' },
      4: { element: 'fire', cross: 'fix' },
      5: { element: 'earth', cross: 'mutable' },
      6: { element: 'air', cross: 'cardinal' },
      7: { element: 'water', cross: 'fix' },
      8: { element: 'fire', cross: 'mutable' },
      9: { element: 'earth', cross: 'cardinal' },
      10: { element: 'air', cross: 'fix' },
      11: { element: 'water', cross: 'mutable' }
    };

    // вычисляем начало овна
    let firstSign = 30 + (180 - (houses[0] || 0));
    if (firstSign < 0) { firstSign += 360 }
    firstSign = firstSign % 360;

    // определяем знак для каждой планеты
    this.objects = this.objects.map((item: any) => {
      const rotateAngle = 30 - firstSign;
      let lon = item.lon + rotateAngle;
      if (lon < 0) { lon += 360 }
      const signIndex = Math.floor(lon % 360 / 30);
      return {
        ...item,
        sign: this.signs[signIndex]
      };
    });
  }

  getCrossesWithObjects() {
    let crosses = [];

    let cardinal = this.objects.filter((item: any) => item.sign.cross === 'cardinal');
    let fix = this.objects.filter((item: any) => item.sign.cross === 'fix');
    let mutable = this.objects.filter((item: any) => item.sign.cross === 'mutable');

    if (cardinal.length > 0) { crosses.push({
      name: t("astro.facts.cardinal"),
      elements: this.groupObjectsByElements(cardinal),
      objectsCount: cardinal.length
    }); }
    if (fix.length > 0) { crosses.push({
      name: t("chronos.app.facts.fixed"),
      elements: this.groupObjectsByElements(fix),
      objectsCount: fix.length
    }); }
    if (mutable.length > 0) { crosses.push({
      name: t("chronos.app.facts.mutable"),
      elements: this.groupObjectsByElements(mutable),
      objectsCount: mutable.length
    }); }

    return crosses;
  }

  groupObjectsByElements(objects: any) {
    let elements = [];

    let fire = objects.filter((item: any) => item.sign.element === 'fire');
    let earth = objects.filter((item: any) => item.sign.element === 'earth');
    let air = objects.filter((item: any) => item.sign.element === 'air');
    let water = objects.filter((item: any) => item.sign.element === 'water');

    if (fire.length > 0) { elements.push({
      name: 'fire',
      color: '#EC7E5B',
      objects: fire
    }); }
    if (earth.length > 0) { elements.push({
      name: 'earth',
      color: '#94C062',
      objects: earth
    }); }
    if (air.length > 0) { elements.push({
      name: 'air',
      color: '#9575CD',
      objects: air
    }); }
    if (water.length > 0) { elements.push({
      name: 'water',
      color: '#64B5F6',
      objects: water
    }); }

    return elements;
  }

  getSigns() {
    let crosses = this.getCrossesWithObjects();
    let maxCrossesLength = crosses.reduce((a, b) => {
      if (a.objectsCount > b.objectsCount) { return a }
      return b;
    }).objectsCount;
    let maxCrosses = crosses.filter(item => item.objectsCount === maxCrossesLength);

    let elements = this.groupObjectsByElements(this.objects);
    let maxElementsLength = elements.reduce((a, b) => {
      if (a.objects.length > b.objects.length) { return a }
      return b;
    }).objects.length;
    let maxElements = elements.filter(item => item.objects.length === maxElementsLength);

    const signs = [];
    for (let c = 0; c < maxCrosses.length; c++) {
      for (let e = 0; e < maxElements.length; e++) {
        signs.push(this.getSignByOptions(maxCrosses[c].name, maxElements[e].name));
      }
    }

    return signs as number[];
  }

  getSignByOptions(cross: any, element: any) {
    if (cross === t("astro.facts.cardinal")) {
      if (element === 'fire') { return 0 }
      if (element === 'air') { return 6 }
      if (element === 'earth') { return 9 }
      if (element === 'water') { return 3 }
    } else if (cross === t("chronos.app.facts.fixed")) {
      if (element === 'fire') { return 4 }
      if (element === 'air') { return 10 }
      if (element === 'earth') { return 1 }
      if (element === 'water') { return 7 }
    } else if (cross === t("chronos.app.facts.mutable")) {
      if (element === 'fire') { return 8 }
      if (element === 'air') { return 2 }
      if (element === 'earth') { return 5 }
      if (element === 'water') { return 11 }
    }
  }
}
