import React from "react";
import styled from "styled-components";
import "src/theme/common.css";
import { objectsIcons, signsIcons } from "src/icons";
import { IHousesForRender, IPlanetForRender } from "./interfaces";
import {
  getSign,
  getObjectName,
  IObject,
  houseNames,
  signs,
  CircleMode,
  ObjectType,
} from "src/libs";
import { degToString } from "./utils";
import { t } from "i18next";
import { avgSpeed } from "../../Circle/Objects";

import { useSelector } from 'src/store/utils';
import { getActiveAstroProfile } from 'src/store/reducers/settings/selectors';

export function isStaticObject(id: ObjectType, speed: number) {
  return Math.abs(speed) > 0 && (Math.abs(speed) < avgSpeed[id] * 0.1);
}

function objectsConstructor(objects: IObject[]) {
  return objects.map((object: IObject, i: number) => {
    const signIndex = getSign(+object.lon);
    const isStatic = isStaticObject(i, object.speed);
    return {
      name: t(getObjectName(i, 'ru')), // FIXME: LANGUAGE
      enSignName: (signs[signIndex].en).toLowerCase(),
      lon: degToString(+object.lon % 30),
      signIndex,
      sign: t(signs[signIndex].ru),
      isRetro: object.speed < 0 && isStatic === false,
      isStatic,
    };
  });
}

function housesConstructor(houses: number[]) {
  const housesResult: IHousesForRender[] = [];

  houses.map((house: number, i: number) => {
    const housSign = getSign(house);
    housesResult.push({
      name: houseNames[i],
      lon: degToString(house % 30),
      sign: t(getObjectName(housSign)),
      signIndex: housSign,
    });
  });
  return housesResult;
}

export default function Objects(props: {
  mode?: CircleMode;
  planets: IObject[];
  houses: number[];
  isTransitsCosmogram?: boolean;
}) {

  const activeAstroProfile = useSelector(getActiveAstroProfile);
  const showMinorPlanets = activeAstroProfile?.showMinorPlanets;

  let localPlanets = props.planets;

  // отключен показ малых планет и Эрида присутствует в конце списка
  if (!showMinorPlanets && localPlanets.length === 16) {
    localPlanets.splice(-1, 1);
  }

  const planetsForProps = objectsConstructor(localPlanets);
  const housesForProps = housesConstructor(props.houses);
  
  const objectIconSimbol = (isRetro: boolean, isStatic: boolean) => {
    if (props.mode !== 'directions') {
      if (isRetro) return 'r'
      if (isStatic) return 's'
    }
    return ''
  }

  return (
    <Wrapper>
      <ObjectsWrapper>
        <table>
          <thead>
            <tr className="header" key={`tro_header`}>
              <th>{t("chronos.app.instruments.widgets.horar.planet")}</th>
              <th>{t("astro.facts.position")}</th>
              <th>{t("chronos.app.instruments.widgets.coordinates.sign")}</th>
            </tr>
          </thead>
          {planetsForProps.map((planet: IPlanetForRender, i: number) => {
            const ObjectIcon = objectsIcons[i];
            const ObjectSignIcon = signsIcons[planet.signIndex];
            return (
              <tbody key={i}>
                <tr>
                  <td>
                    <span className="object-wrapper">
                      <ObjectIcon />
                      <sub>{objectIconSimbol(planet.isRetro, planet.isStatic)}</sub>
                    </span>
                  </td>
                  <td>
                    <span>{planet.lon}</span>
                  </td>
                  <td>
                    <div>
                      <ObjectSignIcon
                        className={`svgSign ${planet.enSignName}`}
                        style={{
                          color: `var(--circle-zodiacs-elements-${signs[planet.signIndex].element
                            })`,
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </ObjectsWrapper>
      {!props.isTransitsCosmogram && <HousesWrapper>
        <table>
          <thead>
            <tr className="header" key={`trh_header`}>
              <th>{t("chronos.app.instruments.widgets.coordinates.house")}</th>
              <th>{t("astro.facts.position")}</th>
              <th>{t("chronos.app.instruments.widgets.coordinates.sign")}</th>
            </tr>
          </thead>
            {housesForProps.map((house: IHousesForRender, i: number) => {
              const HousSignIcon = signsIcons[house.signIndex];
              const sign = signs[house.signIndex];
              const signName = sign && sign.en ? sign.en.toLowerCase() : '';
              if(sign) {

                return (
                  <tbody key={`house_p_${i}`}>
                <tr >
                  <td>
                    <span className="housName">{house.name}</span>
                  </td>
                  <td>
                    <span>{house.lon}</span>
                  </td>
                  <td>
                    <span>
                      <HousSignIcon
                        className={`svgSign ${t(signName)}`}
                        style={{
                          color: `var(--circle-zodiacs-elements-${signs[house.signIndex].element
                            })`,
                        }}
                        />
                    </span>
                  </td>
                </tr>
          </tbody>
              )
            } else {
              return null;
            }
            })}
            </table>
      </HousesWrapper>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  table {
    table-layout: fixed;
    width: 100%;

    tr td:nth-child(n) {
      text-align: center;
      height: 2.3rem;
    }

    thead th:nth-child(n) {
      width: 33%;
    }

    & td .object-wrapper {
      display: inline-block;
      position: relative;

      & sub {
        position: absolute;
        top: 0.7rem;
        right: -0.2rem;
      }
    }
  }

  .header {
    color: var(--text-secondary);
  }

  svg {
    width: 1.3rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ObjectsWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--input-border);
`;
const HousesWrapper = styled.div`
  width: 100%;
`;
