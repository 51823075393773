import { IUser, IAuth } from "src/libs";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { get } from "lodash";

dayjs.extend(isSameOrBefore);

const PROFILE_LS_NAME = 'mobile_profile_v2.4';


export const getProfile = (): Partial<IUser> & Partial<IAuth>  => {
  const item = localStorage.getItem(PROFILE_LS_NAME);
  return item && JSON.parse(item || '{}');
}

export const setProfile = (data: any) => {
  localStorage.setItem(PROFILE_LS_NAME, JSON.stringify(data));
}

export const unsetProfile = () => {
  localStorage.removeItem('mobile_profile_v2.2'); // 25/03/22
  localStorage.removeItem('mobile_profile_v2.3'); // 25/03/22
  localStorage.removeItem(PROFILE_LS_NAME);
}

export const isTrialAccess = (): boolean => {
  const { permissions = undefined, subscriptions = []} = getProfile()
  return Boolean(permissions?.isTrial && !subscriptions?.length);
}

export const isBaseTariff = (): boolean => {
  const { permissions = undefined, subscriptions = undefined, expiryDate = '' } = getProfile()
  return Boolean(
    !permissions?.isTrial 
      && !permissions?.canUsePro 
      && !permissions?.isUnlimited 
      && subscriptions?.slice(-1)?.[0] === 2
      && dayjs().isSameOrBefore(dayjs(expiryDate))
  );
}

export const isArchiveTariff = (): boolean => {
  const { permissions = undefined, subscriptions = undefined, expiryDate = '' } = getProfile()
  return Boolean(
    !permissions?.isTrial  
    && !permissions?.isUnlimited 
    && permissions?.canUsePro
    && subscriptions?.slice(-1)?.[0] === 1)
    && dayjs().isSameOrBefore(dayjs(expiryDate)
  )
}

export const isExtendedTariff = (): boolean => {
  const { permissions = undefined, subscriptions = undefined, expiryDate = '' } = getProfile()
  return Boolean(
    !permissions?.isTrial  
    && !permissions?.isUnlimited 
    && permissions?.canUsePro
    && subscriptions?.slice(-1)?.[0] === 3)
    && dayjs().isSameOrBefore(dayjs(expiryDate)
  )
}

export const isUnlimitedTariff = (): boolean => {
  const { permissions = undefined, subscriptions = undefined, expiryDate = '' } = getProfile()
  return Boolean(
    !permissions?.isTrial  
    && permissions?.isUnlimited 
    && permissions?.canUsePro
    && subscriptions?.slice(-1)?.[0] === 4)
    && dayjs().isSameOrBefore(dayjs(expiryDate)
  )
}

export const isLimitedAccess = (): boolean => {

  return Boolean(
    !isTrialAccess()
    && !isBaseTariff()
    && !isArchiveTariff()
    && !isExtendedTariff()
    && !isUnlimitedTariff()
  );
}