import { PAYMENTS_GET_LOADING, PAYMENTS_GET_SUCCESS } from './actions';
import { IActionPayments, IPaymentsState } from './types';

const initialState: IPaymentsState = {
  loading: false,
  loaded: false,
  data: []
};

export default function payments(state = initialState, action: IActionPayments){
  switch(action.type){
    case PAYMENTS_GET_LOADING:
      return {
        loading: true,
        loaded: false,
        data: state.data
      };
    case PAYMENTS_GET_SUCCESS:
      return {
        loading: false,
        loaded: true,
        data: action.payload
      };
    default:
      return state;
  }
}

