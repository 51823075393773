import React, { useEffect } from 'react';
import { getAllFolders } from 'src/store/reducers/folders/selectors';
import { allForms } from 'src/store/reducers/forms/selectors';
import { getAllLabels } from 'src/store/reducers/labels/selectors';
import { paymentsFullInfo } from 'src/store/reducers/payments/selectors';
import { getProfile as getProfileSelector } from 'src/store/reducers/profile/selectors';
import {
	allSettings,
	allSettingsAstro,
	getCustomizations as getCustomizationsSelector
} from 'src/store/reducers/settings/selectors';
import { useSelector } from 'src/store/utils';
import { Loader } from 'src/libs';
import { useDispatch } from "react-redux";
import { getProfile as getProfileLS, isLimitedAccess, isBaseTariff, isArchiveTariff, isExtendedTariff, isUnlimitedTariff, isTrialAccess } from "../store/localStorage/profile";
import { getCustomizations, getSettings, getSettingsAstro } from "../store/actions/settings";
import { getPaymentsFullInfo } from "../store/reducers/payments/actions";
import { getProfile } from "../store/reducers/profile/actions";
import { getForms } from "../store/reducers/forms/actions";
import { getFolders } from "../store/reducers/folders/actions";
import { getLabels } from "../store/reducers/labels/actions";
import MainRouter from "./routing";
// import i18n from 'src/i18n/i18n';

export default function MainLoader() {
	// const [isInit, setIsInit] = React.useState(false);
	
	const dispatch = useDispatch();
	useSelector((s) => s.profile);

	const isShareMap = new URLSearchParams(window.location.search).has('share');

	useEffect(() => {
		const lsUser = getProfileLS();
		if (lsUser && lsUser.id) {
			dispatch(getSettings(lsUser.id));
			dispatch(getSettingsAstro(lsUser.id));
			dispatch(getCustomizations(lsUser.id));

			if (!isShareMap) {
				dispatch(getPaymentsFullInfo());
				dispatch(getProfile());
				dispatch(getForms());
				dispatch(getFolders());
				dispatch(getLabels());
			}
		}
	}, [])

	const paymentsInfo = useSelector(paymentsFullInfo);
	const settings = useSelector(allSettings);
	const astroSettings = useSelector(allSettingsAstro);
	const forms = useSelector(allForms);
	const profile = useSelector(getProfileSelector);
	const customizations = useSelector(getCustomizationsSelector);
	const folders = useSelector(getAllFolders);
	const labels = useSelector(getAllLabels);


	if (
		!settings.loaded ||
		!astroSettings.loaded ||
		!customizations.loaded ||

		((
			!paymentsInfo.loaded ||
			!profile.loaded ||
			!forms.loaded ||
			!folders.loaded ||
			!labels.loaded) && !isShareMap)
	) {
		return <Loader />;
	}

	return <MainRouter />
}
