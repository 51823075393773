import React, { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { OkCircleInactiveIcon } from 'src/assets/icons/system/index';
import { OkCircleActiveIcon } from 'src/assets/icons/system/index';
import { useDispatch } from 'react-redux';
import { NotificationContext } from 'src/store/context/notifications';
import { addLabel, getLabels, updateLabel } from 'src/store/reducers/labels/actions';
import { getForms } from 'src/store/reducers/forms/actions';
import { IFormLabel } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';


export default function EditLabelForPopup({
  onClose,
  label,
  open
}: {
  onClose(): void;
  label?: IFormLabel;
  open: boolean
}){
  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const context = useContext(NotificationContext);
  const { t } = useTranslation();
  const [value, setValue] = useState(label ? label.title : '');

  const handleChange = (e: SyntheticEvent) => {
    setValue((e.target as HTMLInputElement).value);
  }

  const isActive = value.length >= 1 && value.length <= 32;

  useEffect(() => {
    if(open && ref.current){
      setTimeout(() => ref.current!.focus(), 300);
    }
  }, [open])

  useEffect(() => {
    setValue(label ? label.title : '');
  }, [label])

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if(!isActive) return;
    if(label) {
      const newItem = {...label, title: value.trim()};
      dispatch(updateLabel(newItem, () => {
        dispatch(getLabels(false));
        dispatch(getForms(false));
        onClose();
        context?.add(t("chronos.mobile.dashboard.components.editDroupForPopup.nameChanged"));
      }));
    } else {
      dispatch(addLabel(value.trim(), () => {
        dispatch(getLabels(false));
        onClose();
        context?.add(t("chronos.mobile.dashboard.components.editDroupForPopup.labelAdded"));
      }));
    }
   
  }


  const SubmitButton = <Button>
    {isActive && <OkCircleActiveIcon />}
    {!isActive && <OkCircleInactiveIcon />}
  </Button>

  return <Container onSubmit={handleSubmit}>
    <Input ref={ref} placeholder={t("chronos.profile.security.phoneChanger.startToInput")} value={value} onInput={handleChange}></Input>
    {SubmitButton}
  </Container>
}

const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 1rem;
  margin-top: 1.25rem;
`;

const Input = styled.input`
  &::placeholder {
      color: var(--text-secondary);
  }
`

const Button = styled.button`
  background: none;
  border: none;
`