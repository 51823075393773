import { IPromocode, IPromocodeAction, IPromocodeDiscountAction, IPromocodeSubscriptionAction, ISubscription, TProcessorPromoProduct, daysBetweenDates, CurrencyCode } from 'src/libs';
import { IRootState } from 'src/store';
import { formatMessage } from 'src/utils';
import { t } from 'i18next';
import { colors, descs } from './types'


export function getPayments(state: IRootState) {
  return state.payments.payments
}

export function paymentsFullInfo(state: IRootState) {
  return state.payments.info
}

export function getPriceForAmount(forAmount: number, code: CurrencyCode) {
  return function(state: IRootState): number {
    return state.payments.info.data!.counters.find(({ amount, currencyCode}) => amount === forAmount && currencyCode === code)!.price;
  }
}

export function isExpiredTariff(state: IRootState) {
  const expiryDate = state.payments.info.data!.expiryDate;
  return new Date(expiryDate) < new Date();
}

export function getUserTariffId(state: IRootState) {
  const userSubscriptions = state.payments.info.data!.userSubscriptions;
  return isExpiredTariff(state) ? 0 : userSubscriptions.slice(-1)[0];
}

export function getPayment(state: IRootState) {
  // @ts-ignore
  return state.payments.info.data!.payment;
}

export function getSubscriptions(state: IRootState) {
  return state.payments.info.data!.userSubscriptions;
}

export function getExpiryDate(state: IRootState) {
  return state.payments.info.data!.expiryDate;
}

export function getAutoPayment(state: IRootState) {
  return state.payments.info.data!.autoPayment;
}

export function getPeriod(state: IRootState) {
  return state.payments.info.data!.period;
}

export function getRealActiveTariff(state: IRootState): ISubscription {
  const userSubscriptions = state.payments.info.data!.userSubscriptions;
  const tariffs = state.payments.info.data!.subscriptions;
  const maxTariffId = Math.max(...userSubscriptions);
  const result = tariffs.find(({ id }: ISubscription) => id === maxTariffId)!;
  return result;
}

function isArchiveTariffLast(state: IRootState) {
  const userSubscriptions = state.payments.info.data!.userSubscriptions;
  return userSubscriptions.slice(-1)[0] === 1;
}

function showArchiveTariff(state: IRootState) {
  const userTariffId = getUserTariffId(state);
  const expiryDate = state.payments.info.data!.expiryDate;
  return userTariffId === 1 || 
    isArchiveTariffLast(state) && daysBetweenDates(expiryDate, new Date().toISOString()) < 3
}

export function getAdjustedTariffs(state: IRootState): ISubscription[] {
  const tariffs = state.payments.info.data!.subscriptions;
  return [...tariffs]
    .filter(({ id }) => (showArchiveTariff(state)) || id !== 1)
    .sort((a, b) => a.id - b.id)
    .map(tariff => {
      tariff.color = colors[tariff.id];
      tariff.detail = descs[tariff.id];
      return tariff;
    });
}

export function getShortTariffs(state: IRootState): ISubscription[] {
  return getAdjustedTariffs(state).map(({ id, type, name, sum, color, currencyCode }) => ({ id, type, name, sum, color, currencyCode }));
}

export function getUserTariffData(state: IRootState): ISubscription {
  const userTariffId = getUserTariffId(state);
  return getAdjustedTariffs(state).find(({ id }) => id === userTariffId)!;
}


export function isTariffsAction(action: IPromocodeAction): boolean {
  return action.app === 'processor' && (action?.products as TProcessorPromoProduct).every((v) => typeof v === 'number');
}

export function isFormationsAction(action: IPromocodeAction): boolean {
  return action.app === 'processor' && (action?.products as TProcessorPromoProduct).includes('formations');
}

export function isDiscountAction(action: IPromocodeAction): boolean {
  const act = action as IPromocodeDiscountAction;
  return (act?.discount > 0) && (act?.discount < 1);
}


// PROMO FREE FORMATIONS
function freeFormationsCb(item: IPromocode) {
  return isFormationsAction(item.actions) && !isDiscountAction(item.actions);
}

export function getFreeFormationPromo(state: IRootState): any {
  const promoList = getPromos(state);
  return promoList.find(freeFormationsCb);
}

export function getFreeFormationsValue(state: IRootState) {
  const promo = getFreeFormationPromo(state);
  return (promo?.actions as IPromocodeAction)?.value || 0;
}

// PROMO FREE TARIFF
function freeTariffCb(item: IPromocode) {
  return isTariffsAction(item.actions) && !isDiscountAction(item.actions);
}
export function getIsFreeTariff(state: IRootState) {
  const promoList = getPromos(state);
  return promoList.some(freeTariffCb);
}

export function getFreeTariffPromo(state: IRootState) {
  const promoList = getPromos(state);
  return promoList.find(freeTariffCb);
}

export function getFreeTariffId(state: IRootState) {
  return (getFreeTariffPromo(state)?.actions.products as TProcessorPromoProduct)?.[0];
}

export function getFreeTariffPeriodInfo(state: IRootState) {
  const action = getFreeTariffPromo(state)?.actions as IPromocodeSubscriptionAction;
  return action ? `${getD(state)['subscription']?.period?.[action?.periodType!](action?.period)}` : 'undefined';
}

export function getFreeTariffShortInfo(state: IRootState) {
  return `${t("chronos.app.settings.isFree")} ${getFreeTariffPeriodInfo(state)}`;
}


// PROMO DISCOUNT FORMATIONS
function formationDiscountCb(item: IPromocode) {
  return isFormationsAction(item.actions) && isDiscountAction(item.actions);
}

export function getFormationDiscountPromo(state: IRootState): any {
  const promoList = getPromos(state);
  return promoList.find(formationDiscountCb);
}

export function getFormationDiscountValue(state: IRootState) {
  const promo = getFormationDiscountPromo(state);
  return (promo?.actions as IPromocodeAction)?.discount! * 100;
}

export function getFormationDiscountInfo(state: IRootState): string {
  const promo = getFormationDiscountPromo(state);

  if (!promo) {
    return t("chronos.app.store.promoStore.noDescriptions");
  }
  const { actions: action } = promo;
  const dSection = getD(state)['discount']
  return `
        ${t(dSection?.title)}
        ${dSection?.discount?.(action.discount) || ''}
        ${dSection?.subtype.counter}
      `;
}


// PROMO DISCOUNT TARIFF

function tariffDiscountCb(item: IPromocode) {
  return isTariffsAction(item.actions) && isDiscountAction(item.actions);
}

export function getIsTariffDiscount(state: IRootState) {
  const promoList = getPromos(state);
  return promoList.some(tariffDiscountCb);
}

export function getTariffDiscountPromo(state: IRootState) {
  const promoList = getPromos(state);
  return promoList.find(tariffDiscountCb);
}

export function getTariffWithDiscount(state: IRootState): number[] {
  const promo = getTariffDiscountPromo(state);
  return promo?.actions?.products as number[];
}

export function getTariffDiscountValue(state: IRootState) {
  const promo = getTariffDiscountPromo(state);
  return promo?.actions?.discount! * 100;
}


function getSelectedTariff(state: IRootState, activeTariffId: number) {
  return getAdjustedTariffs(state).find(({ id }) => id === activeTariffId);
}

export function getIsDowngrade(state: IRootState, activeTariffId: number) {
  const userSubscriptions = state.payments.info.data!.userSubscriptions;
  const maxTariffId = Math.max(...userSubscriptions);
  const selectedTariff = getSelectedTariff(state, activeTariffId);
  return selectedTariff && selectedTariff.id <= maxTariffId;
}

function getPromos(state: IRootState) {
  return state.payments?.info.data?.promos || [];
}

function getD(state: IRootState) {
  const d: { [key: string]: any } = {
    'subscription': {
      title: t("chronos.app.config.PromoStore.freeSubscription"),
      tariff: (id: number) => tariffListAsString([id], state),
      period: {
        'days': (num: number) => t("chronos.mobile.serrings.payments.subscription.Ondays", { num: num, formatOfDays: formatMessage(num, [t("chronos.app.day.nom"), t("chronos.app.day.gen"), t("chronos.app.day.plural")]) }),
        'months': (num: number) => t("chronos.mobile.serrings.payments.subscription.OnMonths", { num: num, formatOfMonths: formatMessage(num, [t("chronos.app.month.lower"), t("chronos.app.month.lower.gen"), t("chronos.app.month.lower.gen.plural")]) })
      }
    },
    'discount': {
      title: t("base.discount"),
      subtype: {
        'counter': t("base.onBuilding"),
        'subscription': (subs: number[]) => `${subs.length > 1 ? t("base.forTariffs") : t("base.forTariff")} ${tariffListAsString(subs, state)}`
      },
      discount: (percent: number) => percent ? `${percent * 100}%` : ''
    },
    'counter': {
      title: (num: number) => `${num} ${formatMessage(num, [t("base.building"), t("base.building.gen"), t("base.building.plural")])} ${t("base.free")}`
    }
  };

  return d;
}

function tariffListAsString(ids: number[], state: IRootState): string {
  return getShortTariffs(state)?.reduce((acc, current, i) => {
    if (ids.includes(current.id)) {
      return `${acc}${acc.length ? `, ${current.name}` : current.name}`;
    }
    return acc;

  }, '');
}

export function getTariffs(state: IRootState) {
  return state.payments.tariffs.data;
}