export default {
  rules: {
    1: {
      name: "chronos.app.rectification.events.rules.1.name",
      asp: "0 60 120 36 72 108 144",
      dir: "Saturn",
      nat: "P7 C7 P10 C10 P4 C4"
    },
    2: {
      name: "chronos.app.rectification.events.rules.2.name",
      asp: "0 60 120",
      dir: "P7 C7 P10 C10 P4 C4 Sun Mercury Mars Jupiter",
      nat: "P7 C7 P10 C10 P4 C4"
    },
    3: {
      name: "chronos.app.rectification.events.rules.3.name",
      asp: "36 72 108 144",
      dir: "P7 C7 P10 C10 P4 C4 Sun Mercury Mars Jupiter",
      nat: "P7 C7 P10 C10 P4 C4"
    },
    4: {
      name: "chronos.app.rectification.events.rules.4.name",
      asp: "0 60 120",
      dir: "Sun Mercury Mars Jupiter Saturn",
      nat: "K7 K10 K4"
    },
    5: {
      name: "chronos.app.rectification.events.rules.5.name",
      asp: "36 72 108 144",
      dir: "Sun Mercury Mars Jupiter Saturn",
      nat: "K7 K10 K4"
    },
    6: {
      name: "chronos.app.rectification.events.rules.6.name",
      asp: "0 60 120",
      dir: "K7 K10 K4",
      nat: "P7 C7 P10 C10 P4 C4 Sun Mercury Mars Jupiter Saturn"
    },
    7: {
      name: "chronos.app.rectification.events.rules.7.name",
      asp: "0 60 120",
      dir: "P7 C7 P10 C10 P4 C4 Moon Mercury Venus Jupiter",
      nat: "P7 C7 P10 C10 P4 C4"
    },
    8: {
      name: "chronos.app.rectification.events.rules.8.name",
      asp: "36 72 108 144",
      dir: "P7 C7 P10 C10 P4 C4 Moon Mercury Venus Jupiter",
      nat: "P7 C7 P10 C10 P4 C4"
    },
    9: {
      name: "chronos.app.rectification.events.rules.9.name",
      asp: "0 60 120",
      dir: "Moon Mercury Venus Jupiter Saturn",
      nat: "K7 K10 K4"
    },
    10: {
      name: "chronos.app.rectification.events.rules.10.name",
      asp: "36 72 108 144",
      dir: "Moon Mercury Venus Jupiter Saturn",
      nat: "K7 K10 K4"
    },
    11: {
      name: "chronos.app.rectification.events.rules.11.name",
      asp: "0 60 120",
      dir: "K7 K10 K4",
      nat: "P7 C7 P10 C10 P4 C4 Moon Mercury Venus Jupiter Saturn"
    },
    12: {
      name: "chronos.app.rectification.events.rules.12.name",
      asp: "0 60 120",
      dir: "P7 C7 P4 C4 Sun Mercury Mars Jupiter",
      nat: "P7 C7 P4 C4"
    },
    13: {
      name: "chronos.app.rectification.events.rules.13.name",
      asp: "30 36 72 108 144",
      dir: "P7 C7 P4 C4 Sun Mercury Mars Jupiter",
      nat: "P7 C7 P4 C4"
    },
    14: {
      name: "chronos.app.rectification.events.rules.14.name",
      asp: "0 60 120",
      dir: "Sun Mercury Mars Jupiter",
      nat: "K7 K4"
    },
    15: {
      name: "chronos.app.rectification.events.rules.15.name",
      asp: "30 36 72 108 144",
      dir: "Sun Mercury Mars Jupiter",
      nat: "K7 K4"
    },
    16: {
      name: "chronos.app.rectification.events.rules.16.name",
      asp: "0 60 120",
      dir: "K7 K4",
      nat: "P7 C7 P4 C4 Sun Mercury Mars Jupiter"
    },
    17: {
      name: "chronos.app.rectification.events.rules.17.name",
      asp: "0 60 120",
      dir: "P7 C7 P4 C4 Moon Mercury Venus Jupiter",
      nat: "P7 C7 P4 C4"
    },
    18: {
      name: "chronos.app.rectification.events.rules.18.name",
      asp: "30 36 72 108 144",
      dir: "P7 C7 P4 C4 Moon Mercury Venus Jupiter",
      nat: "P7 C7 P4 C4"
    },
    19: {
      name: "chronos.app.rectification.events.rules.19.name",
      asp: "0 60 120",
      dir: "Moon Mercury Venus Jupiter",
      nat: "K7 K4"
    },
    20: {
      name: "chronos.app.rectification.events.rules.20.name",
      asp: "30 36 72 108 144",
      dir: "Moon Mercury Venus Jupiter",
      nat: "K7 K4"
    },
    21: {
      name: "chronos.app.rectification.events.rules.21.name",
      asp: "0 60 120",
      dir: "K7 K4",
      nat: "P7 C7 P4 C4 Moon Mercury Venus Jupiter"
    },
    22: {
      name: "chronos.app.rectification.events.rules.22.name",
      asp: "0 180 90",
      dir: "P7 C7 Mars Saturn Uranus Pluto Neptune",
      nat: "P7 C7"
    },
    23: {
      name: "chronos.app.rectification.events.rules.23.name",
      asp: "45 135 40 80 100",
      dir: "P7 C7 Mars Saturn Uranus Pluto Neptune",
      nat: "P7 C7"
    },
    24: {
      name: "chronos.app.rectification.events.rules.24.name",
      asp: "0 180 90",
      dir: "Mars Saturn Uranus Pluto Neptune",
      nat: "K7"
    },
    25: {
      name: "chronos.app.rectification.events.rules.25.name",
      asp: "45 135 40 80 100",
      dir: "Mars Saturn Uranus Pluto Neptune",
      nat: "K7"
    },
    26: {
      name: "chronos.app.rectification.events.rules.26.name",
      asp: "0 180 90",
      dir: "K7",
      nat: "P7 C7 Mars Saturn Uranus Pluto Neptune"
    },
    27: {
      name: "chronos.app.rectification.events.rules.27.name",
      asp: "0 180 90",
      dir: "P7 C7 P4 C4 Mars Saturn Uranus Pluto Neptune",
      nat: "P7 C7 P4 C4"
    },
    28: {
      name: "chronos.app.rectification.events.rules.28.name",
      asp: "45 135 40 80 100",
      dir: "P7 C7 P4 C4 Mars Saturn Uranus Pluto Neptune",
      nat: "P7 C7 P4 C4"
    },
    29: {
      name: "chronos.app.rectification.events.rules.29.name",
      asp: "0 180 90",
      dir: "Mars Saturn Uranus Pluto Neptune",
      nat: "K7 K4"
    },
    30: {
      name: "chronos.app.rectification.events.rules.30.name",
      asp: "45 135 40 80 100",
      dir: "Mars Saturn Uranus Pluto Neptune",
      nat: "K7 K4"
    },
    31: {
      name: "chronos.app.rectification.events.rules.31.name",
      asp: "0 180 90",
      dir: "K7 K4",
      nat: "P7 C7 P4 C4 Mars Saturn Uranus Pluto Neptune"
    },
    32: {
      name: "chronos.app.rectification.events.rules.32.name",
      asp: "0 180 90",
      dir: "P7 C7 P10 C10 Mars Saturn Uranus Pluto Neptune",
      nat: "P7 C7 P10 C10"
    },
    33: {
      name: "chronos.app.rectification.events.rules.33.name",
      asp: "45 135 40 80 100",
      dir: "P7 C7 P10 C10 Mars Saturn Uranus Pluto Neptune",
      nat: "P7 C7 P10 C10"
    },
    34: {
      name: "chronos.app.rectification.events.rules.34.name",
      asp: "0 180 90",
      dir: "Mars Saturn Uranus Pluto Neptune",
      nat: "K7 K10"
    },
    35: {
      name: "chronos.app.rectification.events.rules.35.name",
      asp: "45 135 40 80 100",
      dir: "Mars Saturn Uranus Pluto Neptune",
      nat: "K7 K10"
    },
    36: {
      name: "chronos.app.rectification.events.rules.36.name",
      asp: "0 180 90",
      dir: "K7 K10",
      nat: "P7 C7 P10 C10 Mars Saturn Uranus Pluto Neptune"
    },
    37: {
      name: "chronos.app.rectification.events.rules.37.name",
      asp: "0 60 120",
      dir: "P5 C5 P8 C8 P1 C1 Sun Moon Venus Jupiter Neptune",
      nat: "P5 C5 P8 C8 P1 C1"
    },
    38: {
      name: "chronos.app.rectification.events.rules.38.name",
      asp: "30 36 72 108 144",
      dir: "P5 C5 P8 C8 P1 C1 Sun Moon Venus Jupiter Neptune",
      nat: "P5 C5 P8 C8 P1 C1"
    },
    39: {
      name: "chronos.app.rectification.events.rules.39.name",
      asp: "0 60 120",
      dir: "Sun Moon Venus Jupiter Neptune",
      nat: "K5 K8 K1"
    },
    40: {
      name: "chronos.app.rectification.events.rules.40.name",
      asp: "30 36 72 108 144",
      dir: "Sun Moon Venus Jupiter Neptune",
      nat: "K5 K8 K1"
    },
    41: {
      name: "chronos.app.rectification.events.rules.41.name",
      asp: "0 60 120",
      dir: "K5 K8 K1",
      nat: "P5 C5 P8 C8 P1 C1 Sun Moon Venus Jupiter Neptune"
    },
    42: {
      name: "chronos.app.rectification.events.rules.42.name",
      asp: "0 60 120",
      dir: "P5 C5 P8 C8 P4 C4 P1 C1 Sun Moon Venus Jupiter Neptune",
      nat: "P5 C5 P8 C8 P4 C4 P1 C1"
    },
    43: {
      name: "chronos.app.rectification.events.rules.43.name",
      asp: "36 72 108 144",
      dir: "P5 C5 P8 C8 P4 C4 P1 C1 Sun Moon Venus Jupiter Neptune",
      nat: "P5 C5 P8 C8 P4 C4 P1 C1"
    },
    44: {
      name: "chronos.app.rectification.events.rules.44.name",
      asp: "0 60 120",
      dir: "Sun Moon Venus Jupiter Neptune",
      nat: "K5 K8 K4 K1"
    },
    45: {
      name: "chronos.app.rectification.events.rules.45.name",
      asp: "0 60 120",
      dir: "K5 K8 K4 K1",
      nat: "P5 C5 P8 C8 P4 C4 P1 C1 Sun Moon Venus Jupiter Neptune"
    },
    46: {
      name: "chronos.app.rectification.events.rules.46.name",
      asp: "0 60 120",
      dir: "P5 C5 P11 C11 P4 C4 Sun Moon Venus Jupiter Neptune",
      nat: "P5 C5 P11 C11 P4 C4"
    },
    47: {
      name: "chronos.app.rectification.events.rules.47.name",
      asp: "36 72 108 144",
      dir: "P5 C5 P11 C11 P4 C4 Sun Moon Venus Jupiter Neptune",
      nat: "P5 C5 P11 C11 P4 C4"
    },
    48: {
      name: "chronos.app.rectification.events.rules.48.name",
      asp: "0 60 120",
      dir: "Sun Moon Venus Jupiter Neptune",
      nat: "K5 K11 K4"
    },
    49: {
      name: "chronos.app.rectification.events.rules.49.name",
      asp: "36 72 108 144",
      dir: "Sun Moon Venus Jupiter Neptune",
      nat: "K5 K11 K4"
    },
    50: {
      name: "chronos.app.rectification.events.rules.50.name",
      asp: "0 60 120",
      dir: "K5 K11 K4",
      nat: "P5 C5 P11 C11 P4 C4 Sun Moon Venus Jupiter Neptune"
    },
    51: {
      name: "chronos.app.rectification.events.rules.51.name",
      asp: "0 60 120",
      dir: "P3 C3 P4 C4 Sun Moon Mercury Venus Mars Jupiter Neptune",
      nat: "P3 C3 P4 C4"
    },
    52: {
      name: "chronos.app.rectification.events.rules.52.name",
      asp: "30 36 72 108 144",
      dir: "P3 C3 P4 C4 Sun Moon Mercury Venus Mars Jupiter Neptune",
      nat: "P3 C3 P4 C4"
    },
    53: {
      name: "chronos.app.rectification.events.rules.53.name",
      asp: "0 60 120",
      dir: "Sun Moon Mercury Venus Mars Jupiter Neptune",
      nat: "K3 K4"
    },
    54: {
      name: "chronos.app.rectification.events.rules.54.name",
      asp: "30 36 72 108 144",
      dir: "Sun Moon Mercury Venus Mars Jupiter Neptune",
      nat: "K3 K4"
    },
    55: {
      name: "chronos.app.rectification.events.rules.55.name",
      asp: "0 60 120",
      dir: "K3 K4",
      nat: "P3 C3 P4 C4 Sun Moon Mercury Venus Mars Jupiter Neptune"
    },
    56: {
      name: "chronos.app.rectification.events.rules.56.name",
      asp: "0 90 180",
      dir: "P3 C3 P4 C4 Sun Moon Mercury Venus Mars Jupiter Saturn Uranus Neptune",
      nat: "P3 C3 P4 C4"
    },
    57: {
      name: "chronos.app.rectification.events.rules.57.name",
      asp: "45 135 40 80 100",
      dir: "P3 C3 P4 C4 Sun Moon Mercury Venus Mars Jupiter Saturn Uranus Neptune",
      nat: "P3 C3 P4 C4"
    },
    58: {
      name: "chronos.app.rectification.events.rules.58.name",
      asp: "0 90 180",
      dir: "Sun Moon Mercury Venus Mars Jupiter Saturn Uranus Neptune",
      nat: "K3 K4"
    },
    59: {
      name: "chronos.app.rectification.events.rules.59.name",
      asp: "45 135 40 80 100",
      dir: "Sun Moon Mercury Venus Mars Jupiter Saturn Uranus Neptune",
      nat: "K3 K4"
    },
    60: {
      name: "chronos.app.rectification.events.rules.60.name",
      asp: "0 90 180 40 80 100",
      dir: "K3 K4",
      nat: "P3 C3 P4 C4 Sun Moon Mercury Venus Mars Jupiter Saturn Uranus Neptune"
    },
    61: {
      name: "chronos.app.rectification.events.rules.61.name",
      asp: "0 90 180",
      dir: "P5 C5 P8 C8 P1 C1 P6 C6 Mars Saturn Uranus Neptune Pluto",
      nat: "P5 C5 P8 C8 P1 C1 P6 C6"
    },
    62: {
      name: "chronos.app.rectification.events.rules.62.name",
      asp: "45 135",
      dir: "P5 C5 P8 C8 P1 C1 P6 C6 Mars Saturn Uranus Neptune Pluto",
      nat: "P5 C5 P8 C8 P1 C1 P6 C6"
    },
    63: {
      name: "chronos.app.rectification.events.rules.63.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K5 K8 K1 K6"
    },
    64: {
      name: "chronos.app.rectification.events.rules.64.name",
      asp: "45 135",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K5 K8 K1 K6"
    },
    65: {
      name: "chronos.app.rectification.events.rules.65.name",
      asp: "0 90 180",
      dir: "K5 K8 K1 K6",
      nat: "P5 C5 P8 C8 P1 C1 P6 C6 Mars Saturn Uranus Neptune Pluto"
    },
    66: {
      name: "chronos.app.rectification.events.rules.66.name",
      asp: "0 60 120",
      dir: "P3 C3 P9 C9 P1 C1 P10 C10 Sun Mercury Jupiter",
      nat: "P3 C3 P9 C9 P1 C1 P10 C10"
    },
    67: {
      name: "chronos.app.rectification.events.rules.67.name",
      asp: "30 36 72 108 144",
      dir: "P3 C3 P9 C9 P1 C1 P10 C10 Sun Mercury Jupiter",
      nat: "P3 C3 P9 C9 P1 C1 P10 C10"
    },
    68: {
      name: "chronos.app.rectification.events.rules.68.name",
      asp: "0 60 120",
      dir: "Sun Mercury Jupiter",
      nat: "K3 K9 K1 K10"
    },
    69: {
      name: "chronos.app.rectification.events.rules.69.name",
      asp: "30 36 72 108 144",
      dir: "Sun Mercury Jupiter",
      nat: "K3 K9 K1 K10"
    },
    70: {
      name: "chronos.app.rectification.events.rules.70.name",
      asp: "0 60 120",
      dir: "K3 K9 K1 K10",
      nat: "P3 C3 P9 C9 P1 C1 P10 C10 Sun Mercury Jupiter"
    },
    71: {
      name: "chronos.app.rectification.events.rules.71.name",
      asp: "0 60 120",
      dir: "P6 C6 P10 C10 P2 C2 Sun Venus Jupiter Uranus Neptune",
      nat: "P6 C6 P10 C10 P2 C2"
    },
    72: {
      name: "chronos.app.rectification.events.rules.72.name",
      asp: "30 36 72 108 144",
      dir: "P6 C6 P10 C10 P2 C2 Sun Venus Jupiter Uranus Neptune",
      nat: "P6 C6 P10 C10 P2 C2"
    },
    73: {
      name: "chronos.app.rectification.events.rules.73.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Uranus Neptune",
      nat: "K6 K10 K2"
    },
    74: {
      name: "chronos.app.rectification.events.rules.74.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Uranus Neptune",
      nat: "K6 K10 K2"
    },
    75: {
      name: "chronos.app.rectification.events.rules.75.name",
      asp: "0 60 120",
      dir: "K6 K10 K2",
      nat: "P6 C6 P10 C10 P2 C2 Sun Venus Jupiter Uranus Neptune"
    },
    76: {
      name: "chronos.app.rectification.events.rules.76.name",
      asp: "0 90 180",
      dir: "P6 C6 P10 C10 P2 C2 Mars Saturn Uranus Neptune",
      nat: "P6 C6 P10 C10 P2 C2"
    },
    77: {
      name: "chronos.app.rectification.events.rules.77.name",
      asp: "45 135 40",
      dir: "P6 C6 P10 C10 P2 C2 Mars Saturn Uranus Neptune",
      nat: "P6 C6 P10 C10 P2 C2"
    },
    78: {
      name: "chronos.app.rectification.events.rules.78.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Neptune",
      nat: "K6 K10 K2"
    },
    79: {
      name: "chronos.app.rectification.events.rules.79.name",
      asp: "45 135 40",
      dir: "Mars Saturn Uranus Neptune",
      nat: "K6 K10 K2"
    },
    80: {
      name: "chronos.app.rectification.events.rules.80.name",
      asp: "0 90 180 40",
      dir: "K6 K10 K2",
      nat: "P6 C6 P10 C10 P2 C2 Mars Saturn Uranus Neptune"
    },
    81: {
      name: "chronos.app.rectification.events.rules.81.name",
      asp: "0 90 180",
      dir: "P6 C6 P10 C10 P2 C2 Mars Saturn Uranus Neptune Pluto",
      nat: "P6 C6 P10 C10 P2 C2"
    },
    82: {
      name: "chronos.app.rectification.events.rules.82.name",
      asp: "45 135 40",
      dir: "P6 C6 P10 C10 P2 C2 Mars Saturn Uranus Neptune Pluto",
      nat: "P6 C6 P10 C10 P2 C2"
    },
    83: {
      name: "chronos.app.rectification.events.rules.83.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K6 K10 K2"
    },
    84: {
      name: "chronos.app.rectification.events.rules.84.name",
      asp: "45 135 40",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K6 K10 K2"
    },
    85: {
      name: "chronos.app.rectification.events.rules.85.name",
      asp: "0 90 180 40 80 100",
      dir: "K6 K10 K2",
      nat: "P6 C6 P10 C10 P2 C2 Mars Saturn Uranus Neptune Pluto"
    },
    86: {
      name: "chronos.app.rectification.events.rules.86.name",
      asp: "0 60 120",
      dir: "P2 C2 P8 C8 Sun Venus Jupiter Neptune",
      nat: "P2 C2 P8 C8"
    },
    87: {
      name: "chronos.app.rectification.events.rules.87.name",
      asp: "30 36 72 108 144",
      dir: "P2 C2 P8 C8 Sun Venus Jupiter Neptune",
      nat: "P2 C2 P8 C8"
    },
    88: {
      name: "chronos.app.rectification.events.rules.88.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Neptune",
      nat: "K2 K8"
    },
    89: {
      name: "chronos.app.rectification.events.rules.89.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Neptune",
      nat: "K2 K8"
    },
    90: {
      name: "chronos.app.rectification.events.rules.90.name",
      asp: "0 60 120",
      dir: "K2 K8",
      nat: "P2 C2 P8 C8 Sun Venus Jupiter Neptune"
    },
    91: {
      name: "chronos.app.rectification.events.rules.91.name",
      asp: "0 60 120",
      dir: "P5 C5 P2 C2 P8 C8 Sun Venus Jupiter Neptune",
      nat: "P5 C5 P2 C2 P8 C8"
    },
    92: {
      name: "chronos.app.rectification.events.rules.92.name",
      asp: "30 36 72 108 144",
      dir: "P5 C5 P2 C2 P8 C8 Sun Venus Jupiter Neptune",
      nat: "P5 C5 P2 C2 P8 C8"
    },
    93: {
      name: "chronos.app.rectification.events.rules.93.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Neptune",
      nat: "K5 K2 K8"
    },
    94: {
      name: "chronos.app.rectification.events.rules.94.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Neptune",
      nat: "K5 K2 K8"
    },
    95: {
      name: "chronos.app.rectification.events.rules.95.name",
      asp: "0 60 120",
      dir: "K5 K2 K8",
      nat: "P5 C5 P2 C2 P8 C8 Sun Venus Jupiter Neptune"
    },
    96: {
      name: "chronos.app.rectification.events.rules.96.name",
      asp: "0 60 120",
      dir: "P5 C5 P2 C2 P4 C4 Sun Venus Jupiter Neptune",
      nat: "P5 C5 P2 C2 P4 C4"
    },
    97: {
      name: "chronos.app.rectification.events.rules.97.name",
      asp: "30 36 72 108 144",
      dir: "P5 C5 P2 C2 P4 C4 Sun Venus Jupiter Neptune",
      nat: "P5 C5 P2 C2 P4 C4"
    },
    98: {
      name: "chronos.app.rectification.events.rules.98.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Neptune",
      nat: "K5 K2 K4"
    },
    99: {
      name: "chronos.app.rectification.events.rules.99.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Neptune",
      nat: "K5 K2 K4"
    },
    100: {
      name: "chronos.app.rectification.events.rules.100.name",
      asp: "0 60 120",
      dir: "K5 K2 K4",
      nat: "P5 C5 P2 C2 P4 C4 Sun Venus Jupiter Neptune"
    },
    101: {
      name: "chronos.app.rectification.events.rules.101.name",
      asp: "0 60 120",
      dir: "P5 C5 P2 C2 P3 C3 Sun Venus Mars Jupiter Uranus",
      nat: "P5 C5 P2 C2 P3 C3"
    },
    102: {
      name: "chronos.app.rectification.events.rules.102.name",
      asp: "30 36 72 108 144",
      dir: "P5 C5 P2 C2 P3 C3 Sun Venus Mars Jupiter Uranus",
      nat: "P5 C5 P2 C2 P3 C3"
    },
    103: {
      name: "chronos.app.rectification.events.rules.103.name",
      asp: "0 60 120",
      dir: "Sun Venus Mars Jupiter Uranus",
      nat: "K5 K2 K3"
    },
    104: {
      name: "chronos.app.rectification.events.rules.104.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Mars Jupiter Uranus",
      nat: "K5 K2 K3"
    },
    105: {
      name: "chronos.app.rectification.events.rules.105.name",
      asp: "0 60 120",
      dir: "K5 K2 K3",
      nat: "P5 C5 P2 C2 P3 C3 Sun Venus Mars Jupiter Uranus"
    },
    106: {
      name: "chronos.app.rectification.events.rules.106.name",
      asp: "0 60 120",
      dir: "P4 C4 P2 C2 P8 C8 Sun Venus Jupiter Neptune",
      nat: "P4 C4 P2 C2 P8 C8"
    },
    107: {
      name: "chronos.app.rectification.events.rules.107.name",
      asp: "30 36 72 108 144",
      dir: "P4 C4 P2 C2 P8 C8 Sun Venus Jupiter Neptune",
      nat: "P4 C4 P2 C2 P8 C8"
    },
    108: {
      name: "chronos.app.rectification.events.rules.108.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Neptune",
      nat: "K4 K2 K8"
    },
    109: {
      name: "chronos.app.rectification.events.rules.109.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Neptune",
      nat: "K4 K2 K8"
    },
    110: {
      name: "chronos.app.rectification.events.rules.110.name",
      asp: "0 60 120",
      dir: "K4 K2 K8",
      nat: "P4 C4 P2 C2 P8 C8 Sun Venus Jupiter Neptune"
    },
    111: {
      name: "chronos.app.rectification.events.rules.111.name",
      asp: "0 60 120",
      dir: "P1 C1 P10 C10 Sun Mars Jupiter Saturn Pluto",
      nat: "P1 C1 P10 C10"
    },
    112: {
      name: "chronos.app.rectification.events.rules.112.name",
      asp: "30 36 72 108 144",
      dir: "P1 C1 P10 C10 Sun Mars Jupiter Saturn Pluto",
      nat: "P1 C1 P10 C10"
    },
    113: {
      name: "chronos.app.rectification.events.rules.113.name",
      asp: "0 60 120",
      dir: "Sun Mars Jupiter Saturn Pluto",
      nat: "K1 K10"
    },
    114: {
      name: "chronos.app.rectification.events.rules.114.name",
      asp: "30 36 72 108 144",
      dir: "Sun Mars Jupiter Saturn Pluto",
      nat: "K1 K10"
    },
    115: {
      name: "chronos.app.rectification.events.rules.115.name",
      asp: "0 60 120",
      dir: "K1 K10",
      nat: "P1 C1 P10 C10 Sun Mars Jupiter Saturn Pluto"
    },
    116: {
      name: "chronos.app.rectification.events.rules.116.name",
      asp: "0 90 180",
      dir: "P2 C2 P8 C8 Mars Saturn Uranus Neptune Pluto",
      nat: "P2 C2 P8 C8"
    },
    117: {
      name: "chronos.app.rectification.events.rules.117.name",
      asp: "45 135 40 80 100",
      dir: "P2 C2 P8 C8 Mars Saturn Uranus Neptune Pluto",
      nat: "P2 C2 P8 C8"
    },
    118: {
      name: "chronos.app.rectification.events.rules.118.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K2 K8"
    },
    119: {
      name: "chronos.app.rectification.events.rules.119.name",
      asp: "45 135 40 80 100",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K2 K8"
    },
    120: {
      name: "chronos.app.rectification.events.rules.120.name",
      asp: "0 90 180",
      dir: "K2 K8",
      nat: "P2 C2 P8 C8 Mars Saturn Uranus Neptune Pluto"
    },
    121: {
      name: "chronos.app.rectification.events.rules.121.name",
      asp: "0 60 120",
      dir: "P4 C4 P3 C3 Sun Venus Jupiter Uranus",
      nat: "P4 C4 P3 C3"
    },
    122: {
      name: "chronos.app.rectification.events.rules.122.name",
      asp: "30 36 72 108 144",
      dir: "P4 C4 P3 C3 Sun Venus Jupiter Uranus",
      nat: "P4 C4 P3 C3"
    },
    123: {
      name: "chronos.app.rectification.events.rules.123.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Uranus",
      nat: "K4 K3"
    },
    124: {
      name: "chronos.app.rectification.events.rules.124.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Uranus",
      nat: "K4 K3"
    },
    125: {
      name: "chronos.app.rectification.events.rules.125.name",
      asp: "0 60 120",
      dir: "K4 K3",
      nat: "P4 C4 P3 C3 Sun Venus Jupiter Uranus"
    },
    126: {
      name: "chronos.app.rectification.events.rules.126.name",
      asp: "0 90 180",
      dir: "P4 C4 P3 C3 Saturn Uranus Pluto",
      nat: "P4 C4 P3 C3"
    },
    127: {
      name: "chronos.app.rectification.events.rules.127.name",
      asp: "45 135 40 80 100",
      dir: "P4 C4 P3 C3 Saturn Uranus Pluto",
      nat: "P4 C4 P3 C3"
    },
    128: {
      name: "chronos.app.rectification.events.rules.128.name",
      asp: "0 90 180",
      dir: "Saturn Uranus Pluto",
      nat: "K4 K3"
    },
    129: {
      name: "chronos.app.rectification.events.rules.129.name",
      asp: "45 135 40 80 100",
      dir: "Saturn Uranus Pluto",
      nat: "K4 K3"
    },
    130: {
      name: "chronos.app.rectification.events.rules.130.name",
      asp: "0 90 180 40 80 100",
      dir: "K4 K3",
      nat: "P4 C4 P3 C3 Saturn Uranus Pluto"
    },
    131: {
      name: "chronos.app.rectification.events.rules.131.name",
      asp: "0 60 120",
      dir: "P4 C4 P9 C9 Sun Venus Jupiter Uranus",
      nat: "P4 C4 P9 C9"
    },
    132: {
      name: "chronos.app.rectification.events.rules.132.name",
      asp: "30 36 72 108 144",
      dir: "P4 C4 P9 C9 Sun Venus Jupiter Uranus",
      nat: "P4 C4 P9 C9"
    },
    133: {
      name: "chronos.app.rectification.events.rules.133.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Uranus",
      nat: "K4 K9"
    },
    134: {
      name: "chronos.app.rectification.events.rules.134.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Uranus",
      nat: "K4 K9"
    },
    135: {
      name: "chronos.app.rectification.events.rules.135.name",
      asp: "0 60 120",
      dir: "K4 K9",
      nat: "P4 C4 P9 C9 Sun Venus Jupiter Uranus"
    },
    136: {
      name: "chronos.app.rectification.events.rules.136.name",
      asp: "0 90 180",
      dir: "P4 C4 P9 C9 Saturn Uranus Pluto",
      nat: "P4 C4 P9 C9"
    },
    137: {
      name: "chronos.app.rectification.events.rules.137.name",
      asp: "45 135 40 80 100",
      dir: "P4 C4 P9 C9 Saturn Uranus Pluto",
      nat: "P4 C4 P9 C9"
    },
    138: {
      name: "chronos.app.rectification.events.rules.138.name",
      asp: "0 90 180",
      dir: "Saturn Uranus Pluto",
      nat: "K4 K9"
    },
    139: {
      name: "chronos.app.rectification.events.rules.139.name",
      asp: "45 135 40 80 100",
      dir: "Saturn Uranus Pluto",
      nat: "K4 K9"
    },
    140: {
      name: "chronos.app.rectification.events.rules.140.name",
      asp: "0 90 180 40 80 100",
      dir: "K4 K9",
      nat: "P4 C4 P9 C9 Saturn Uranus Pluto"
    },
    141: {
      name: "chronos.app.rectification.events.rules.141.name",
      asp: "0 60 120",
      dir: "P4 C4 P12 C12 Sun Venus Jupiter Uranus",
      nat: "P4 C4 P12 C12"
    },
    142: {
      name: "chronos.app.rectification.events.rules.142.name",
      asp: "30 36 72 108 144",
      dir: "P4 C4 P12 C12 Sun Venus Jupiter Uranus",
      nat: "P4 C4 P12 C12"
    },
    143: {
      name: "chronos.app.rectification.events.rules.143.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Uranus",
      nat: "K4 K12"
    },
    144: {
      name: "chronos.app.rectification.events.rules.144.name",
      asp: "0 60 120",
      dir: "K4 K12",
      nat: "P4 C4 P12 C12 Sun Venus Jupiter Uranus"
    },
    145: {
      name: "chronos.app.rectification.events.rules.145.name",
      asp: "0 90 180",
      dir: "P4 C4 P12 C12 Saturn Uranus Pluto",
      nat: "P4 C4 P12 C12"
    },
    146: {
      name: "chronos.app.rectification.events.rules.146.name",
      asp: "45 135 40 80 100",
      dir: "P4 C4 P12 C12 Saturn Uranus Pluto",
      nat: "P4 C4 P12 C12"
    },
    147: {
      name: "chronos.app.rectification.events.rules.147.name",
      asp: "0 90 180",
      dir: "Saturn Uranus Pluto",
      nat: "K4 K12"
    },
    148: {
      name: "chronos.app.rectification.events.rules.148.name",
      asp: "0 90 180",
      dir: "K4 K12",
      nat: "P4 C4 P12 C12 Saturn Uranus Pluto"
    },
    149: {
      name: "chronos.app.rectification.events.rules.149.name",
      asp: "0 60 120",
      dir: "P5 C5 P3 C3 Sun Venus Jupiter Uranus Neptune",
      nat: "P5 C5 P3 C3"
    },
    150: {
      name: "chronos.app.rectification.events.rules.150.name",
      asp: "30 36 72 108 144",
      dir: "P5 C5 P3 C3 Sun Venus Jupiter Uranus Neptune",
      nat: "P5 C5 P3 C3"
    },
    151: {
      name: "chronos.app.rectification.events.rules.151.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Uranus Neptune",
      nat: "K5 K3"
    },
    152: {
      name: "chronos.app.rectification.events.rules.152.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Uranus Neptune",
      nat: "K5 K3"
    },
    153: {
      name: "chronos.app.rectification.events.rules.153.name",
      asp: "0 60 120",
      dir: "K5 K3",
      nat: "P5 C5 P3 C3 Sun Venus Jupiter Uranus Neptune"
    },
    154: {
      name: "chronos.app.rectification.events.rules.154.name",
      asp: "0 60 120",
      dir: "P5 C5 P9 C9 Sun Venus Jupiter Uranus Neptune",
      nat: "P5 C5 P9 C9"
    },
    155: {
      name: "chronos.app.rectification.events.rules.155.name",
      asp: "30 36 72 108 144",
      dir: "P5 C5 P9 C9 Sun Venus Jupiter Uranus Neptune",
      nat: "P5 C5 P9 C9"
    },
    156: {
      name: "chronos.app.rectification.events.rules.156.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Uranus Neptune",
      nat: "K5 K9"
    },
    157: {
      name: "chronos.app.rectification.events.rules.157.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Uranus Neptune",
      nat: "K5 K9"
    },
    158: {
      name: "chronos.app.rectification.events.rules.158.name",
      asp: "0 60 120",
      dir: "K5 K9",
      nat: "P5 C5 P9 C9 Sun Venus Jupiter Uranus Neptune"
    },
    159: {
      name: "chronos.app.rectification.events.rules.159.name",
      asp: "0 60 120",
      dir: "P5 C5 P11 C11 P7 C7 Sun Venus Mars Jupiter Neptune",
      nat: "P5 C5 P11 C11 P7 C7"
    },
    160: {
      name: "chronos.app.rectification.events.rules.160.name",
      asp: "30 36 72 108 144",
      dir: "P5 C5 P11 C11 P7 C7 Sun Venus Mars Jupiter Neptune",
      nat: "P5 C5 P11 C11 P7 C7"
    },
    161: {
      name: "chronos.app.rectification.events.rules.161.name",
      asp: "0 60 120",
      dir: "Sun Venus Mars Jupiter Neptune",
      nat: "K5 K11 K7"
    },
    162: {
      name: "chronos.app.rectification.events.rules.162.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Mars Jupiter Neptune",
      nat: "K5 K11 K7"
    },
    163: {
      name: "chronos.app.rectification.events.rules.163.name",
      asp: "0 60 120",
      dir: "K5 K11 K7",
      nat: "P5 C5 P11 C11 P7 C7 Sun Venus Mars Jupiter Neptune"
    },
    164: {
      name: "chronos.app.rectification.events.rules.164.name",
      asp: "0 60 120",
      dir: "P1 C1 P5 C5 P7 C7 Sun Moon Venus Jupiter Neptune",
      nat: "P1 C1 P5 C5 P7 C7"
    },
    165: {
      name: "chronos.app.rectification.events.rules.165.name",
      asp: "30 36 72 108 144",
      dir: "P1 C1 P5 C5 P7 C7 Sun Moon Venus Jupiter Neptune",
      nat: "P1 C1 P5 C5 P7 C7"
    },
    166: {
      name: "chronos.app.rectification.events.rules.166.name",
      asp: "0 60 120",
      dir: "Sun Moon Venus Jupiter Neptune",
      nat: "K1 K5 K7"
    },
    167: {
      name: "chronos.app.rectification.events.rules.167.name",
      asp: "30 36 72 108 144",
      dir: "Sun Moon Venus Jupiter Neptune",
      nat: "K1 K5 K7"
    },
    168: {
      name: "chronos.app.rectification.events.rules.168.name",
      asp: "0 60 120",
      dir: "K1 K5 K7",
      nat: "P1 C1 P5 C5 P7 C7 Sun Moon Venus Jupiter Neptune"
    },
    169: {
      name: "chronos.app.rectification.events.rules.169.name",
      asp: "0",
      dir: "Sun",
      nat: "K12"
    },
    170: {
      name: "chronos.app.rectification.events.rules.170.name",
      asp: "0 90 180",
      dir: "P12 C12 Mars Saturn Uranus Pluto",
      nat: "P1 C1 P12 C12"
    },
    171: {
      name: "chronos.app.rectification.events.rules.171.name",
      asp: "45 135 40",
      dir: "P12 C12 Mars Saturn Uranus Pluto",
      nat: "P1 C1 P12 C12"
    },
    172: {
      name: "chronos.app.rectification.events.rules.172.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K1 K12"
    },
    173: {
      name: "chronos.app.rectification.events.rules.173.name",
      asp: "45 135 40",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K1 K12"
    },
    174: {
      name: "chronos.app.rectification.events.rules.174.name",
      asp: "0 90 180 40",
      dir: "K12",
      nat: "P1 C1 P12 C12 Mars Saturn Uranus Pluto"
    },
    175: {
      name: "chronos.app.rectification.events.rules.175.name",
      asp: "0 90 180",
      dir: "P8 C8 P3 C3 P1 C1 Mars Saturn Uranus Neptune Pluto",
      nat: "P8 C8 P3 C3 P1 C1"
    },
    176: {
      name: "chronos.app.rectification.events.rules.176.name",
      asp: "45 135 40 80 100",
      dir: "P8 C8 P3 C3 P1 C1 Mars Saturn Uranus Neptune Pluto",
      nat: "P8 C8 P3 C3 P1 C1"
    },
    177: {
      name: "chronos.app.rectification.events.rules.177.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K8 K3 K1"
    },
    178: {
      name: "chronos.app.rectification.events.rules.178.name",
      asp: "45 135 40 80 100",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K8 K3 K1"
    },
    179: {
      name: "chronos.app.rectification.events.rules.179.name",
      asp: "0 90 180 40 80 100",
      dir: "K8 K3 K1",
      nat: "P8 C8 P3 C3 P1 C1 Mars Saturn Uranus Neptune Pluto"
    },
    180: {
      name: "chronos.app.rectification.events.rules.180.name",
      asp: "0 60 120",
      dir: "P1 C1 Sun Venus Jupiter Uranus Pluto",
      nat: "P1 C1"
    },
    181: {
      name: "chronos.app.rectification.events.rules.181.name",
      asp: "30 36 72 108 144",
      dir: "P1 C1 Sun Venus Jupiter Uranus Pluto",
      nat: "P1 C1"
    },
    182: {
      name: "chronos.app.rectification.events.rules.182.name",
      asp: "0 60 120",
      dir: "Sun Venus Jupiter Uranus Pluto",
      nat: "K1"
    },
    183: {
      name: "chronos.app.rectification.events.rules.183.name",
      asp: "30 36 72 108 144",
      dir: "Sun Venus Jupiter Uranus Pluto",
      nat: "K1"
    },
    184: {
      name: "chronos.app.rectification.events.rules.184.name",
      asp: "0 60 120",
      dir: "K1",
      nat: "P1 C1 Sun Venus Jupiter Uranus Pluto"
    },
    185: {
      name: "chronos.app.rectification.events.rules.185.name",
      asp: "45 135",
      dir: "P6 C6 P8 C8 P1 C1 Mars Saturn Uranus Neptune Pluto",
      nat: "P6 C6 P8 C8 P1 C1"
    },
    186: {
      name: "chronos.app.rectification.events.rules.186.name",
      asp: "45 135",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K6 K8 K1"
    },
    187: {
      name: "chronos.app.rectification.events.rules.187.name",
      asp: "0 90 180",
      dir: "P6 C6 P8 C8 P12 C12 P1 C1 Mars Saturn Uranus Neptune Pluto",
      nat: "P6 C6 P8 C8 P12 C12 P1 C1"
    },
    188: {
      name: "chronos.app.rectification.events.rules.188.name",
      asp: "45 135",
      dir: "P6 C6 P8 C8 P12 C12 P1 C1 Mars Saturn Uranus Neptune Pluto",
      nat: "P6 C6 P8 C8 P12 C12 P1 C1"
    },
    189: {
      name: "chronos.app.rectification.events.rules.189.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K6 K8 K12 K1"
    },
    190: {
      name: "chronos.app.rectification.events.rules.190.name",
      asp: "45 135",
      dir: "Mars Saturn Uranus Neptune Pluto",
      nat: "K6 K8 K12 K1"
    },
    191: {
      name: "chronos.app.rectification.events.rules.191.name",
      asp: "0 90 180",
      dir: "K6 K8 K12 K1",
      nat: "P6 C6 P8 C8 P12 C12 P1 C1 Mars Saturn Uranus Neptune Pluto"
    },
    192: {
      name: "chronos.app.rectification.events.rules.192.name",
      asp: "0 90 180",
      dir: "P8 C8 P4 C4 P10 C10 P1 C1 P7 C7 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P4 C4 P10 C10 P1 C1 P7 C7"
    },
    193: {
      name: "chronos.app.rectification.events.rules.193.name",
      asp: "45 135 40 80 100",
      dir: "P8 C8 P4 C4 P10 C10 P1 C1 P7 C7 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P4 C4 P10 C10 P1 C1 P7 C7"
    },
    194: {
      name: "chronos.app.rectification.events.rules.194.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K4 K10 K1 K7"
    },
    195: {
      name: "chronos.app.rectification.events.rules.195.name",
      asp: "45 135 40 80 100",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K4 K10 K1 K7"
    },
    196: {
      name: "chronos.app.rectification.events.rules.196.name",
      asp: "0 90 180",
      dir: "K8 K4 K10 K1 K7",
      nat: "P8 C8 P4 C4 P10 C10 P1 C1 P7 C7 Mars Saturn Uranus Pluto"
    },
    197: {
      name: "chronos.app.rectification.events.rules.197.name",
      asp: "0 90 180",
      dir: "P8 C8 P3 C3 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P3 C3"
    },
    198: {
      name: "chronos.app.rectification.events.rules.198.name",
      asp: "45 135 40 80 100",
      dir: "P8 C8 P3 C3 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P3 C3"
    },
    199: {
      name: "chronos.app.rectification.events.rules.199.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K3"
    },
    200: {
      name: "chronos.app.rectification.events.rules.200.name",
      asp: "45 135 40 80 100",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K3"
    },
    201: {
      name: "chronos.app.rectification.events.rules.201.name",
      asp: "0 90 180",
      dir: "K8 K3",
      nat: "P8 C8 P3 C3 Mars Saturn Uranus Pluto"
    },
    202: {
      name: "chronos.app.rectification.events.rules.202.name",
      asp: "0 90 180",
      dir: "P8 C8 P5 C5 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P5 C5"
    },
    203: {
      name: "chronos.app.rectification.events.rules.203.name",
      asp: "45 135 40",
      dir: "P8 C8 P5 C5 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P5 C5"
    },
    204: {
      name: "chronos.app.rectification.events.rules.204.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K5"
    },
    205: {
      name: "chronos.app.rectification.events.rules.205.name",
      asp: "45 135 40",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K5"
    },
    206: {
      name: "chronos.app.rectification.events.rules.206.name",
      asp: "0 90 180",
      dir: "K8 K5",
      nat: "P8 C8 P5 C5 Mars Saturn Uranus Pluto"
    },
    207: {
      name: "chronos.app.rectification.events.rules.207.name",
      asp: "0 90 180",
      dir: "P8 C8 P7 C7 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P7 C7"
    },
    208: {
      name: "chronos.app.rectification.events.rules.208.name",
      asp: "45 135 40",
      dir: "P8 C8 P7 C7 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P7 C7"
    },
    209: {
      name: "chronos.app.rectification.events.rules.209.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K7"
    },
    210: {
      name: "chronos.app.rectification.events.rules.210.name",
      asp: "45 135 40",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K7"
    },
    211: {
      name: "chronos.app.rectification.events.rules.211.name",
      asp: "0 90 180",
      dir: "K8 K7",
      nat: "P8 C8 P7 C7 Mars Saturn Uranus Pluto"
    },
    212: {
      name: "chronos.app.rectification.events.rules.212.name",
      asp: "0 90 180",
      dir: "P8 C8 P6 C6 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P6 C6"
    },
    213: {
      name: "chronos.app.rectification.events.rules.213.name",
      asp: "45 135",
      dir: "P8 C8 P6 C6 Mars Saturn Uranus Pluto",
      nat: "P8 C8 P6 C6"
    },
    214: {
      name: "chronos.app.rectification.events.rules.214.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K6"
    },
    215: {
      name: "chronos.app.rectification.events.rules.215.name",
      asp: "45 135",
      dir: "Mars Saturn Uranus Pluto",
      nat: "K8 K6"
    },
    216: {
      name: "chronos.app.rectification.events.rules.216.name",
      asp: "0 90 180",
      dir: "K8 K6",
      nat: "P8 C8 P6 C6 Mars Saturn Uranus Pluto"
    },
    217: {
      name: "chronos.app.rectification.events.rules.217.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "Saturn &Saturn",
      nat: "K1 K4",
      school: 1
    },
    218: {
      name: "chronos.app.rectification.events.rules.218.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "Venus P7 C7 &Venus &C7",
      nat: "K1 K4",
      school: 1
    },
    219: {
      name: "chronos.app.rectification.events.rules.219.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "K1 K4",
      nat: "Venus P7 C7 &Venus &C7",
      school: 1
    },
    220: {
      name: "chronos.app.rectification.events.rules.220.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "Sun Moon P5 C5 &C5 &Sun &Moon",
      nat: "K5 K1 K4",
      school: 1
    },
    221: {
      name: "chronos.app.rectification.events.rules.221.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "K5 K1 K4",
      nat: "Sun Moon P5 C5 &C5 &Sun &Moon",
      school: 1
    },
    222: {
      name: "chronos.app.rectification.events.rules.222.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "Mars Pluto P8 C8 &C8 &Mars &Pluto",
      nat: "K8",
      school: 1
    },
    223: {
      name: "chronos.app.rectification.events.rules.223.name",
      asp: "0 90 180 60 120",
      dir: "Mars Pluto P8 C8 &C8 &Mars &Pluto",
      nat: "K4",
      school: 1
    },
    224: {
      name: "chronos.app.rectification.events.rules.224.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "K8",
      nat: "Mars Pluto P8 C8 &C8 &Mars &Pluto",
      school: 1
    },
    225: {
      name: "chronos.app.rectification.events.rules.225.name",
      asp: "0 90 180 60 120",
      dir: "K4",
      nat: "Mars Pluto P8 C8 &C8 &Mars &Pluto",
      school: 1
    },
    226: {
      name: "chronos.app.rectification.events.rules.226.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "Mars Pluto Neptune P8 P12 P6 C8 C12 C6 &C8 &C12 &C6 &Mars &Pluto",
      nat: "K1 K6 K8",
      school: 1
    },
    227: {
      name: "chronos.app.rectification.events.rules.227.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "K1 K6 K8",
      nat: "Mars Pluto Neptune P8 P12 P6 C8 C12 C6 &C8 &C12 &C6 &Mars &Pluto",
      school: 1
    },
    228: {
      name: "chronos.app.rectification.events.rules.228.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "Mars Pluto Neptune P8 P12 C8 C12 &C8 &C12 &Mars &Pluto",
      nat: "K2 K12",
      school: 1
    },
    229: {
      name: "chronos.app.rectification.events.rules.229.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "K2 K12",
      nat: "Mars Pluto Neptune P8 P12 C8 C12 &C8 &C12 &Mars &Pluto",
      school: 1
    },
    230: {
      name: "chronos.app.rectification.events.rules.230.name",
      asp: "0 90 180",
      dir: "P8 C8 P6 C6 P1 C1 Mars Saturn Uranus Pluto Neptune",
      nat: "P8 C8 P6 C6 P1 C1"
    },
    231: {
      name: "chronos.app.rectification.events.rules.231.name",
      asp: "0 90 180",
      dir: "Mars Saturn Uranus Pluto Neptune",
      nat: "K6 K8 K1"
    },
    232: {
      name: "chronos.app.rectification.events.rules.232.name",
      asp: "0 90 180",
      dir: "K6 K8 K1",
      nat: "P8 C8 P6 C6 P1 C1 Mars Saturn Uranus Pluto Neptune"
    },
    233: {
      name: "chronos.app.rectification.events.rules.233.name",
      asp: "0 90 180 60 120 30 150 40 80 100 36 72 108 144 45 135",
      dir: "K1 K4",
      nat: "Saturn &Saturn",
      school: 1
    },
    234: {
      name: "chronos.app.rectification.events.rules.234.name",
      asp: "0",
      dir: "SouthNode NorthNode &SouthNode &NorthNode",
      nat: "K1 K4",
      school: 1
    },
    235: {
      name: "chronos.app.rectification.events.rules.235.name",
      asp: "0",
      dir: "K1 K4",
      nat: "SouthNode NorthNode &SouthNode &NorthNode",
      school: 1
    }
  },
  events: {
    1: {
      name: "chronos.app.rectification.events.events.1.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.1.synonims",
      rules: [
        1,
        2,
        3,
        4,
        5,
        6
      ]
    },
    2: {
      name: "chronos.app.rectification.events.events.2.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.2.synonims",
      rules: [
        1,
        7,
        8,
        9,
        10,
        11
      ]
    },
    3: {
      name: "chronos.app.rectification.events.events.3.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.3.synonims",
      rules: [
        12,
        13,
        14,
        15,
        16
      ]
    },
    4: {
      name: "chronos.app.rectification.events.events.4.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.4.synonims",
      rules: [
        17,
        18,
        19,
        20,
        21
      ]
    },
    5: {
      name: "chronos.app.rectification.events.events.5.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.5.synonims",
      rules: [
        22,
        23,
        24,
        25,
        26
      ]
    },
    6: {
      name: "chronos.app.rectification.events.events.6.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.6.synonims",
      rules: [
        27,
        28,
        29,
        30,
        31
      ]
    },
    7: {
      name: "chronos.app.rectification.events.events.7.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.7.synonims",
      rules: [
        32,
        33,
        34,
        35,
        36
      ]
    },
    8: {
      name: "chronos.app.rectification.events.events.8.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.8.synonims",
      rules: [
        37,
        38,
        39,
        40,
        41
      ]
    },
    9: {
      name: "chronos.app.rectification.events.events.9.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.9.synonims",
      rules: [
        42,
        43,
        44,
        45
      ]
    },
    10: {
      name: "chronos.app.rectification.events.events.10.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.10.synonims",
      rules: [
        46,
        47,
        48,
        49,
        50
      ]
    },
    11: {
      name: "chronos.app.rectification.events.events.11.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.11.synonims",
      rules: [
        51,
        52,
        53,
        54,
        55
      ]
    },
    12: {
      name: "chronos.app.rectification.events.events.12.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.12.synonims",
      rules: [
        56,
        57,
        58,
        59,
        60
      ]
    },
    13: {
      name: "chronos.app.rectification.events.events.13.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.13.synonims",
      rules: [
        61,
        62,
        63,
        64,
        65
      ]
    },
    14: {
      name: "chronos.app.rectification.events.events.14.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.14.synonims",
      rules: [
        66,
        67,
        68,
        69,
        70
      ]
    },
    15: {
      name: "chronos.app.rectification.events.events.15.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.15.synonims",
      rules: [
        71,
        72,
        73,
        74,
        75
      ]
    },
    16: {
      name: "chronos.app.rectification.events.events.16.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.16.synonims",
      rules: [
        76,
        77,
        78,
        79,
        80
      ]
    },
    17: {
      name: "chronos.app.rectification.events.events.17.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.17.synonims",
      rules: [
        81,
        82,
        83,
        84,
        85
      ]
    },
    18: {
      name: "chronos.app.rectification.events.events.18.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.18.synonims",
      rules: [
        86,
        87,
        88,
        89,
        90
      ]
    },
    19: {
      name: "chronos.app.rectification.events.events.19.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.19.synonims",
      rules: [
        91,
        92,
        93,
        94,
        95
      ]
    },
    20: {
      name: "chronos.app.rectification.events.events.20.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.20.synonims",
      rules: [
        96,
        97,
        98,
        99,
        100
      ]
    },
    21: {
      name: "chronos.app.rectification.events.events.21.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.21.synonims",
      rules: [
        101,
        102,
        103,
        104,
        105
      ]
    },
    22: {
      name: "chronos.app.rectification.events.events.22.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.22.synonims",
      rules: [
        106,
        107,
        108,
        109,
        110
      ]
    },
    23: {
      name: "chronos.app.rectification.events.events.23.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.23.synonims",
      rules: [
        111,
        112,
        113,
        114,
        115
      ]
    },
    24: {
      name: "chronos.app.rectification.events.events.24.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.24.synonims",
      rules: [
        116,
        117,
        118,
        119,
        120
      ]
    },
    25: {
      name: "chronos.app.rectification.events.events.25.name",
      weight: 2,
      synonims: "chronos.app.rectification.events.events.25.synonims",
      rules: [
        121,
        122,
        123,
        124,
        125
      ]
    },
    26: {
      name: "chronos.app.rectification.events.events.26.name",
      weight: 2,
      synonims: "chronos.app.rectification.events.events.26.synonims",
      rules: [
        126,
        127,
        128,
        129,
        130
      ]
    },
    27: {
      name: "chronos.app.rectification.events.events.27.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.27.synonims",
      rules: [
        131,
        132,
        133,
        134,
        135
      ]
    },
    28: {
      name: "chronos.app.rectification.events.events.28.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.28.synonims",
      rules: [
        136,
        137,
        138,
        139,
        140
      ]
    },
    29: {
      name: "chronos.app.rectification.events.events.29.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.29.synonims",
      rules: [
        141,
        142,
        143,
        144
      ]
    },
    30: {
      name: "chronos.app.rectification.events.events.30.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.30.synonims",
      rules: [
        145,
        146,
        147,
        148
      ]
    },
    31: {
      name: "chronos.app.rectification.events.events.31.name",
      weight: 2,
      synonims: "chronos.app.rectification.events.events.31.synonims",
      rules: [
        149,
        150,
        151,
        152,
        153
      ]
    },
    32: {
      name: "chronos.app.rectification.events.events.32.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.32.synonims",
      rules: [
        154,
        155,
        156,
        157,
        158
      ]
    },
    33: {
      name: "chronos.app.rectification.events.events.33.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.33.synonims",
      rules: [
        159,
        160,
        161,
        162,
        163
      ]
    },
    34: {
      name: "chronos.app.rectification.events.events.34.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.34.synonims",
      rules: [
        164,
        165,
        166,
        167,
        168
      ]
    },
    35: {
      name: "chronos.app.rectification.events.events.35.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.35.synonims",
      rules: [
        169,
        170,
        171,
        172,
        173,
        174
      ]
    },
    36: {
      name: "chronos.app.rectification.events.events.36.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.36.synonims",
      rules: [
        175,
        176,
        177,
        178,
        179
      ]
    },
    37: {
      name: "chronos.app.rectification.events.events.37.name",
      weight: 1,
      synonims: "chronos.app.rectification.events.events.37.synonims",
      rules: [
        180,
        181,
        182,
        183,
        184
      ]
    },
    38: {
      name: "chronos.app.rectification.events.events.38.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.38.synonims",
      rules: [
        230,
        185,
        231,
        186,
        232
      ]
    },
    39: {
      name: "chronos.app.rectification.events.events.39.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.39.synonims",
      rules: [
        187,
        188,
        189,
        190,
        191
      ]
    },
    40: {
      name: "chronos.app.rectification.events.events.40.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.40.synonims",
      rules: [
        192,
        193,
        194,
        195,
        196
      ],
      isDeath: true
    },
    41: {
      name: "chronos.app.rectification.events.events.41.name",
      weight: 4,
      synonims: "chronos.app.rectification.events.events.41.synonims",
      rules: [
        197,
        198,
        199,
        200,
        201
      ],
      isDeath: true
    },
    42: {
      name: "chronos.app.rectification.events.events.42.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.42.synonims",
      rules: [
        202,
        203,
        204,
        205,
        206
      ],
      isDeath: true
    },
    43: {
      name: "chronos.app.rectification.events.events.43.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.43.synonims",
      rules: [
        207,
        208,
        209,
        210,
        211
      ],
      isDeath: true
    },
    44: {
      name: "chronos.app.rectification.events.events.44.name",
      weight: 2,
      synonims: "chronos.app.rectification.events.events.44.synonims",
      rules: [
        212,
        213,
        214,
        215,
        216
      ],
      isDeath: true
    },
    45: {
      name: "chronos.app.rectification.events.events.45.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.45.synonims",
      rules: [
        217,
        233,
        234,
        235,
        218,
        219
      ],
      school: 1
    },
    46: {
      name: "chronos.app.rectification.events.events.46.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.46.synonims",
      rules: [
        220,
        221
      ],
      school: 1
    },
    47: {
      name: "chronos.app.rectification.events.events.47.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.47.synonims",
      rules: [
        222,
        223,
        224,
        225
      ],
      school: 1,
      isDeath: true
    },
    48: {
      name: "chronos.app.rectification.events.events.48.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.39.synonims",
      rules: [
        226,
        227
      ],
      school: 1
    },
    49: {
      name: "chronos.app.rectification.events.events.49.name",
      weight: 3,
      synonims: "chronos.app.rectification.events.events.49.synonims",
      rules: [
        228,
        229
      ],
      school: 1
    },
    50: {
      name: "chronos.app.rectification.events.events.50.name",
      weight: 5,
      synonims: "chronos.app.rectification.events.events.50.synonims",
      rules: [
        222,
        223,
        224,
        225
      ],
      school: 1,
      isDeath: true
    }
  }
};
