import React from 'react';
import styled, { css } from 'styled-components';

import { ChronosIcon } from 'src/assets/icons/profiles/index';
import { PAIcon } from 'src/assets/icons/profiles/index';
import { t } from 'i18next';

export const schools = {
  0: {
    icon: ChronosIcon,
    title: t("chronos.app.instruments.widgets.schoolSelector.standart")
  },
  1: {
    icon: PAIcon,
    title: t("chronos.app.instruments.widgets.schoolSelector.paa")
  }
} as {
  [key: number]: {
    icon: any;
    title: string;
  };
};

export default function SchoolSelector(props: {
  school: number;
  onSelect(id: number): void;
}) {
  const listRef = React.useRef<HTMLDivElement>(null);
  const [showList, setShowList] = React.useState(false);
  const Icon = schools[props.school].icon;

  const onSelect = (ev: any, id: number) => {
    ev.stopPropagation();
    setShowList(false);
    props.onSelect(id);
  };

  React.useEffect(() => {
    const onClick = (ev: any) => {
      if (showList && !listRef.current?.contains(ev.target)) {
        setShowList(false);
      }
    };

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  });

  return (
    <Container onClick={() => setShowList(true)}>
      <Icon style={{
        width: '24px',
        marginLeft: 0
      }} />
      <Arrow />
      {showList &&
        <ListContainer ref={listRef}>
          <List style={{ margin: 0, padding: 0 }}>
            {Object
              .entries(schools)
              .map(([id, s]) => {
                const Icon = s.icon;
                const _id = parseInt(id);
                return (
                  <ListItem
                    key={`school_${id}`} style={{ margin: 0 }}
                    isActive={props.school == _id}
                    onClick={ev => onSelect(ev, _id)}
                  >
                    <Icon style={{ marginLeft: 0, width: '24px' }} />
                    <span>{s.title}</span>
                  </ListItem>
                );
              })
            }
          </List>
        </ListContainer>
      }
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0;
`;

const Arrow = styled.div`
  margin-top: -0.35em;
  margin-left: 0.75em;
  border-right: 2px solid var(--icon-third);
  border-bottom: 2px solid var(--icon-third);
  width: 0.5em;
  height: 0.5em;
  transform: rotate(45deg);
`;

const ListContainer = styled.div`
  position: absolute;
  padding: 0.5em;
  border-radius: 0.5em;
  top: calc(100% + 1em);
  right: 0;
  z-index: 1;
  background-color: var(--notifications-background);
  border: 1px solid var(--rectification-block2-border);
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  color: var(--rectification-block2-color);

  border-radius: 0.5em;
  padding: 0.5em;
  margin: 0;

  & span {
    margin-left: 1em;
  }

  ${props => props.isActive && css`
    background: var(--rectification-block2-background-hover);
  `}
`;
