import React, { FormEvent, useContext } from 'react';
import styled from 'styled-components';

import { NotificationContext } from 'src/store/context/notifications';
import { useSelector } from 'src/store/utils';
import { getUserTariffId } from 'src/store/reducers/payments/selectors';
import { useTranslation } from 'src/i18n/useTranslation';
import api from 'src/api';

export default function Promocode({
}: {
}) {
  const context = useContext(NotificationContext);
  const userTariffId = useSelector(getUserTariffId)
  
  const [promocode, setPromoCode] = React.useState<string>('')
  const [codeApplied, setCodeApplied] = React.useState<boolean>(false)
  const [success, setSuccess] = React.useState<boolean>(false)
  const [error, setError] = React.useState<boolean>(false)
  const { t } = useTranslation();
  const disabled = promocode.length < 6;
  
  const checkCode = async () => {
    /*store.promo.usePromocode(promocode, true)
      .then(() => {
        setError(false);
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
      })*/
  }
  
  const changeCode = (value: string) => {
    setPromoCode(value.trim())
    setError(false)
    setSuccess(false)
    setCodeApplied(false)
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    use(promocode);
  }
  
  const use = (code: string | number) => {
    if(disabled) return;

    api.usePromocode((code as string))
      .then((useResult: any) => {

        context?.add(t("chronos.mobile.settings.payments.promocodeApplied"), 'success');        
        setTimeout(() => {window.location.reload()}, 2000)

      })
      .catch((err: any) => {
        const { message: text = 'unknown', type = 'warning' } = HTTP_ERRORS_DESCRIPTION[err._errors[0]];
        context?.add(t(text), 'error');
      })
      
  }
  
  return <Container onSubmit={onSubmit}>
    <input placeholder={t("chronos.app.components.promoCodeInput.inputPromo")} value={promocode} onChange={(v) => changeCode(v.target.value)} />
    <ApplyButton disabled={disabled}>{t("chronos.app.components.promoCodeInput.apply")}</ApplyButton>
  </Container>

  /*return (
    <Container success={success} error={error} applied={codeApplied}>
      <Status error={error} applied={false}><CloseIcon /></Status>
      <Status success={success} applied={codeApplied}><SuccessIcon /></Status>
      <Input className="input" placeholder="Введите промокод" onChange={changeCode} value={promocode}/>
      {!codeApplied && !error && !success && <Button size="small"  onClick={checkCode}>Применить</Button>}
    </Container>
  )*/
};

const Container = styled.form`
  background-color: var(--input-background);
  border: 1px solid var(--border-lines);
  border-radius: 6px;
  padding: 0.4375rem;
  color: var(--text-secondary);
  line-height: 1.1875rem !important;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 0.75rem;
  justify-content: space-between;
  margin-top: 1rem;

  input {
    font-size: 1rem !important;
  }
`

const ApplyButton = styled.button`
  padding: 0.4375rem 0.5625rem;
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.875rem;

  color: var(--text-primary);
  background-color: var(--color-blue);
  &:disabled {
  color: var(--text-third);
    background-color: var(--bg-dashboard-cell);
  }

`

export const HTTP_ERRORS_DESCRIPTION: {[key: string]: {message: string; type: any}} = {
  'PROMO_NOT_FOUND': {
    message: "chronos.app.config.promo.notFound",
    type: 'error'
  },
  'PROMO_ALREADY_USED': {
    message: "chronos.app.config.promo.alreadyUsed",
    type: 'warning'
  },
  'PROMO_HAVE_ALREADY_USED': {
    message: "chronos.app.config.promo.alreadyUsed",
    type: 'warning'
  },
  'PROMO_EXPIRED': {
    message: "chronos.app.config.promo.expired",
    type: 'warning'
  },
  'PROMO_HAVE_DISCOUNT_ALREADY': {
    message: "chronos.app.config.promo.discountAlready",
    type: 'warning'
  },
  'PROMO_NOT_IMPLEMENTED': {
    message: "chronos.app.config.promo.notImplemented",
    type: 'error'
  },
  'PROMO_INCORRECT': {
    message: "chronos.app.config.promo.incorrect",
    type: 'error'
  },
  'PROMO_BAD_DATA': {
    message: "chronos.app.config.promo.badData",
    type: 'error'
  },
  'PROMO_FOR_DOWN': {
    message: "chronos.app.config.promo.forDown",
    type: 'warning'
  },
  'PROMO_FOR_CURRENT_TARIFF': {
    message: "chronos.app.config.promo.forCurrentTariff",
    type: 'error'
  }
};