import styled from "styled-components"

interface DividingLineProps {
	className?: string
}

export const DividingLine = (props: DividingLineProps) => {

	return (
		<Container className={props.className ?? ''}>
		</Container>
	)
}

const Container = styled.span`
	width: 100%;
	height: 2px;
	margin: 0.5rem 0;
	background-color: var(--input-border);
`
