import api, { IFolder } from "src/api";
import { Dispatch } from "redux";
import { signOut } from "../profile/actions";
import { IActionFoldersGetLoading, IActionFoldersGetSuccess } from "./types";

export const FOLDERS_GET_SUCCESS = 'FOLDERS_GET_SUCCESS';
export const FOLDERS_GET_LOADING = 'FOLDERS_GET_LOADING';

export const getFolders = (loading: boolean = true) => {
  return (dispatch: Dispatch) => {
      loading && dispatch(getLoading());
      api.folders()
          .then((data: IFolder[]) => { dispatch(getSuccess(data)) })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const addFolder = (title: string, callback: () => void) => {
  return (dispatch: Dispatch) => {
      api.addFolder({ title, color: '#5E5E5E', is_pinned: false } as IFolder)
          .then(() => { callback && callback(); })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}



export const updateFolder = (item: IFolder, callback: () => void) => {
  return (dispatch: Dispatch) => {
      const { id, is_pinned, color, title } = item;
      api.updateFolder(id, {is_pinned, color, title} as IFolder)
          .then(() => { callback && callback(); })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const deleteFolder = (id: number) => {
  return (dispatch: Dispatch) => {
      dispatch(getLoading());
      api.deleteFolder(id)
          .then((data: unknown) => { dispatch(getSuccess(data as IFolder[])) })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

const getLoading = (): IActionFoldersGetLoading => {
  return {
    type: FOLDERS_GET_LOADING
  }
}

const getSuccess = (data: IFolder[]): IActionFoldersGetSuccess => {
  return {
    type: FOLDERS_GET_SUCCESS,
    payload: data
  }
}