import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'src/i18n/useTranslation';
import { ITransaction, toDateTime } from 'src/api';
import { useCurrency } from 'src/hooks';
import { getCurrencySymbol } from 'src/utils';

export default function PaymentHistoryItem({
  data
}:{
  data: ITransaction
}) {
  
  let name = data.text;
  if(!name) {
    if(data.type === 'formation') name = "base.building";
  }

  let date = '';

  try {
    date = toDateTime(data.date).date;
  } catch {
  }
  

  let sum = data.sum;
  const currency = getCurrencySymbol(useCurrency().currencyCode);
  const { t, l } = useTranslation();
  return <Container>
    <CircleNameGrid>
      <span><Circle color="var(--color-blue)" /></span>
      <Title>
        {l(name)}
        <span>{date}</span>
      </Title>
    </CircleNameGrid>
    <Card>
      {/*<MastercardIcon />
      *3124*/}
    </Card>
    <Summary>
      {sum} {currency}
    </Summary>
  </Container>
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 56% 1fr auto;
  grid-column-gap: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  padding: 0.5rem 0px;
`
const CircleNameGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5rem;
`
const Circle = styled.div<{ color: string }>`
  display: inline-block;
  background-color: ${p => p.color};
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
`
const Title = styled.div`
  > span {    
    color: var(--text-third);
    display: block;
  }
`
const Card = styled.div`
  color: var(--text-secondary);
  > svg {
    display: inline-block;
    vertical-align: middle;
  }
`
const Summary = styled.div`
  
`
