import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components';
import { allAspects, IOrbises } from 'src/libs';
import Cell from './Cell';

export interface IMenu {
  aspType: number
  objType: number
}

export const emptyMenu = {
  aspType: -1,
  objType: -1
};

export default function MapDetailSwitcher(props: {
  orbises: IOrbises;
  onChange(data: IOrbises): void;
}) {
  const [menu, setMenu] = useState<IMenu>(emptyMenu);
  const hHeaders: any[] = [];
  const tableRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const onClick = (event: any) => !tableRef?.current?.contains(event.target) && setMenu(emptyMenu)

    window.addEventListener('click', onClick)

    return () => {
      window.addEventListener('click', onClick)
    }
  }, [])

  for (let i = 0; i < 13; i++) {
    const cell = <Cell key={`headers_cell_${i}`}
                       aspType={-1}
                       objType={i}
                       orbises={props.orbises}
                       onChange={data => props.onChange(data)}
                       setMenu={() => setMenu(emptyMenu)}/>

    hHeaders.push(cell);
  }

  return (
    <Container ref={tableRef}>

      <section className="table">
        <div className="row header">
          <div className="cell empty" onClick={() => setMenu(emptyMenu)}></div>

          {hHeaders}

          <Cell aspType={-1}
                objType={13}
                orbises={props.orbises}
                onChange={data => props.onChange(data)}/>

        </div>

        {allAspects.map(aspType =>
          <div
            key={`row_${aspType}`}
            className="row"
          >
            <Cell aspType={aspType}
                  objType={-1}
                  orbises={props.orbises}
                  onChange={data => props.onChange(data)}
                  setMenu={() => setMenu(emptyMenu)}/>

            {hHeaders.map((_, i) => <Cell key={`cell_${i}`}
                                          aspType={aspType}
                                          objType={i}
                                          orbises={props.orbises}
                                          onChange={data => props.onChange(data)}
                                          menu={menu}
                                          setMenu={(menu: IMenu) => setMenu(menu)}/>)}

            <Cell aspType={aspType}
                  objType={13}
                  orbises={props.orbises}
                  onChange={data => props.onChange(data)}
                  menu={menu}
                  setMenu={(menu: IMenu) => setMenu(menu)}/>

          </div>
        )}
      </section>
    </Container>
  );
}


const Container = styled.div`
  width: 100%;
  color: var(--text-primary);
  font-weight: 600;
  overflow-x: scroll;

  & .table {
    display: block;
    width: auto;
  }

  & .row {
    white-space: nowrap;

    &.header .cell{
      border-bottom: 1px solid var(--astro-table-border);
    }

    .cell:first-child {
      border-right: 1px solid var(--astro-table-border);
    }

    .cell:last-child {
      border-right: none;
    }

    &.major .cell{
      color: var(--astro-table-object-major);
      border-bottom: 3px solid var(--astro-table-object-major);
    }

    &.disabled .cell{
      color: var(--text-third);
    }

    input[type=text] {
      height: 2.45em;
      border: none;
      text-align: center;
      color: inherit;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  & .row:last-child .cell:not(.disabled):not(.major) {
    border-bottom: none;
  }

  & .cell {
    display: inline-flex;
    box-sizing: border-box;
    width: 3.5rem;
    height: 2.5rem;
    vertical-align: top;
    border-right: 1px solid var(--astro-table-border-light);
    border-bottom: 1px solid var(--astro-table-border-light);
  }

`;
