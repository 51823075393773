import { AspectType, ObjectType, SignType } from 'src/libs';

import { 
  SunIcon,
  MoonIcon,
  MercuryIcon,
  VenusIcon,
  MarsIcon,
  JupiterIcon,
  SaturnIcon,
  UranusIcon,
  NeptuneIcon,
  PlutoIcon,
  NorthNodeIcon,
  SouthNodeIcon,
  LilithIcon,
  ChironIcon,
  SelenaIcon,
  ErisIcon
} from 'src/assets/icons/astro/objects/index';

import { 
  AriesIcon,
  TaurusIcon,
  GeminiIcon,
  CancerIcon,
  LeoIcon,
  VirgoIcon,
  LibraIcon,
  ScorpioIcon,
  SagittariusIcon,
  CapricornIcon,
  AquariusIcon,
  PiscesIcon
} from 'src/assets/icons/astro/signs/index';


import { ConjunctionIcon } from 'src/assets/icons/astro/aspects/index';
import { SemisextileIcon } from 'src/assets/icons/astro/aspects/index';
import { SemiquintileIcon } from 'src/assets/icons/astro/aspects/index';
import { NovileIcon } from 'src/assets/icons/astro/aspects/index';
import { SemisquareIcon } from 'src/assets/icons/astro/aspects/index';
import { SextileIcon } from 'src/assets/icons/astro/aspects/index';
import { QuintileIcon } from 'src/assets/icons/astro/aspects/index';
import { BinovileIcon } from 'src/assets/icons/astro/aspects/index';
import { SquareIcon } from 'src/assets/icons/astro/aspects/index';
import { MinusOrPlusIcon } from 'src/assets/icons/astro/aspects/index';
import { TredecileIcon } from 'src/assets/icons/astro/aspects/index';
import { TrineIcon } from 'src/assets/icons/astro/aspects/index';
import { SesquiquadrateIcon } from 'src/assets/icons/astro/aspects/index';
import { BiquintileIcon } from 'src/assets/icons/astro/aspects/index';
import { QuincunxIcon } from 'src/assets/icons/astro/aspects/index';
import { OppositionIcon } from 'src/assets/icons/astro/aspects/index';


import { ReactNode, SVGProps } from 'react';

export const objectsIcons: {
  [key: number]: React.FC<SVGProps<SVGSVGElement>>;
} = {
  [ObjectType.Sun]: SunIcon,
  [ObjectType.Moon]: MoonIcon ,
  [ObjectType.Mercury]: MercuryIcon ,
  [ObjectType.Venus]: VenusIcon ,
  [ObjectType.Mars]: MarsIcon ,
  [ObjectType.Jupiter]: JupiterIcon ,
  [ObjectType.Saturn]: SaturnIcon ,
  [ObjectType.Uranus]: UranusIcon ,
  [ObjectType.Neptune]: NeptuneIcon ,
  [ObjectType.Pluto]: PlutoIcon ,
  [ObjectType.NorthNode]: NorthNodeIcon ,
  [ObjectType.SouthNode]: SouthNodeIcon ,
  [ObjectType.Lilith]: LilithIcon ,
  [ObjectType.Chiron]: ChironIcon ,
  [ObjectType.Selena]: SelenaIcon,
  [15]: ErisIcon
};

export const signsIcons: {
  [key: number]: React.FC<SVGProps<SVGSVGElement>>;
} = {
  [SignType.Aries]: AriesIcon ,
  [SignType.Taurus]: TaurusIcon ,
  [SignType.Gemini]: GeminiIcon ,
  [SignType.Cancer]: CancerIcon ,
  [SignType.Leo]: LeoIcon ,
  [SignType.Virgo]: VirgoIcon ,
  [SignType.Libra]: LibraIcon ,
  [SignType.Scorpio]: ScorpioIcon ,
  [SignType.Sagittarius]: SagittariusIcon ,
  [SignType.Capricorn]: CapricornIcon ,
  [SignType.Aquarius]: AquariusIcon ,
  [SignType.Pisces]: PiscesIcon 
};

export const aspectsIcons: {
  [key: number]: React.FC<SVGProps<SVGSVGElement>>;
} = {
  [AspectType.Conjunction]: ConjunctionIcon ,
  [AspectType.Semisextile]: SemisextileIcon ,
  [AspectType.Semiquintile]: SemiquintileIcon ,
  [AspectType.Novile]: NovileIcon ,
  [AspectType.Semisquare]: SemisquareIcon ,
  [AspectType.Sextile]: SextileIcon ,
  [AspectType.Quintile]: QuintileIcon ,
  [AspectType.Binovile]: BinovileIcon ,
  [AspectType.Square]: SquareIcon ,
  [AspectType.MinusOrPlus]: MinusOrPlusIcon ,
  [AspectType.Tredecile]: TredecileIcon ,
  [AspectType.Trine]: TrineIcon ,
  [AspectType.Sesquiquadrate]: SesquiquadrateIcon ,
  [AspectType.Biquintile]: BiquintileIcon ,
  [AspectType.Quincunx]: QuincunxIcon ,
  [AspectType.Opposition]: OppositionIcon 
};
