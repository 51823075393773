import { useTranslation } from 'src/i18n/useTranslation';
import React, {useState, useEffect} from 'react';
import styled, { css } from 'styled-components';
import SwipeScreens, { ISwipeTab } from 'src/ui/SwipeScreens';
import { IWidget, IWidgetData } from './data';
import { CircleMode } from 'src/libs';
import { isCompatibility } from 'src/utils';

import { useSelector } from 'src/store/utils';
import { getWorkspaceIndicator } from 'src/store/reducers/mapIndicator/selectors';
import { IMapIndicatorState, ECompatibilityTransitMode } from 'src/store/reducers/mapIndicator/types';
import { extractMode } from '../../utils';

export type TWidgetCircleMode = CircleMode | `compatibility${number}-transit-partner` | `compatibility${number}-transit-natal`;

export default function Widgets({
  widgets,
  widgetsData,
  active,
  currentMode,
  onWidgetChange,
	onOpenWidget
}:{
  widgets: IWidget[],
  widgetsData: IWidgetData,
  active?: string,
  currentMode: CircleMode,
  onWidgetChange(type: string, data: any): void,
	onOpenWidget(id: string): void
}) {
  const { t } = useTranslation();
  const [openedWidget, setOpenedWidget] = useState<number>(0);
  const [selectedMode, setSelectedMode] = useState<any>('');

  const mapIndicator: IMapIndicatorState = useSelector(getWorkspaceIndicator);
  const isHasCompatibilityTransit = Boolean(widgetsData.form?.syn_prognostics);

	useEffect(() => {
		active && onOpenWidget(items[openedWidget].key!)

    // extractedMode - это реальный режим, так как в currentMode могут быть субрежимы типа 'compatibility1-transit-partner'
    const _realMode = extractMode(currentMode, '-') as CircleMode

    if (items[openedWidget].key! === 'coordinates-of-planets-and-houses' && isCompatibility(_realMode) && isHasCompatibilityTransit) {
      setSelectedMode('syn_natal');
    } else
    
    if (items[openedWidget].key! === 'aspects-table' && isCompatibility(_realMode) && isHasCompatibilityTransit) {
      setSelectedMode(_realMode);
      return;

    }
	}, [openedWidget])
  
  React.useEffect(() => {
    if (selectedMode?.includes('transit')) return; // что бы не переключались табы при пересчете карт

    // extractedMode - это реальный режим, так как в currentMode могут быть субрежимы типа 'compatibility1-transit-partner'
    const _realMode = extractMode(currentMode, '-') as CircleMode

    if (widgetsData.activeWidget === 'coordinates-of-planets-and-houses' && isCompatibility(_realMode) && isHasCompatibilityTransit) {
      setSelectedMode('syn_natal');
      return;
    } else
    
    if (widgetsData.activeWidget === 'aspects-table' && isCompatibility(_realMode) && isHasCompatibilityTransit) {
      setSelectedMode(_realMode);
      return;

    }
    setSelectedMode(widgetsData.widgetMode);
  }, [widgetsData])

  // Рисуем табы для определенных виджетов
  const widgetTabs = React.useMemo(() => {

    if (isCompatibility(currentMode) && isHasCompatibilityTransit) {

      const partnerIndex = Number(currentMode.slice(-1)) - 1 || 0;
      const partnerData = widgetsData.form.partners[partnerIndex];
  
      if (widgetsData.activeWidget === 'aspects-table') {
        return  (
          <Tabs>
            <TabItem isSelected={selectedMode === currentMode} onClick={() => setSelectedMode(currentMode)}>{t(`astro.${currentMode}`)}</TabItem>
            { mapIndicator.compatibilityTransits[currentMode].transitMode === ECompatibilityTransitMode.MODE_1 &&
              <>
                <TabItem isSelected={selectedMode === `${currentMode}-transit-natal`} onClick={() => setSelectedMode(`${currentMode}-transit-natal`)}>Транзит-натал</TabItem>
                <TabItem isSelected={selectedMode === `${currentMode}-transit-partner`} onClick={() => setSelectedMode(`${currentMode}-transit-partner`)}>Транзит-партнер</TabItem>
              </>
            }
          </Tabs>
        )
      } else if (widgetsData.activeWidget === 'coordinates-of-planets-and-houses') {
        
        return (
          <Tabs>
            <TabItem isSelected={selectedMode === 'syn_natal'} onClick={() => setSelectedMode('syn_natal')}>Натал</TabItem>
            <TabItem isSelected={selectedMode === currentMode} onClick={() => setSelectedMode(currentMode)}>{partnerData.name || 'Совместимость'}</TabItem>
            <TabItem isSelected={selectedMode === `${currentMode}-transit`} onClick={() => setSelectedMode(`${currentMode}-transit`)}>Транзит</TabItem>
          </Tabs>
        )
      }
    }

    return null;

  }, [currentMode, selectedMode, widgetsData])

  const items: ISwipeTab[] = React.useMemo(() => {
    
    const result = widgets.map((w, index) => {
      const WidgetComponent = w.component;  
      return {
        key: w.id,
        icon: w.icon,
        title: w.full,
        content: WidgetComponent 
          ? <>
              {widgetTabs}
              <WidgetComponent 
                subMode={selectedMode}
                data={widgetsData}
                onChanged={onWidgetChange}
                openedWidget={index === openedWidget} 
              /> 
            </>
          : <>'no-comp'</>,
        fullscreen: w.fullscreen,
      }
    });

    return result;
  }, [currentMode, selectedMode, widgetsData])


  return <SwipeScreens
    items={items}
    active={widgetsData.activeWidget || widgets[0]?.id}
    mode={currentMode}
    setOpenedWidget={setOpenedWidget}
  />
}

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 0.875rem;
`;

const TabItem = styled.div<{ isSelected: boolean }>`
  position: relative;
  max-width: 7rem;
  /* flex: 1; */
  padding: 0.25rem 0.25rem 0.75rem;
  text-align: left;

  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.isSelected && css`
    border-bottom: 2px solid var(--accent-blue);
  `}
`;

export const WidgetHeader = styled.div`
  height: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;

  > svg:first-child {
    margin-right: 0.5rem;
  }

  > span {
    padding-top: 0.125rem;
  }
`
