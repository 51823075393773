import React from 'react';
import styled from 'styled-components';

const colors = ['#5E5E5E','#8E8E8E', '#9C6652', '#EF5350', '#EF598B', '#BA68C8', '#4760E7', '#16CAB9', '#73B230']

export default function ColorSelector({
  current,
  onChange
}: {
  current: string,
  onChange: (color: string) => void
}) {

  return <Container>
    <Slider>
      {colors.map(item => <Circle color={item} onClick={() => onChange(item)} key={item}>
        {current === item && <Active />}
      </Circle>)}
    </Slider>
  </Container>
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`

const Slider = styled.div`
  white-space: nowrap;
  overflow-x: scroll;
`

const Circle = styled.div<{ color: string }>`
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: ${p => p.color};

  &:not(:last-child){
    margin-right: 0.7rem;
  }
`

const Active = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--color-white)
`