import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { PlusIcon, MenuIcon, SettingsIcon, ShareIcon, DragNDropIcon, CopyIcon, ChronosIcon } from 'src/assets/icons/system/index';
import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';

import { Link } from 'react-router-dom';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import { ILabelsWithIcon } from 'src/ui/LabelsWithIcon';
import MenuForPopup from 'src/ui/Popups/PopupBottom/MenuForPopup';
import { Button, IFormData } from 'src/libs';
import ShareForm from 'src/ui/ShareForm/ShareForm';
import { useTranslation } from 'src/i18n/useTranslation';
import { TSaveFormMode } from "../index";

import { clearURLSearchParams } from 'src/utils';
import api from "src/api";

interface IWorkspaceHeaderProps {
  title: string,
  subtitle: string,
  setOpenFormPopup(value: boolean): void;
  setSaveFormMode(value: TSaveFormMode): void;
  form: IFormData;
}

export default React.memo(function Header({
  title,
  subtitle,
  setOpenFormPopup,
  setSaveFormMode,
  form
}: IWorkspaceHeaderProps) {

  const navigate = useNavigate();

  const [openMenuPopup, setOpenMenuPopup] = useState(false);
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [openSaveSharedMapPopup, setOpenSaveSharedMapPopup] = useState(false);
  const { t } = useTranslation();

  const openFormPopup = () => {
    setOpenMenuPopup(false);
    setOpenFormPopup(true);
  }

  const { id } = useParams<{id: string}>();

  const isShare = new URLSearchParams(window.location.search).has('share');
  const isPersonalMap = form.access.isPersonal;
  const showMapPersonalData = form.access.showPersonalData;

  const saveButtons = Number(id) === -1 || !isPersonalMap
    ? [
        {icon: <PlusIcon />, short: t("chronos.mobile.pages.components.saveMap.short"), full: t("chronos.mobile.dashboard.addButton.addInList.full"), action: () => {openFormPopup(); setSaveFormMode('add')}  },
        {icon: <SettingsIcon />, short: t("chronos.app.settings.astro.mapSettings"), full: t("chronos.mobile.pages.components.mapSettings.full"), action: () => {openFormPopup(); setSaveFormMode('set')}  }
      ]
    : [{icon: <SettingsIcon />, short: t("chronos.app.settings.astro.mapSettings"), full: t("chronos.mobile.pages.components.mapSettings.full"), action: openFormPopup  }];

  const saveSharedMapButtons = [
    {
      icon: <CopyIcon />,
      short: t("Сохранить себе"),
      full: t("Она появится в списке ваших карт"),
      action: () => {
        setOpenSaveSharedMapPopup(false)
        openFormPopup();
        setSaveFormMode('add');
      }
    },
    {
      icon: <ChronosIcon />,
      short: t("Открыть карту"),
      full: t("Откроется рабочее пространство"),
      action: () => {
        setOpenSaveSharedMapPopup(false)
        onSharedMapOpen()
      }
    }
  ]

  const buttons: ILabelsWithIcon[] = [
      ...saveButtons,
    {icon: <ShareIcon />, short: t("base.share"), full: t("chronos.mobile.pages.components.mapSettings.saveOnFuture"), action: () => { setOpenSharePopup(true); } },
    // {disabled: true, icon: <DragNDropIcon />, short: t("chronos.mobile.pages.components.mapSettings.shangePlace.title"), full: t("chronos.mobile.pages.components.mapSettings.shangePlace.full"), action: () => {} },
  ]

  const sharedMapSaveChandler = () => {
    setOpenSaveSharedMapPopup(true);
  }

  const onSharedMapOpen = async () => {
    const dfltForm = await api.form(-1);
    if (!dfltForm) { return }

    const tmpForm = { ...form };

    delete tmpForm.rectification;

    await api.updateForm({
      ...tmpForm,
      name: "chronos.app.tmpMap",
      isTemporary: true,
      id: dfltForm.id
    });

    navigate('/workspace/-1');
  };


  return <Container>
    <div>{!isShare && <Link to="/"><LeftTriangleIcon /></Link>}</div>
    <div>
      {showMapPersonalData &&
        <>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </>
      }
    </div>

    <div>
      { !isShare && isPersonalMap
          ? <MenuIcon onClick={() => setOpenMenuPopup(true)} />
          : !isShare && !isPersonalMap && showMapPersonalData
            ? <Button
                size='medium'
                color='transparent'
                bordered
                onClick={sharedMapSaveChandler}
              >
                {t('Сохранить')}
              </Button>
            : <Button
                size='medium'
                onClick={() => {
                  clearURLSearchParams('share');
                  api.goToAuth();
                }}
              >
                {t('Войти')}
              </Button>
      }
    </div>

    <PopupBottom open={openMenuPopup} onClose={() => setOpenMenuPopup(false)}>
      <MenuForPopup buttons={buttons} />
    </PopupBottom>

    <PopupBottom open={openSharePopup} onClose={() => setOpenSharePopup(false)}>
      <ShareForm form={form} onUpdate={() => {}} />
    </PopupBottom>

    <PopupBottom open={openSaveSharedMapPopup} onClose={() => setOpenSaveSharedMapPopup(false)}>
      <MenuForPopup buttons={saveSharedMapButtons} />
    </PopupBottom>

  </Container>
})

const Container = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.75rem;
  width: 100%;
  max-width: 100%;
`

const Title = styled.div`
  margin-top: 0.20rem;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  max-width: 70vw;
  word-wrap: break-word
`

const Subtitle = styled.div`
  font-size: 0.75rem;
  color: var(--text-secondary);
`
