import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Container as Container_ } from '../PopupRight';

import { OkIcon } from 'src/assets/icons/system/index';
import { CloseIcon } from 'src/assets/icons/system/index';

interface IResultPopupProps {
  open: boolean;
  success: boolean;
  title: string;
  subtitle: string;
  buttons?: ReactNode[];
}

export default function ResultPopup({
  open,
  success,
  title,
  subtitle,
  buttons = [],
}: IResultPopupProps) {
  return <Container open={open}>
    {success && <Circle color='var(--color-green)'><OkIcon /></Circle>}
    {!success && <Circle color='var(--color-red)'><CloseIcon /></Circle>}
    <Title>{title}</Title>
    <Text>{subtitle}</Text>
    <Buttons>
      {buttons}
    </Buttons>
  </Container>
}

const Circle = styled.div<{ color: string }>`
  display: inline-block;
  padding: 1.5rem;
  border-radius: 50%;
  background-color: ${p => p.color};
  color: var(--color-white);
`

const Container = styled(Container_)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 1rem;
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-top: 1.875rem;
`

const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
`
const Buttons = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-row-gap: 1rem;
`