import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import LineHorizontal from 'src/ui/LineHorizontal';
import NavigationPoints from 'src/ui/NavigationPoints.tsx';
import { throttle, cloneDeep, isEqual } from 'lodash';
import { swipeAndCall } from 'src/ui/SwipeTabs';
import { Button, IAstroSettings, CircleMode, dispositors } from 'src/libs';

import DispositorsSettings, { IDispositorsSettings } from 'src/app/pages/workspace/components/Widgets/Dispositors/DispositorsSettings';
import { WidgetHeader } from 'src/app/pages/workspace/components/Widgets';

import { useSelector } from 'src/store/utils';
import {
  allSettingsAstro,
  getActiveAstroProfile,
} from 'src/store/reducers/settings/selectors';

import { getProfile } from 'src/store/localStorage/profile';

import { getSettings, getSettingsAstro, updateAstroSettings, updateSettings } from 'src/store/actions/settings';


// import { widgets } from 'src/app/pages/workspace/components/Widgets/data';
import { SettingsIcon } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';

export interface ISwipeTab {
  key?: string;
  icon: ReactNode;
  title: string;
  content: ReactNode | string;
  fullscreen?: boolean;
}

export const widgetsSettings: {
  [key: string]: { component?: any };
} = {
  'aspects-table': {},
  'coordinates-of-planets-and-houses': {},
  'orbise-corrector': {},
  'strong-objects': {},
  'house-formulas': {},
  'analyze': {},
  'devplan': {},
  'autoprediction': {},
  'interpretation': {},
  'rectification': {},
  'dispositors': {
    component: DispositorsSettings
  },
  'description': {},
  'prompt': {},
  'horar-events': {},
  'horar-speed': {},
  'horar-essentials': {},
  'horar-analysis': {},
  'horar-light': {},
  'formula-strength': {},
  'ephemeris-formulas': {},
  'formula-years': {},
  'interpretation-soul': {},
  'formula-soul': {}
};

export default function SwipeScreens({
  items,
  active,
  mode,
  setOpenedWidget,
}: {
  items: ISwipeTab[],
  active?: string,
  mode: CircleMode,
  setOpenedWidget: (value: number) => void
}) {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const astroSettings = useSelector(allSettingsAstro);
  const activeProfileId = useSelector(getActiveAstroProfile).id;
  const profiles = astroSettings.data;
  const startSettings = profiles.find((item: any) => item.id === activeProfileId).widgets?.dispositors;
  

  const tabsRef = useRef<HTMLDivElement>(null);
  const screensRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [settings, setSettings] = React.useState<IDispositorsSettings>(startSettings);
  const [showSettings, setShowSettings] = useState(false);


  useEffect(() => {
    setOpenedWidget(currentIndex)
  }, [currentIndex, setOpenedWidget])

  useEffect(() => {
    const width = getComputedStyle(screensRef.current!).width;
    screensRef.current!.querySelectorAll('.swipe-screen').forEach(item => {
      (item as HTMLDivElement).style.width = width;
    })
    tabsRef.current!.querySelectorAll('.swipe-screen').forEach(item => {
      (item as HTMLDivElement).style.width = width;
    })
  }, [items])

  useEffect(() => {
    const handleScroll = throttle((e: any) => {
      if(items[currentIndex].fullscreen) {
        screensRef.current!.style.height = 'initial';
        return;
      }
      const currentScreen = screensRef.current!.querySelectorAll('.swipe-screen')[currentIndex] as HTMLDivElement
      if(screensRef.current!.offsetHeight < currentScreen.offsetHeight) {
        screensRef.current!.style.height = currentScreen.offsetHeight + 'px';
      }
    }, 500);

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);

  }, []);

  useEffect(() => {
    if(active){
      const index = items.findIndex(item => item.key === active);
      if(index !== -1) changeTab(index);
    } else {
      changeTab(0);
    }
  }, [active]);

  const changeTab = (index: number) => {
    if (index < 0 || index >= items.length) return;

    //const currentScreen = screensRef.current!.querySelectorAll('r.swipe-screen')[index] as HTMLDivElement
    const currentScreen = screensRef.current!.children[0].children[index] as HTMLDivElement;
    currentScreen.style.display = 'inline-flex';

    const width = getComputedStyle(screensRef.current!).width;
    screensRef.current!.scrollTo({ 'left': index * parseInt(width), behavior: 'smooth' })

    if(items[index].fullscreen){
      (screensRef.current!.closest('.popup-bottom') as HTMLDivElement).style.height = '100%';
      //screensRef.current!.style.height = 'initial';
    } else {
      (screensRef.current!.closest('.popup-bottom') as HTMLDivElement).style.height = 'initial';
      //screensRef.current!.style.height = currentScreen.offsetHeight + 'px';
    }

    tabsRef.current!.scrollTo({ 'left': index * parseInt(width) }) //, behavior: 'smooth'

    setTimeout(() => {
      if(index === currentIndex) return;
      const prevScreen = screensRef.current!.children[0].children[currentIndex] as HTMLDivElement;
      prevScreen && (prevScreen.style.display = 'none');
    }, 100)
    setCurrentIndex(index);
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => swipeAndCall(e, () => changeTab(currentIndex + 1)),
    onSwipedRight: (e) => swipeAndCall(e, () => changeTab(currentIndex - 1)),
  });

  const updateWidgetSettings = async () => {
    await update({ widgets: { dispositors: { ...settings } } })
    setShowSettings(false);
  }

  const update = async (newValue: { [key: string]: any }) => {
    if (!profiles) { return }

    const activeAstroProfile = profiles.find((item: any) => item.id === activeProfileId);
    let profile = cloneDeep(activeAstroProfile);

    profile = {
      ...profile,
      ...newValue
    };

    const userProfile = profiles.find((p: IAstroSettings) => p.mutable)!;

    if (profile.id !== userProfile.id) {
      profile.id = userProfile.id;
      profile.title = userProfile.title;
      profile.mutable = true;
    }

    dispatch(updateAstroSettings(profile, (data: IAstroSettings) => {
      dispatch(updateSettings({ activeProfileId: data.id, activeCustomizationId: data.idCustomization }));
    }));

  }

  const isSettingsHanged = React.useMemo((): boolean => {
    return isEqual(startSettings, settings)
  }, [settings]);

  return <Container {...swipeHandlers}>

    <ScreensScrollContainer ref={tabsRef}>
      <ItemsContainer {...swipeHandlers}>
        {items.map((item, index) =>
          <Item key={item.key} className="swipe-screen">
            <WidgetHeader>
              {item.icon}
              <div>{!showSettings ? t(item.title as string) : t("chronos.app.settings.widgetSettings")}</div>
            </WidgetHeader>
          </Item>
        )}
      </ItemsContainer>
    </ScreensScrollContainer>

    <NavigationPoints count={items.length} active={currentIndex} onChange={changeTab}/>

    <LineWrapper><LineHorizontal /></LineWrapper>

    <ScreensScrollContainer ref={screensRef}>
      <ItemsContainer>
        {
          items.map((item, index) => {
            const WidgetContent = item.content;
            const SettingsContent = ((): JSX.Element | null => {
              switch(item.key) {
                case 'dispositors': 
                  return <DispositorsSettings settings={settings} onChange={setSettings} />
                default: return null
              }
            })();

            return (
              <ScreenItem key={`screen_${item.key}_${index}`} className="swipe-screen">
                { !Boolean(showSettings) 
                    ? WidgetContent 
                    : SettingsContent
                }
                {
                  (SettingsContent && !showSettings) && 
                  <SettingsButton onClick={() => { setShowSettings(true) }}>
                    <SettingsIcon />{t("chronos.app.settings.widgetSettings")}
                  </SettingsButton>
                }
                {
                  (SettingsContent && showSettings) && 
                    <Buttons>
                      <Button onClick={updateWidgetSettings} disabled={isSettingsHanged}>{t('chronos.app.widget.confirm')}</Button>
                      <Button color='transparent' bordered onClick={() => { setSettings(startSettings); setShowSettings(false) }}>{t('base.cancel')}</Button>
                    </Buttons>
                }
              </ScreenItem>
            )
          })
        }
      </ItemsContainer>
    </ScreensScrollContainer>

  </Container>
}

const Container = styled.div`
  min-height: 100%;
`

const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const ScreensScrollContainer = styled(ScrollContainer)`
  overflow: hidden;
`

const ItemsContainer = styled.div`
  white-space: nowrap;
`

const Item = styled.div`
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
`

const ScreenItem = styled(Item)`
  display: none;
  min-height: 80vh;
`

const LineWrapper = styled.div`
  margin-top: 0.875rem;
  margin-bottom: 0.75rem;
`

const Buttons = styled.div`
  display: flex;
  position: relative;
  margin-top: auto;
  padding-top: 1rem;
  padding: 1rem 0.2rem;
  align-items: baseline;
  margin-bottom: 1rem;
  gap: 0.5rem;

  & > button {
    flex: 1;
  }
`

const SettingsButton = styled.button`
  width: 11.5rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  padding: 0.425rem 0.625rem;
  margin-bottom:0.25rem;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  background: none;
  color: inherit;
  border: 1px solid var(--element-neutral);
  user-select: none !important;

  & > svg {
    /* display: block; */
    width: 1.5rem;
    color: var(--text-primary);
    /* opacity: 0.37; */
  }

  &:hover {
    background: var(--element-neutral);

    & svg {
      opacity: 1;
    }

    & i {
      color: var(--text-primary);
    }
  }

  &:last-of-type{
    margin-bottom:0rem;
  }
`;
