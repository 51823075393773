import { FOLDERS_GET_LOADING, FOLDERS_GET_SUCCESS } from './actions';
import { IActionFolders, IFoldersState } from './types';

const initialState: IFoldersState = {
  loading: false,
  loaded: false,
  data: []
};


export default function folders(state = initialState, action: IActionFolders){
  switch(action.type){
    case FOLDERS_GET_LOADING:
      return {
        loading: true,
        loaded: false,
        data: state.data
      };
    case FOLDERS_GET_SUCCESS:
      return {
        loading: false,
        loaded: true,
        data: action.payload
      };
    default:
      return state;
  }
}
