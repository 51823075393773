import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, IPlaceEx, CircleMode, makeForm } from 'src/libs';
import { activeHousesSystem, Errors, IFormError, IPartnerDataWithAdditionalParams } from '.';
import { useTranslation } from 'src/i18n/useTranslation';
import { OkInCircleIcon, PersonIcon } from 'src/assets/icons/system';
import { addForm } from 'src/store/reducers/forms/actions';


export default function SavePartner(props: {
  mode: CircleMode,
  partner: IPartnerDataWithAdditionalParams,
  cosmogram?: boolean,
  activeAstroProfile: any,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<IFormError[]>([]);
  const [state, setState] = useState({
    processing: false,
    saved: false,
  });

  useEffect(() => setState({...state, saved: false}), [props.partner])

  const savePartner = async () => {
    const errs: IFormError[] = [];
    const formName = t(`astro.${props.mode}`);
    const partnerPlace = props.partner?.place as IPlaceEx;

    if(!props.partner.name) {
      errs.push({
        node: 'synastry',
        field: 'name',
        text: `${formName}: ${t('chronos.app.components.editForm.noName')}`,
      });
    }

    if (props.partner.invalidDateTime) {
      errs.push({
        node: 'synastry',
        field: 'date',
        text: `${formName}: ${t('chronos.app.inputBirthDate')}`,
      });
    }

    if ((props.partner?.place?.name.trim().length === 0) && !props.cosmogram) {
      errs.push({
        node: 'synastry',
        field: 'place',
        text: `${formName}: ${t('chronos.app.noLocation')}`,
      });
    } else if (
      (partnerPlace?.originalName != undefined && (props.partner?.place?.name.trim() !== partnerPlace?.originalName) && partnerPlace?.id) ||
      (!partnerPlace?.originalLat && !partnerPlace?.originalLon && partnerPlace?.originalName && partnerPlace?.name && partnerPlace?.id) &&
      !props.cosmogram
    ) {
      errs.push({
        node: 'synastry',
        field: 'place',
        text: `${formName}: ${t('chronos.app.choosePlaceFromList')}`,
      });
    }
    if (
      (!partnerPlace?.id && !props.partner?.place?.lat || !props.partner?.place?.lon) ||
      (!partnerPlace?.id && !partnerPlace?.originalLat && !partnerPlace?.originalLon && !partnerPlace?.originalName && props.partner?.place?.name)
    ) {
      errs.push({
        node: 'synastry',
        field: 'place',
        text: `${formName}: ${t('chronos.app.noCoordinates')}`,
      });
    }

    setErrors(errs);

    if(errs.length) {
      return;
    }

    setState({
      processing: true,
      saved: false,
    });

    const newForm = {
      ...makeForm({
        name: props.partner.name || '',
        housesSystem: activeHousesSystem(props.activeAstroProfile),
        natal: {
          place: props.partner.place!,
          dt: props.partner.dt!,
          gmt: props.partner.gmt ?? 0,
          // gender: props.partner.gender, // FIXME:
        }
      }),
    };
    newForm.natal.dt = props.partner.dt!;
    dispatch(addForm(newForm, (data: any) => {
      setTimeout(() => {
        setState({
          processing: false,
          saved: data.id ? true : false,
        });
      }, 750);
    }));
  }

  return (
    <>
      <Errors>
        {errors.map(e => <li key={e.text}>{t(e.text)}</li>)}
      </Errors>
      {state.saved &&
        <Saved>
          <OkIcon />
          <span>{t("chronos.app.components.editForm.mainGroup.savedToCRM")}</span>
        </Saved>
      }
      {!state.processing && !state.saved && !props.partner.partnerFormId &&
        <>
          <SavePersonButton color='transparent' onClick={savePartner}>
            <PersonIcon />
            <span>{t('chronos.app.components.editForm.mainGroup.addToMapsList')}</span>
          </SavePersonButton>
        </>
      }
    </>
  );
}

const SavePersonButton = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;

  & > svg {
    height: 1.125rem;
    margin-right: 0.5rem;
    fill: var(--text-third);
  }
  & > span {
    line-height: 1;
  }
`;

const Saved = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const OkIcon = styled(OkInCircleIcon) <{ color?: number }>`
  color: #73B330;
  margin-right: 0.5rem;
`;
