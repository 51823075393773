import { combineReducers } from 'redux';

import astro from './astro';
import settings from './settings';
import customizations from './customizations';

const settingsReducer = combineReducers({
  astro,
  settings,
  customizations
})

export default settingsReducer;
