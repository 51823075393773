export default {
  "1#1": "chronos.guide.formulas.1_1",
  "1#2": "chronos.guide.formulas.1_2",
  "1#3": "chronos.guide.formulas.1_3",
  "1#4": "chronos.guide.formulas.1_4",
  "1#5": "chronos.guide.formulas.1_5",
  "1#6": "chronos.guide.formulas.1_6",
  "1#7": "chronos.guide.formulas.1_7",
  "1#8": "chronos.guide.formulas.1_8",
  "1#9": "chronos.guide.formulas.1_9",
  "1#10": "chronos.guide.formulas.1_10",
  "1#11": "chronos.guide.formulas.1_11",
  "1#12": "chronos.guide.formulas.1_12",
  "2#2": "chronos.guide.formulas.2_2",
  "2#3": "chronos.guide.formulas.2_3",
  "2#4": "chronos.guide.formulas.2_4",
  "2#5": "chronos.guide.formulas.2_5",
  "2#6": "chronos.guide.formulas.2_6",
  "2#7": "chronos.guide.formulas.2_7",
  "2#8": "chronos.guide.formulas.2_8",
  "2#9": "chronos.guide.formulas.2_9",
  "2#10": "chronos.guide.formulas.2_10",
  "2#11": "chronos.guide.formulas.2_11",
  "2#12": "chronos.guide.formulas.2_12",
  "3#3": "chronos.guide.formulas.3_3",
  "3#4": "chronos.guide.formulas.3_4",
  "3#5": "chronos.guide.formulas.3_5",
  "3#6": "chronos.guide.formulas.3_6",
  "3#7": "chronos.guide.formulas.3_7",
  "3#8": "chronos.guide.formulas.3_8",
  "3#9": "chronos.guide.formulas.3_9",
  "3#10": "chronos.guide.formulas.3_10",
  "3#11": "chronos.guide.formulas.3_11",
  "3#12": "chronos.guide.formulas.3_12",
  "4#4": "chronos.guide.formulas.4_4",
  "4#5": "chronos.guide.formulas.4_5",
  "4#6": "chronos.guide.formulas.4_6",
  "4#7": "chronos.guide.formulas.4_7",
  "4#8": "chronos.guide.formulas.4_8",
  "4#9": "chronos.guide.formulas.4_9",
  "4#10": "chronos.guide.formulas.4_10",
  "4#11": "chronos.guide.formulas.4_11",
  "4#12": "chronos.guide.formulas.4_12",
  "5#5": "chronos.guide.formulas.5_5",
  "5#6": "chronos.guide.formulas.5_6",
  "5#7": "chronos.guide.formulas.5_7",
  "5#8": "chronos.guide.formulas.5_8",
  "5#9": "chronos.guide.formulas.5_9",
  "5#10": "chronos.guide.formulas.5_10",
  "5#11": "chronos.guide.formulas.5_11",
  "5#12": "chronos.guide.formulas.5_12",
  "6#6": "chronos.guide.formulas.6_6",
  "6#7": "chronos.guide.formulas.6_7",
  "6#8": "chronos.guide.formulas.6_8",
  "6#9": "chronos.guide.formulas.6_9",
  "6#10": "chronos.guide.formulas.6_10",
  "6#11": "chronos.guide.formulas.6_11",
  "6#12": "chronos.guide.formulas.6_12",
  "7#7": "chronos.guide.formulas.7_7",
  "7#8": "chronos.guide.formulas.7_8",
  "7#9": "chronos.guide.formulas.7_9",
  "7#10": "chronos.guide.formulas.7_10",
  "7#11": "chronos.guide.formulas.7_11",
  "7#12": "chronos.guide.formulas.7_12",
  "8#8": "chronos.guide.formulas.8_8",
  "8#9": "chronos.guide.formulas.8_9",
  "8#10": "chronos.guide.formulas.8_10",
  "8#11": "chronos.guide.formulas.8_11",
  "8#12": "chronos.guide.formulas.8_12",
  "9#9": "chronos.guide.formulas.9_9",
  "9#10": "chronos.guide.formulas.9_10",
  "9#11": "chronos.guide.formulas.9_11",
  "9#12": "chronos.guide.formulas.9_12",
  "10#10": "chronos.guide.formulas.10_10",
  "10#11": "chronos.guide.formulas.10_11",
  "10#12": "chronos.guide.formulas.10_12",
  "11#11": "chronos.guide.formulas.11_11",
  "11#12": "chronos.guide.formulas.11_12",
  "12#12": "chronos.guide.formulas.12_12",
} as {
  [key: string]: string;
};
