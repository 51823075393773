import { signOut } from "src/store/reducers/profile/actions";
import { IFormData, S3Urls } from "src/libs";

import api from "src/api";
import i18n from "src/i18n/i18n";

export const s3urls = new S3Urls();

export const restURL = `${process.env.REACT_APP_STATIC_URL}/api`;
export const authURL = `${process.env.REACT_APP_AUTH_URL}/api/auth`;

export const getForm = (id: number) => new Promise<IFormData>(async (resolve) => {
    try {
      const result = await api.form(id);
      resolve(result as IFormData);
    } catch (error) {
      	signOut(error as Error);
    }
})

export const autoprediction = (confirmed: boolean, form: IFormData): any => new Promise(async (resolve) => {
    try {
        const result = await api.autoprediction(confirmed, form, i18n.language);
        resolve(result);
    } catch (error) {
        signOut(error as Error);
    }
});


export const devplan = (confirmed: boolean, form: IFormData): any => new Promise(async (resolve) => {
    try {
        const result = await api.devplan(confirmed, form, i18n.language);
        resolve(result);
    } catch (error) {
        signOut(error as Error);
    }
});

export const rectification = (confirmed: boolean, form: IFormData): any => new Promise(async (resolve) => {
    try {

        const result = api.rectification(confirmed, form);
        resolve(result);
    } catch (error) {
        signOut(error as Error);
    }
});
