import React from 'react';
import { ICalculation } from 'src/libs';

interface IGlobalTrendGroupItem {
  text: string;
  weight: string;
  compensation?: string;
  compensationIntro: string;
  texts?: [{
    text: string;
    weight: string;
  }]
}
interface IGlobalTrend {
  title: string;
  importantNotice?: string | null;
  description: string;
  actualTo: string;
  group: IGlobalTrendGroupItem[];
}
export interface IGlobalTrends {
  natal?: ICalculation;
  solars?: any;
  primaryTrends?: IGlobalTrend;
  secondaryTrends?: IGlobalTrend;
  heading: string;
  description: string;
  actualTo?: string;
  compensationIntro: string;
  actualToText?: string;
}

const GlobalTrends = (props: IGlobalTrends) => {
  const { primaryTrends, secondaryTrends, heading, description } = props;

  return <div className="trends">
    <div className="print-inseparable">
      <h1>{heading}</h1>
      <p>{description}</p>
    </div>

    { [primaryTrends, secondaryTrends].map((trends, i) => trends
        ? <React.Fragment key={`trend_${i}`}>
            <h2>{trends.title}</h2>
            { trends.importantNotice &&
                <p className="important-notice">
                  {trends.importantNotice}
                </p>
            }
            <ins>{props.actualToText}{props.actualTo}</ins>
            <p>{trends.description}</p>
            <ul className="list-wrap-global">
              { trends.group?.map((item, j) => item.compensation
                  ? <li className="list-item red" key={`trend_${i}_group_${j}`}>
                      <div className="list-item-data">
                       {item?.texts?.length ?
                  item.texts.map(text => `+${text.weight} ${text.text}`) :
                  null
                }
                      </div>
                      <div className="list-item-data compensation">
                        <div className="block">
                          <div className="text">
                            <span className="intro">!{props.compensationIntro}: </span> 
                            {item.compensation}
                          </div>
                        </div>
                      </div>
                    </li>
                  : <li className="list-item gray" key={`trend_${i}_group_${j}`}>
                      <div className="list-item-data">
                        <div className="block">
                        <span>+{item.weight} {item.text}</span>
                        </div>
                      </div>
                    </li>
              )}
            </ul>
          </React.Fragment>
        : null
    )}      
  </div>
}

export default GlobalTrends;