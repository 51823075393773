import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';


import { Input, Switcher, Button, DateTimeInput, InputContainer, nowISOString, isValidISOString } from 'src/libs';

import RuleItem from './RuleItem';

import { IEvent, IRule } from './types';
//import { showOnboarding } from '~lib/onboarding';
//import onboarding from './onboarding.cfg';

import { CloseIcon } from 'src/assets/icons/system/index';
import { TopTriangleIcon } from 'src/assets/icons/arrows/index';
import { DeleteIcon } from 'src/assets/icons/system/index';
import { PlusIcon } from 'src/assets/icons/system/index';
import { useTranslation } from 'src/i18n/useTranslation';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export default function EventEdit(props: {
  onClose: Function;
  onSave: Function;
  onDelete?(): void;
  allEvents: IEvent[];
  type: 'add' | 'edit';
  event?: IEvent;
  index?: number;
  rulesOpened?: boolean;
}) {
  const [advancedOpened, _setAdvancedOpened] = React.useState(!!props.rulesOpened);
  const [rules, setRules] = React.useState<IRule[]>(props.event ? _.cloneDeep(props.event.rules) : []);
  const [eventId, _setEventId] = React.useState(props.event?.id || 0);
  const [date, _setDate] = React.useState(dayjs(props.event?.date, 'DD.MM.YYYY')?.toISOString() || nowISOString());
  const [weight, _setWeight] = React.useState(props.event?.weight || 5);
  const { t } = useTranslation();
  const saveRef = React.useRef(null);

  const setAdvancedOpened = (v: boolean) => {
    _setAdvancedOpened(v);
    //showOnboarding();
  };

  const setEventId = (v: number) => {
    _setEventId(v);
    //showOnboarding();
  };

  const setDate = (v: string) => {
    _setDate(v);
    //showOnboarding();
  };

  const setWeight = (v: number) => {
    _setWeight(v);
    //showOnboarding();
  };

  /*React.useEffect(() => {
    onboarding.show && showOnboarding({
      target: saveRef.current,
      summary: 'Расширенные настройки',
      description: 'Тут вы можете изменить событие, дату события, а<br>также включить или выключить правила которые<br>срабатывают. После изменений нажмите сохранить'
    });
  }, []);*/

  const changeEventId = (id: number) => {
    const event = props.allEvents.find(item => item.id == id);
    if (!event) { return }

    setEventId(id);

    setRules(event.rules.map((item: IRule) => ({
      ...item,
      checked: true
    })));

    setWeight(event.weight);
  };

  const changeRuleChecked = (id: number, checked: boolean) => {
    if (rules) {
      const newRules = [...rules];
      const rule = newRules.find(item => item.id == id);
      if (rule) {
        rule.checked = checked;
        setRules(newRules);
      }
    }
  };

  const onSave = () => {
    const event = props.allEvents.find(item => item.id == eventId);
    if (event) {
      const eventDate = dayjs(date).format('DD.MM.YYYY')
      props.onSave({ ...event, rules, date: eventDate, weight });
    }
    //onboarding.edited = true;
    //showOnboarding();
  };

  const eventsList = props.allEvents.map(item => ({ value: item.id, label: t(item.name) }));

  const checkDate = (date: string) => {
    if (/\d{2}\.\d{2}.\d{4}/.test(date)) {
      const dateParts = date.split('.');
      const dateForParse = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      return !isNaN(Date.parse(dateForParse)) && Date.parse(dateForParse) <= Date.now();
    }
    return false;
  };

  const isValidDate = isValidISOString(date);

  return <OpenedForm>
    <header>
      <Index>{(props.index || 0) + 1}</Index>
      {props.type === 'add' && <><PlusIcon /><span>{t("chronos.app.widgets.rectification.addEvent")}</span> </>}
      {props.type === 'edit' && <span>{t("base.editing")}</span>}
      <CloseIcon onClick={() => {props.onClose()}} />
    </header>

    <Fields>
      <InputContainer label={t("base.event")}>
        <Input
          options={eventsList}
          value={eventId}
          onChange={changeEventId}
        />
      </InputContainer>
      <InputContainer label={t("chronos.app.rectification.dateOfEvent")}>
        <DateTimeInput size='big' type='date' hideIcon={true} utcMode={true} value={date} onChange={setDate}/>
      </InputContainer>

      <label></label>
      <DateTooltip>* {t("chronos.app.rectification.ifForgotDate")}</DateTooltip>

      <label>{t("chronos.app.rectification.significance")}</label>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <input style={{
          flex: 1,
          maxWidth: '90%'
        }} type="range" value={weight} min={1} max={5} onChange={v => setWeight(parseInt(v.target.value))} />
        <span>{weight}</span>
      </div>
    </Fields>

    <AdvancedFields reverse={advancedOpened}>
      <header onClick={() => setAdvancedOpened(!advancedOpened)}>{t("chronos.app.rectification.rules")}
        <TopTriangleIcon />
      </header>
      {advancedOpened &&
        <section>
          {rules?.map(item =>
            <article key={`rule_${item.id}`}>
              <RuleItem rule={item} />
              <Switcher checked={item.checked} onChange={(checked: boolean) => { changeRuleChecked(item.id, checked) }} />
            </article>
          )}
        </section>
      }
    </AdvancedFields>

    <footer>
      {isValidDate &&
        <div>
          <Button
            //ref={saveRef}
            color="accent"
            onClick={onSave}
          >{props.type === 'add' ? t("add") : t("base.save")}</Button>
        </div>
      }
      {!isValidDate &&
        <span title={t("chronos.app.rectification.inputValidDate")}><Button color='rgba(255, 255, 255, 0.15)' disabled onClick={() => {}}>{props.type === 'add' ? t("add") : t("base.save")}</Button></span>
      }

      {props.type === 'edit' && <a onClick={props.onDelete || (() => {})}><DeleteIcon />{t("chronos.app.rectification.deleteEvent")}</a>}
    </footer>
  </OpenedForm >;
}

const Index = styled.span`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  background: var(--rectification-block2-background);
  border: 1px solid var(--rectification-block2-border);
  border-radius: 4px;
  font-size: 0.75rem;
  color: var(--rectification-block2-color);
`;

const OpenedForm = styled.div`
  padding: 1rem;
  background: var(--rectification-block-background);
  border: 1px solid var(--rectification-block-border);
  border-radius: 5px;
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  color: var(--rectification-block-color);

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 1.125rem;
    color: var(--text-primary);

    & svg {
      width: 0.75rem;
      fill-opacity: 0.5;
    }
    & svg:first-child {
      margin-right: 0.75rem;
    }
    & svg:last-child {
      margin-left: auto;
      cursor: pointer;
    }
    & svg:last-child:hover {
      & path {
        fill-opacity: 1;
      }
    }
  }

  & > footer {
    display: flex;
    align-items: center;
  }
  
  & > footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    color: var(--color-red) !important;
    font-size: 0.875rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    & svg {
      width: 1.5rem;
      margin-right: 0.5rem;
      vertical-align: text-bottom;
    }
  }
`;

const Fields = styled.div`
  margin-bottom: 1.125rem;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-row-gap: 0.75rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 0.875rem;

  & > div {
    width: 100%;
  }

  & > input {
    width: auto;
  }
`;

const AdvancedFields = styled.div<{ reverse?: boolean }>`
  margin-bottom: 1.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--text-secondary);

  & header {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1rem;
    cursor: pointer;
    color: var(--text-primary);
  }

  & header svg {
    fill-opacity: 0.37;
    width: 1.5rem;
    margin-left: 0.3rem;
    transform: scaleY(${props => props.reverse ? '1' : '-1'});
  }

  & section {
    margin-top: 1.125rem;
  }

  & article {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 1.75rem;
    margin-bottom: 0.875rem;
    align-items: top;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid var(--rectification-block-border);
  }
`;

const DateTooltip = styled.div`
  font-size: 0.65rem;
`;
