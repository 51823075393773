import { astroObjects, ObjectType } from 'src/libs';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { default as TooltipWrapper } from 'src/ui/Wrappers/TooltipWrapper';
import { objectsIcons } from 'src/helpers/icons';
import { t } from 'i18next';

export default function PlanetsTabs(props: {
  objects: ObjectType[];
  activeIds: [ObjectType | null, ObjectType| null];
  onChanged(obj1: ObjectType | null, obj2: ObjectType | null): void;
}) {
  const planetsTabsRef = useRef<HTMLDivElement>(null);

  const changeHandler = (obj: ObjectType) => {
    const obj1 = props.activeIds[0];
    const obj2 = props.activeIds[1];

    if (obj1 === null) {
      return props.onChanged(obj, null);
    }

    if (obj2 !== null) {
      return props.onChanged(obj, null);
    }

    return props.onChanged(obj1, obj);
  };

  return (
    <Container>
      <Planets ref={planetsTabsRef}>
        {props.objects.map(obj => {
          const ObjIcon = objectsIcons[obj];
          return <Planet isActive={props.activeIds.includes(obj)} onClick={() => changeHandler(obj)}>
              <ObjIcon />
            </Planet>
        })}
      </Planets>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
`;

const Planets = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  //min-width: 35rem;
  overflow: hidden;
`;

const Planet = styled.span<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  padding: 0.5rem 0.6875rem;
  border-radius: 0.375rem;

  > svg {
    width: 1.5em;
    fill: var(--text-secondary);
  }

  ${props => props.isActive && css`
    color: var(--text-primary);
    background: var(--element-neutral);
    
    > svg {
      fill: var(--text-primary);
    }    
  `}
`;
