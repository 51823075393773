import { ObjectType } from 'src/libs';
import { t } from 'i18next';

export const Ephemeris = [
  {
    type: t("chronos.app.instruments.widgets.soul.efemerisFormulas.personal"),
    planets: [
      ObjectType.Sun,
      ObjectType.Moon,
      ObjectType.Mercury,
      ObjectType.Venus,
      ObjectType.Mars
    ]
  },
  {
    type: t("chronos.app.instruments.widgets.soul.efemerisFormulas.social"),
    planets: [
      ObjectType.Jupiter,
      ObjectType.Saturn
    ]
  },
  {
    type: t("chronos.app.instruments.widgets.soul.efemerisFormulas.transuranium"),
    planets: [
      ObjectType.Pluto,
      ObjectType.Uranus,
      ObjectType.Neptune
    ]
  }
];
