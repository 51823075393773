import BuyFormations from 'src/app/pages/settings/components/payments/BuyFormations';
import { useTranslation } from 'src/i18n/useTranslation';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, IGenInfo } from 'src/libs';
import { PopupRight } from 'src/ui/Popups/PopupRight';

export default function Info(props: {
  onAdd(): void;
  onBuild(): void;
  info: IGenInfo;
}
) {
  
  const [openBuyPopup, setOpenBuyPopup] = useState(false);
  const { t } = useTranslation();

  return (
    <Container>
      <Count count={props.info.count}>
        {props.info.count}
      </Count>

      {t("chronos.app.instruments.widgets.genDialog.constructions")}

      <InfoC>
        {props.info.count
          ? t("chronos.app.instruments.widgets.genDialog.warning")
          : t("chronos.app.instruments.widgets.genDialog.warning.info")
        }
      </InfoC>

      <Buttons>
        <Button
          color="var(--color-blue)"
          onClick={() => {
            setOpenBuyPopup(true);
            //props.onAdd();
            //ym('reachGoal', 'open-add-buildings-dialog');
          }}
        >{t("chronos.app.settings.addMore")}</Button>
        {props.info.count > 0 &&
          <Button
            color="var(--color-blue)"
            onClick={() => {
              props.onBuild();
              //ym('reachGoal', 'create-gen');
            }}
          >{t("chronos.app.instruments.widgets.genDialog.build")}</Button>
        }
      </Buttons>
      <PopupRight open={openBuyPopup} onClose={() => {setOpenBuyPopup(false)}} verticalAlign='flex-end'>
        <BuyFormations onClose={() => {setOpenBuyPopup(false)}} />
      </PopupRight>

    </Container>
  );
  
}

const Container = styled.div` 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Count = styled.div<{ count: number }>`
  font-size: 3rem;

  width: calc(1em * 2);
  height: calc(1em * 2);
  margin: 2rem;
  margin-bottom: 1rem;

  text-align: center;
  line-height: calc(1em * 2);
  vertical-align: middle;

  color: var(--text-primary);
  border: 2px solid var(--text-third);
  border-radius: 50%;

  ${props => props.count == 0 && css`
    border: 2px solid var(--color-red);
  `}
`;

const InfoC = styled.div`
  font-size: 0.875rem;
  text-align: center;
  color: var(--text-secondary);
  margin: 1em 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5em;

`;
