import React from 'react';
import styled, { css } from 'styled-components';

export default function TariffTextBlock({
  title,
  subtitle,
  children
}:{
  title: string,
  subtitle: string,
  children: string
}) {
  return <Container>
    <SubTitle>{subtitle}</SubTitle>
    <Title>{title}</Title>
    <Text>{children}</Text>
  </Container>
}


const Container = styled.div`
  margin-top: 1rem;
`
const SubTitle = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
`
const Title = styled.div`
  font-size: 1rem;
  line-height: 1.1875rem;
`
const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
  > span {
    color: var(--color-blue);
  }
`