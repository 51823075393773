import { Dispatch } from 'redux';
import { IFormData } from 'src/libs';

import { signOut } from '../profile/actions';
import api, { IShortFormData } from 'src/api';
import { IActionFormsGetLoading, IActionFormsGetSuccess } from './types';

export const FORMS_GET_SUCCESS = 'FORMS_GET_SUCCESS';
export const FORMS_GET_LOADING = 'FORMS_GET_LOADING';

export const getForms = (loading: boolean = true) => {
  return (dispatch: Dispatch) => {
      loading && dispatch(getLoading());
      api.forms()
          .then((data: IShortFormData[]) => { dispatch(getSuccess(data)); })
          .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const deleteForm = (id: number) => {
  return (dispatch: Dispatch) => {
      dispatch(getLoading());
      api.deleteForm(id)
          .then((data: IShortFormData[]) => { dispatch(getSuccess(data)) })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const pinForm = (id: number, value: boolean) => {
  return (dispatch: Dispatch) => {
      dispatch(getLoading());
      api.setFormPin(id, value)
          .then((data: IShortFormData[]) => { dispatch(getSuccess(data)) })
          .catch(_ => { signOut(_)(dispatch); });
  };
}

export const colorForm = (id: number, value: string) => {
  return (dispatch: Dispatch) => {
      dispatch(getLoading());
      api.setFormColor(id, value)
          .then((data: IShortFormData[]) => { dispatch(getSuccess(data)) })
          .catch(_ => { signOut(_)(dispatch); });
  };
}

export const folderForm = (id: number, folderId: number, callback:() => void) => {
  return (dispatch: Dispatch) => {
      api.updateFormFolder(id, folderId)
          .then(() => { callback && callback(); })
          .catch(_ => { signOut(_)(dispatch); });
  };
}

export const labelFormAdd = (id: number, labelId: number, callback:() => void) => {
  return (dispatch: Dispatch) => {
      api.addFormLabel(id, labelId)
          .then(() => { callback && callback(); })
          .catch(_ => { signOut(_)(dispatch); });
  };
}

export const labelFormRemove = (formId: number, labelId: number, callback:() => void) => {
  return (dispatch: Dispatch) => {
      api.deleteFormLabel(formId, labelId)
          .then(() => { callback && callback(); })
          .catch(_ => { signOut(_)(dispatch); });
  };
}

export const addForm = (form: IFormData, callback: Function) => {
  return (dispatch: Dispatch) => {
    api.addForm(form)
        .then((data: {
          id: number;
          forms: IShortFormData[];
        }) => { dispatch(getSuccess(data.forms)); callback(data);  })
        .catch(_ => { signOut(_)(dispatch); })
  };
}

export const updateForm = (form: IFormData, callback: Function) => {
  return (dispatch: Dispatch) => {
    api.updateForm(form)
        .then((data: {
          form: IShortFormData;
        }) => {
          api.forms()
          .then((forms: IShortFormData[]) => {
            dispatch(getSuccess(forms));
            callback(data.form);
          })
          .catch((_: Error) => { signOut(_)(dispatch); });
        })
        .catch(_ => { signOut(_)(dispatch);;})// })
  };
}

const getLoading = (): IActionFormsGetLoading => {
  return {
    type: FORMS_GET_LOADING
  }
}

const getSuccess = (data: IShortFormData[]): IActionFormsGetSuccess => {
  return {
    type: FORMS_GET_SUCCESS,
    payload: data
  }
}
