import React from 'react';

import {
  IObject,
  ObjectType,
  isNotTransits,
  signs,
  getSign,
  IFixedStar
} from 'src/libs';

import { CirclePartProps, Group } from './index';

import { degToString } from 'src/api';
import i18n from 'src/i18n/i18n';
import { t } from 'i18next';
import { intersection } from 'lodash';
import { hideMapInfoPopup, showMapInfoPopup } from '../../Maps';
import { arrangeObjects as getArrangeObjects, fortuneLon } from './Objects';
import styled from 'styled-components';

interface IFixedStarsProps extends CirclePartProps {
  objects: IObject[];
  houses: number[];
  fixedStars: IFixedStar[];
  horarSigns: number[];
  soulStrongs?: number[];
  isExt?: boolean;
  t: (v: string, vars?: any) => string;
}

export interface IArrangedObject extends IObject {
  id: number;
  alon: number;
  order: number;
}

interface IFixedStarExt extends IFixedStar {
  drawLon?: number;
}

interface IStarDrawObject {
  type: 'house' | 'object' | 'double';
  lon: number;
  signId: number,
  sign?: string;
  stars: IFixedStarExt[],
  iconName: string
}

export default React.memo(function FixedStars(props: IFixedStarsProps) {
  const w3Url = 'http://www.w3.org/2000/svg';
  const _container = React.createRef<SVGGElement>();

  const { computedRadius, personalization } = props;
  const orbis = 1;
  const iconSize = props.radius * personalization.circle.objects.iconSize;


  let pointRadius = 0;
  let pointRadiusExt = 0;
  let objectsRadius = 0;

  if (props.hasExt) {
    pointRadius = props.radius * computedRadius.external;
  } else {
    pointRadius = props.radius * computedRadius.zodiacsExternal;
  }

  const offset = props.mode === 'compatibility'
    ? 64
    : props.mode.includes('partner')
      ? 24
      : props.mode === 'directions'
        ? 64
        : ['solars', 'transits', 'prog_natal'].includes(props.mode)
          ? 64
          : 10;
  
  const getAverageLon = (lons: number[]): number | undefined => {
    let result: number | undefined = undefined;
    if (!lons?.length) return result;

    return lons.reduce((acc: number, lon: number) => (acc + lon), 0) / lons.length;
  }

  const createStarGroup = (drawObject: IStarDrawObject, radius: number): SVGGElement => {

    const { stars } = drawObject;
    const isWithCusp = drawObject.type === 'house';
    const isDouble = drawObject.type === 'double';

    const group = document.createElementNS(w3Url, 'g');
    const starIcon = document.createElementNS(w3Url, 'circle');

    const animation = document.createElementNS(w3Url, 'animate');
    animation.setAttribute('attributeName', 'opacity')
    animation.setAttribute('values', '0.3;1;0.3')
    animation.setAttribute('dur', `${Math.floor(Math.random() * (5 - 3 + 1)) + 3}s`)
    animation.setAttribute('repeatCount', 'indefinite')
    starIcon.append(animation)

    if (!stars.length) return group;

    const drawOffset = isWithCusp ? offset + (props.mode === 'horar' ? 10 : 21) : offset;
    const iconPoint = props.point(
      radius + drawOffset,
      drawObject.lon + (isWithCusp ? -3 : 0)
    );

    starIcon.setAttribute('r', '10');

    starIcon.setAttribute('cx', iconPoint.x.toString());
    starIcon.setAttribute('cy', iconPoint.y.toString());
    starIcon.setAttribute('fill', `url(#circle-${stars.length > 2 ? 'star3' : `star${stars.length}`}`);

    const opacity = !props.highlights || props.highlights.includes(drawObject.signId) ? 1 : 0.1;
    group.style.opacity = `${opacity}`;

    const lines = (drawObject.stars as IFixedStarExt[])
      .sort((star1, star2) => {
        return (star1.drawLon! > star2.drawLon!) ? 1 : (star1.drawLon! < star2.drawLon!) ? -1 : 0;
      })
      .map((star) => {
        return `${i18n.language === "ru" ? star.ru : star.en}: ${degToString(star.lon! % 30, { isInternational: false })}`;
      })

    starIcon.onclick = (ev: MouseEvent) => {
      showMapInfoPopup(ev.target as SVGGElement, [`В соединении с ${isWithCusp ? 'куспидом:' : isDouble ? 'куспидом и планетой:' : 'планетой:'}`, ...lines]);
    };

    starIcon.onmouseleave = () => {
      hideMapInfoPopup();
    };

    group.append(starIcon);

    for (let star of drawObject.stars) {
      const starCircle = document.createElementNS(w3Url, 'circle');
      const point = props.point(radius, props.mode === 'horar' ? star.drawLon! : star.lon);
      starCircle.setAttribute('r', '1.1');
      starCircle.setAttribute('cx', point.x.toString());
      starCircle.setAttribute('cy', point.y.toString());
      starCircle.setAttribute('fill', 'var(--workspace-background)')
      starCircle.setAttribute('stroke', 'var(--icon-primary)');
      starCircle.onmouseenter = (ev: MouseEvent) => {
        const lines = [
          `${i18n.language === "ru" ? star.ru : star.en}: ${degToString(star.lon! % 30, { isInternational: false })}`
        ];
        showMapInfoPopup(ev.target as SVGGElement, lines);
      };

      starCircle.onmouseleave = () => {
        hideMapInfoPopup();
      };
      group.append(starCircle)
    }

    return group;
  }

  React.useEffect(() => {
    if (!_container.current) return;

    const cuspConjuctions: IStarDrawObject[] = [];
    const objectConjuctions: IStarDrawObject[] = [];
    const doubleConjuctions: IStarDrawObject[] = [];

    // table to calculate angular displacement-------------
    let tableObjCalcLon: { house: number; cuspLon: number; }[] = [];

    // function to calculate angular displacement----------
    const objCalcLon = (lon: number): number => {
      let result = lon;
      for(let i = 1; i < tableObjCalcLon.length; i++) {
        const prevHouse = tableObjCalcLon[i - 1]?.house
        const currHouse = tableObjCalcLon[i]?.house
        const prevCuspLon = tableObjCalcLon[i - 1]?.cuspLon
        if(lon <= currHouse && lon >= prevHouse) {
          result = tableObjCalcLon[i].cuspLon - (30 * (currHouse - lon)) / (currHouse - prevHouse)
          return result
        }
        if(lon >= currHouse && lon >= prevHouse && prevHouse >= currHouse) {
          result = prevCuspLon + (30 * (lon - prevHouse)) / (360 - prevHouse + currHouse)
          return result
        }
        if(lon <= currHouse && prevHouse >= currHouse) {
          result = prevCuspLon + (30 * (360 - prevHouse + lon)) / (360 - prevHouse + currHouse)
          return result
        }
      }
      if(lon < tableObjCalcLon[0]?.house) {
        result = tableObjCalcLon[0].cuspLon - (30 * (tableObjCalcLon[0]?.house - lon)) / Math.abs(tableObjCalcLon[0]?.house - tableObjCalcLon[tableObjCalcLon.length-1]?.house) 
        return result
      }
      if (lon > tableObjCalcLon[tableObjCalcLon.length - 1]?.house) {
        result = tableObjCalcLon[tableObjCalcLon.length - 1].cuspLon + (30 * (lon - tableObjCalcLon[tableObjCalcLon.length - 1]?.house)) / (360 - tableObjCalcLon[tableObjCalcLon.length - 1]?.house + tableObjCalcLon[0]?.house);
        return result;
      }
      return result;
    }

    props.houses.forEach((house, idx) => {
      const cuspLon = ['horar', 'soul'].includes(props.mode) ? (props.mode === 'soul' ? 0 : 180) + idx * 30 : props.houses[idx];
      tableObjCalcLon.push({house, cuspLon})

      const stars = (props.fixedStars as IFixedStarExt[]).reduce((acc, star) => {
        if (Math.abs((star.lon) - house) <= orbis) {
          star.drawLon = cuspLon;
          acc.push(star);
          // @ts-ignore
          // console.log(`star: lon - ${star.lon} sign: ${star.signRu} - cups: index: ${idx} lon: ${cuspLon} realLon: ${house}`)
        }
        return acc;
      }, [] as IFixedStarExt[]);


      if (stars.length) {
        cuspConjuctions.push({
          type: 'house',
          lon: cuspLon,
          signId: getSign(house),
          sign: t(signs[getSign(house)].ru),
          stars,
          iconName: `${stars.length === 1 ? 'star' : (stars.length === 2 ? 'two' : 'several')}`
        })
      };
    })

    const orderSize = props.mode === 'horar' ?
      3
      : props.mode === 'soul'
        ? 1
        : personalization.circle.objects.location === 1
          ? Math.floor((objectsRadius - (props.isExt ? pointRadiusExt : pointRadius)) * 2 / (iconSize * 1.6)) || 1
          : 1;


    let arrangeObjects: IArrangedObject[] = getArrangeObjects(
      props.objects
        .filter((obj, id) => (id !== ObjectType.Chiron || !isNotTransits(props.mode)) && (props.mode === 'soul' || id !== ObjectType.Selena))
        .map((obj, id) => {
          if (props.mode === 'soul' && id === ObjectType.SouthNode) {
            return {
              ...obj,
              lon: fortuneLon(props.objects, props.houses)
            };
          }
          return obj;
        })
      ,
      props.houses,
      +(iconSize / 4).toFixed(2),
      props.horarSigns,
      orderSize,
      props.soulStrongs || [],
      // store.activeAstroProfile?.showHigherPlanets
    );

    for (let object of arrangeObjects) {
      if ([ObjectType.Chiron, ObjectType.Lilith, ObjectType.SouthNode, ObjectType.NorthNode].includes(object.id) || (props.mode === 'horar' && object.id === ObjectType.Selena)) continue;
      // if (props.mode === 'horar' && ([ObjectType.Uranus, ObjectType.Neptune, ObjectType.Pluto].includes(object.id))) continue;

      const isFortune = props.mode === 'soul' && object.id === ObjectType.SouthNode;
      const objectLon = isFortune ? fortuneLon(props.objects, props.houses.length ? props.houses : [0]) : props.objects[object.id].lon;

      const stars = (props.fixedStars as IFixedStarExt[]).reduce((acc, star) => {
        if (Math.abs(star.lon - objectLon) <= orbis) {
          star.drawLon = props.mode === 'horar' ? objCalcLon(star.lon) : star.lon;
          acc.push(star);
        }
        return acc;
      }, [] as IFixedStarExt[]);

      if (!stars.length) continue;

      objectConjuctions.push({
        type: 'object',
        lon: props.mode === 'horar' ? objCalcLon(getAverageLon(stars.map((star) => star.lon))!) : getAverageLon(stars.map((star) => star.lon))!,
        signId: getSign(objectLon),
        sign: t(signs[getSign(objectLon)].ru),
        stars,
        iconName:
          `${stars.length === 1 ? 'star' : (stars.length === 2 ? 'two' : 'several')}`
      })

    }

    cuspConjuctions.forEach((cuspConjuction, i) => {
      let cuspStars = cuspConjuction.stars;

      objectConjuctions.forEach((objectConjuction, j) => {
        let objectStars = objectConjuction.stars;

        const common = intersection(cuspStars, objectStars);

        if (common.length) {
          const commonLon = common.map(s => s.drawLon);
          const lon = commonLon[0];

          doubleConjuctions.push({
            type: 'double',
            lon: lon!,
            signId: getSign(lon!),
            sign: t(signs[getSign(lon!)].ru),
            stars: common,
            iconName:
              `${common.length === 1 ? 'star' : (common.length === 2 ? 'two' : 'several')}`
          });

          cuspConjuctions[i].stars = cuspStars.filter((star) => !commonLon.includes(star.drawLon));
          objectConjuctions[j].stars = objectStars.filter((star) => !commonLon.includes(star.drawLon));
        }
      });

    })

    const drawResult = [...cuspConjuctions, ...objectConjuctions, ...doubleConjuctions];
    const drawGroup = document.createElementNS(w3Url, 'g');

    for (let drawObject of drawResult) {
      const svgStar = createStarGroup(drawObject, pointRadius);
      drawGroup.append(svgStar);
    }
    _container.current.innerHTML = '';
    _container.current.appendChild(drawGroup);
  }, [props.houses, props.objects, props.fixedStars, props.highlights, _container.current]);

  return (
    <Group id="circle-fixed-stars" ref={_container}></Group>
  );
});

