import { IObject, IChain } from 'src/libs';
import { dispositors, ObjectType, SignType, StatusType } from 'src/helpers/utils';

function getLinks(objects: IObject[], status = 0) {
  const links: {[key: number]: number} = {};
  for (let p = ObjectType.Sun; p <= ObjectType.Eris; p++) {
    if (!objects[p]) continue;
    const disp = dispositors[Math.floor(objects[p].lon / 30)][status][0];
    if (disp !== p && disp !== null) {
      links[p] = disp;
    }
  }
  return links;
}

function getRevLinks(links: {[key: number]: number}): {[key: number]: number[]} {
  const revLinks: {[key: number]: number[]} = {};

  Object
    .keys(links)
    .forEach((p: string) => {
      const _p = parseInt(p);
      revLinks[links[_p]] = [...revLinks[links[_p]] || [], _p];
    });

  return revLinks;
}

class ChainConstructor {
  private readonly _links: {[key: number]: number} = {};
  private readonly _revLinks: {[key: number]: number[]} = {};
  private readonly _objects: IObject[] = [];
  private readonly _passed: number[] = [];

  constructor(objects: IObject[], status: StatusType) {
    this._objects = objects;
    this._links = getLinks(objects, status);
    this._revLinks = getRevLinks(this._links);
  }

  _findChain(p = 0, path: number[] = []): number[] {
    const cycled = path.includes(p);
    path.push(p);
    return this._links[p] === undefined || cycled ? path : this._findChain(this._links[p], path);
  }

  _findCenters() {
    const centers = [];
    const cycled = new Set();

    for (let p = ObjectType.Sun; p <= ObjectType.Pluto; p++) {
      const chain = this._findChain(p);

      if (chain.length === 1) {
        centers.push(chain);
      } else if (chain[0] === chain[chain.length - 1]) {
        chain.pop();
        const key = [...chain].sort((a, b) => a - b).join(',');
        if (cycled.has(key)) { continue }
        centers.push(chain);
        cycled.add(key);
      }
    }

    return centers;
  }

  _buildPlanet(p: number, cycled: number[], chain: IChain, center: boolean, orbit: number) {
    this._passed.push(p);

    const prev = (this._revLinks[p] || []).filter((e: number) => e !== p);

    prev.forEach((p: number) => !this._passed.includes(p) && this._buildPlanet(p, cycled, chain, false, orbit + (cycled.some(c => c === p) ? 0 : 1)));

    chain.objects[p] = { center, prev, minor: this._objects[p].speed < 0, orbit };
  }

  private _buildChain(c: number[]): IChain {
    const chain = {
      objects: {}
    };

    c.forEach(p => this._buildPlanet(p, c, chain, true, 0));

    return chain;
  }

  private _findStrong(disp: {[key: number]: (number | null)[]}, object: number, sign: number) {
    if (object === ObjectType.Mercury) {
      if (sign === SignType.Virgo) return 6;
      if (sign === SignType.Gemini) return 6;
      if (sign === SignType.Sagittarius) return 1;
      if (sign === SignType.Pisces) return 0;
    }
    return StatusType.detriment - Object.values(disp).findIndex(value => value.includes(object));
  }

  getChains(): IChain[] {
    return this
      ._findCenters()
      .map(c => this._buildChain(c));
  }

  getStrongs() {
    const strongs = [];
    for (let p = ObjectType.Sun; p <= ObjectType.Pluto; p++) {
      strongs.push(this._findStrong(dispositors[Math.floor(this._objects[p].lon / 30)], p, Math.floor(this._objects[p].lon / 30)));
    }
    return strongs;
  }

  getRulers() {
    const rulers = [];
    for (let s = SignType.Aries; s <= SignType.Pisces; s++) {
      rulers.push(dispositors[s][0][0]);
    }
    return rulers;
  }
}

export function Dispositors(objects: IObject[], status = StatusType.ruler) {
  const chain = new ChainConstructor(objects, status);
  return {
    objects,
    chains: chain.getChains(),
    strongs: chain.getStrongs(),
    objectsExt: chain.getRulers()
  };
}
