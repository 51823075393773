import { Astro, CircleMode } from "src/libs";
import wasm from "./wasm";

export const CircleModes: CircleMode[] = [
    'natal', 'transits', 'solars', 'directions', 'prog_prog', 'prog_natal', 'horar', 'syn_natal',
    'partner1', 'partner2', 'partner3', 'partner4', 'partner5',
    'partner6', 'partner7', 'partner8', 'partner9', 'partner10',
    'compatibility1', 'compatibility2', 'compatibility3', 'compatibility4', 'compatibility5',
    'compatibility6', 'compatibility7', 'compatibility8', 'compatibility9', 'compatibility10',
];

export default new Astro(wasm)