import React from 'react';
import { IAspect } from 'src/libs';

export interface ILocalTrend {
  actualTo: string;
  affect?: string;
  aspect?: IAspect;
  compensation: string | null;
  compensationIntro: string;
  items?: (string | {
    marker?: string;
    text?: string;
  })[];
  reason: string | null;
  relevance: string | number;
  trendName: string;
}
export interface ILocalTrends {
  date: string;
  time: string;
  trends?: ILocalTrend[];
  heading: string;
  comment: string;
}

const LocalTrends = (props: ILocalTrends) => {
  return (
    <div className="trends">
      <div className="print-inseparable">
        <h1>{props.heading}</h1>
        <div className='comment'>{props.comment}</div><br/>
      </div>
      <div className="local-trends">
        {props
          .trends?.map((t, i) => (
            <div key={`trent_${i}`} className="local-trend">
              { t.trendName &&
                  <h2>{t.trendName}:</h2>
              }
              <span className="actual">
                {t.actualTo}
              </span>
              { t.reason &&
                  <p className="ap-reasons">
                    {t.reason}
                  </p>
              }
              <ul className='ul-with-items'>
                {t.items?.map((item, j) => (
                  <li key={`trent_${i}_item_${j}`} className="list-item gray">
                    {typeof item === "string" ?
                      item :
                      `${item.marker} ${item.text}`
                    }
                  </li>
                ))
                }
                <br/>
              </ul>

              { t.affect && <p className="ap-affects">{t.affect}</p>}

              {t.compensation &&
                <p className="compensation">
                  ! {t.compensation}
                </p>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default LocalTrends;