import { ObjectType } from "src/libs";

export default {
  oneCenter: {
    rest: "chronos.guide.soul.oneCenter.rest",
    alienation: "chronos.guide.soul.oneCenter.alienation",
    restPlanets: {
      [ObjectType.Sun]: "chronos.guide.soul.oneCenter.restPlanets.Sun",
      [ObjectType.Moon]: "chronos.guide.soul.oneCenter.restPlanets.Moon",
      [ObjectType.Mercury]: "chronos.guide.soul.oneCenter.restPlanets.Mercury",
      [ObjectType.Venus]: "chronos.guide.soul.oneCenter.restPlanets.Venus",
      [ObjectType.Mars]: "chronos.guide.soul.oneCenter.restPlanets.Mars",
      [ObjectType.Jupiter]: "chronos.guide.soul.oneCenter.restPlanets.Jupiter",
      [ObjectType.Saturn]: "chronos.guide.soul.oneCenter.restPlanets.Saturn",
      [ObjectType.Uranus]: "chronos.guide.soul.oneCenter.restPlanets.Uranus",
      [ObjectType.Neptune]: "chronos.guide.soul.oneCenter.restPlanets.Neptune",
      [ObjectType.Pluto]: "chronos.guide.soul.oneCenter.restPlanets.Pluto"
    },
    alienationPlanets: {
      [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
      [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
      [ObjectType.Mercury]: "chronos.guide.soul.oneCenter.alienationPlanets.Mercury",
      [ObjectType.Venus]: "chronos.guide.soul.oneCenter.alienationPlanets.Venus",
      [ObjectType.Mars]: "chronos.guide.soul.oneCenter.alienationPlanets.Mars",
      [ObjectType.Jupiter]: "chronos.guide.soul.oneCenter.alienationPlanets.Jupiter",
      [ObjectType.Saturn]: "chronos.guide.soul.oneCenter.alienationPlanets.Saturn",
      [ObjectType.Uranus]: "chronos.guide.soul.oneCenter.alienationPlanets.Uranus",
      [ObjectType.Neptune]: "chronos.guide.soul.oneCenter.alienationPlanets.Neptune",
      [ObjectType.Pluto]: "chronos.guide.soul.oneCenter.alienationPlanets.Pluto"
    },
    commonPlanets: {
      [ObjectType.Sun]: "chronos.guide.soul.oneCenter.commonPlanets.Sun",
      [ObjectType.Moon]: "chronos.guide.soul.oneCenter.commonPlanets.Moon",
      [ObjectType.Mercury]: "chronos.guide.soul.oneCenter.commonPlanets.Mercury",
      [ObjectType.Venus]: "chronos.guide.soul.oneCenter.commonPlanets.Venus",
      [ObjectType.Mars]: "chronos.guide.soul.oneCenter.commonPlanets.Mars",
      [ObjectType.Jupiter]: "chronos.guide.soul.oneCenter.commonPlanets.Jupiter",
      [ObjectType.Saturn]: "chronos.guide.soul.oneCenter.commonPlanets.Saturn",
      [ObjectType.Uranus]: "chronos.guide.soul.oneCenter.commonPlanets.Uranus",
      [ObjectType.Neptune]: "chronos.guide.soul.oneCenter.commonPlanets.Neptune",
      [ObjectType.Pluto]: "chronos.guide.soul.oneCenter.commonPlanets.Pluto"
    },
    retroDescription: "chronos.guide.soul.oneCenter.retroDescription",
    commonPlanetsRetro: {
      [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
      [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
      [ObjectType.Mercury]: "chronos.guide.soul.oneCenter.commonPlanetsRetro.Mercury",
      [ObjectType.Venus]: "chronos.guide.soul.oneCenter.commonPlanetsRetro.Venus",
      [ObjectType.Mars]: "chronos.guide.soul.oneCenter.commonPlanetsRetro.Mars",
      [ObjectType.Jupiter]: "chronos.guide.soul.oneCenter.commonPlanetsRetro.Jupiter",
      [ObjectType.Saturn]: "chronos.guide.soul.oneCenter.commonPlanetsRetro.Saturn",
      [ObjectType.Uranus]: "chronos.guide.soul.oneCenter.commonPlanetsRetro.Uranus",
      [ObjectType.Neptune]: "chronos.guide.soul.oneCenter.commonPlanetsRetro.Neptune",
      [ObjectType.Pluto]: "chronos.guide.soul.oneCenter.commonPlanetsRetro.Pluto"
    }
  },
  twoCenters: {
    libra: "chronos.guide.soul.twoCenters.libra",
    pendulum: "chronos.guide.soul.twoCenters.pendulum",
    pie: "chronos.guide.soul.twoCenters.pie"
  },
  threeCenters: {
    description: "chronos.guide.soul.threeCenters.description"
  },
  orbits: {
    1: {
      description: "chronos.guide.soul.orbits.1.description",
      retro: "chronos.guide.soul.orbits.1.retro",
      directPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.orbits.1.directPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.orbits.1.directPlanets.Moon",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.1.directPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.1.directPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.1.directPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.1.directPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.1.directPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.1.directPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.1.directPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.1.directPlanets.Pluto"
      },
      retroPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.1.retroPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.1.retroPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.1.retroPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.1.retroPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.1.retroPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.1.retroPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.1.retroPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.1.retroPlanets.Pluto"
      }
    },
    2: {
      description: "chronos.guide.soul.orbits.2.description",
      retro: "chronos.guide.soul.orbits.1.retro",
      directPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.orbits.1.directPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.orbits.2.directPlanets.Moon",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.2.directPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.2.directPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.2.directPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.2.directPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.2.directPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.2.directPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.2.directPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.2.directPlanets.Pluto"
      },
      retroPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.2.retroPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.2.retroPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.2.retroPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.2.retroPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.2.retroPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.2.retroPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.2.retroPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.2.retroPlanets.Pluto"
      }
    },
    3: {
      description: "chronos.guide.soul.orbits.3.description",
      retro: "chronos.guide.soul.orbits.1.retro",
      directPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.orbits.1.directPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.orbits.3.directPlanets.Moon",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.3.directPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.3.directPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.3.directPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.3.directPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.3.directPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.3.directPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.3.directPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.3.directPlanets.Pluto"
      },
      retroPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.3.retroPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.3.retroPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.3.retroPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.3.retroPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.3.retroPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.3.retroPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.3.retroPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.3.retroPlanets.Pluto"
      }
    },
    4: {
      description: "chronos.guide.soul.orbits.4.description",
      retro: "chronos.guide.soul.orbits.1.retro",
      directPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.orbits.1.directPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.orbits.4.directPlanets.Moon",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.4.directPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.4.directPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.4.directPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.4.directPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.4.directPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.4.directPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.4.directPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.4.directPlanets.Pluto"
      },
      retroPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.4.retroPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.4.retroPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.4.retroPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.4.retroPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.4.retroPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.4.retroPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.4.retroPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.4.retroPlanets.Pluto"
      }
    },
    5: {
      description: "chronos.guide.soul.orbits.5.description",
      retro: "chronos.guide.soul.orbits.5.retro",
      directPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.orbits.1.directPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.orbits.5.directPlanets.Moon",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.5.directPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.5.directPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.5.directPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.5.directPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.5.directPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.5.directPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.5.directPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.5.directPlanets.Pluto"
      },
      retroPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.5.retroPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.5.retroPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.5.retroPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.5.retroPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.5.retroPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.5.retroPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.5.retroPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.5.retroPlanets.Pluto"
      }
    },
    6: {
      description: "chronos.guide.soul.orbits.6.description",
      retro: "chronos.guide.soul.orbits.1.retro",
      directPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.orbits.1.directPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.orbits.6.directPlanets.Moon",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.6.directPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.6.directPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.6.directPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.6.directPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.6.directPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.6.directPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.6.directPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.6.directPlanets.Pluto"
      },
      retroPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.5.retroPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.6.retroPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.6.retroPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.6.retroPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.6.retroPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.6.retroPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.6.retroPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.6.retroPlanets.Pluto"
      }
    },
    7: {
      description: "chronos.guide.soul.orbits.7.description",
      retro: "chronos.guide.soul.orbits.1.retro",
      directPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.orbits.1.directPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.orbits.7.directPlanets.Moon",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.7.directPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.7.directPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.7.directPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.7.directPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.7.directPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.7.directPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.7.directPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.7.directPlanets.Pluto"
      },
      retroPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.5.retroPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.7.retroPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.7.retroPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.7.retroPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.7.retroPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.7.retroPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.7.retroPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.7.retroPlanets.Pluto"
      }
    },
    8: {
      description: "chronos.guide.soul.orbits.8.description",
      retro: "chronos.guide.soul.orbits.8.retro",
      directPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.orbits.1.directPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.orbits.8.directPlanets.Moon",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.8.directPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.8.directPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.8.directPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.8.directPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.8.directPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.8.directPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.8.directPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.8.directPlanets.Pluto"
      },
      retroPlanets: {
        [ObjectType.Sun]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Moon]: "chronos.guide.soul.oneCenter.alienationPlanets.Sun",
        [ObjectType.Mercury]: "chronos.guide.soul.orbits.4.retroPlanets.Mercury",
        [ObjectType.Venus]: "chronos.guide.soul.orbits.8.retroPlanets.Venus",
        [ObjectType.Mars]: "chronos.guide.soul.orbits.8.retroPlanets.Mars",
        [ObjectType.Jupiter]: "chronos.guide.soul.orbits.8.retroPlanets.Jupiter",
        [ObjectType.Saturn]: "chronos.guide.soul.orbits.8.retroPlanets.Saturn",
        [ObjectType.Uranus]: "chronos.guide.soul.orbits.8.retroPlanets.Uranus",
        [ObjectType.Neptune]: "chronos.guide.soul.orbits.8.retroPlanets.Neptune",
        [ObjectType.Pluto]: "chronos.guide.soul.orbits.8.retroPlanets.Pluto"
      }
    }
  }
}