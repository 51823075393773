import api, { ISubscription } from 'src/api';
import { Dispatch } from 'redux';

import { signOut } from '../profile/actions';
import { 
  IActionPaymentsChangeAutoSuccess, 
  IActionPaymentsFullInfoGetLoading, 
  IActionPaymentsFullInfoGetSuccess, 
  IActionPaymentsGetLoading, 
  IActionPaymentsGetSuccess, 
  IActionTariffsGetLoading, 
  IActionTariffsGetSuccess 
} from './types';
import { IPaymentFullInfo, ITransaction } from 'src/libs';

export const PAYMENTS_FULL_INFO_GET_SUCCESS = 'PAYMENTS_FULL_INFO_GET_SUCCESS';
export const PAYMENTS_FULL_INFO_GET_LOADING = 'PAYMENTS_FULL_INFO_GET_LOADING';

export const PAYMENTS_GET_SUCCESS = 'PAYMENTS_GET_SUCCESS';
export const PAYMENTS_GET_LOADING = 'PAYMENTS_GET_LOADING';

export const PAYMENTS_CHANGE_AUTO_SUCCESS = 'PAYMENTS_CHANGE_AUTO_SUCCESS';

export const TARIFFS_GET_SUCCESS = 'TARIFFS_GET_SUCCESS';
export const TARIFFS_GET_LOADING = 'TARIFFS_GET_LOADING';


export const getPaymentsFullInfo = () => {
  return (dispatch: Dispatch) => {
      dispatch(getPaymentsFullInfoLoading());
      api.paymentFullInfo()
          .then((data: IPaymentFullInfo) => { dispatch(getPaymentsFullInfoSuccess(data)) })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const getPayments = () => {
  return (dispatch: Dispatch) => {
      dispatch(getPaymentsLoading());
      api.paymentList()
          .then((data: ITransaction[]) => { dispatch(getPaymentsSuccess(data)) })
        .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const changeAutoPayments = (auto: boolean) => {
  return (dispatch: Dispatch) => {
    api.autopaymentChange(auto)
          .then((_: boolean) => { dispatch(changeAutoPaymentsSuccess(auto)) })
          .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const subscriptionChange = (subs: number[], callback:() => void) => {
  return (dispatch: Dispatch) => {
    api.subscriptionChange(subs)
          .then((_: IPaymentFullInfo) => { callback(); })
          .catch((_: Error) => { signOut(_)(dispatch); });
  };
}

export const getTariffs = () => {
  return (dispatch: Dispatch) => {
    dispatch(getTariffsLoading());
    api.getTariffs()
      .then((data: ISubscription[]) => dispatch(getTariffsSuccess(data)))
      .catch((_: Error) => signOut(_)(dispatch));
  };
}




const getPaymentsFullInfoLoading = (): IActionPaymentsFullInfoGetLoading => {
  return {
    type: PAYMENTS_FULL_INFO_GET_LOADING
  }
}

const getPaymentsFullInfoSuccess = (data: IPaymentFullInfo): IActionPaymentsFullInfoGetSuccess => {
  return {
    type: PAYMENTS_FULL_INFO_GET_SUCCESS,
    payload: data
  }
}

const getPaymentsLoading = (): IActionPaymentsGetLoading => {
  return {
    type: PAYMENTS_GET_LOADING
  }
}

const getPaymentsSuccess = (data: ITransaction[]): IActionPaymentsGetSuccess => {
  return {
    type: PAYMENTS_GET_SUCCESS,
    payload: data
  }
}

const changeAutoPaymentsSuccess = (auto: boolean): IActionPaymentsChangeAutoSuccess => {
  return {
    type: PAYMENTS_CHANGE_AUTO_SUCCESS,
    payload: auto
  }
}

const getTariffsLoading = (): IActionTariffsGetLoading => {
  return {
    type: TARIFFS_GET_LOADING
  }
}

const getTariffsSuccess = (data: ISubscription[]): IActionTariffsGetSuccess => {
  return {
    type: TARIFFS_GET_SUCCESS,
    payload: data
  }
}
