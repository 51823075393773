import { IPlace, degToString, toDateTime } from 'src/libs';
import { getProfile } from 'src/store/localStorage/profile';

export function checkGenChanged(gen: {
  autoprediction: boolean;
  devplan: boolean;
  rectification: boolean;
}, dt1: string, dt2: string, pl1: IPlace, pl2: IPlace): boolean {

  const { subscriptions } = getProfile();
  const isProfTariff = subscriptions?.length && subscriptions[subscriptions.length - 1] === 4;

  return (gen.autoprediction || gen.devplan || gen.rectification) && !isProfTariff &&
  (
    toDateTime(dt1).date !== toDateTime(dt2).date ||
    degToString(pl1.lat, { isLatitude: true }) !== degToString(pl2.lat, { isLatitude: true }) ||
    degToString(pl1.lon) !== degToString(pl2.lon)
  );
}