import React, { ReactNode } from 'react';
import styled from 'styled-components';

export default function Label({
  children,
  color
}: {
  children: ReactNode,
  color: string
}) {
  return <Container color={color}>{children}</Container>
}

const Container = styled.div<{color: string}>`
  display: inline-block;
  padding: 0.15rem 0.375rem 0.05rem 0.375rem;
  background: ${p => p.color}55;
  border-radius: 0.25rem;
  color: var(--text-primary);
  font-size: 0.75rem;
`