// FIXME:
// @ts-nocheck

import { astroObjects, IChain, ObjectType } from 'src/libs';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// import { ReactMemo } from 'src/hooks';

import { IWidgetData } from 'src/pages/Instruments/Widgets/Widget';
import soulInterpretation from 'src/guide/soul';
import { objectsIcons } from 'src/icons';
import { useTranslation } from 'src/i18n/useTranslation';

const Enumerable = [
  "astro.facts.first", 
  "chronos.app.instruments.widgets.prompts.houses.5.description.facts.2.value", 
  "chronos.app.instruments.widgets.prompts.houses.8.description.facts.2.value", 
  "chronos.app.instruments.widgets.prompts.houses.11.description.facts.2.value", 
  "base.fifth", 
  "base.sixth"
];
const EnumerablePlanets = [
  "base.enumerablePlanets.0", 
  "base.enumerablePlanets.1", 
  "base.enumerablePlanets.2", 
  "base.enumerablePlanets.3", 
  "base.enumerablePlanets.4", 
  "base.enumerablePlanets.5", 
  "base.enumerablePlanets.6", 
  "base.enumerablePlanets.7", 
  "base.enumerablePlanets.8", 
  "base.enumerablePlanets.9", 
  "base.enumerablePlanets.10", 
  "base.enumerablePlanets.11", 
  "base.enumerablePlanets.12", 
  "base.enumerablePlanets.13"];
const Orbits = {
  '1': "chronos.app.instruments.widgets.interpretation.orbits.1.mercury",
  '2': "chronos.app.instruments.widgets.interpretation.orbits.2.venus",
  '3': "chronos.app.instruments.widgets.interpretation.orbits.3.mars",
  '4': "chronos.app.instruments.widgets.interpretation.orbits.4.jupiter",
  '5': "chronos.app.instruments.widgets.interpretation.orbits.5.saturn",
  '6': "chronos.app.instruments.widgets.interpretation.orbits.6.uranium",
  '7': "chronos.app.instruments.widgets.interpretation.orbits.7.neptune",
  '8': "chronos.app.instruments.widgets.interpretation.orbits.8.pluto"
};
const Planets = [
  "chronos.app.instruments.widgets.interpretation.planets.0", 
  "chronos.app.instruments.widgets.interpretation.planets.1", 
  "chronos.app.instruments.widgets.interpretation.planets.2", 
  "chronos.app.instruments.widgets.interpretation.planets.3", 
  "chronos.app.instruments.widgets.interpretation.planets.4", 
  "chronos.app.instruments.widgets.interpretation.planets.5", 
  "chronos.app.instruments.widgets.interpretation.planets.6", 
  "chronos.app.instruments.widgets.interpretation.planets.7", 
  "chronos.app.instruments.widgets.interpretation.planets.8", 
  "chronos.app.instruments.widgets.interpretation.planets.9", 
  "chronos.app.instruments.widgets.interpretation.planets.10", 
  "chronos.app.instruments.widgets.interpretation.planets.11"
];

export default React.memo(function SoulInterpretation(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const [centers, setCenters] = useState(0);
  const [chains, setChains] = useState<IChain[] | null>(null);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const [ map ] = props.data.maps;
    const tempChains = JSON.parse(JSON.stringify(map.chains));

    setChains(tempChains?.map(chain => {
      Object.keys(chain.objects).forEach(obj => {
        chain.objects[parseInt(obj, 10)].prev = chain.objects[parseInt(obj, 10)].prev.filter(prev => prev <= ObjectType.Pluto);
        if (parseInt(obj, 10) > ObjectType.Pluto) delete chain.objects[parseInt(obj, 10)];
      });
      return chain;
    }));

    setCenters(map.chains?.length);
  }, [props.data.maps]);

  useEffect(() => {
    const id = `#planet${String(props.data.descriptionSoul)}`;
    const widgetContainer = document.querySelector('#widget-container');

    if (props.data.descriptionSoul !== null && widgetContainer && containerRef.current && containerRef.current.querySelector(id)) {
      const anchor = containerRef.current.querySelector(id)!;

      widgetContainer.scrollTop = anchor.offsetTop + 50;
    }
  }, [props.data.descriptionSoul, containerRef.current]);

  const getCentersSubheaderText = (centers: number) => {
    switch (centers) {
      case 1: return "chronos.app.instruments.widgets.interpretation.centersSubheaderText.1";
      case 2: return "chronos.app.instruments.widgets.interpretation.centersSubheaderText.2";
      case 3: return "chronos.app.instruments.widgets.interpretation.centersSubheaderText.3";
      default: return "chronos.app.instruments.widgets.interpretation.centersSubheaderText.4";
    }
  };

  const getTitleText = (text: string) => <h2>{t(text)}</h2>;

  const getSubtitleText = (text: string) => <h3>{t(text)}</h3>;

  const getPlanetText = (text: string, planet: string | ObjectType) => {
    const Icon = objectsIcons[planet as ObjectType];

    return <Planet id={`planet${planet}`}>
      <Icon />
      {t(text)}
    </Planet>;
  };

  const getBodyText = (text: string) => <Text>{t(text)}</Text>;

  const getCentersBodyText = (t: Function): JSX.Element => {
    const [ map ] = props.data.maps;
    const body = [];

    chains!.map((chain, i) => {

      const planetsInCenter: ObjectType[] = Object.keys(chain.objects).map(key => parseInt(key, 10)).filter(obj => chain.objects[obj].center);

      body.push(getTitleText(`${t(Enumerable[i])} ${t("chronos.app.instruments.widgets.interpretation.getTitleText.centerConsistsOf")} ${planetsInCenter.length === 1 ? t(EnumerablePlanets[planetsInCenter.length]) : `${t(EnumerablePlanets[planetsInCenter.length])} ${t("chronos.app.instruments.widgets.interpretation.getTitleText.centerConsistsOf.planets")}`}`));

      if (planetsInCenter.length === 1) {
        if (map.objects[planetsInCenter[0]].speed >= 0 && map.strongs![planetsInCenter[0]] === 6 && Object.keys(chain.objects).length === 1) {
          body.push(getSubtitleText(t("chronos.app.instruments.widgets.interpretation.getTitleText.planetInRecreationArea")));
          body.push(getBodyText((soulInterpretation.oneCenter.rest)));
          body.push(getPlanetText(t(astroObjects[planetsInCenter[0]].ru), planetsInCenter[0]));
          body.push(getBodyText(soulInterpretation.oneCenter.restPlanets[planetsInCenter[0]]));
        } else if (map.objects[planetsInCenter[0]].speed < 0 && map.strongs![planetsInCenter[0]] === 6 && Object.keys(chain.objects).length === 1) {
          body.push(getSubtitleText("chronos.app.instruments.widgets.interpretation.getTitleText.planetInexclusionZone"));
          body.push(getBodyText(soulInterpretation.oneCenter.alienation));
          body.push(getPlanetText(`${t("astro.retro")} ${t(astroObjects[planetsInCenter[0]].ru)}`, planetsInCenter[0]));
          body.push(getBodyText(soulInterpretation.oneCenter.alienationPlanets[planetsInCenter[0]]));
        } else {
          const isRetro = map.objects[planetsInCenter[0]].speed < 0;
          body.push(getBodyText(isRetro ? soulInterpretation.oneCenter.retroDescription : ''));
          body.push(getPlanetText(`${isRetro ? t("astro.retro") : ''}${t(astroObjects[planetsInCenter[0]].ru)}`, planetsInCenter[0]));
          body.push(getBodyText(isRetro ? soulInterpretation.oneCenter.commonPlanetsRetro[planetsInCenter[0]] : soulInterpretation.oneCenter.commonPlanets[planetsInCenter[0]]));
        }
      } else if (planetsInCenter.length === 2) {
        const planet1 = chain.objects[planetsInCenter[0]];
        const planet2 = chain.objects[planetsInCenter[1]];
        const isRetro1 = map.objects[planetsInCenter[0]].speed < 0;
        const isRetro2 = map.objects[planetsInCenter[1]].speed < 0;

        if (planet1.prev.length === 1 || planet2.prev.length === 1) {
          body.push(getSubtitleText("astro.itsAScale"));
          body.push(getBodyText(soulInterpretation.twoCenters.libra));
        }
        if (planet1.prev.length !== 1 && planet2.prev.length !== 1) {
          body.push(getSubtitleText(t("astro.itsAPendulum")));
          body.push(getBodyText(soulInterpretation.twoCenters.pendulum));
        }
        if (planet1.prev.length === 1 && planet2.prev.length === 1) {
          body.push(getSubtitleText(t(t("astro.itsAPendulum"))));
          body.push(getBodyText(soulInterpretation.twoCenters.pie));
        }
        body.push(getPlanetText(`${isRetro1 ? t("astro.retro") : ''}${t(astroObjects[planetsInCenter[0]].ru)}`, planetsInCenter[0]));
        body.push(getBodyText(soulInterpretation.oneCenter[isRetro1 ? 'commonPlanetsRetro' : 'restPlanets'][planetsInCenter[0]]));
        body.push(getPlanetText(`${isRetro2 ? t("astro.retro") : ''}${t(astroObjects[planetsInCenter[1]].ru)}`, planetsInCenter[1]));
        body.push(getBodyText(soulInterpretation.oneCenter[isRetro2 ? 'commonPlanetsRetro' : 'restPlanets'][planetsInCenter[1]]));
      } else {
        body.push(getBodyText(soulInterpretation.threeCenters.description));

        for (const p of planetsInCenter) {
          body.push(getPlanetText(`${map.objects[p].speed < 0 ? t("astro.retro") : ''}${t(astroObjects[p].ru)}`, p));
          body.push(getBodyText(soulInterpretation.oneCenter[map.objects[p].speed < 0 ? 'commonPlanetsRetro' : 'restPlanets'][p]));
        }
      }
    });
    const orbits = {};

    for (const chain of chains!) {
      Object.keys(chain.objects).map(obj => {
        if (!orbits[chain.objects[obj].orbit]) orbits[chain.objects[obj].orbit] = [];
        orbits[chain.objects[obj].orbit] = [...orbits[chain.objects[obj].orbit], obj];
      });
    }
    for (const orbit of Object.keys(orbits).slice(1)) {
      body.push(getTitleText(t("chronos.app.instruments.widgets.interpretation.titleText.countOfPlanets", { orbit: t(Orbits[orbit]), count: t(Planets[orbits[orbit].length]) })));
      body.push(getBodyText(t(soulInterpretation.orbits[orbit].description)));

      const isRetro = orbits[orbit].some(planet => map.objects[planet].speed < 0);

      if (isRetro) {
        body.push(getBodyText(soulInterpretation.orbits[orbit].retro));
      }

      for (const planet of orbits[orbit]) {
        const isRetro = map.objects[planet].speed < 0;

        body.push(getPlanetText(`${isRetro ? t("astro.retro") : ''}${t(astroObjects[planet].ru)}`, planet));
        body.push(getBodyText(soulInterpretation.orbits[orbit][isRetro ? 'retroPlanets' : 'directPlanets'][planet]));
      }
    }

    return body;
  };

  return (
    <Container ref={containerRef}>
      <Section>
        <Subheader>
          {t("chronos.app.instruments.widgets.interpretation.inFormulaSoulYouHave")}
          {t(getCentersSubheaderText(centers))}
        </Subheader>
        {chains && <SectionBody>
          {getCentersBodyText(t)}
        </SectionBody>}
      </Section>
    </Container>
  );
});

const Container = styled.div`
  display: block;
  white-space: initial;
  color: var(--text-secondary);
  margin-top: 0.5rem;
  width: 100%;
  overflow: hidden;
`;

const Section = styled.section`
  > h1, h2, h3 {
    color: var(--text-primary);
  }
`;

const Subheader = styled.h1`
  word-wrap: break-word;
`;

const SectionBody = styled.div`
  word-wrap: break-word;
`;

const Planet = styled.h2`
  color: var(--text-primary);
  display: flex;
  align-items: center;
  font-size: 1rem;
  > svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
`;

const Text = styled.p`
  color: var(--text-secondary);
`;
