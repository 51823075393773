import React from 'react';
import styled from 'styled-components';

import { Avatar, IShortFormData } from 'src/libs';

import { PencilIcon } from 'src/assets/icons/system/index';

import { PinIcon } from 'src/assets/icons/system/index';
//import { ConsultIcon } from 'assets/icons/system/index';
import { CopyIcon } from 'src/assets/icons/system/index';
//import { MoveIcon } from 'assets/icons/system/index';
import { PhotoIcon } from 'src/assets/icons/system/index';
import { TagIcon } from 'src/assets/icons/system/index';
import { ThemeIcon } from 'src/assets/icons/system/index';
import { FolderIcon } from 'src/assets/icons/system/index';
import { PersonIcon } from 'src/assets/icons/system/index';
import { DeleteIcon } from 'src/assets/icons/system/index';
//import { LinkIcon } from 'assets/icons/system/index';
import { ShareIcon } from 'src/assets/icons/system/index';

import HitIcon from 'src/ui/HitIcon';
import LineHorizontal from 'src/ui/LineHorizontal';
import LabelsWithIcon from 'src/ui/LabelsWithIcon';
import { useTranslation } from 'src/i18n/useTranslation';

export default function FormOptionsForPopup({
  form,
  actions
}: {
  form: IShortFormData,
  actions: any
}) {

  const { t } = useTranslation();

  return <>
    <Block>
      <Header>
        <Avatar size={36} color={form.color} bgColor="var(--bg-400)"></Avatar>
        <Name>{t(form.name)}</Name>
        <HitIcon><PencilIcon onClick={actions.openEditPopup} /></HitIcon>
      </Header>

      <LineHorizontal />
    </Block>

    <Block>
      <LabelsWithIcon icon={<PinIcon />} short={form.isPinned ? t("chronos.mobile.dashboard.components.editDroupForPopup.unpinTheMap") : t("chronos.mobile.dashboard.components.editDroupForPopup.pinMap")} action={() => actions.togglePin(form)} />
      
      <LabelsWithIcon icon={<PhotoIcon />} short={t("chronos.app.dashboard.addPhoto")} disabled />
      <LabelsWithIcon icon={<ThemeIcon />} short={t("chronos.app.dashboard.color")} arrow={true} action={actions.openColorPopup} />
      <LabelsWithIcon icon={<FolderIcon />} short={t("chronos.app.dashboard.group")} arrow={true} action={actions.openFolderPopup} />
      <LabelsWithIcon icon={<TagIcon />} short={t("chronos.app.dashboard.labels")} arrow={true} action={actions.openLabelPopup} />
      {/*<LabelsWithIcon icon={<MoveIcon />} short="Переместить" arrow={true} disabled />*/}
      {<LabelsWithIcon icon={<PersonIcon />} short={t("chronos.app.addPartner")} arrow={true} action={actions.openAddPartnerPopup} />}
      <LabelsWithIcon icon={<CopyIcon />} short={t("chronos.mobile.dashboard.components.editDroupForPopup.duplicate")} action={() => actions.duplicate(form)} />
      <LineHorizontal />
    </Block>

    <Block>
      <LabelsWithIcon icon={<ShareIcon />} short={t("base.share")} action={actions.openSharePopup} />
      {/*<LabelsWithIcon icon={<LinkIcon />} short="Копировать ссылку" disabled />*/}
      <LineHorizontal />
    </Block>

    <Block>
      <LabelsWithIcon icon={<DeleteIcon />} short={t("chronos.app.removeCard")} color="var(--color-red)" action={actions.openDeletePopup} />
    </Block>

  </>
}

//<LabelsWithIcon icon={<ConsultIcon />} short="Записать на консультацию" />

const Header = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.75rem;
  align-items: center;
  margin-bottom: 1rem;
`

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.5rem;
  line-height: 1em;
`

const Block = styled.div``