import { HousesSystem } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';
import { Label, Labels } from './MainGroup';
import { Radio } from 'src/libs';
import styled from 'styled-components';

export const housesSystemNames = [
  {
    label: "chronos.app.components.editForm.placidus",
    value: HousesSystem.Placidus
  },
  {
    label: "chronos.app.components.editForm.regiomontanus",
    value: HousesSystem.Regiomontanus
  },
  {
    label: "chronos.app.components.editForm.koh",
    value: HousesSystem.Koch
  },
  {
    label: "chronos.app.components.editForm.equal",
    value: HousesSystem.Equal
  }
  // {
  //   label: "Порфириус",
  //   value: HousesSystem.Porphyrius
  // },
];

export function HousesSystemSelect(props: {
  title?: string;
  value: number;
  setValue(v: number): void;
}) {
  const { t } = useTranslation();

  return (
    <>
      {props.title && <Label><Title>{t(props.title)}</Title></Label>}
      <Labels>
        {housesSystemNames
          .map(i =>
            <Label key={i.value}>
              <Radio
                value={i.value}
                label={t(i.label)}
                checked={props.value === i.value}
                onChange={props.setValue}
              />
            </Label>
          )}
      </Labels>
    </>
  );
}

const Title = styled.span`
  font-size: 0.75rem;
	font-weight: 500;
	color: var(--text-secondary);
`
