import React from 'react';
import styled from 'styled-components';

import { PlusSmallIcon } from 'src/assets/icons/system/index';
import { LeftTriangleIcon } from 'src/assets/icons/arrows/index';
import { OkSmallIcon } from 'src/assets/icons/system/index';
//import { MenuIcon } from 'assets/icons/system/index';
import { IFolder, IShortFormData } from 'src/libs';
import { getAllFolders } from 'src/store/reducers/folders/selectors';
import { useSelector } from 'src/store/utils';
import { useTranslation } from 'src/i18n/useTranslation';

export default function FolderSelector({
  form,
  onChange,
  onOpenAddGroupPopup,
  onClose
}:{
  form: IShortFormData;
  onChange(formId: number, folderId: number): void;
  onOpenAddGroupPopup(value: boolean): void;
  onClose(): void;
}) {

  const {data: folders} = useSelector(getAllFolders);
  const { t } = useTranslation();
  const currentFolderId = (form as any).folderId;
  
  return <>
    <Header>
      <div onClick={onClose}><LeftTriangleIcon /></div>
      <HeaderTitle>
        {t("chronos.app.dashboard.group")}
      </HeaderTitle>
      <div onClick={() => onOpenAddGroupPopup(true)}><PlusSmallIcon /></div>
    </Header>
    
    <Folders>
      {folders.map((item: IFolder) => 
        <Folder key={item.id}>
          <div>{currentFolderId === item.id && <OkSmallIcon />}</div>
          <FolderName color={item.color} onClick={() => onChange(form.id, item.id)}>{item.title}</FolderName>
          <div></div>
        </Folder>
      )}
    </Folders>
  </>
}

//<MenuIcon />

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.875rem;

  svg {
    color: var(--text-secondary);
  }
`

 
const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  > svg {
    margin-right: 0.625rem;
  }
`

const Folders = styled.div``

const Folder = styled.div`
  display: grid;
  grid-template-columns: 1.5rem 1fr min-content;
  grid-gap: 1rem;
  align-items: center;

  svg {
    color: var(--text-secondary);
  }
`

const FolderName = styled.div<{color: string}>`
  padding: 0.675rem 0;
  color: ${p => p.color};
`