import React from 'react';
import styled from 'styled-components';

import { PinIcon } from 'src/assets/icons/system/index';
import { MenuIcon } from 'src/assets/icons/system/index';
import { FolderBigIcon } from 'src/assets/icons/system/index';
import { IFolder } from 'src/api';
import { IFolderWithCount } from 'src/store/reducers/folders/types';
import { useTranslation } from 'src/i18n/useTranslation';



export default function GridItemFolder({
  item,
  onOpenMenu,
  onChangeFolder
}: {
  item: IFolder,
  onOpenMenu(f: IFolder): void;
  onChangeFolder(id: number): void;
}){
  const { t, U } = useTranslation();
  return <Container>
    <Buttons>
      <div>{item.is_pinned && <PinIcon />}</div>
      <div><MenuIcon onClick={() => onOpenMenu(item)} /></div>
    </Buttons>
    
    <Image color={item.color} onClick={() => onChangeFolder(item.id)}>
      <FolderBigIcon/>
    </Image>

    <Content onClick={() => onChangeFolder(item.id)}>
      <NameWithIcon>
        <Name>{item.title}</Name>
      </NameWithIcon>
      
      <SmallText>{U("chronos.app.dashboard.map.plural")}: {(item as IFolderWithCount).count}</SmallText>
    </Content>

    

  </Container>
}

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  min-height: 14rem;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-secondary);
`

const Image = styled.div<{color: string}>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 6rem;
  color: ${p => p.color};
  
  > svg {
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const NameWithIcon = styled.div`
  margin-bottom: 0.375rem;
`

const Name = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.875rem;
  overflow: hidden;
`

const SmallText = styled.div`
  color: var(--text-secondary);
  font-size: 0.75rem;
  line-height: 1.2;
`