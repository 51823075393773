import ReactDOM from 'react-dom';

import './theme';

import App from './App';


ReactDOM.render(
  <App />,
  document.getElementById('root')
);
