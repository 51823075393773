import React, { useState } from 'react';
import styled from 'styled-components';

import { useSelector } from 'src/store/utils';
import { newNotifications } from 'src/store/reducers/notifications/selectors';

import AppSwitcher from './AppSwitcher';
import { Avatar } from 'src/libs';
import HitIcon from 'src/ui/HitIcon';

import { SwitcherIcon } from 'src/assets/icons/system/index';
import { BellIcon } from 'src/assets/icons/system/index';
import { BellActiveIcon } from 'src/assets/icons/system/index';
import { getProfile } from 'src/store/reducers/profile/selectors';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Header(){
  const [ appSwitcherOpen, setAppSwitcherOpen ] = useState(false);
  const { t } = useTranslation();
  const notifications = useSelector(newNotifications);
  const { data: profile } = useSelector(getProfile);

  const openAppSwitcher = () => setAppSwitcherOpen(true);
  const closeAppSwitcher = () => setAppSwitcherOpen(false);

  return <>
    <HeaderContainer>
      <HitIcon onClick={openAppSwitcher}><SwitcherIcon/></HitIcon>

      <Center>
        <Avatar image={profile.avatarUrl!} active bgColor="var(--bg-400)" />
        <Label>{t("chronos.app.title")}</Label>
      </Center>

      <HitIcon>{notifications.length > 0 ? <BellActiveIcon /> : <BellIcon/>}</HitIcon>
    </HeaderContainer>

    <AppSwitcherContainer open={appSwitcherOpen}><AppSwitcher onClose={closeAppSwitcher} /></AppSwitcherContainer>
  </>
}

export const HeaderContainer = styled.header`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  margin-bottom: 0.75rem;

  svg {
    color: var(--text-secondary);
  }
`

const Center = styled.div`
  padding-left: 0.75rem;
`

export const Label = styled.span`
  margin-left: 0.875rem;
  font-size: 1.125rem;
`

const AppSwitcherContainer = styled.div<{ open?:boolean}>`
  position: fixed;
  top: 0;
  left: ${p => p.open ? '0' : '-100vw'};
  transition: left 0.3s;
  z-index: 1000;

`