// Переключатель двойных-тройных карт

export type TPrognosticsKey = 'prog_prog' | 'directions' | 'solars' | 'transits' | string;
export type TPartnerKey = 'partner1' | 'partner2' | 'partner3' | 'partner4' | 'partner5' | 'partner6' | 'partner7' | 'partner8' | 'partner9' | string;
export type TCompatibilityKey = 'compatibility1' | 'compatibility2' | 'compatibility3' | 'compatibility4' | 'compatibility5' | 'compatibility6' | 'compatibility7' | 'compatibility8' | 'compatibility9' | string;

export interface IPrognosticsMapsState { [key: TPrognosticsKey]: boolean };
export interface ISynastryMapsState { [key: TCompatibilityKey]: boolean };

export enum ECompatibilityTransitMode {
  MODE_1 = 1,
  MODE_2 = 2,
}


export enum EInitSynastryTransitsCommand {
  ADD = 1,
  DELETE = 2,
  NONE = 0,
}

export interface ICompatibilityTransits {
  [key: TCompatibilityKey]: { showTransit: boolean; transitMode: ECompatibilityTransitMode };
  
}

export interface IMapIndicatorState {
  compatibilityReversed: boolean;
  prognosticsExt: IPrognosticsMapsState;
  prognosticsNatal: IPrognosticsMapsState;

  partnerExt: ISynastryMapsState;
  partnerNatal: ISynastryMapsState;

  compatibilityTransits: ICompatibilityTransits

  initSynastryTransits: EInitSynastryTransitsCommand; // 1 - add syn_prognostic, 2 - delete syn_prognostic | 0 - no action
}

export const prognosticsMapIndicatorsDefault = (): IPrognosticsMapsState => ({ 'directions': true, 'solars': true, 'transits': true, 'prog_natal': true });
export const synastryMapIndicatorsDefault = (): ISynastryMapsState => ({
  'partner1': true,
  'partner2': true,
  'partner3': true,
  'partner4': true,
  'partner5': true,
  'partner6': true,
  'partner7': true,
  'partner8': true,
  'partner9': true,
  'compatibility1': true,
  'compatibility2': true,
  'compatibility3': true,
  'compatibility4': true,
  'compatibility5': true,
  'compatibility6': true,
  'compatibility7': true,
  'compatibility8': true,
  'compatibility9': true,
});
