import React, { useState } from 'react';
import styled from 'styled-components';

import Tariff from './Tariff';

import { RowsIcon } from 'src/assets/icons/system/index';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import TariffsCompare from './TariffsCompare';
import { useSelector } from 'src/store/utils';
import {
  getFreeTariffId,
  getFreeTariffPeriodInfo,
  getShortTariffs,
  getUserTariffId,
} from 'src/store/reducers/payments/selectors';
import { useTranslation } from 'src/i18n/useTranslation';
import { useCurrency } from 'src/hooks';

export default function Tariffs({
   setOpenChangeTariffPopup
}: {
  setOpenChangeTariffPopup(value: boolean): void
}) {
  const [openCompareTariffs, setOpenCompareTariffs] = useState(false);

  const userTariffId = useSelector(getUserTariffId);
  const shortTariffs = useSelector(getShortTariffs);
	const region = useCurrency()
	const filteredShortTariffs = shortTariffs.filter(tariff => region.currencyCode === tariff.currencyCode)
  const freeTariffId = useSelector(getFreeTariffId);
  const freeTariffPeriodInfo = useSelector(getFreeTariffPeriodInfo);

  const { t } = useTranslation();


  return <Container>
    <Title>{t("chronos.mobile.settings.payments.tariffs")}</Title>
    { filteredShortTariffs.map(item => (
        <Tariff
          key={item.id}
          activePayType={1}
          data={item}
          active={userTariffId === item.id}
          free={freeTariffId === item.id}
          freeText={`${freeTariffPeriodInfo}, ${t("chronos.app.settings.then")} ${item.sum}₽`}
          onClick={() => {
            setOpenChangeTariffPopup(true)
          }}
					currency={region.symbol}
        />
    ))}

    <CompareLink onClick={() => setOpenCompareTariffs(true)}>
      {t("chronos.app.settings.tariffs.compareTariffs")} <RowsIcon />
    </CompareLink>

    <PopupBottom open={openCompareTariffs} onClose={() => setOpenCompareTariffs(false)} title={t("chronos.mobile.settings.payments.tariffs.tariffComparison")} back={true}>
      <TariffsCompare onClose={() => { setOpenCompareTariffs(false) }} />
    </PopupBottom>
  </Container>
}

const Container = styled.div`
  margin-top: 2rem;
  // padding-bottom: 1rem;
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: 1.3125rem;
`

const CompareLink = styled.a`
  display: block;
  text-align: center;
  color: var(--text-secondary);
  margin-top: 1.625rem;
  font-size: 1rem;
  line-height: 1.1875rem;

  > svg {
    color: var(--text-secondary);
    margin-left: 0.5rem;
    display: inline-block;
    vertical-align: middle;
  }
`
