import { cloneDeep } from 'lodash';
import { ICourse, ICourseInfo } from "src/libs"

export function updateCourse(course: ICourseInfo) {
  const course_ = cloneDeep(course);
  course_.plans = course_.plans.filter(item => item.auditory > 0).sort((a, b) => a.price > b.price ? 1 : -1);
  course_.plans.forEach(item => { if(!item.color) item.color = 'var(--accent-blue)'})
  return course_;
}

export function getPlanId(course: ICourse) {
    return (course.isActive || undefined) && course.planId;
    //return course.planId;
}