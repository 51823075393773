import { IShortFormData } from 'src/api';
import { t } from 'i18next';
import { FORMS_GET_LOADING, FORMS_GET_SUCCESS } from './actions';
import { IActionForms, IFormsState } from './types';

const initialState: IFormsState = {
  loading: false,
  loaded: false,
  data: []
};

//<IFormsState>
export default function forms(state = initialState, action: IActionForms){
  switch(action.type){
    case FORMS_GET_LOADING:
      return {
        loading: true,
        loaded: false,
        data: state.data
      };
    case FORMS_GET_SUCCESS:
      const forms = action.payload.filter((item: IShortFormData) => item.name !== 'chronos.app.tmpMap');
      return {
        loading: false,
        loaded: true,
        data: forms
      };
    default:
      return state;
  }
}
