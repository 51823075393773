import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {ICourseInfo, ICourseLesson} from 'src/libs';

import LessonWebinar from './components/LessonWebinar';
import LessonContent from './components/LessonContent';
import dayjs from 'dayjs';
import Header from './components/Header';
import { useNavigate, useParams} from 'react-router-dom';
import {getPlanId} from './utils';
import {getCourse} from "../../../store/reducers/courses/actions";
import {setLastLessonLC} from "../../../store/localStorage/courses";
import {useSelector} from "../../../store/utils";
import {getAllCourses} from "../../../store/reducers/courses/selectors";
import {useDispatch} from "react-redux";
import { useTranslation } from 'src/i18n/useTranslation';

export default function CourseLesson() {
  const [course, setCourse] = useState<ICourseInfo | null>(null);
  const [lesson, setLesson] = useState<ICourseLesson | null>(null);
  const {courses: courses} = useSelector(getAllCourses)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { courseId, lessonId } = useParams<{
    courseId: string;
    lessonId: string;
  }>();

  useEffect(() => {
    const selectedCourse = courses.find(course => course.course.id === Number(courseId))
    if (selectedCourse) {
      const selectedLesson = selectedCourse.lessons.find((item: ICourseLesson) => item.id === Number(lessonId)) || null
      if (getPlanId(selectedCourse.course)) {
        setCourse(selectedCourse)
        selectedLesson !== null ? setLesson(selectedLesson) : navigate(`/courses/${courseId}/lessons`)
      } else {
        navigate(`/courses/${courseId}/plans`)
      }
    } else {
      dispatch(getCourse(Number(courseId)))
    }
    setLastLessonLC(lessonId!)
  }, [courses])

  if(!course || !lesson) return null;
  
  const isFuture = dayjs().add(10, 'minutes') < dayjs(lesson.start);

  const currentPlan = course.plans.find(plan => plan.id === course.course.planId);
  const neededPlan = course.plans.find(plan => plan.id === lesson.planId)!;
  const isByued = lesson.planId === course.course.planId || currentPlan?.parents.includes(lesson.planId);
  
  const backToLessons = () => {
    navigate(`/courses/${course.course.id}/lessons`)
  }

  const index = (course.lessons.findIndex(l => lesson.id === l.id) || 0) + 1;
  return <div>
    <Header buttonType="back" buttonHandler={backToLessons} title={''}  />
    <div>
      <Container>
        <LessonWebinar lesson={lesson} neededPlan={neededPlan} isByued={isByued} isFuture={isFuture} course={course} />
        <Content className="hideOnWebinar">
          <Title>{lesson.title}</Title>
          <Subtitle>{t("chronos.mobile.lesson")} {index}</Subtitle>
          <Description>{lesson.description}</Description>
          <LessonContent lesson={lesson} neededPlan={neededPlan} isByued={isByued} isFuture={isFuture} />
        </Content>
      </Container>
    </div>
    </div>
  ;
}

const Container = styled.div`
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-top: -4rem;
  padding-bottom: 2rem;
`

const Content = styled.div`
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1.125rem 1rem;
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--text-primary);
  margin-bottom: 0.375rem;
`

const Subtitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
`

const Description = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  width: 50%;
`