import React from 'react';
import styled from 'styled-components';

import { PencilIcon } from 'src/assets/icons/system/index';

import { PinIcon } from 'src/assets/icons/system/index';
import { ThemeIcon } from 'src/assets/icons/system/index';
import { DeleteIcon } from 'src/assets/icons/system/index';

import LineHorizontal from 'src/ui/LineHorizontal';
import LabelsWithIcon from 'src/ui/LabelsWithIcon';
import { IFolder } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';

export default function FolderOptionsForPopup({
  folder,
  actions
}: {
  folder: IFolder,
  actions: any
}) {

  const { t } = useTranslation();

  return <>
    <Block>
      <Header>
        <Name>{folder.title}</Name>
      </Header>

      <LineHorizontal />
    </Block>
    
    <Block>
      <LabelsWithIcon icon={<PinIcon />} short={folder.is_pinned ? t("base.unpin") : t("base.pin")} action={() => actions.togglePin(folder)} />
      
      <LabelsWithIcon icon={<ThemeIcon />} short={t("chronos.app.dashboard.color")} arrow={true} action={actions.openColorPopup} />
      <LabelsWithIcon icon={<PencilIcon />} short={t("chronos.app.components.editForm.name")} arrow={true} action={actions.openEditPopup} />
      
      <LineHorizontal />
    </Block>

    <Block>
      <LabelsWithIcon icon={<DeleteIcon />} short={t("chronos.app.dashboard.removeGroup")} color="var(--color-red)" action={actions.openDeletePopup} />
    </Block>

  </>
}

const Header = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.75rem;
  align-items: center;
  margin-bottom: 1rem;
`

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.5rem;
  line-height: 1em;
`

const Block = styled.div``