import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

//import api from '~api';

import { NotificationContext } from 'src/store/context/notifications';

import { IFormData, IShortFormData, Input, Button, Switcher } from 'src/libs';

import { updateForm } from 'src/store/reducers/forms/actions';

import { TopTriangleIcon } from "src/assets/icons/arrows/index";
import { GlobalIcon } from "src/assets/icons/system/index";
import { PrivateIcon } from "src/assets/icons/system/index";
import { DeniedIcon } from "src/assets/icons/system/index";
import { OkIcon } from "src/assets/icons/system/index";

import { TelegramIcon } from "src/assets/icons/social/index";
import { WhatsAppIcon } from "src/assets/icons/social/index";
import { VkontakteIcon } from "src/assets/icons/social/index";
import { FacebookIcon } from "src/assets/icons/social/index";
import { TwitterIcon } from "src/assets/icons/social/index";

import { useDispatch } from 'react-redux';
import { getForm } from 'src/helpers/api';
import { useTranslation } from 'src/i18n/useTranslation';
import dayjs from 'dayjs'

const PrivateIconColor = styled(DeniedIcon)`color: #EF5350;`;
const PublicIconColor = styled(GlobalIcon)`color: #73B330;`;
const ProtectedIconColor = styled(PrivateIcon)`color: #F59300;`;

interface IAccessType {
    id: string;
    icon: React.ReactNode;
    title: string;
    description: string;
}

const accessTypes: IAccessType[] = [
    { id: 'public', icon: <PublicIconColor />, title: "chronos.app.components.shareForm.public.title", description: "chronos.app.components.shareForm.public.description" },
    { id: 'protected', icon: <ProtectedIconColor />, title: "chronos.app.components.shareForm.protected.title", description: "chronos.app.components.shareForm.protected.description" },
    { id: 'private', icon: <PrivateIconColor />, title: "chronos.app.components.shareForm.private.title", description: "chronos.app.components.shareForm.private.description" }
];

export default function ShareForm(props: {
    form: IShortFormData | IFormData;
    center?: boolean;
    onUpdate(form: IShortFormData | IFormData): void;
    isOpen?: boolean;
}) {
    const { t } = useTranslation();
    const context = useContext(NotificationContext);
    const dispatch = useDispatch();

    const [accessWindowShow, setAccesWindowShow] = React.useState(false);
    const [accessType, setAccessType] = React.useState(accessTypes.find(t => t.id === props.form.access?.type)!);
    const [showPersonalData, setShowPersonalData] = React.useState(props.form.access?.showPersonalData);
    const [copiedToClipboard, setCopiedToClipboard] = React.useState(false);

    const accessRef = React.useRef<HTMLDivElement>(null);

    const link = `${process.env.REACT_APP_BACKEND}/api/share/${props.form.id}?t=${Date.now()}`;

    React.useLayoutEffect(() => {
        
        // если форма скрыта или в пропсах уже есть form.access, то ничего не делаем
        if (!props.isOpen || props.form.access) return;

        
        // получаем access для формы, если пришла IShortFormData
        const initForm = async () => {
            try {
                const form = await getForm(props.form.id);
                setAccessType(accessTypes.find(t => t.id === form.access?.type)!);
                setShowPersonalData(form.access?.showPersonalData);
            } catch (err) {
                console.log('ShareForm getForm error - ', err);
            }
        }

        initForm()

    }, [props.isOpen])


    const toggleAccessWindow = () => setAccesWindowShow(!accessWindowShow);

    const update = (access: IAccessType, showPersonalData: boolean) => {
        getForm(props.form.id).then((form: any) => {
            // console.log(`*-------- ShareForm getForm -`, form.id);
            const newForm = {
                ...form,
                access: {
                    ...form.access,
                    type: access.id,
                    showPersonalData,
                },
                // folderId: access.id === "private" ? form.folderId : null,
            }
            
            dispatch(updateForm(newForm, () => {
                props.onUpdate(newForm);
            }));
        })
        
        
    };

    const changeAccessType = (type: IAccessType) => {
        setAccessType(type);
        setAccesWindowShow(false);
        update(type, showPersonalData);
    };

    const changeShowDateTime = (showPersonalData: boolean) => {
        setShowPersonalData(showPersonalData);
        update(accessType, showPersonalData);
    };

    const copyToClipboard = () => {
        navigator.clipboard?.writeText(link)
            .then(() => {
                context?.add(t("chronos.mobile.shareForm.linkCopied"));
                setCopiedToClipboard(true);
                setTimeout(() => setCopiedToClipboard(false), 3000);
            })
            .catch(err => {
                context?.add(t("chronos.mobile.shareForm.clipboard"));
            });
    };

    const socURL = encodeURIComponent(link);
    const date = dayjs((props.form as any).dateTime).format('DD.MM.YYYY, HH:mm:ss');
    const socText = encodeURIComponent(`${props.form.name}${props.form?.access?.showPersonalData ? `, ${date}, ${(props.form as any).place}` : ""}`);


    if (!accessType) return null;

    return <Container>
        <ChooseAccess onClick={toggleAccessWindow}>
            <GlobalIconContainer>
                {accessType.icon}
            </GlobalIconContainer>
            <ChooseAcessTitle>
                <Title>{t(accessType.title)}</Title>
                <SubTitle>{t(accessType.description)}</SubTitle>
            </ChooseAcessTitle>
            <ShevronIconContainer opened={accessWindowShow}>
                <TopTriangleIcon />
            </ShevronIconContainer>
        </ChooseAccess>

        {accessWindowShow && <ChooseAccessHiddenBlock ref={accessRef}>
                {accessTypes.map(item =>
                    <ChooseAccessBlock
                        key={item.id}
                        onClick={() => changeAccessType(item)}
                    >
                        {item.icon}
                        <div>
                            <Title>{t(item.title)}</Title>
                            <SubTitle>{t(item.description)}</SubTitle>
                        </div>
                    </ChooseAccessBlock>
                )}
            </ChooseAccessHiddenBlock>
        }

        {accessType.id !== 'private' && <>
                <CircleButtons>
                    <CircleButton color="#2AABEE" target="_blank" href={`https://t.me/share/url?url=${socURL}&text=${socText}`}><TelegramIcon /></CircleButton>
                    <CircleButton color="#25D366" target="_blank" href={`https://wa.me/?text=${socURL + " " + socText}`}><WhatsAppIcon /></CircleButton>
                    <CircleButton color="#4680C2" target="_blank" href={`https://vk.com/share.php?url=${socURL}&title=${socText}&noparse=true`}><VkontakteIcon /></CircleButton>
                    <CircleButton color="#1877F2" target="_blank" href={`https://www.fb.com/sharer/sharer.php?p[url]=${socURL}&p[title]=${socText}`}><FacebookIcon /></CircleButton>
                    <CircleButton color="#1DA1F2" target="_blank" href={`https://twitter.com/share?url=${socURL}&text=${socText}`}><TwitterIcon /></CircleButton>
                </CircleButtons>

                <LinkBlock copied={copiedToClipboard}>
                    <Input size='big' onChange={() => { }} value={link} />
                    {!copiedToClipboard && <Button onClick={copyToClipboard}>{t("chronos.app.components.shareForm.copy")}</Button>}
                    {copiedToClipboard && <Button onClick={copyToClipboard}><OkIconSized /></Button>}
                </LinkBlock>

                <DateTimeContainer>
                    <span>{t("chronos.app.components.shareForm.showDateTimePlace")}</span>
                    <Switcher checked={showPersonalData} onChange={changeShowDateTime} />
                </DateTimeContainer>
            </>
        }

        {/* <DownloadContainer>
                <Download>
                    <PageIcon />
                    <div>
                        <Title>Скачать как PDF</Title>
                        <SubTitle>Доступно всем, кому вы скинете ссылку</SubTitle>
                    </div>
                </Download>
                <Download>
                    <PlanIcon />
                    <div>
                        <Title>Сохранить план развития</Title>
                        <SubTitle>Доступно всем, кому вы скинете ссылку</SubTitle>
                    </div>
                </Download>
            </DownloadContainer> */}
    </Container>

}


const Container = styled.div`
    min-height: 18.5rem;
`


const DateTimeContainer = styled.div`
    margin-bottom: 1.375rem;
    font-size: 0.875rem;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const LinkBlock = styled.div<{ copied: boolean }>`
    display: grid;
    grid-template-columns: 1fr 7.5rem;
    grid-gap: 0.675rem;
    margin-bottom: 1.125rem;

    & > input {
        padding: 0.5rem 0.375rem 0.55rem 0.375rem;
    }

    & button {
        font-size: 0.875rem;
        padding: 0.5rem 0 0.55rem 0;

        ${props => props.copied && css`
            color: var(--color-green);
            border-color: var(--color-green);
            background: rgba(115, 178, 48, 0.08);
            padding: 0.55rem 0 0.5rem 0;
        `}

        & > svg {
            display: inline-block;
        }
    }
`

const OkIconSized = styled(OkIcon)`
    height: 1rem;
    width: auto;
`

const ChooseAccessHiddenBlock = styled.div`
    position: absolute;
    width: calc(100% - 2.5rem);
    background-color: var(--bg-share);
    box-shadow: 0px 0.6px 1.8px 0px #000000 11%;
    box-shadow: 0px 3.2px 7.2px 0px #000000 13%;
    border-radius: 6px;
    z-index: 100;
    box-sizing: border-box;
    padding: 1.36rem 1.125rem 1.56rem 1.125rem;
    display: grid;
    grid-row-gap: 2.25rem;
`

const ChooseAccessBlock = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    position: relative;
    grid-column-gap: 1.125rem;

    & > svg {
        width: 1.5rem;
    }

    &:hover::before {
        cursor: pointer;
        content: '';
        position: absolute;
        width: calc(100% + 1.5rem);
        height: calc(100% + 1.7rem);
        top: -0.75rem;
        left: -0.75rem;
        background-color: var(--bg-share-hover);
        border: 1px solid var(--bg-share-hover);
        border-radius: 4px;
        box-sizing: border-box;
    }
`

const ChooseAcessTitle = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Title = styled.div`
    font-size: 1rem;
    color: var(--text-primary);
    margin-bottom: 0.325rem;
`

const SubTitle = styled.div`
    font-size: 0.75rem;
    color: var(--text-secondary);
`

const ChooseAccess = styled.div`
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 1.125rem;
    margin-bottom: 1.375rem;
    
    &:hover div{
        color: var(--text-primary) !important;
    }
`

const GlobalIconContainer = styled.div`
    line-height: 0px;
    & > svg {
        width: 1.5rem;
        background-color: var(--bg-share-hover);
        padding: 0.56rem;
        border: 1px solid var(--bg-share-hover);
        border-radius: 6px;
        box-sizing: content-box;
    }
`

const ShevronIconContainer = styled.div<{ opened: boolean }>`
    display: flex;
    align-items: center;
    & > svg {
        width: 1.5rem;
        color: var(--icon-secondary);
        transition: transform 0.2s;
        transform: rotate(${props => props.opened ? '180' : '0'}deg);
    }
`


const CircleButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
`

const CircleButton = styled.a<{ color: string }>`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    background: ${props => props.color};
    border-radius: 50%;
    opacity: 1;
    cursor: pointer;
    color: var(--color-white);

    & > svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--color-white);
    }

    &:hover {
        opacity: 0.6;
    }
`