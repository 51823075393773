import { IActionCourses, ICoursesState } from "./types";
import { COURSES_SET_COURSE } from "./actions";

const initialState: ICoursesState = {
    courses: [],
}

export default function courses(state = initialState, action: IActionCourses) {
    switch (action.type) {
        case COURSES_SET_COURSE:
            return {
                courses: [...state.courses, action.course],
            }
        default:
            return state;
    }
}
