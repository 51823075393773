import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import api from 'src/api';
import i18n from 'src/i18n/i18n';
import { useTranslation } from 'src/i18n/useTranslation';
import InterpretationList from './InterpretationList';
import { IWidgetData } from '../data';

import { objectsIcons } from 'src/helpers/icons';

import { Loader } from 'src/libs';
import { LoaderContainer } from '../AutoPrediction/AutoPredictionScreen';

export const housesIcons = ['I','II','III','IV','V','VI','VII','VIII','IX','X','XI','XII'];


interface IInterpritationPhaseItem {
  id: string;
  isActive: boolean;
  name: string;
  text: string;
}

interface IInterpritation extends Array<IInterpritationItems>{}

interface IInterpritationItems extends Array<IInterpritationItem>{}

interface IInterpritationItem extends Array<IInterpritationObject>{}
interface IInterpritationObject {
  id: string;
  title: string;
  icon?: string;
  items?: string[];
  houses?: IInterpritationItem;
  phases?: IInterpritationPhaseItem[];
}

export default function Interpretation(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
  openedWidget: boolean;
}) {
  const { t } = useTranslation();
  const [interpretation, setInterpretation] = useState<null | IInterpritation>(null);

  React.useEffect(() => {
    if (!props.openedWidget) return;

    fetchInterpretation();
  }, [props.openedWidget]);

  const fetchInterpretation = async () => {
    try {
      const { data } = await api.interpretation(props.data.maps, props.data.form, i18n.language);
      if(data?.length) {
        setInterpretation(data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  if(!interpretation?.length) {
    return <LoaderContainer><Loader /></LoaderContainer>;
  }

  return (
    <Container>
      { interpretation.map((interpretationItems, i: number) => (
          <React.Fragment key={`interpretationItems_${i}`}>
            { interpretationItems?.length
                ? interpretationItems.map((interpretationItem, j: number) => (
                    <React.Fragment key={`interpretationItem_${i}_${j}`}>
                      {
                        interpretationItem.map(({id, title, icon, items, houses, phases}, idx: number) => {
                          const Icon = icon && objectsIcons[+icon]
                            ? objectsIcons[+icon]
                            : () => null;

                          const housesEl = houses?.length
                            ? houses.map(house => (
                                <InterpretationList
                                  key={`house_${i}_${j}_${house.id}`}
                                  icon={<Icon />}
                                  title={house.title}
                                  items={house.items || []}
                                />
                              ))
                            : null;

                          const itemsEl = <InterpretationList
                            key={`list_${id}`}
                            icon={<Icon />}
                            title={title}
                            items={items || []}
                          />;

                          const phasesEl = phases?.length
                            ? phases.map((p) => (
                                <MoonPhase isActive={p.isActive} key={`moon_phase_${i}_${j}_${p.id}`}>
                                  <MoonPhaseTitle>{t(p.name)}</MoonPhaseTitle>
                                  <MoonPhaseText>{t(p.text)}</MoonPhaseText>
                                </MoonPhase>
                              ))
                            : null;

                          return (
                            <React.Fragment key={`group_${i}_${j}_${(+new Date).toString(16 + idx)}`}>
                              { id === 'prog'
                                  ? [itemsEl, housesEl, phasesEl]
                                  : [housesEl, itemsEl, phasesEl]
                              }
                            </React.Fragment>
                          );
                        })
                      }
                    </React.Fragment>)
                  )
                : null
              }
            </React.Fragment>
          )
        )
      }
    </Container>
  );
};

const Container = styled.div`
  display: block;
  white-space: initial;
  color: var(--text-secondary);
  margin-top: 0.5rem;
  width: 100%;
  overflow: hidden;

  ul {
    list-style: none;
    padding-left: 2rem;
  }
`

const MoonPhase = styled.div<{ isActive: boolean }>`
  margin-bottom: 1em;
  
  ${props => !props.isActive && css`
    opacity: 0.5;
  `}
`;

const MoonPhaseTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 1em;
`;

const MoonPhaseText = styled.div`
  white-space: pre-line;
`;