import React from 'react';

import LabelsWithIcon, { ILabelsWithIcon } from 'src/ui/LabelsWithIcon';

interface IAddForPopupProps {
  buttons: ILabelsWithIcon[];
}
export default function MenuForPopup(props: IAddForPopupProps){

  return <>
    {props.buttons.map((item, i) => <LabelsWithIcon key={i} {...item} />)}
  </>
}