import { Dispatch } from "redux";
import api from "../../../api";
import { updateCourse } from "../../../app/pages/courses/utils";
import { ICourseInfo } from "src/libs";
import { IActionCourses } from "./types";

export const COURSES_SET_COURSE = 'COURSES_SET_COURSE';

const setCourse = (course: ICourseInfo): IActionCourses => {
    return {
        type: COURSES_SET_COURSE,
        course,
    }
}

export const getCourse = (id: number) => {
    return (dispatch: Dispatch) => {
        api.getCourse(id)
            .then((data: ICourseInfo) => {
                const course = updateCourse(data)
                dispatch(setCourse(course))
            })
            .catch(error => console.log(`Courses Error: ${error}`))
    }
}

