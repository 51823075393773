import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

export default class Icon extends React.Component<{
  icon?: any;
  color?: string;
  dot?: boolean;
  text?: string;
}> {
  private readonly _ref = React.createRef<HTMLDivElement>();

  setIcon(icon: any, color: string) {
    const ref = this._ref.current as HTMLDivElement;
    if (icon) {
      //const Icon = <div style={{fill: color || 'var(--text-third)', color: color || 'var(--text-third)'}}>{icon}</div>;
      //ReactDOM.render(Icon, ref);
      const Icon = icon;
      ReactDOM.render(<Icon fill={color || 'var(--icon-third)'} />, ref);
    } else {
      const Null = () => null;
      ReactDOM.render(<Null />, ref);
    }

  }

  render() {
    const { icon, text, dot } = this.props;
    const I = icon;

    return (
      <IconWrap>
        <span>{text}</span>
        <span ref={this._ref}>{icon && <I/>}</span>
        {dot && <span>&bull;</span>}
      </IconWrap>
    );
  }
}

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-third);
  width: 2em;
  height: 2em;

  svg {
    width: 1em;
    pointer-events: none;
  }
`;
