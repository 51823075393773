import React, { useState } from 'react';
import styled from 'styled-components';

import HitIcon from 'src/ui/HitIcon';
import { ArrowLeftIcon } from 'src/assets/icons/arrows/index';
import { TopTriangleIcon } from 'src/assets/icons/arrows/index';
import { Avatar } from 'src/libs';
import { DropDown } from 'src/ui/DropDown';
import SimpleList, { ISimpleListItem } from 'src/ui/DropDown/simpleList';
import { useSelector } from 'src/store/utils';
import { allSettings, allSettingsAstro, getActiveAstroProfile, getCurrentCustomization } from 'src/store/reducers/settings/selectors';
import { useDispatch } from 'react-redux';
import { getSettings, updateSettings, getSettingsSuccess, updateAstroSettings } from 'src/store/actions/settings';
import { getProfile as getProfileLS } from 'src/store/localStorage/profile';
import { Link } from 'react-router-dom';
import { levelNames } from 'src/store/reducers/profile/types';
import { getProfile } from 'src/store/reducers/profile/selectors';
import { useTranslation } from 'src/i18n/useTranslation';
import { IAstroSettings } from '@chronos/astro-lib';

export default function Header() {
  const [showProfilesDropDown, setShowProfilesDropDown] = useState(false);
  
  const { data: currentSettings } = useSelector(allSettings);
  const currentCustomization = useSelector(getCurrentCustomization);
  const activeAstroProfile = useSelector(getActiveAstroProfile);
  const { data: astroProfiles } = useSelector(allSettingsAstro);
  const { data: profile } = useSelector(getProfile);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const astroProfilesItems: ISimpleListItem[] = astroProfiles.map((item: any) => ({
    id: item.id,
    icon: undefined,
    label: item.title,
    action: async () => {
      setShowProfilesDropDown(false);

      // В соответствующем astro профиле будем брать idCustomization
      const selectedAstroProfile = astroProfiles.find((p: IAstroSettings) => p.id === item.id);
      const isFirstMayProfileSelect = item.id == -1 && selectedAstroProfile.id == -1; 
      
      // вычисляем кастомизацию карты для выбранного профиля
      let updatedCustomizationId = currentSettings.syncMaps 
        ? (selectedAstroProfile?.idCustomization ?? currentCustomization.id)
        : currentCustomization.id;
      
      // то, что будет обновлено
      const newSettingsData = { activeProfileId: item.id, activeCustomizationId: updatedCustomizationId }

      if (isFirstMayProfileSelect) {
        
        selectedAstroProfile.idCustomization = 3; // вновь создаваемому Моему профилю назначаем карту ZET //TODO
        dispatch(updateAstroSettings(selectedAstroProfile, (result: IAstroSettings) => {
          newSettingsData.activeProfileId = result.id
          newSettingsData.activeCustomizationId = result.idCustomization;
          dispatch(updateSettings(newSettingsData));
        }))
      } else {
        dispatch(updateSettings(newSettingsData));
      }
    }
  }));

  //const currentProfile = astroProfiles.find((item: any) => +item.id === +settings.activeProfileId);
  
  // КОНЕЦ - Код вырезан с основного интерфейса 

  return <Container>
    <ArrowHeader>
      <Link to="/"><HitIcon><ArrowLeftIcon /></HitIcon></Link>
    </ArrowHeader>
    <AboutContent>
      <Avatar size={80} bgColor="var(--bg-element-neutral)" image={profile.avatarUrl!} />
      <Title>{profile.firstName}</Title>
      <Level>{t(levelNames[profile.levelOfAstrology!])}</Level>
    </AboutContent>
    <SelectButton onClick={() => setShowProfilesDropDown(!showProfilesDropDown)}>
      {/*<ChronIcon />*/}
        {t(activeAstroProfile.title)}
      <ArrowTopIcon open={showProfilesDropDown} />
    </SelectButton>
    {showProfilesDropDown && <DropDown><SimpleList items={astroProfilesItems} activeId={activeAstroProfile.id} /></DropDown>}
  </Container>
}

const Container = styled.div`
  color: var(--text-primary);
  padding-bottom: 0.625rem;
  // background-color: var(--bg-0);
`

const ArrowHeader = styled.div`
  color: var(--text-secondary);
`
const AboutContent = styled.div`
  text-align: center;
`
const Title = styled.div`
  margin-top: 0.75rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
`
const Level = styled.div`
  color: var(--text-secondary);
  margin-top: 0.625rem;
  font-size: 0.875rem;
  line-height: 1rem;
`
const SelectButton = styled.div`
  margin-top: 1.125rem;
  padding: 0.625rem;
  font-size: 0.875rem;
  line-height: 1rem;
  background-color: var(--bg-dashboard-cell);
  border: 1px solid var(--bg-element-neutral);
  border-radius: 4px;
  text-align: center;

  > svg{
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-left: 0.375rem;
  }
`
const ArrowTopIcon = styled(TopTriangleIcon)<{open: boolean}>`
  transform: ${p => p.open ? 'scaleY(1)' : 'scaleY(-1)'};
  color: var(--text-third);
`