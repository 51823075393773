import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Input } from 'src/libs';

interface IDashboardInputProps {
  placeholder?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  onChange: (value: string) => void;
  value: string;
}

export default function DashboardInput(props:IDashboardInputProps){
  
  const handleChange = (val: string) => {
    props.onChange(val);
  }

  return <Container>
    <Input size="big" icon={props.iconLeft} rightIcon={props.iconRight} placeholder={props.placeholder} value={props.value} onChange={handleChange} />
  </Container>
}

const Container = styled.div`
  width: 100%;
  position: relative;
`