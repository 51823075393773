import React, { ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'src/i18n/useTranslation';
import styled, { css } from 'styled-components';

export interface ISimpleListItem {
  id?: number;
  icon?: ReactNode;
  label: string;
  action(ev: SyntheticEvent): void;
  color?: string;
}

export default function SimpleList({
  items,
  activeId
}: {
  items: ISimpleListItem[],
  activeId?: number
}){
  const { t } = useTranslation();
  return <div>
    {items.map((item, index: number) => <SimpleListItem key={index} active={activeId===item.id} color={item.color} onClick={(ev) => item.action(ev)}>
      {item.icon}{t(item.label)}
    </SimpleListItem>)}
  </div>
}

export const SimpleListItem = styled.div<{active?: boolean, color?: string}>`
  display: flex;
  height: 2.5rem;
  align-items: center;
  padding: 0 0.5rem;

  font-size: 0.875rem;
  border-radius: 0.375rem;
  ${p => p.active && css`
    background: var(--bg-element-neutral);
  `}

  ${p => p.color && css`
    color: ${_ => p.color};
  `}

  > svg {
    margin-right: 0.375rem;
  }
`