import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import api from 'src/api';
import { MinusIcon, PlusIcon } from 'src/assets/icons/system/index';
import { Policy } from 'src/components/payment/Policy';
import { getUtmLs } from 'src/helpers/localStorage';
import { getIokaLink } from 'src/helpers/payments';
import { useCurrency } from 'src/hooks';
import { useTranslation } from 'src/i18n/useTranslation';
import { Avatar, Button, PaymentCardPicker } from 'src/libs';
import { getPayments, getPaymentsFullInfo } from 'src/store/reducers/payments/actions';
import { getFormationDiscountInfo, getFormationDiscountPromo, getFormationDiscountValue, getFreeFormationPromo, getFreeFormationsValue, paymentsFullInfo } from 'src/store/reducers/payments/selectors';
import { getProfile } from 'src/store/reducers/profile/selectors';
import { useSelector } from 'src/store/utils';
import LineHorizontal from 'src/ui/LineHorizontal';
import ResultPopup from 'src/ui/Popups/ResultPopup';
import { getCurrencySymbol } from 'src/utils';
import { IPaymentCardMethod, initPaymentMethod, paymentMethods } from './helpers';


export default function BuyFormations({
  onClose
}: {
  onClose(): void
}) {
	const region = useCurrency()
  const dispatch = useDispatch();

  const { data: profile } = useSelector(getProfile);
  const info = useSelector(paymentsFullInfo).data!;
  const discountPromo = useSelector(getFormationDiscountPromo);
  const discountPromoValue = useSelector(getFormationDiscountValue);
  const discountPromoText = useSelector(getFormationDiscountInfo);

  const freePromo = useSelector(getFreeFormationPromo);
  const freePromoValue = useSelector(getFreeFormationsValue);

  const [paymentResult, setPaymentResult] = useState<'success'|'failed'>();
	const [paymentMethod, setPaymentMethod] = React.useState<IPaymentCardMethod>(initPaymentMethod(region.region));

  const { t } = useTranslation();

  const counters: any = Object.entries(info.counters.filter(counter => paymentMethod.code === counter.currencyCode));
	const [counterIndex, setCounterIndex] = useState<number>(+counters[0][0]);
  const amount = counters[counterIndex][1].amount;

  const currencySymbol = getCurrencySymbol(paymentMethod.code);

  const price = counters[counterIndex][1].price;
  const resultPrice = Boolean(discountPromo)
    ? price - (price * (discountPromoValue / 100))
      : Boolean(freePromo)
        ? 0
        : price;


  const buttonStart = <Button
      onClick={() => {
        setPaymentResult(undefined);
        window.location.href = '/dashboard';
      }}
      color="var(--color-blue)" key="button_1">{t("chronos.app.settings.payment.selectedFormations.startEnjojing")}
    </Button>;

    const buttonRestart = <Button
        onClick={() => {
          setPaymentResult(undefined);
        }}
        color="var(--color-blue)"
        key="button_2">{t("chronos.app.settings.payment.selectedFormations.repeatPayment")}
      </Button>;

    const buttonReturn = <Button
        onClick={() => {
          setPaymentResult(undefined);
          onClose();
        }}
        color="transparent"
        key="button_3s">{t("chronos.app.settings.payment.selectedFormations.backToSettings")}
      </Button>;

  async function paymentAction() {
    const result = await api.payment({
      type: 'additional',
      counter: amount,
      cart: [],
      country: paymentMethod.country,
      language: localStorage.getItem('i18nextLng'),
      backUrl: getIokaLink('back'),
      failUrl: getIokaLink('fail'),
      successUrl: getIokaLink('success'),
      comment: getUtmLs(),
    }, 'charge');

    if (result.failed) {
			setPaymentResult('failed');
		} else {
			dispatch(getPaymentsFullInfo);
      dispatch(getPayments);
      setPaymentResult('success');
		}
  }


  return <Container>
    <AboutContent>
      <Avatar size={80} bgColor="var(--bg-element-neutral)" image={profile.avatarUrl || undefined} />
      <Title>{t("chronos.app.settings.buildings")}</Title>
      <Text>{t("chronos.app.settings.selectedFormations.selectNumberOfBuilds")}</Text>
    </AboutContent>

    <Counter>
      <Sign onClick={() => setCounterIndex(v => Math.max(v - 1, counters[0][0]))}><MinusIcon /></Sign>
      <span>{amount}</span>
      <Sign onClick={() => setCounterIndex(v => Math.min(v + 1, counters[counters.length - 1][0]))}><PlusIcon /></Sign>
    </Counter>


    <LineHorizontalWrapper><LineHorizontal /></LineHorizontalWrapper>

		<StyledPaymentCardPicker items={paymentMethods}
														 value={paymentMethod}
														 onChange={(value: IPaymentCardMethod) => setPaymentMethod(value)}
														 t={t}
														 showInfo/>

    <SumContainer>
      <SubTitle>{t("chronos.app.settings.payment.selectedFormations.total")}</SubTitle>
      <Price>
        { Boolean(discountPromo)
            ? <>{amount !== 0 ? <><ins>{price}{currencySymbol}</ins>&nbsp;</> : null}{resultPrice}{currencySymbol}</>
            : freePromo
              ? t("chronos.mobile.settings.payments.free", { freeFormationsValue: freePromoValue })
              : <>{t("chronos.mobile.settings.payments.price", { price: price })}{currencySymbol}</>
          }
      </Price>

      {Boolean(discountPromo) &&
        <DiscountContent>
          {discountPromoText}
        </DiscountContent>
      }
    </SumContainer>
    <Button color="var(--color-blue)" onClick={paymentAction} disabled={amount === 0}>{t("chronos.app.payments.goToPayment")}</Button>

    <Policy paymentMethod={paymentMethod} />

    {paymentResult === 'success' && <ResultPopup
      open={true}
      title={t("chronos.app.settings.selectedFormations.paymentWasSuccessful")}
      subtitle={t("chronos.app.settings.selectedFormations.welcome")}
      success={true}
      buttons={[buttonStart]}
    />}

    {paymentResult === 'failed' && <ResultPopup
      open={true}
      title={t("chronos.app.settings.selectedFormations.somethingWentWrong")}
      subtitle={t("chronos.app.settings.selectedFormations.paymentFailed")}
      success={false} buttons={[buttonRestart, buttonReturn]}
    /> }

  </Container>
}

const Container = styled.div`
  padding: 1rem;
  color: var(--text-primary);
  padding-bottom: 0.625rem;
  width: 100%;
  height: 100%;;
  white-space: normal;
`

const AboutContent = styled.div`
  text-align: center;
`

const DiscountContent = styled.div`
  position: relative;
  display: flex;

  font-size: 0.75rem;
`;

const Title = styled.div`
  margin-top: 0.75rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
`
const Text = styled.div`
  color: var(--text-secondary);
  margin-top: 0.625rem;
  font-size: 0.875rem;
  line-height: 1rem;
`

const Counter = styled.div`
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
    display: inline-block;
    font-size: 3rem;
    line-height: 3.25rem;
  }
`

const Sign = styled.a`
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  background-color: var(--input-background);
`


const LineHorizontalWrapper = styled.div`
  margin-top: 1rem;
`

const SumContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const SubTitle = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: var(--text-secondary);
`
const Price = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 0.375rem;
  margin-top: 0.5rem;

  & ins {
    font-size: smaller;
    text-decoration: line-through;
  }
`

const StyledPaymentCardPicker = styled(PaymentCardPicker)`
	.switcher_button {
		font-size: 0.875rem;
		text-align: center;
		height: 3rem;
	}
`
