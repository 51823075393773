import React from 'react';
import styled, { css } from 'styled-components';

import { OkInCircleIcon } from 'src/assets/icons/system/index';
import { useTranslation } from 'src/i18n/useTranslation';

const colors = ['var(--color-blue)', 'var(--color-orange)', 'var(--color-magenta)'];
const getOpacity = (val: number) => {
  if(val === -1) {
    return 0;
  }
  if(val === 0) {
    return 0.3;
  }
  return 1;
}

export default function TariffCompare({
  title = "",
  rows,
  note,
}: {
  title?: string;
  note?: string;
  disabled?: boolean;
  rows?: any;
}) {
  const { t } = useTranslation();

  return <Container>
    <Title>{t(title)}</Title>
    {note && <Sticker>{t(note)}</Sticker>}

    { rows?.map((row: {title: string; note?: string; support: number[]}, idx: number) => (
      <TariffLine key={`line_${idx}`}>
        <Name>{t(row.title)}</Name>
        { row.note && <Sticker>{t(row.note)}</Sticker> }

        <OkCircles>
          { row.support?.map((s: number, i: number) => (
            <OkIcon style={{opacity: getOpacity(s), color: colors[i]}} key={`row_${i}`}/>
          ))}
        </OkCircles>
      </TariffLine>
    ))}
  </Container>
}

const Sticker = styled.div`
  display: inline-block;
  opacity: 0.5;
  vertical-align: middle;
  background-color: var(--bg-popup);
  color: var(--text-secondary);
  border-radius: 3px;
  padding: 0.25rem 0.375rem 0.3rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-left: 0.5rem;
`

const Container = styled.div<{ disabled?: boolean }>`
  > ${Sticker} {
    margin-top: 0.5rem;
  }
`

const Title = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
`

const TariffLine = styled.div<{ disabled?: boolean }>`
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
`

const Name = styled.div`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1rem;
  vertical-align: middle;
`

const OkCircles = styled.div`
  display: inline-block;
  float: right;
`

const OkIcon = styled(OkInCircleIcon) <{ color?: number }>`
  color: var(--color-blue);
  display: inline-block;
  margin-left: 1.5rem;
  width: 1.2rem;

  &:first-child {
    margin-left: 0;
  }

  ${p => p.color && css`
    color: ${colors[p.color]};
  `}
`