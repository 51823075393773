import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IWidgetData } from '../../data';
import { t } from 'i18next';

const yearsWords = [
  t("chronos.app.year.plural"), 
  t("chronos.app.year.plural"), 
  t("chronos.app.year.acc"), 
  t("chronos.app.year.plural"), 
  t("chronos.app.year.plural"), 
  t("chronos.app.year.gen"), 
  t("chronos.app.year.plural"), 
  t("chronos.app.year.plural"), 
  t("chronos.app.year.gen"),
  t("chronos.app.year.plural"), 
  t("chronos.app.year.plural"), 
  t("chronos.app.year.gen")
];

export default function FormulaYears(props: {
  data: IWidgetData;
  openedWidget: boolean;
}) {
  const [baseYear, setBaseYear] = useState(new Date(props.data.form.natal.dt).getFullYear());
  const lifeDot = new Date().getFullYear() - new Date(props.data.form.natal.dt).getFullYear();

  useEffect(() => {
    setBaseYear(new Date(props.data.form.natal.dt).getFullYear());
  }, [props.data.form.natal.dt]);

  const getFirstYearsRows = () => {
    const rows = [];
    for (let step = 1; step <= 5; step++) {
      rows.push(
        <Row key={`row_year_${step}`}>
          <Title>
            {step * 7} {t(yearsWords[step - 1])}
          </Title>
          <Value>
            {step * 7 + baseYear}
          </Value>
        </Row>
      );
    }
    return rows;
  };

  const getSecondYearsRows = () => {
    const rows = [];
    for (let step = 6; step <= 12; step++) {
      rows.push(
        <Row key={`row_year_${step}`}>
          <Title>
            {step * 7} {t(yearsWords[step - 1])}
          </Title>
          <Value>
            {step * 7 + baseYear}
          </Value>
        </Row>
      );
    }
    return rows;
  };

  return (
    <Container>
      <TableColumn>
        <Row>
          <Title>
            {t("chronos.app.instruments.widgets.soul.formulaYears.livePoint")}
          </Title>
          <Value>
            {lifeDot < 0 ? 0 : lifeDot}
          </Value>
        </Row>
        <Row>
          <Title>
            {t("chronos.app.instruments.widgets.soul.formulaYears.birthYear")}
          </Title>
          <Value>
            {baseYear}
          </Value>
        </Row>
        {getFirstYearsRows()}
      </TableColumn>

      <TableColumn>
        {getSecondYearsRows()}
      </TableColumn>
    </Container>
  );
}

const Container = styled.div`
  font-size: 0.8em;
  display: flex;
  width: 100%;
`;

const TableColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  box-sizing: border-box;
  :nth-child(1) {
    padding-right: 1rem;
    border-right: 1px solid var(--element-neutral);
  }
  :nth-child(2) {
    padding-left: 1rem;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.625rem 0;
  justify-content: space-between;
`;

const Title = styled.div`
  color: var(--text-primary);
`;

const Value = styled.div`
  color: var(--text-secondary);
`;
