import React from 'react';
import styled from 'styled-components';

import Sorting from './components/Sorting';

import { PinIcon } from 'src/assets/icons/system/index';
import { FolderSmallIcon } from 'src/assets/icons/system/index';

import { NatalIcon } from 'src/assets/icons/maps/index';
import { SynastryIcon } from 'src/assets/icons/maps/index';
import { PrognosticsIcon } from 'src/assets/icons/maps/index';

import { Avatar, IFolder, IShortFormData } from 'src/libs';
import { IDashboardSort } from 'src/store/localStorage/forms';
import { Link } from 'react-router-dom';
import { IFolderWithCount } from 'src/store/reducers/folders/types';
import { useTranslation } from 'src/i18n/useTranslation';

import {AutoSizer, List, WindowScroller} from 'react-virtualized';

export default function DashboardRows({
  folders,
  items,
  sort,
  onSort,
  onChangeFolder,
}:{
  folders: IFolder[],
  items: IShortFormData[],
  sort: IDashboardSort,
  onSort(data: IDashboardSort): void,
  onChangeFolder(id: number): void
}){
  const { t } = useTranslation();

  const rowCount = folders.length + items.length
  const overscanRowCount = 5
  const rowHeight = 42

  const rowRenderer = ({index, key, style}: any) => {
    const commonArr = [...folders, ...items]

    if (index < folders.length) {
      //@ts-ignore
      const item: IFolder = commonArr[index]
      if (!item) return ''
      return <Row onClick={() => onChangeFolder(item.id)} key={key} style={style}>
        <Icons>
          {item.is_pinned && <PinIcon_ />}
          <Count>{(item as IFolderWithCount).count}</Count>
          <Folder color={item.color}></Folder>
        </Icons>
        <Name>{item.title}</Name>
        <LineVertical />
        <MapsContainer>
          <LineHorizontal />
        </MapsContainer>
      </Row>
    } else {
      //@ts-ignore
      const item: IFormData = commonArr[index]
      const maps = [];
      maps.push(<NatalIcon key="natal" />);
      (item.synastry || item.partners?.length) && maps.push(<SynastryIcon key="synastry" />);
      item.prognostics && maps.push(<PrognosticsIcon key="prognostics" />);

      return <Link to={`/workspace/${item.id}`} key={key} style={style}><Row>
        <Icons>
          {item.isPinned && <PinIcon_/>}
          <Avatar size={24} color={item.color} bgColor="var(--bg-element-neutral)"/>
        </Icons>
        <Name>{t(item.name)}</Name>
        <LineVertical/>
        <MapsContainer>
          {maps.length === 0 && <LineHorizontal/>}
          {maps.map((item, i) => item)}
        </MapsContainer>
      </Row></Link>
    }
  }

  return <Container>
    <Row>
      <Icons><Sorting onSort={onSort} sort={sort} /></Icons>
      <Name></Name>
      <LineVertical />
      <MapsContainer>{t("chronos.app.dashboard.maps")}</MapsContainer>
    </Row>

    <WindowScroller>
      {({height, scrollTop}) => (
          <AutoSizer disableHeight
                     scrollTop={scrollTop}>
            {({width}): any => {
              return <List
                  autoHeight
                  height={height}
                  overscanRowCount={overscanRowCount}
                  rowCount={rowCount}
                  rowHeight={rowHeight}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                  width={width}
              />
            }
            }
          </AutoSizer>
      )}
    </WindowScroller>

  </Container>;
}

const Container = styled.div`
  padding-bottom: 2rem;
`

export const Row = styled.div`
  height: 2.625rem;
  display: grid;
  grid-template-columns: min-content 1fr min-content 4rem;
  align-items: center;

  font-size: 0.875rem;
`

const LineVertical = styled.div`
  width: 1px;
  height: 100%;
  background-color: var(--bg-500);
`

const LineHorizontal = styled.div`
  width: 1.125rem;
  height: 0.125rem;
  background-color: var(--bg-500);
`

const MapsContainer = styled.div`
  display: flex;
  padding: 0 0 0 0.75rem;
  color: var(--text-secondary);

  > svg {
    &:not(:last-child){
      margin-right: 0.5rem;
    }
  }
`

const Icons = styled.div`
  display: flex;
  align-items: center;

  >* {
    margin-right: 0.75rem;
  }
`

const PinIcon_ = styled(PinIcon)`
  color: var(--text-third);
`


const Count = styled.div`
  color: var(--text-secondary);
`
export const Folder = styled(FolderSmallIcon)<{color?: string}>`
  color: ${p => p.color || '#707070'};
  margin-bottom: 0.125rem;
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.75rem;
`
