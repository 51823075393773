import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface IDropDownProps {
  children: ReactNode;
  autoWidth?: boolean;
}

export default function DropDownStatic({
  children,
  autoWidth
}: IDropDownProps) {
  return <Container autoWidth={autoWidth}>
    {children}
  </Container>
}

export function DropDown(props: IDropDownProps) {
  return <ContainerFloat autoWidth={props.autoWidth}><DropDownStatic {...props} /></ContainerFloat>
}

const Container = styled.div<{autoWidth?: boolean}>`
  ${p => !p.autoWidth && css`
    width: 100%;
  `}
  padding: 0.375rem;
  background: var(--bg-100);
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
  border-radius: 0.5rem;
`

const ContainerFloat = styled.div<{autoWidth?: boolean}>`
  position: relative;
  z-index: 10;

  > div{
    position: absolute;
    top: 0.375rem;
    ${p => !p.autoWidth && css`
      width: 100%;
    `}
  }
`