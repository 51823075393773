import React from 'react';
import styled, { css } from 'styled-components';

import { MoreIcon } from 'src/assets/icons/system/index';

import Tooltip from 'src/ui/old/TooltipWrapper';

import EventEdit from './EventEdit';
import { IEvent } from './types';
import { Switcher } from 'src/libs';
import { DropDown } from 'src/ui/DropDown';
import SimpleList from 'src/ui/DropDown/simpleList';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Event(props: {
  index: number;
  event: IEvent;
  allEvents: IEvent[];
  onDelete(): void;
  onSave(ev: IEvent): void;
  onEnabled(v: boolean): void;
  onEdit(v:boolean): void;
  last: boolean;
  failed: boolean;
}) {
  const moreRef = React.useRef(null);

  const { enabled } = props.event;

  const [openedNav, setOpenedNav] = React.useState(false);
  const [edited, setEdited] = React.useState(false);
  const { t } = useTranslation();
  const checkedRulesLength = props.event.rules.reduce((sum, rule) => sum + (rule.checked ? 1 : 0), 0);
  const weightDefault = props.allEvents.find(item => item.id === props.event.id)?.weight || 0;
  const weightDelta = props.event.weight - weightDefault;

  React.useEffect(() => {
    /*const onBoarding = (ev: Event) => {
      const msg = (ev as CustomEvent).detail;
      if (msg.id == 'event-more') {
        showOnboarding({
          target: moreRef.current,
          summary: 'Расширенные настройки',
          description: 'Вы можете внести изменение в событие и настроить<br>правила, которые будут срабатывать нажав на три<br>точки.'
        });
      }
    };*/

    const documentClick = (e: MouseEvent) => {
      setOpenedNav(false);
    };

    document.addEventListener('click', documentClick);

    //props.last && window.addEventListener('onboarding', onBoarding);

    return () => {
      document.removeEventListener('click', documentClick);
      //props.last && window.removeEventListener('onboarding', onBoarding);
    };
  }, []);

  function openNav() {
    setTimeout(() => {
      setOpenedNav(true);
      //onboarding.show && showOnboarding();
    }, 0);
  }

  function onSave(event: IEvent) {
    setEdited(false);
    props.onSave(event);
  }

  if (edited) {
    return <EventEdit
      onClose={() => { props.onEdit(false); setEdited(false) }}
      onSave={(event: IEvent) => { props.onEdit(false); onSave(event) }}
      onDelete={() => { props.onEdit(false); props.onDelete() }}
      allEvents={props.allEvents}
      type="edit"
      event={props.event}
      index={props.index}
      rulesOpened={true}
    />;
  }

  return <Container failed={props.failed}>
    <EventC disabled={!enabled}>
      <Switcher checked={enabled} onChange={() => props.onEnabled(!enabled)} />
      <article>
        <header>
          {t(props.event.name)}
          <WeightContainer>
            <Tooltip text={t("chronos.app.eventRate")}>
              <Weight>{props.event.weight || 0}</Weight>
            </Tooltip>
            {weightDelta > 0 && <WeightDelta green>+{weightDelta}</WeightDelta>}
            {weightDelta < 0 && <WeightDelta red>{weightDelta}</WeightDelta>}
          </WeightContainer>

        </header>
        <i>
          {props.event.date} · {t("chronos.app.enabledRules")}: {checkedRulesLength} {t("base.from")} {props.event.rules.length}
        </i>
      </article>
      <div className="rec-event-more" ref={moreRef} onClick={openNav}><MoreIcon /></div>
      {openedNav &&
        <nav className="rec-event-nav">
          <DropDown><SimpleList items={[
            {id: 1, label: (enabled ? t("base.disable") : t("base.turnOn")), action: () => props.onEnabled(!enabled)},
            { id: 2, label: t("base.edit"), action: () => { props.onEdit(true); setEdited(true) }},
            {id: 3, label: t("chronos.app.delete"), action:() => props.onDelete(), color: 'var(--color-red)'}
          ]} /></DropDown>
        </nav>
      }
    </EventC>
  </Container>;
}

const Container = styled.div<{ failed: boolean }>`
  //padding: 1rem;
  background: var(--rectification-block-background);
  border: 1px solid ${props => props.failed ? '#EF5350' : 'var(--rectification-block-border)'};
  border-radius: 5px;
  margin-bottom: 1rem;
  //color: var(--rectification-block-color);
`;

const EventC = styled.section<{ disabled: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.675rem;
  align-items: top;


  & article {
    & > header {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    & > i {
      font-style: normal;
      font-size: 0.75rem;
      color: var(--text-secondary);
    }

    opacity: ${props => props.disabled ? 0.5 : 1};
  }

  & svg {
    height: 1rem;
    fill: var(--text-third);
    cursor: pointer;

    &:hover {
      fill: var(--text-primary);
    }
  }

  & nav {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 10;
    width: 12rem;
    
  }
`;

const WeightContainer = styled.span`
  white-space: nowrap;
`;

const Weight = styled.span`
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  text-align: center;

  margin-left: 0.3rem;
  border-radius: 50%;
  font-size: 0.725rem;
  background: var(--bg-element-neutral);
  vertical-align: top;
  padding-top: 1px;
`;

const WeightDelta = styled.span<{ red?: boolean; green?: boolean }>`
  margin-left: 0.4rem;
  font-size: 0.725rem;
  vertical-align: middle;

  ${props => props.red && css`
    color: var(--color-red);
  `}

  ${props => props.green && css`
    color: var(--color-green);
  `}

  & > svg {
    display: inline-block;
    width: 0.725rem;
    vertical-align: middle;
    fill: var(--color-green);
    pointer-events: none;

    ${props => props.red && css`
      fill: var(--color-red);
      transform: scaleY(-1);
    `}
  }
`;
